import { useGetReceiverForecastListQuery } from "../../../graphql/generated";
import ForecastingDashboard from "./ForecastingDashboard";

export default function ForecastingDashboardContainer() {
  return (
    <ForecastingDashboard
      onBulkCreate={(goodProfiles) => {}}
      query={useGetReceiverForecastListQuery}
    />
  );
}
