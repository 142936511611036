import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { AlertColor } from "@mui/material";
import { ReactNode } from "react";

export type DialogState = {
  isVisible?: boolean;
  title?: string;
  description?: ReactNode;
  type?: "primary" | "error";
  actions?: Array<{
    type:
      | "inherit"
      | "success"
      | "info"
      | "warning"
      | "error"
      | "accent"
      | "primary"
      | "secondary"
      | "lightPrimary"
      | undefined;
    title: string;
    onClick?: () => void;
  }>;
};

type ToastState = {
  isVisible?: boolean;
  message?: string;
  type?: AlertColor;
};

type AlertState = {
  dialog: DialogState;
  toast: ToastState;
};

const initialState: AlertState = {
  dialog: {
    isVisible: false,
    title: "",
    description: "",
    type: "primary",
    actions: [
      {
        type: "primary",
        title: "",
        onClick: () => {},
      },
    ],
  },
  toast: {
    isVisible: false,
    message: "",
    type: "success",
  },
};

const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    showDialog: (state, action: PayloadAction<AlertState["dialog"]>) => {
      state.dialog = { ...action.payload };
      state.dialog.isVisible = true;
    },
    hideDialog: (state) => {
      state.dialog.isVisible = false;
    },
    showToast: (state, action: PayloadAction<AlertState["toast"]>) => {
      state.toast = { ...action.payload };
      state.toast.isVisible = true;
    },

    hideToast: (state) => {
      state.toast.isVisible = false;
    },
  },
});

export const { showDialog, hideDialog, showToast, hideToast } =
  alertSlice.actions;

export default alertSlice.reducer;
