import {
  useGetDriverListQuery,
  useGetTractorListQuery,
  useGetTrailerListQuery,
} from "../../../graphql/generated";
import AssetLinkingForm, { AssetLinkingFormProps } from "./AssetLinkingForm";

export type AssetLinkingFormContainerProps = Omit<
  AssetLinkingFormProps,
  "drivers" | "trailers" | "tractors"
>;

const AssetLinkingFormContainer = (props: AssetLinkingFormContainerProps) => {
  const driversQuery = useGetDriverListQuery();
  const trailersQuery = useGetTrailerListQuery();
  const tractorsQuery = useGetTractorListQuery();

  return (
    <AssetLinkingForm
      drivers={driversQuery.data?.drivers.data || []}
      trailers={trailersQuery.data?.trailers.data || []}
      tractors={tractorsQuery.data?.tractors.data || []}
      {...props}
      loading={
        props.loading ||
        driversQuery.isLoading ||
        trailersQuery.isLoading ||
        tractorsQuery.isLoading
      }
    />
  );
};

export default AssetLinkingFormContainer;
