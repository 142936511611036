import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import AssetFilters, { AssetFiltersValue } from "./AssetFilters";
import { GetBusinessEntityListQuery } from "../../../graphql/generated";
import { useState } from "react";
import { useTranslation } from "react-i18next";

type AssetFiltersModalProps = {
  open: boolean;
  onClose: () => void;
  filters: AssetFiltersValue;
  domiciles: GetBusinessEntityListQuery["businessEntities"]["data"];
  onChange: (filters: AssetFiltersValue) => void;
};

const AssetFiltersModal = (props: AssetFiltersModalProps) => {
  const [localAssetFilters, setLocalAssetFilters] = useState<AssetFiltersValue>(
    props.filters
  );
  const { t } = useTranslation(["planning", "common"]);

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth="lg">
      <DialogTitle>{t("filterAssets")}</DialogTitle>
      <DialogContent
        sx={{
          minWidth: 600,
        }}
      >
        <AssetFilters
          filters={localAssetFilters}
          domiciles={props.domiciles}
          onChange={setLocalAssetFilters}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={() => {
            setLocalAssetFilters({});
          }}
        >
          {t("common:clear")}
        </Button>
        <Button variant="outlined" onClick={props.onClose}>
          {t("common:cancel")}
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            props.onChange(localAssetFilters);
            props.onClose();
          }}
        >
          {t("common:apply")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssetFiltersModal;
