import EnumSelect from "../../../common/EnumSelect";
import {
  CustomFieldInputComponent,
  CustomFieldValue,
} from "../CustomFieldsForm";

enum BooleanSelectValue {
  YES = "YES",
  NO = "NO",
  UNSET = "UNSET",
}

const booleanSelectValueToBoolean = (value: BooleanSelectValue) => {
  switch (value) {
    case BooleanSelectValue.YES:
      return true;
    case BooleanSelectValue.NO:
      return false;
    case BooleanSelectValue.UNSET:
      return null;
  }
};

const customFieldValueToBooleanSelectValue = (value: CustomFieldValue) => {
  if (value === true) {
    return BooleanSelectValue.YES;
  } else if (value === false) {
    return BooleanSelectValue.NO;
  } else {
    return BooleanSelectValue.UNSET;
  }
};

const BooleanCustomFieldInput: CustomFieldInputComponent = ({
  value,
  customFieldDefinition,
  onChange,
}) => {
  return (
    <EnumSelect
      label={customFieldDefinition.label}
      enumObject={BooleanSelectValue}
      value={customFieldValueToBooleanSelectValue(value)}
      onChange={(e, value) => {
        if (value === null) {
          onChange(null);
          return;
        }
        onChange(booleanSelectValueToBoolean(value));
      }}
      optionLabel={(value) => {
        switch (value) {
          case BooleanSelectValue.YES:
            return "Yes";
          case BooleanSelectValue.NO:
            return "No";
          case BooleanSelectValue.UNSET:
            return "Unset";
        }

        return value;
      }}
    />
  );
};

export default BooleanCustomFieldInput;
