import { styled } from "@mui/material/styles";
import Button, { ButtonProps } from "@mui/material/Button";

const AddButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.getContrastText("#dddddd"),
  backgroundColor: "#e2e2e2",
  "&:hover": {
    backgroundColor: "#a9a6a6",
  },
  borderRadius: 4,
}));

export default AddButton;
