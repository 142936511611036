import { Alert } from "@mui/material";
import { omit } from "lodash";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  GetGroupDetailsQuery,
  useAddGroupMutation,
  useEditGroupMutation,
  useGetGroupDetailsQuery,
} from "../../../graphql/generated";
import { showDialog } from "../../../redux/slices/alert/Alert.slice";
import LoadingOverlay from "../../common/LoadingOverlay";
import GroupForm from "./GroupForm";

type GroupFormContainerProps = {
  groupId?: string;
  onLoad?: (group: GetGroupDetailsQuery["groupById"]) => void;
  onSaved?: (group: GetGroupDetailsQuery["groupById"]) => void;
};

function GroupFormContainer({
  groupId,
  onLoad,
  onSaved,
}: GroupFormContainerProps) {
  const addGroupMutation = useAddGroupMutation();
  const editGroupMutation = useEditGroupMutation();
  const initialGroupId = useRef(groupId);

  // We call this hook conditionally because the component
  // should never rerender with a different value for
  // businessEntityId, but if it does we don't update
  // the ref anyway so this is safe
  const getGroupQuery = initialGroupId.current
    ? // eslint-disable-next-line react-hooks/rules-of-hooks
      useGetGroupDetailsQuery(
        {
          id: initialGroupId.current,
        },
        {
          refetchOnWindowFocus: false,
          retry: false,
        }
      )
    : null;
  // const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (getGroupQuery?.data?.groupById) {
      onLoad?.(getGroupQuery.data?.groupById);
    }
  }, [onLoad, getGroupQuery?.data]);

  if (groupId) {
    if (getGroupQuery?.isLoading) {
      return <LoadingOverlay loading />;
    }
    if (getGroupQuery?.error) {
      return (
        <Alert severity="error">
          An error occurred while fetching the group.{" "}
          {(getGroupQuery?.error as Error).message || "Unknown error"}
        </Alert>
      );
    }
  }

  return (
    <GroupForm
      initialGroup={getGroupQuery?.data?.groupById}
      saving={addGroupMutation.isLoading || editGroupMutation.isLoading}
      onSave={async (group) => {
        try {
          if (groupId) {
            const result = await editGroupMutation.mutateAsync({
              id: groupId,
              editGroupData: omit(group, "_id"),
            });
            onSaved?.({
              ...group,
              _id: result.editGroup._id,
            });
            // navigate(`/groups/details/${result.editGroup._id}`);
          } else {
            const result = await addGroupMutation.mutateAsync({
              newGroupData: {
                ...group,
                description: "",
              },
            });
            onSaved?.({
              ...group,
              _id: result.addGroup._id,
            });
            // navigate(`/groups/details/${result.addGroup._id}`);
            window.analytics?.track("Group Created", {
              groupId: result.addGroup._id,
            });
            window.analytics?.identify({
              groupCreated: true,
              lastGroupCreationDate: new Date(),
              lastGroupCreationDateOnly: new Date().toISOString().split("T")[0],
            });
            window.analytics?.group(window.analytics?.group?.()?.id?.(), {
              groupCreated: true,
              lastGroupCreationDate: new Date(),
              lastGroupCreationDateOnly: new Date().toISOString().split("T")[0],
            });
          }
        } catch (error) {
          dispatch(
            showDialog({
              title: "Error",
              description:
                `An error occurred while ${
                  groupId ? "editing" : "creating"
                } the group. ` + (error as Error).message,
              type: "error",
            })
          );
        }
      }}
    />
  );
}

export default GroupFormContainer;
