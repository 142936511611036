import { BusinessEntity } from "../../graphql/generated";

function carrierLabel(
  carrier: Pick<BusinessEntity, "code" | "name"> | null | undefined
) {
  if (!carrier) {
    return "N/A";
  }
  return `${carrier.code || ""} ${carrier.name || ""}`.trim() || "N/A";
}

export default carrierLabel;
