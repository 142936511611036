import { useEffect, useRef, useState } from "react";
import { unstable_useBlocker as useBlocker } from "react-router-dom";
import { usePrevious } from "./usePrevious";

function useConfirmBeforeLeave(valueToMonitor: any) {
  const [valueHasChanged, setValueHasChanged] = useState(false);
  const initialized = useRef(false);

  // Some components take time before initializing the value to monitor
  // for changes, we give them enough time to run their initial render effects
  // but not enough for the user to make a change that goes unnoticed
  useEffect(() => {
    setTimeout(() => {
      initialized.current = true;
    }, 100);
  }, []);
  useEffect(() => {
    if (valueHasChanged) {
      window.onbeforeunload = () => {
        return "Changes you made might not be saved";
      };
    } else {
      window.onbeforeunload = null;
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [valueHasChanged]);

  const previousValueToMonitor = usePrevious(valueToMonitor);
  useEffect(() => {
    if (!initialized.current) {
      return;
    }
    if (!valueToMonitor) {
      // the value is initializing ignore
      return;
    }
    if (valueToMonitor === previousValueToMonitor) {
      return;
    }
    setValueHasChanged(true);
  }, [valueToMonitor, previousValueToMonitor]);

  useBlocker(() => {
    if (valueHasChanged) {
      if (
        window.confirm(
          "Do you want to leave ? Changes you made might not be saved."
        )
      ) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  });

  return {
    cancelConfirm() {
      setValueHasChanged(false);
    },
  };
}

export default useConfirmBeforeLeave;
