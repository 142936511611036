import LocaleProvider from "../../providers/LocaleProvider";
import { celsiusToLocalTemperature } from "../conversion/temperature";

export default function formatTemperature(
  temperatureInCelsius: number | null | undefined,
  includeUnit = true
) {
  if (temperatureInCelsius === null || temperatureInCelsius === undefined) {
    return "";
  }
  const temperatureInLocal = celsiusToLocalTemperature(temperatureInCelsius);
  const roundedTemperatureInLocal = Math.round(temperatureInLocal);
  return `${roundedTemperatureInLocal} ${
    includeUnit ? LocaleProvider.getTemperatureUnit() : ""
  }`.trim();
}
