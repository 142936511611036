import { Container } from "@mui/material";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import TrailerForm from "../../../components/asset-management/TrailerForm";
import HeaderTitle from "../../../components/common/HeaderTitle";
import { Trailer } from "../../../graphql/generated";
import { useNavigationOptions } from "../../LayoutRoutes";
import { useTranslation } from "react-i18next";

function EditTrailer() {
  const { t } = useTranslation("assets");
  const { id } = useParams();
  const { setNavigationOptions } = useNavigationOptions();
  const onLoad = useCallback(
    (trailer: Pick<Trailer, "serialNumber">) => {
      setNavigationOptions({
        title: (
          <HeaderTitle
            label={t("trailer.edit", "Edit Trailer")}
            suffix={trailer?.serialNumber || ""}
          />
        ),
        showBackIcon: true,
      });
    },
    [setNavigationOptions, t]
  );
  if (!id) {
    return null;
  }
  return (
    <Container>
      <TrailerForm trailerId={id} onLoad={onLoad} />
    </Container>
  );
}

export default EditTrailer;
