import { Box, Typography } from "@mui/material";
import { Group } from "../../../graphql/generated";

export type GroupsDisplayProps = {
  groups: Pick<Group, "_id" | "name">[];
};

const GroupsDisplay = ({ groups }: GroupsDisplayProps) => {
  return (
    <Box>
      <Typography>{groups.map((group) => group.name).join(", ")}</Typography>
    </Box>
  );
};

export default GroupsDisplay;
