import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  EditShipmentNoteInput,
  GetShipmentQuery,
  Receiver,
  ShipmentNote,
  ShipmentNoteInput,
  Shipper,
} from "../../../../graphql/generated";
import {
  hideDialog,
  showDialog,
} from "../../../../redux/slices/alert/Alert.slice";
import NoteList from "./NotesList";
import ShipmentNotesForm from "../ShipmentNotes/ShipmentNotesForm";
import { defaultShipmentNote } from "../../../../redux/slices/shipment/ShipmentForm.slice";
import { NoteFormMode } from "../ShipmentNotes/NoteForm";
import { useTranslation } from "react-i18next";

export type NoteType = Pick<ShipmentNote, "_id" | "content" | "accessLevel"> & {
  receiver?: Pick<Receiver, "_id" | "name"> | null;
} & {
  shipper?: Pick<Shipper, "_id" | "name"> | null;
};

export default function ShipmentNoteList({
  notes,
  readonly,
  onEdit,
  onDelete,
  shipmentLocations,
}: {
  notes: GetShipmentQuery["shipmentById"]["notes"];
  onEdit: (note: EditShipmentNoteInput) => void;
  onDelete: (id: string) => void;
  shipmentLocations: GetShipmentQuery["shipmentById"]["shipmentLocations"];
  readonly?: boolean;
}) {
  const { t } = useTranslation(["common", "orders"]);
  const [state, setState] = useState<{
    note: ShipmentNoteInput;
    editing: boolean;
    openModal: boolean;
    mode?: NoteFormMode;
  }>({
    note: defaultShipmentNote,
    editing: false,
    openModal: false,
    mode: "readOnly",
  });

  const dispatch = useDispatch();

  const toShipmentNote = (data: GetShipmentQuery["shipmentById"]["notes"]) => {
    return (
      data?.map((note) => ({
        ...note,
        receiver: {
          _id: note.receiver?._id || "",
          name: note.receiver?.name || "",
        },
        shipper: {
          _id: note.shipper?._id || "",
          name: note.shipper?.name || "",
        },
      })) || []
    );
  };

  const handleEdit = (id: string) => {
    const note = notes?.find((n) => n._id === id);
    if (note) {
      setState({
        note: {
          ...note,
          receiver: note.receiver?._id || null,
          shipper: note.shipper?._id || null,
        },
        editing: true,
        openModal: true,
        mode: "edit",
      });
    }
    //
  };

  const handleItemClicked = (id: string) => {
    const note = notes?.find((n) => n._id === id);
    if (note) {
      setState({
        note: {
          ...note,
          receiver: note.receiver?._id || null,
          shipper: note.shipper?._id || null,
        },
        editing: false,
        openModal: true,
        mode: "readOnly",
      });
    }
  };
  const handleClose = async (notes: Array<ShipmentNoteInput>) => {
    setState({ ...state, openModal: false });
    if (notes.length) {
      onEdit(notes[0]);
    }
    setState({ ...state, openModal: false });
  };

  const handleDelete = (id: string) => {
    dispatch(
      showDialog({
        type: "primary",
        title: t("delete", "Delete"),
        description: t("orders:notes.deleteConfirmation", "Delete this note ?"),
        actions: [
          {
            title: t("delete", "Delete"),
            type: "info",
            onClick: () => {
              onDelete(id);
              dispatch(hideDialog());
            },
          },
        ],
      })
    );
  };

  return (
    <>
      <NoteList
        onItemClick={handleItemClicked}
        notes={toShipmentNote(notes)}
        onEdit={handleEdit}
        onDelete={handleDelete}
        readonly={readonly}
      />
      {state?.openModal && (
        <ShipmentNotesForm
          openModal
          mode={state.mode || "readOnly"}
          shipmentNotes={[state.note]}
          shipmentLocations={shipmentLocations}
          onClose={handleClose}
        />
      )}
    </>
  );
}
