import { useCallback } from "react";
import HeaderTitle from "../../components/common/HeaderTitle";
import TripDetailsContainer from "../../components/trip/TripDetails";
import { Trip } from "../../graphql/generated";
import { useNavigationOptions } from "../LayoutRoutes";
import { useParams } from "react-router-dom";
import ErrorMessage from "../../components/common/ErrorMessage/ErrorMessage";
import { useTranslation } from "react-i18next";

function ViewTrip() {
  const { setNavigationOptions } = useNavigationOptions();

  const { id } = useParams();
  const { t } = useTranslation("trips");

  const onLoad = useCallback(
    (trip: Pick<Trip, "tripNumber">) => {
      setNavigationOptions({
        title: (
          <HeaderTitle label={t("trip")} suffix={trip?.tripNumber || ""} />
        ),
        showBackIcon: true,
      });
    },
    [setNavigationOptions, t]
  );

  if (!id) {
    return <ErrorMessage message="Not found" />;
  }
  return <TripDetailsContainer tripId={id} onLoad={onLoad} />;
}

export default ViewTrip;
