import Joi from "joi";
import { Coordinates, Trailer } from "../../../graphql/generated";
import customFieldSchema from "../../extensions/CustomFieldsForm/customFieldsSchema";

const trailerSchema = Joi.object<Trailer>({
  serialNumber: Joi.string().min(3).max(50).required().label("Asset Code"),
  licenseNumber: Joi.string().label("License Number").allow(null, ""),
  type: Joi.string().label("Type").required(),
  mileage: Joi.number().label("Mileage").allow(null, ""),
  height: Joi.number().min(0).label("Height").allow(null, ""),
  width: Joi.number().min(0).label("Width").allow(null, ""),
  length: Joi.number().min(0).label("Length").allow(null, ""),
  maxWeight: Joi.number().min(1).label("Max Weight").allow(null, ""),
  minTemperature: Joi.number().label("Min Temperature").allow(null, ""),
  maxTemperature: Joi.number().label("Max Temperature").allow(null, ""),
  numberOfAxles: Joi.number().label("Number of axles").allow(null, ""),
  domicile: Joi.object<Trailer["domicile"]>({
    label: Joi.string().required().label("Address Label"),
    coordinates: Joi.object<Coordinates>({
      latitude: Joi.number().required(),
      longitude: Joi.number().required(),
    })
      .required()
      .label("Coordinates"),
    line1: Joi.string().allow("", null).label("Address Line 1"),
    line2: Joi.string().allow("", null).label("Address Line 2"),
    postalCode: Joi.string().allow("", null).label("Postal Code"),
    city: Joi.string().allow("", null).label("City"),
    state: Joi.string().allow("", null).label("State"),
    country: Joi.string().allow("", null).label("Country"),
    googlePlaceId: Joi.string().allow("", null).label("Google Place ID"),
  })
    .label("Physical Address")
    .allow(null),
  status: Joi.string().allow(null),
  compartments: Joi.array().allow(null),
  tags: Joi.array().items(Joi.string()).label("Tags").allow(null),
  customFields: Joi.array()
    .items(customFieldSchema)
    .label("Custom Fields")
    .allow(null),
  groupIds: Joi.array().items(Joi.string()).label("Group IDs").allow(null),
});

export default trailerSchema;
