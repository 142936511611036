import { useCallback } from "react";
import { useParams } from "react-router-dom";
import TrailerDetails from "../../../components/asset-management/TrailerDetails";
import HeaderTitle from "../../../components/common/HeaderTitle";
import { Trailer } from "../../../graphql/generated";
import { useNavigationOptions } from "../../LayoutRoutes";
import { useTranslation } from "react-i18next";

function ViewTrailer() {
  const { t } = useTranslation("assets");
  const { id } = useParams();
  const { setNavigationOptions } = useNavigationOptions();
  const onLoad = useCallback(
    (trailer: Pick<Trailer, "serialNumber">) => {
      setNavigationOptions({
        title: (
          <HeaderTitle
            label={t("trailer.one", "Trailer")}
            suffix={trailer?.serialNumber || ""}
          />
        ),
        showBackIcon: true,
      });
    },
    [setNavigationOptions, t]
  );
  if (!id) {
    return null;
  }
  return <TrailerDetails onLoad={onLoad} trailerId={id} />;
}

export default ViewTrailer;
