import { useCallback } from "react";
import { useParams } from "react-router-dom";
import ReceiverForecastDetails from "../../components/forecasting/ReceiverForecastDetails";
import HeaderTitle from "../../components/common/HeaderTitle";
import { ReceiverForecast } from "../../graphql/generated";
import { useNavigationOptions } from "../LayoutRoutes";

function ViewReceiverForecast() {
  const { id } = useParams();
  const { setNavigationOptions } = useNavigationOptions();
  const onLoad = useCallback(
    (receiverForecast: Pick<ReceiverForecast, "_id">) => {
      setNavigationOptions({
        title: <HeaderTitle label="Forecasting" suffix={""} />,
        showBackIcon: true,
      });
    },
    [setNavigationOptions]
  );
  if (!id) {
    return null;
  }
  return <ReceiverForecastDetails onLoad={onLoad} receiverForecastId={id} />;
}

export default ViewReceiverForecast;
