import Joi from "joi";
import {
  BillingRuleConditionInput,
  NewSupplierContractInput,
} from "../../../graphql/generated";

const conditionSchema = Joi.object<BillingRuleConditionInput>({
  target: Joi.string().label("Target").required(),
  operator: Joi.string().label("Operator").required(),
  value: Joi.any().label("Value").required(),
});

const supplierContractSchema = Joi.object<NewSupplierContractInput>({
  customerId: Joi.string().label("Customer").required(),
  goodProfileId: Joi.string().label("Commodity").required(),
  shipperId: Joi.string().label("Shipper").required(),
  startDate: Joi.date().label("Start Date").required(),
  endDate: Joi.date().label("End Date").required(),
  unitPrice: Joi.number().label("Unit Price").required(),
  contractNumber: Joi.string().label("Contract Number").required(),
  allocation: Joi.object({
    dailyQuantity: Joi.number().label("Daily Allocation").allow(null),
    weeklyQuantity: Joi.number().label("Weekly Allocation").allow(null),
    monthlyQuantity: Joi.number().label("Monthly Allocation").allow(null),
    totalQuantity: Joi.number().label("Total Allocation").allow(null),
  })
    .label("Allocation")
    .required(),
  conditions: Joi.array()
    .items(conditionSchema)
    .label("Conditions")
    .allow(null),
});

export default supplierContractSchema;
