import { keyBy, without } from "lodash";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import {
  CustomFieldContext,
  ExtensionOperationContext,
  useCallExtensionOperationMutation,
  useGetCustomFieldDefinitionsQuery,
  useGetExtensionsOperationsQuery,
  useGetGroupsQuery,
} from "../../../graphql/generated";
import useDialog from "../../../utils/hooks/useDialog";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import LynksTable from "../LynksTable";
import { LynksTableProps, Sort, TableField } from "../LynksTable/LynksTable";
import { usePrevious } from "../../../utils/hooks/usePrevious";
import { useTranslation } from "react-i18next";
import LoadingOverlay from "../LoadingOverlay";
import BulkActionModal from "../BulkActionModal";

const DEFAULT_ROWS_COUNT = 10;

type SmartLynksTableQueryVariables<C, V = {}> = {
  search?: string;
  sort?: Sort<C> | null;
  skip?: number;
  take?: number;
} & V;

type SmartLynksTableQueryOptions = {
  retry?: boolean;
  refetchOnWindowFocus?: boolean;
  refetchOnMount?: boolean;
  refetchOnReconnect?: boolean;
  refetchInterval?: number;
  refetchIntervalInBackground?: boolean;
  refetchOnLimit?: boolean;
  retryDelay?: number;
  staleTime?: number;
  cacheTime?: number;
  useErrorBoundary?: boolean;
  onError?: (error: Error) => void;
  enabled?: boolean;
  suspense?: boolean;
  keepPreviousData?: boolean;
  structuralSharing?: boolean;
};

export type QueryFn<T, K extends string, C = unknown, V = {}> = (
  variables: SmartLynksTableQueryVariables<C, V>,
  queryOptions?: SmartLynksTableQueryOptions
) => {
  data:
    | {
        [key in K]:
          | {
              data: T[];
              count: number | null;
            }
          | undefined;
      }
    | undefined;
  isLoading: boolean;
  error?: Error | null;
  refetch?: () => Promise<unknown>;
};

export type MutationFn<V = {}, R = unknown> = () => {
  mutateAsync: (variables: V) => Promise<R>;
  isLoading: boolean;
  error?: Error | null;
};

export type DeleteMutation = MutationFn<{ id: string }>;

export type SmartLynksTableProps<
  T,
  K extends string,
  E extends string | number | symbol = never,
  C extends string = never,
  V = {}
> = {
  fields?: LynksTableProps<T, E, C>["fields"];
  renderRow?: LynksTableProps<T, E, C>["renderRow"];
  renderHeader?: LynksTableProps<T, E, C>["renderHeader"];
  sortFields?: LynksTableProps<T, E, C>["sortFields"];
  actions?: LynksTableProps<T, E, C>["actions"];
  onRecordClick?: LynksTableProps<T, E, C>["onRecordClick"];
  onSelect?: LynksTableProps<T, E, C>["onSelect"];
  selectable?: boolean;
  detailsUrlPrefix?: LynksTableProps<T, E, C>["detailsUrlPrefix"];
  dataKey: K;
  query: QueryFn<T, K, C, V>;
  additionalQueryVariables?: V;
  queryOptions?: SmartLynksTableQueryOptions;
  disableSearch?: boolean;
  disablePagination?: boolean;
  deleteMutation?: DeleteMutation;
  extensionOperationContext?: ExtensionOperationContext;
  id?: LynksTableProps<T, E, C>["id"];
  customFieldContext?: CustomFieldContext[];
  showGroups?: boolean;
  // additionnalsCustomFieldsContext?: CustomFieldContext[];
  customFieldsGetter?: LynksTableProps<T, E, C>["customFieldsGetter"];
  getRecordParentId?: LynksTableProps<T, E, C>["getRecordParentId"];
  onFilteredRecordsChange?: LynksTableProps<T, E, C>["onFilteredRecordsChange"];
};

export default function SmartLynksTable<
  T extends {
    _id: string;
    groupIds?: string[] | null;
  },
  K extends string,
  E extends string | number | symbol = never,
  C extends string = never,
  V = {}
>({
  fields,
  renderRow,
  renderHeader,
  sortFields,
  actions,
  query,
  additionalQueryVariables,
  queryOptions,
  dataKey,
  detailsUrlPrefix,
  onRecordClick,
  onSelect,
  selectable,
  disableSearch,
  disablePagination,
  deleteMutation,
  extensionOperationContext,
  id,
  customFieldContext = [],
  showGroups,
  customFieldsGetter,
  getRecordParentId,
  onFilteredRecordsChange,
}: SmartLynksTableProps<T, K, E, C, V>) {
  const { t } = useTranslation("common");
  const [take, setTake] = useState(DEFAULT_ROWS_COUNT);
  const [skip, setSkip] = useState(0);
  const [sort, setSort] = useState<Sort<C> | null>(null);
  const [selected, setSelected] = useState<T[]>([]);
  const [loadingRecordIds, setLoadingRecordIds] = useState<string[]>([]);

  const { showDialog, hideDialog } = useDialog();
  const { enqueueSnackbar } = useSnackbar();

  const groupsQuery =
    process.env.NODE_ENV !== "test"
      ? // eslint-disable-next-line react-hooks/rules-of-hooks
        useGetGroupsQuery()
      : null;
  const groupsData = useMemo(
    () => groupsQuery?.data?.groups.data || [],
    [groupsQuery?.data]
  );
  const groupMap = useMemo(() => keyBy(groupsData, (g) => g._id), [groupsData]);

  const augmentedFields = useMemo(() => {
    const groupsField: TableField<T> = {
      value: (row: T) =>
        (row?.groupIds || [])
          .map((id) => groupMap[id])
          .filter(Boolean)
          .map((g) => g.name),
      label: t("groups"),
      type: "select",
      values: groupsData.map((g) => g.name),
    };
    return showGroups ? fields?.concat(groupsField) : fields;
  }, [fields, showGroups, groupMap, groupsData, t]);

  const queryResult = query(
    //@ts-ignore additionalQueryVariables will always be compatible
    // with query variables (type V) but somehow ts does not see it that way
    {
      sort,
      take: 300,
      skip: 0,
      ...(additionalQueryVariables || {}),
    },
    {
      retry: false,
      ...(queryOptions || {}),
    }
  );

  const deleteMutationHandle = deleteMutation ? deleteMutation() : null;

  const previousSelected = usePrevious(selected);
  useEffect(() => {
    // avoid resetting page when user select a load
    if (!selected.length && previousSelected?.length !== 1) {
      setSkip(0);
    }
    // we do not want to reset the page when selected loads change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, additionalQueryVariables]);

  const data = useMemo(() => {
    return queryResult.data ? queryResult.data[dataKey]?.data || [] : [];
  }, [queryResult.data, dataKey]);

  const count = useMemo(() => {
    return queryResult.data ? queryResult.data[dataKey]?.count || null : null;
  }, [queryResult.data, dataKey]);

  useEffect(() => {
    setSelected([]);
  }, [data]);

  const extensionsOperationsQuery =
    process.env.NODE_ENV !== "test" && extensionOperationContext
      ? // eslint-disable-next-line react-hooks/rules-of-hooks
        useGetExtensionsOperationsQuery({
          context: extensionOperationContext,
        })
      : null;
  const extensionOperations =
    extensionsOperationsQuery?.data?.extensionsOperations || [];

  const callExtensionOperationMutation =
    process.env.NODE_ENV !== "test"
      ? // eslint-disable-next-line react-hooks/rules-of-hooks
        useCallExtensionOperationMutation()
      : null;

  const customFieldDefinitionsQuery =
    customFieldContext.length && process.env.NODE_ENV !== "test"
      ? // eslint-disable-next-line react-hooks/rules-of-hooks
        useGetCustomFieldDefinitionsQuery()
      : null;

  const customFieldDefinitions =
    customFieldDefinitionsQuery?.data?.cutomFieldDefinitions.data.filter(
      (customFieldDefinition) =>
        customFieldContext.length &&
        customFieldDefinition.context.some((ctx) =>
          customFieldContext.includes(ctx)
        )
    );

  if (
    queryResult.isLoading ||
    customFieldDefinitionsQuery?.isLoading ||
    groupsQuery?.isLoading
  ) {
    return <LoadingOverlay loading />;
  }

  return (
    <>
      <ErrorMessage error={queryResult.error} />
      {(selectable || onSelect) && selected.length > 0 && (
        <BulkActionModal
          entityIds={selected.map((entity) => entity._id)}
          onSubmitComplete={() => queryResult?.refetch?.()}
          dataKey={dataKey}
        />
      )}
      {/* @ts-expect-error */}
      <LynksTable
        data={data}
        fields={augmentedFields}
        renderRow={renderRow}
        renderHeader={renderHeader}
        sortFields={sortFields}
        loadingRecordIds={loadingRecordIds}
        actions={(actions || []).concat(
          extensionOperations.map((extensionOperation) => ({
            icon: (
              <img
                src={extensionOperation.icon}
                alt={extensionOperation.description}
                style={{
                  width: 20,
                  height: 20,
                }}
              />
            ),
            tooltip: extensionOperation.name,
            onClick: async (item) => {
              try {
                if (!callExtensionOperationMutation) {
                  return;
                }
                setLoadingRecordIds(loadingRecordIds.concat(item._id));
                const response =
                  await callExtensionOperationMutation.mutateAsync({
                    extensionId: extensionOperation.extensionId,
                    operationKey: extensionOperation.key,
                    recordId: item._id,
                  });
                if (response.callExtensionOperation.message) {
                  enqueueSnackbar(response.callExtensionOperation.message);
                }
                if (response.callExtensionOperation.redirect) {
                  if (response.callExtensionOperation.openNewWindow) {
                    window.open(
                      response.callExtensionOperation.redirect,
                      `${item._id}::${extensionOperation.extensionId}::${extensionOperation.key}`,
                      `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=992,height=680,left=100,top=100`
                    );
                  } else {
                    window.location.href =
                      response.callExtensionOperation.redirect;
                  }
                }
              } catch (error) {
                showDialog({
                  title: t("error.title", "Error"),
                  description: (error as Error).message,
                  type: "error",
                });
              } finally {
                setLoadingRecordIds(without(loadingRecordIds, item._id));
              }
            },
          }))
        )}
        loading={queryResult.isLoading}
        detailsUrlPrefix={detailsUrlPrefix}
        disableSearch={disableSearch}
        onSortChange={setSort}
        count={disablePagination ? null : count}
        onPageChange={
          disablePagination
            ? undefined
            : (page: number) => setSkip((page - 1) * take)
        }
        page={skip / take + 1}
        onPerPageChange={
          disablePagination ? undefined : (perPage: number) => setTake(perPage)
        }
        perPage={disablePagination ? undefined : take}
        selected={selected}
        onRecordClick={onRecordClick}
        onSelect={
          onSelect || selectable
            ? (selected) => {
                setSelected(selected);
                if (onSelect) {
                  onSelect(selected);
                }
              }
            : undefined
        }
        onDelete={
          deleteMutationHandle
            ? async (id) => {
                showDialog({
                  title: t("error.dangerZone", "Danger zone"),
                  description: t(
                    "error.deleteConfirmation",
                    "Do you really want to delete this? This action cannot be undone."
                  ),
                  type: "error",
                  actions: [
                    {
                      type: "primary",
                      title: t("error.noCancel", "No, Cancel"),
                    },
                    {
                      type: "error",
                      title: t("error.yesDelete", "Yes, Delete"),
                      onClick: async () => {
                        try {
                          setLoadingRecordIds(loadingRecordIds.concat(id));

                          await deleteMutationHandle.mutateAsync({
                            id,
                          });
                          hideDialog();
                          if (queryResult.refetch) {
                            queryResult.refetch();
                          }
                        } catch (error) {
                          console.error(error);
                          showDialog({
                            title: t("error.title", "Error"),
                            description:
                              t(
                                "error.deleteError",
                                "An error occurred while deleting."
                              ) +
                              (deleteMutationHandle.error?.message ||
                                t("error.unknownError", "Unknown error")),
                          });
                        } finally {
                          setLoadingRecordIds(without(loadingRecordIds, id));
                        }
                      },
                    },
                  ],
                });
              }
            : undefined
        }
        id={id || dataKey}
        customFieldDefinitions={customFieldDefinitions}
        customFieldsGetter={customFieldsGetter}
        getRecordParentId={getRecordParentId}
        onFilteredRecordsChange={onFilteredRecordsChange}
      />
    </>
  );
}
