import { Box, IconButton, Stack, TextField } from "@mui/material";
import {
  TrailerProfile,
  TrailerProfileCompartment,
} from "../../../graphql/generated";
import LabeledAddButton from "../../common/LabeledAddButton";
import { Delete } from "@mui/icons-material";

type TrailerProfileFormProps = {
  trailerProfile: TrailerProfile;
  onChange: (profile: TrailerProfile) => void;
};

const TrailerProfileForm = (props: TrailerProfileFormProps) => {
  return (
    <Box>
      <Stack spacing={2}>
        {props.trailerProfile.compartments.map((compartment, index) => (
          <Stack direction="row" spacing={1}>
            <Box flex={1}>
              <CompartmentForm
                key={index}
                compartment={compartment}
                onChange={(newCompartment) => {
                  props.onChange({
                    ...props.trailerProfile,
                    compartments: props.trailerProfile.compartments.map(
                      (c, i) => (i === index ? newCompartment : c)
                    ),
                  });
                }}
              />
            </Box>
            <IconButton
              onClick={() => {
                props.onChange({
                  ...props.trailerProfile,
                  compartments: props.trailerProfile.compartments.filter(
                    (c, i) => i !== index
                  ),
                });
              }}
            >
              <Delete />
            </IconButton>
          </Stack>
        ))}
      </Stack>
      <LabeledAddButton
        label="Add Compartment"
        onClick={() => {
          props.onChange({
            ...props.trailerProfile,
            compartments: [
              ...props.trailerProfile.compartments,
              {
                label: `Compartment ${
                  props.trailerProfile.compartments.length + 1
                }`,
                capacity: 0,
                maxWeight: 0,
                minUsage: 0,
                hasSeparatorBefore: false,
                hasSeparatorAfter: false,
              },
            ],
          });
        }}
      />
    </Box>
  );
};

const CompartmentForm = ({
  compartment,
  onChange,
}: {
  compartment: TrailerProfileCompartment;
  onChange: (compartment: TrailerProfileCompartment) => void;
}) => {
  return (
    <Stack direction="row" spacing={2}>
      <TextField
        label="Label"
        value={compartment.label}
        onChange={(event) => {
          onChange({
            ...compartment,
            label: event.target.value,
          });
        }}
        fullWidth
      />
      <TextField
        label="Capacity"
        value={compartment.capacity}
        onChange={(event) => {
          onChange({
            ...compartment,
            capacity: parseFloat(event.target.value) || 0,
          });
        }}
        fullWidth
      />
      <TextField
        label="Max Weight"
        value={compartment.maxWeight}
        onChange={(event) => {
          onChange({
            ...compartment,
            maxWeight: parseFloat(event.target.value) || 0,
          });
        }}
        fullWidth
      />
      <TextField
        label="Min Usage"
        value={compartment.minUsage}
        onChange={(event) => {
          onChange({
            ...compartment,
            minUsage: parseFloat(event.target.value) || 0,
          });
        }}
        fullWidth
      />
    </Stack>
  );
};

export default TrailerProfileForm;
