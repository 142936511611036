import { Container } from "@mui/material";
import ReceiverForecastFormContainer from "../../components/forecasting/ReceiverForecastForm";

function CreateReceiverForecast() {
  return (
    <Container>
      <ReceiverForecastFormContainer />
    </Container>
  );
}

export default CreateReceiverForecast;
