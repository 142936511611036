import { useEffect } from "react";
import PlanningToolContainer from "../../components/trip-planning/PlanningTool";
import { useNavigationOptions } from "../LayoutRoutes";
import { useTranslation } from "react-i18next";

export default function Planning() {
  const { setNavigationOptions } = useNavigationOptions();
  const { t } = useTranslation();

  useEffect(() => {
    setNavigationOptions({
      title: t("planning", "Planning"),
      showBackIcon: true,
      headerSize: "small",
    });
  }, [setNavigationOptions, t]);

  return <PlanningToolContainer />;
}
