import { InputAdornment, TextField } from "@mui/material";
import { isNumber } from "lodash";
import { formatMileage } from "../../../../utils/labels/formatDistance";
import { mileageToMeters } from "../../../../utils/conversion/distance";
import { ConditionValueSelectorComponent } from "../ConditionForm";
import LocaleProvider from "../../../../providers/LocaleProvider";
import { useTranslation } from "react-i18next";

const MileageInput: ConditionValueSelectorComponent = ({ value, onChange }) => {
  const { t } = useTranslation("billingRules");
  return (
    <TextField
      type="number"
      value={isNumber(value) ? formatMileage(value, false) || "" : ""}
      onChange={(event) => {
        onChange(mileageToMeters(parseFloat(event.target.value) || 0));
      }}
      size="small"
      label={t("target.MILEAGE", "Mileage")}
      inputProps={{
        type: "number",
        min: 0,
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            {LocaleProvider.getMileageUnit()}
          </InputAdornment>
        ),
      }}
    />
  );
};

export default MileageInput;
