import { User } from "../../graphql/generated";

function userLabel(
  user: Pick<User, "firstName" | "lastName"> | null | undefined
) {
  if (!user) {
    return "N/A";
  }
  return `${user.firstName || ""} ${user.lastName || ""}`.trim() || "N/A";
}

export default userLabel;
