import { LinkProps, tableCellClasses } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import RouterLinkComponent from "./components/common/RouterLinkComponent";

declare module "@mui/material/styles" {
  interface Palette {
    accent: Palette["primary"];
    lightPrimary: Palette["primary"];
    primaryBackground: Palette["primary"];
    honeyDew: Palette["primary"];
    lightGrey: Palette["primary"];
    lightOrange: Palette["primary"];
  }

  interface PaletteOptions {
    accent: PaletteOptions["primary"];
    lightPrimary: PaletteOptions["primary"];
    primaryBackground: PaletteOptions["primary"];
    honeyDew: PaletteOptions["primary"];
    lightGrey: PaletteOptions["primary"];
    lightOrange: PaletteOptions["primary"];
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    accent: true;
    lightPrimary: true;
    primaryBackground: true;
    lightOrange: true;
  }
}

declare module "@mui/material/Chip" {
  interface ChipPropsColorOverrides {
    accent: true;
    lightPrimary: true;
    honeyDew: true;
    lightOrange: true;
  }
}

const theme = createTheme({
  palette: {
    accent: {
      main: "#e3bd03",
      contrastText: "#FFFFFF",
    },
    lightPrimary: {
      main: "#a4283d",
      contrastText: "#FFFFFF",
      light: "#fffbfb",
    },
    primary: {
      main: "#610010",
      contrastText: "#FFFFFF",
      light: "#a4283d",
    },
    primaryBackground: {
      main: "#F0E8E9",
      contrastText: "#000000",
      dark: "#E2D1D3",
    },
    secondary: {
      main: "#9d480a",
      contrastText: "#FFFFFF",
      light: "#61001030",
    },
    error: {
      main: "#FF0000",
    },
    honeyDew: {
      main: "#ECFDF5",
      contrastText: "#064E3B",
    },
    lightGrey: {
      main: "#EFF2F2",
      contrastText: "#FFFFFF",
    },
    lightOrange: {
      main: "#e69445",
      contrastText: "#FFFFFF",
      light: "#61001030",
    },
  },
  typography: (palette) => ({
    h1: {
      color: palette.primary.main,
    },
    h2: {
      color: palette.primary.main,
    },
    h3: {
      color: palette.primary.main,
    },
    h4: {
      color: palette.primary.main,
    },
    h5: {
      color: palette.primary.main,
    },
    h6: {
      color: palette.primary.main,
    },
    subtitle1: {
      color: "inherit",
    },
  }),
  components: {
    MuiListSubheader: {
      styleOverrides: {
        root: (props) => ({
          backgroundColor: `${props.theme.palette.primary.light} !important`, // otherwise it gets overriden in autocomplete group headers
          color: props.theme.palette.primary.contrastText,
        }),
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: (props) => ({
          "& label.Mui-focused": {
            color: props.theme.palette.primary.main,
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: props.theme.palette.primary.main,
          },
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: props.theme.palette.primary.main,
            },
          },
        }),
      },
    },
    MuiTable: {
      styleOverrides: {
        root: (props) =>
          props.ownerState.size !== "small"
            ? {
                borderCollapse: "separate",
                borderSpacing: "0px 9px",
              }
            : undefined,
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            "&.MuiTableRow-selected": {
              "&:selected": {
                backgroundColor: "black",
              },
            },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        body: (props) => ({
          borderTop: "1px solid rgba(224, 224, 224, 1) !important",
          borderBottom: "1px solid rgba(224, 224, 224, 1) !important",
          "&:first-of-type": {
            borderTopLeftRadius: 5,
            borderBottomLeftRadius: 5,
            borderLeft:
              props.ownerState.size !== "small"
                ? "1px solid rgba(224, 224, 224, 1) !important"
                : `1px solid ${props.theme.palette.primary.main} !important`,
          },
          "&:last-child": {
            borderTopRightRadius: 5,
            borderBottomRightRadius: 5,
            borderRight:
              props.ownerState.size !== "small"
                ? "1px solid rgba(224, 224, 224, 1) !important"
                : `1px solid ${props.theme.palette.primary.main} !important`,
          },
          // display cell on one line when size is small
          whiteSpace: props.ownerState.size === "small" ? "nowrap" : undefined,
          overflow: props.ownerState.size === "small" ? "hidden" : undefined,
          textOverflow:
            props.ownerState.size === "small" ? "ellipsis" : undefined,
        }),
        head: (props) =>
          props.ownerState.size !== "small" && {
            "&:first-of-type": {
              borderTopLeftRadius: 5,
              borderBottomLeftRadius: 5,
            },
            "&:last-child": {
              borderTopRightRadius: 5,
              borderBottomRightRadius: 5,
            },
          },
        root: (props) => ({
          [`&.${tableCellClasses.head}`]: {
            backgroundColor: props.theme.palette.primary.main,
            color: "#FFFFFF",
          },
          [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
          },
        }),
      },
    },
    MuiLink: {
      defaultProps: {
        component: RouterLinkComponent,
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: RouterLinkComponent,
      },
    },
    MuiSvgIcon: {
      styleOverrides: {},
    },
    MuiStepLabel: {
      styleOverrides: {
        root: (props) => ({
          "& .Mui-active": {
            color: `${props.theme.palette.secondary.main} !important`,
          },
          "& .Mui-completed": {
            color: props.theme.palette.secondary.main,
          },
        }),
      },
    },
  },
});

export default theme;
