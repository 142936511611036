import { useState, useEffect } from "react";
import { Stack, Button, Box, Typography, Divider } from "@mui/material";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import { Breakpoint } from "@mui/material";
import {
  GoodDistributionInput,
  GoodInput,
} from "../../../../graphql/generated";
import AddIcon from "@mui/icons-material/Add";
import {
  FieldErrors,
  FormError,
  ShipmentLocationInputData,
} from "../../../../redux/slices/Types";
import {
  defaultGood,
  setShipperGoods,
} from "../../../../redux/slices/shipment/ShipmentForm.slice";
import { v4 as uuid } from "uuid";
import { isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import AddButton from "../../../common/AddButton";
import _ from "lodash";
import {
  hideDialog,
  showDialog,
} from "../../../../redux/slices/alert/Alert.slice";
import GoodFormContainer from "./GoodFormContainer";
import { useTranslation } from "react-i18next";

export interface ShipperGoodsFormContainerProps {
  openModal: boolean;
  width?: Breakpoint;
  shippedGoods: Array<GoodInput>;
  onClose: () => void;
  customer: string;
  shipper: string | null;
  shipmentLocation: ShipmentLocationInputData;
  allReceivedGoods?: Array<GoodDistributionInput>;
}

export default function ShipperGoodsFormContainer({
  openModal,
  onClose,
  width,
  customer,
  shipper,
  shippedGoods,
  shipmentLocation,
  allReceivedGoods,
}: ShipperGoodsFormContainerProps) {
  const { t } = useTranslation(["orders", "common"]);
  const [maxWidth] = useState<DialogProps["maxWidth"]>(width);
  const [errors, setErrors] = useState<Array<FormError>>([]);
  const [goods, setGoods] = useState<Array<GoodInput>>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (shippedGoods.length) {
      setGoods([...shippedGoods]);
    } else {
      setGoods([{ ...defaultGood, _id: uuid() }]);
    }
  }, [shippedGoods]);

  const handleValidate = () => {
    // add logic to verify goods data and update the store to set all the goods
    const errors: Array<FormError> = [];
    for (let i = 0; i < goods.length; i++) {
      const err: FieldErrors = {};
      if (goods[i].label.length === 0) {
        err["label"] = "Commodities name cannot be empty";
      }
      if (goods[i].quantity === 0) {
        err["quantity"] = "Commodity quantity cannot be empty or equal to zero";
      }
      if (!isEmpty(err)) {
        errors.push({ formId: goods[i]._id, error: err });
      }
    }

    if (errors.length) {
      setErrors(errors);
    } else {
      setErrors([]);
      dispatch(
        setShipperGoods({
          _id: shipmentLocation._id,
          goods,
        })
      );
      onClose();
    }
  };

  const handleAddGood = () => {
    setGoods([...goods, { ...defaultGood, _id: uuid() }]);
  };

  const handleChange = (
    id: string,
    name: string,
    value: string | null | undefined
  ) => {
    setGoods((goods) => {
      const _goods = [...goods];
      const goodIndex: number = goods.findIndex((good) => good._id === id);

      if (errors.length) {
        const _errors = [...errors];
        const goodErrorIndex: number =
          errors?.findIndex((err) => err.formId === id) || 0;
        _errors[goodErrorIndex] = {
          formId: id,
          error: _.omit(errors[goodErrorIndex].error, name),
        };
        setErrors(_errors);
      }

      let _good: GoodInput;
      if (
        name.toLowerCase() === "_id" ||
        name.toLowerCase() === "label" ||
        name.toLowerCase() === "unit" ||
        name === "goodProfileId" ||
        name === "supplierId" ||
        name === "pinCode"
      ) {
        _good = {
          ..._goods[goodIndex],
          [name]: value,
        };
      } else {
        _good = {
          ..._goods[goodIndex],
          [name]: value
            ? !isNaN(Number.parseFloat(value))
              ? Number.parseFloat(value)
              : 0
            : null,
        };
      }

      _goods[goodIndex] = _good;
      return _goods;
    });
  };

  const handleCancel = () => {
    // when canceled, reset the form with the goods already validated
    // if not present, use the default good input
    setGoods(shippedGoods.length ? shippedGoods : [defaultGood]);
    setErrors([]);
    onClose();
  };

  return (
    <Box>
      <Dialog maxWidth={maxWidth} fullWidth open={openModal}>
        <DialogTitle>
          <Typography variant="h6">
            {t("commoditiesToShip", "Commodities to ship")}
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Stack spacing={2}>
            {goods?.map((good, index) => (
              <Box key={index}>
                <GoodFormContainer
                  errors={
                    errors?.find((err) => err.formId === good._id) || undefined
                  }
                  good={good}
                  customer={customer}
                  shipper={shipper}
                  handleChange={handleChange}
                  handleDelete={() => {
                    dispatch(
                      showDialog({
                        title: t("common:error.dangerZone", "Danger zone"),
                        description: t(
                          "error.deleteGoodConfirmation",
                          "Do you really want to delete this good ? This action cannot be undone."
                        ),
                        type: "error",
                        actions: [
                          {
                            type: "error",
                            title: t("common:error.yesDelete", "Yes, Delete"),
                            onClick: () => {
                              setGoods(goods.filter((g) => g._id !== good._id));
                              dispatch(hideDialog());
                            },
                          },
                          {
                            type: "primary",
                            title: t("common:error.noCancel", "No, Cancel"),
                          },
                        ],
                      })
                    );
                  }}
                  allReceivedGoods={allReceivedGoods}
                />
                {goods.length > 1 && index < goods.length - 1 && (
                  <Divider sx={{ m: "1rem 0" }} />
                )}
              </Box>
            ))}
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            sx={{ m: 1, display: "flex", alignItems: "center" }}
          >
            <AddButton
              sx={{ height: 50, width: 20 }}
              onClick={handleAddGood}
              id="add-good-button"
            >
              <AddIcon />
            </AddButton>
            <span>{t("orders:addCommodity", "Add Commodity")}</span>
          </Stack>
          <Stack direction="row" spacing={3} sx={{ m: 1 }}></Stack>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            color="inherit"
            variant="contained"
            onClick={handleCancel}
          >
            {t("common:cancel", "Cancel")}
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleValidate}
            id="validate-goods-button"
          >
            {t("common:validate", "Validate")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
