import { useTranslation } from "react-i18next";
import { useSplitShipmentMutation } from "../../../graphql/generated";
import useDialog from "../../../utils/hooks/useDialog";
import LoadSplittingModal, {
  LoadSplittingModalProps,
} from "./LoadSplittingModal";

type LoadSplittingModalContainerProps = Omit<
  LoadSplittingModalProps,
  "onSubmit" | "splitting"
> & {};

const LoadSplittingModalContainer = ({
  ...props
}: LoadSplittingModalContainerProps) => {
  const { t } = useTranslation(["orders", "common"]);
  const splitShipmentMutation = useSplitShipmentMutation();
  const { showDialog } = useDialog();
  return (
    <LoadSplittingModal
      {...props}
      splitting={splitShipmentMutation.isLoading}
      onSubmit={async (shipmentId, locations) => {
        try {
          await splitShipmentMutation.mutateAsync({
            shipmentId,
            locations,
          });
          props.onClose();
        } catch (error) {
          showDialog({
            title: t("common:error.title", "Error"),
            description:
              t(
                "error.splitError",
                `An error occurred while splitting the load. `
              ) + (error as Error)?.message ||
              t("common:error.unknownError", "Unknown error"),
            type: "error",
          });
        }
      }}
    />
  );
};

export default LoadSplittingModalContainer;
