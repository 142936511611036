import { Autocomplete, AutocompleteProps, TextField } from "@mui/material";
import { Trailer } from "../../../../../graphql/generated";
import { ConditionValueSelectorComponentProps } from "../../ConditionForm";

const TrailerInput = ({
  value,
  onChange,
  trailers,
  ...otherProps
}: ConditionValueSelectorComponentProps & {
  trailers: Pick<Trailer, "_id" | "serialNumber">[];
} & Pick<AutocompleteProps<string, true, undefined, undefined>, "sx">) => {
  return (
    <Autocomplete
      disablePortal
      options={trailers || []}
      getOptionLabel={(trailer) => trailer.serialNumber}
      onChange={(event, value) => {
        if (!value) {
          return;
        }
        onChange(value._id);
      }}
      value={trailers.find((trailer) => trailer._id === value) || null}
      size="small"
      renderInput={(inputProps) => <TextField {...inputProps} />}
      sx={{
        minWidth: 300,
      }}
      {...otherProps}
    />
  );
};

export default TrailerInput;
