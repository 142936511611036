import { Language } from "../../graphql/generated";

const languageLabelMapping: { [key in Language]: string | undefined } = {
  [Language.En]: "English",
  [Language.Fr]: "Français",
};

function languageLabel(language: Language | undefined | null) {
  if (!language) return "N/A";
  return languageLabelMapping[language] || language;
}

export default languageLabel;
