import {
  AssetLinkingInput,
  GetAssetLinkingDetailsQuery,
  useAddAssetLinkingMutation,
  useEditAssetLinkingMutation,
  useGetAssetLinkingDetailsForDriverQuery,
  useGetAssetLinkingDetailsForTractorQuery,
  useGetAssetLinkingDetailsForTrailerQuery,
} from "../../../graphql/generated";
import AssetLinkingFormModal, {
  AssetLinkingFormModalProps,
} from "./AssetLinkingFormModal";

type AssetLinkingFormModalContainerProps = Omit<
  AssetLinkingFormModalProps,
  "assetLinking" | "onSubmit"
>;

type AssetLinkingDetailsData = Exclude<
  GetAssetLinkingDetailsQuery["assetLinking"],
  null | undefined
>;

const assetLinkingToInput = (
  assetLinking: AssetLinkingDetailsData | null
): AssetLinkingInput | null => {
  if (!assetLinking) {
    return null;
  }
  return {
    driver: assetLinking.driver?._id || null,
    trailer: assetLinking.trailer?._id || null,
    tractor: assetLinking.tractor?._id || null,
    additionalTrailers: assetLinking.additionalTrailers?.map(
      (trailer) => trailer._id
    ),
  };
};

const AssetLinkingFormModalContainer = (
  props: AssetLinkingFormModalContainerProps
) => {
  if (
    !props.selectedDriverId &&
    !props.selectedTrailerId &&
    !props.selectedTractorId
  ) {
    throw new Error(
      "AssetLinkingFormModalContainer: props.selectedDriverId, props.selectedTrailerId or props.selectedTractorId must be provided"
    );
  }
  // Runtime guarantee that one of the three is provided
  const assetLinkingQuery = props.selectedDriverId
    ? // eslint-disable-next-line react-hooks/rules-of-hooks
      useGetAssetLinkingDetailsForDriverQuery(
        {
          id: props.selectedDriverId,
        },
        {
          cacheTime: 0,
        }
      )
    : props.selectedTrailerId
    ? // eslint-disable-next-line react-hooks/rules-of-hooks
      useGetAssetLinkingDetailsForTrailerQuery(
        {
          id: props.selectedTrailerId,
        },
        {
          cacheTime: 0,
        }
      )
    : props.selectedTractorId
    ? // eslint-disable-next-line react-hooks/rules-of-hooks
      useGetAssetLinkingDetailsForTractorQuery(
        {
          id: props.selectedTractorId,
        },
        {
          cacheTime: 0,
        }
      )
    : null;

  const addAssetLinkingMutation = useAddAssetLinkingMutation();
  const editAssetLinkingMutation = useEditAssetLinkingMutation();

  const existingAssetLinking = assetLinkingQuery?.data?.assetLinking || null;

  const submitAssetLinking = async (assetLinking: AssetLinkingInput) => {
    if (existingAssetLinking?._id) {
      await editAssetLinkingMutation.mutateAsync({
        id: existingAssetLinking._id,
        editAssetLinkingData: assetLinking,
      });
    } else {
      await addAssetLinkingMutation.mutateAsync({
        newAssetLinkingData: assetLinking,
      });
    }
    props.onClose();
  };

  return (
    <AssetLinkingFormModal
      assetLinking={assetLinkingToInput(existingAssetLinking)}
      onSubmit={submitAssetLinking}
      loading={
        addAssetLinkingMutation.isLoading ||
        editAssetLinkingMutation.isLoading ||
        assetLinkingQuery?.isLoading
      }
      {...props}
    />
  );
};

export default AssetLinkingFormModalContainer;
