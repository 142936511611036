import { Container } from "@mui/material";
import OrgSettingsEditor from "../../components/account/OrgSettingsEditor";

function ManageOrgSettings() {
  return (
    <Container>
      <OrgSettingsEditor />
    </Container>
  );
}

export default ManageOrgSettings;
