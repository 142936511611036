import { Box, Stack } from "@mui/material";
import TripSummary from "../TripSummary";
import { TripSummaryProps } from "../TripSummary/TripSummary";

interface TripSummariesProps {
  trips: Array<TripSummaryProps["trip"]>;
  selected?: TripSummaryProps["trip"] | null;
  onSelect?: (trip: TripSummaryProps["trip"]) => void;
  onRefetch: () => void;
}

export default function TripSummariesList({
  trips,
  selected,
  onSelect,
  onRefetch,
}: TripSummariesProps) {
  return (
    <Stack component="div" spacing={2} direction="column">
      {trips.map((trip) => (
        <Box
          key={trip._id}
          sx={(theme) => ({
            borderColor: theme.palette.primary.main,
            borderWidth: selected?._id === trip._id ? 2 : 0,
            borderStyle: "solid",
            borderRadius: 5,
          })}
        >
          <TripSummary
            trip={trip}
            onClick={() => (onSelect ? onSelect(trip) : null)}
            onRefetch={onRefetch}
          />
        </Box>
      ))}
    </Stack>
  );
}
