import { isValid, formatDuration as dateFnsFormatDuration } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { enGB, enUS, fr } from "date-fns/locale";
import LocaleProvider, { Locale } from "../../providers/LocaleProvider";

export const localeEnumToDateFnLocale: { [key in Locale]: typeof enUS } = {
  [Locale.FR]: fr,
  [Locale.EN]: enGB,
  [Locale.EN_US]: enUS,
};

export const fallbackTimezone = LocaleProvider.getTimezone();

export const locale = localeEnumToDateFnLocale[LocaleProvider.getLocale()];

export function formatDateTime(
  value: Date | string,
  timezone?: string | null,
  showTimezone = true,
  customFormat?: string
) {
  if (!isValid(new Date(value))) {
    return "Invalid Datetime";
  }
  return formatInTimeZone(
    new Date(value),
    timezone || fallbackTimezone,
    customFormat || `Pp ${showTimezone ? "(zzz)" : ""}`.trim(),
    {
      locale,
    }
  );
}

export function formatTime(
  value: Date | string | undefined | null,
  timezone?: string | null,
  showTimezone = true
) {
  if (!value) {
    return "";
  }
  if (!isValid(new Date(value))) {
    return "Invalid Time";
  }
  return formatInTimeZone(
    new Date(value),
    timezone || fallbackTimezone,
    `p ${showTimezone ? "(zzz)" : ""}`.trim(),
    {
      locale: localeEnumToDateFnLocale[LocaleProvider.getLocale()],
    }
  );
}

export function formatDate(
  value: Date | string | undefined | null,
  timezone?: string | null,
  showTimezone = true
) {
  if (!value) {
    return "";
  }
  if (!isValid(new Date(value))) {
    return "Invalid Date";
  }
  return formatInTimeZone(
    new Date(value),
    timezone || fallbackTimezone,
    `P ${showTimezone ? "(zzz)" : ""}`.trim(),
    {
      locale: localeEnumToDateFnLocale[LocaleProvider.getLocale()],
    }
  );
}

type ShipmentLocationWithTimezone = {
  addressTimezone?: string | null;
  shipper?: {
    addressTimezone?: string | null;
  } | null;
  receiver?: {
    addressTimezone?: string | null;
  } | null;
};

export function formatDateTimeForLocation(
  value: Date | string,
  location?: ShipmentLocationWithTimezone | null,
  showTimezone = true
) {
  return formatDateTime(
    value,
    location?.addressTimezone ||
      location?.receiver?.addressTimezone ||
      location?.shipper?.addressTimezone,
    showTimezone
  );
}

export function formatDateForLocation(
  value: Date | string,
  location?: ShipmentLocationWithTimezone | null,
  showTimezone = true
) {
  return formatDate(
    value,
    location?.addressTimezone ||
      location?.receiver?.addressTimezone ||
      location?.shipper?.addressTimezone,
    showTimezone
  );
}

export function formatTimeForLocation(
  value: Date | string,
  location?: ShipmentLocationWithTimezone | null,
  showTimezone = true
) {
  return formatTime(
    value,
    location?.addressTimezone ||
      location?.receiver?.addressTimezone ||
      location?.shipper?.addressTimezone,
    showTimezone
  );
}

export function formatLocationTimezone(
  location?: ShipmentLocationWithTimezone | null
) {
  return formatInTimeZone(
    new Date(),
    location?.addressTimezone ||
      location?.shipper?.addressTimezone ||
      location?.receiver?.addressTimezone ||
      fallbackTimezone,
    "zzz"
  );
}

export function formatDuration(durationInSeconds: number) {
  return dateFnsFormatDuration(
    {
      hours: Math.floor(durationInSeconds / 3600),
      minutes: Math.floor((durationInSeconds % 3600) / 60),
      seconds: durationInSeconds % 60,
    },
    {
      format: ["hours", "minutes"],
    }
  );
}

const zeroPad = (num: number) => String(num).padStart(2, "0");

export function formatDurationClock(durationInSeconds: number) {
  return dateFnsFormatDuration(
    {
      hours: Math.floor(durationInSeconds / 3600),
      minutes: Math.floor((durationInSeconds % 3600) / 60),
      seconds: durationInSeconds % 60,
    },
    {
      format: ["hours", "minutes"],
      zero: true,
      delimiter: ":",
      locale: {
        formatDistance: (_token, count) => zeroPad(count),
      },
    }
  );
}
