import { Alert } from "@mui/material";
import { toArray } from "lodash";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  GetReceiverForecastDetailsQuery,
  useDeleteReceiverForecastMutation,
  useGetReceiverForecastDetailsQuery,
} from "../../../graphql/generated";
import {
  hideDialog,
  showDialog,
} from "../../../redux/slices/alert/Alert.slice";
import LoadingOverlay from "../../common/LoadingOverlay";
import ReceiverForecastDetails from "./ReceiverForecastDetails";

type ReceiverForecastDetailsContainerProps = {
  receiverForecastId: string;
  onLoad?: (
    receiverForecast: GetReceiverForecastDetailsQuery["receiverForecastById"]
  ) => void;
};

function ReceiverForecastDetailsContainer({
  receiverForecastId,
  onLoad,
}: ReceiverForecastDetailsContainerProps) {
  const query = useGetReceiverForecastDetailsQuery({
    id: receiverForecastId,
  });
  const deleteReceiverForecastMutation = useDeleteReceiverForecastMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (query.data?.receiverForecastById) {
      onLoad?.(query.data?.receiverForecastById);
    }
  }, [onLoad, query.data]);

  if (query.isLoading) {
    return <LoadingOverlay loading />;
  }
  if (query.error) {
    return (
      <Alert severity="error">
        An error occurred while fetching.{" "}
        {(query.error as Error).message || "Unknown error"}
      </Alert>
    );
  }
  if (!toArray(query.data)[0]) {
    return <Alert severity="error">Not found</Alert>;
  }
  return (
    <ReceiverForecastDetails
      receiverForecast={toArray(query.data)[0]}
      onEdit={() => {
        navigate(`/forecasting/${receiverForecastId}/edit`);
      }}
      onDelete={async () => {
        dispatch(
          showDialog({
            title: "Danger zone",
            description:
              "Do you really want to delete this receiverForecast? This action cannot be undone.",
            type: "error",
            actions: [
              {
                type: "error",
                title: "Yes, Delete",
                onClick: async () => {
                  try {
                    await deleteReceiverForecastMutation.mutateAsync({
                      id: receiverForecastId,
                    });
                    dispatch(hideDialog());
                    navigate("/forecasting");
                  } catch (error) {
                    console.error(error);
                    dispatch(
                      showDialog({
                        title: "Error",
                        description:
                          "An error occurred while deleting the receiverForecast." +
                            (deleteReceiverForecastMutation.error as Error)
                              .message || "Unknown error",
                      })
                    );
                  }
                },
              },
              {
                type: "primary",
                title: "No, Cancel",
              },
            ],
          })
        );
      }}
      onReadingsRefresh={() => {
        query.refetch();
      }}
    />
  );
}

export default ReceiverForecastDetailsContainer;
