import {
  ListItemText,
  ListSubheader,
  MenuItem,
  TextField,
  TextFieldProps,
} from "@mui/material";

type StateSelectProps = TextFieldProps;

const US_STATES = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

const CANADA_STATES = [
  "Alberta",
  "British Columbia",
  "Manitoba",
  "New Brunswick",
  "Newfoundland and Labrador",
  "Nova Scotia",
  "Ontario",
  "Prince Edward Island",
  "Quebec",
  "Saskatchewan",
  "Northwest Territories",
  "Nunavut",
  "Yukon",
];

export default function StateSelect({ ...props }: StateSelectProps) {
  return (
    <TextField select {...props}>
      <ListSubheader>United States</ListSubheader>
      {US_STATES.map((state) => (
        <MenuItem key={state} value={state}>
          <ListItemText primary={state} />
        </MenuItem>
      ))}
      <ListSubheader>Canada</ListSubheader>
      {CANADA_STATES.map((state) => (
        <MenuItem key={state} value={state}>
          <ListItemText primary={state} />
        </MenuItem>
      ))}
    </TextField>
  );
}
