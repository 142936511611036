import {
  Stack,
  Box,
  FormHelperText,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Chip,
  OutlinedInput,
  SelectChangeEvent,
} from "@mui/material";
import { capitalize } from "lodash";
import { useEffect, useState } from "react";
import {
  GetShipmentQuery,
  NoteAccessLevel,
  ShipmentNoteInput,
} from "../../../../graphql/generated";
import { FormError } from "../../../../redux/slices/Types";
import { omit } from "lodash";
import { useTranslation } from "react-i18next";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

export type NoteFormMode = "readOnly" | "edit" | "add";

interface NoteFormProps {
  note: ShipmentNoteInput;
  onChange: (note: ShipmentNoteInput) => void;
  errors?: FormError;
  shipmentLocations: GetShipmentQuery["shipmentById"]["shipmentLocations"];
  mode?: NoteFormMode;
}

export default function NoteForm({
  note,
  onChange,
  errors,
  shipmentLocations,
  mode,
}: NoteFormProps) {
  const { t } = useTranslation("orders");
  const [formErrors, setFormErrors] = useState<FormError | undefined>(errors);

  useEffect(() => {
    setFormErrors(errors);
  }, [errors]);

  const removeError = (name: string) => {
    const err: FormError = {
      formId: formErrors?.formId || "",
      error: omit(formErrors?.error, name),
    };
    setFormErrors(err);
  };

  const handleChange = (name: string, value: string) => {
    removeError(name);
    if (name === "receiver") {
      return onChange({ ...note, [name]: value, shipper: null });
    }
    if (name === "shipper") {
      return onChange({ ...note, [name]: value, receiver: null });
    }
    if (name === "none") {
      return onChange({
        ...note,
        receiver: null,
        shipper: null,
      });
    }
    onChange({ ...note, [name]: value });
  };

  const handleAccessLevelChange = (
    event: SelectChangeEvent<NoteAccessLevel[]>
  ) => {
    const {
      target: { value },
    } = event;
    removeError("accessLevel");
    onChange({
      ...note,
      accessLevel: typeof value === "string" ? note.accessLevel : value,
    });
  };

  const shippersAndReceivers = shipmentLocations.map((location) => ({
    kind: location.receiver ? "receiver" : "shipper",
    name: location.receiver ? location.receiver?.name : location.shipper?.name,
    id: location.receiver ? location.receiver?._id : location.shipper?._id,
  }));

  return (
    <Stack component="form" spacing={3}>
      <Stack
        direction={{
          sm: "row",
          xs: "column",
        }}
        spacing={2}
      >
        <TextField
          size="small"
          label={t("notes.associatedLocation", "Associated location")}
          name="shipment"
          select
          fullWidth
          disabled={mode && mode === "readOnly"}
          value={note.receiver || note.shipper}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const location = shippersAndReceivers.find(
              (c) => c.id === event.target.value
            );
            handleChange(location?.kind || "none", location?.id || "none");
          }}
        >
          <MenuItem key={"none"} value={"none"}>
            {t("none", "-- None --")}
          </MenuItem>
          {shippersAndReceivers.map((shipmentLocation, index) => {
            return (
              <MenuItem key={index} value={shipmentLocation.id}>
                {shipmentLocation.name}
              </MenuItem>
            );
          })}
        </TextField>
        <FormControl fullWidth>
          <InputLabel
            error={formErrors && formErrors.error["accessLevel"] ? true : false}
            id="user-label"
          >
            {t("notes.visibility", "Visibility")}
          </InputLabel>
          <Select
            labelId="user-label"
            id="user"
            disabled={mode && mode === "readOnly"}
            multiple
            size="small"
            value={note.accessLevel}
            onChange={handleAccessLevelChange}
            name="accessLevel"
            error={formErrors && formErrors.error["accessLevel"] ? true : false}
            renderValue={(selected) => (
              <Box
                sx={{
                  "& .MuiChip-root": {
                    height: 20,
                  },
                }}
              >
                {selected.map((value) => (
                  <Chip
                    key={value}
                    label={`${capitalize(
                      t(`notes.accessLevel.${value}`, value)
                    )}`}
                  />
                ))}
              </Box>
            )}
            input={
              <OutlinedInput label={t("notes.visibility", "Visibility")} />
            }
            MenuProps={MenuProps}
          >
            {Object.values(NoteAccessLevel).map((accessLevel, index) => (
              <MenuItem key={index} value={accessLevel}>
                {t(`notes.accessLevel.${accessLevel}`, accessLevel)}
              </MenuItem>
            ))}
          </Select>
          {formErrors && (
            <FormHelperText error>
              {formErrors.error["accessLevel"]}
            </FormHelperText>
          )}
        </FormControl>
      </Stack>
      <TextField
        name="content"
        disabled={mode && mode === "readOnly"}
        defaultValue={note.content}
        InputLabelProps={{ shrink: true }}
        required
        onChange={(event) => {
          handleChange(event.target.name, event.target.value);
        }}
        error={formErrors && formErrors.error["content"] ? true : false}
        helperText={formErrors && formErrors.error["content"]}
        label={t("notes.label", "Notes")}
        multiline
        rows={4}
      />
    </Stack>
  );
}
