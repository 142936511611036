import { Warning } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { differenceInCalendarDays } from "date-fns";
import { Driver } from "../../../graphql/generated";

type DriverLicenseExpiryWarningProps = {
  driver: {
    license?:
      | Pick<Exclude<Driver["license"], undefined | null>, "expiryDate">
      | undefined
      | null;
  };
};

const DriverLicenseExpiryWarning = ({
  driver,
}: DriverLicenseExpiryWarningProps) => {
  const driverExpiryRemainingDays = driver.license?.expiryDate
    ? differenceInCalendarDays(new Date(driver.license.expiryDate), new Date())
    : Infinity;
  return driverExpiryRemainingDays <= 0 ? (
    <Tooltip title="Driver license expired">
      <Warning color="error" />
    </Tooltip>
  ) : driverExpiryRemainingDays <= 14 ? (
    <Tooltip
      title={`Driver license expires in ${driverExpiryRemainingDays} day${
        driverExpiryRemainingDays > 1 ? "s" : ""
      }`}
    >
      <Warning color="warning" />
    </Tooltip>
  ) : null;
};

export default DriverLicenseExpiryWarning;
