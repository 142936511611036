import Joi from "joi";
import { Document } from "../../../graphql/generated";

const documentSchema = Joi.object<Document>({
  name: Joi.string().required().label("Name"),
  url: Joi.string().required().label("URL"),
  _id: Joi.string().allow(null).label("ID"),
});

export default documentSchema;
