import { useGetExtensionsMessagesQuery } from "../../../graphql/generated";
import ExtensionsMessages from "./ExtensionsMessages";

const ExtensionsMessagesContainer = () => {
  const getExtensionsMessagesQuery = useGetExtensionsMessagesQuery(
    {},
    {
      refetchInterval: 60 * 1000,
      refetchOnWindowFocus: true,
    }
  );

  return (
    <ExtensionsMessages
      messages={getExtensionsMessagesQuery.data?.extensionsMessages || []}
    />
  );
};

export default ExtensionsMessagesContainer;
