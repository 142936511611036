import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  Stack,
} from "@mui/material";
import ExtensionCardContainer from "../ExtensionCard";
import { ExtensionWithStatusListItemData } from "../ExtensionsManager/ExtensionsManager";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export type ExtensionInstallPromptProps = {
  extensions: ExtensionWithStatusListItemData[];
};

const ExtensionInstallPrompt = ({
  extensions,
}: ExtensionInstallPromptProps) => {
  const { t } = useTranslation(["settings", "common"]);
  const [open, setOpen] = useState(true);
  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>
        {t("settings:extensions.required", "Extension Required")}
      </DialogTitle>
      <DialogContent>
        <Typography sx={{ mb: 2 }}>
          {t(
            "settings:extensions.mandatoryDesc",
            "You need to install one of the following extensions to use this feature."
          )}
        </Typography>
        <Stack direction="column" spacing={2}>
          {extensions.map((extension) => (
            <ExtensionCardContainer
              key={extension.extension._id}
              extension={extension}
            />
          ))}
        </Stack>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "flex-start",
        }}
      >
        <Button
          variant="outlined"
          onClick={() => {
            setOpen(false);
          }}
        >
          {t("common:cancel", "Cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExtensionInstallPrompt;
