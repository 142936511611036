import {
  Box,
  Button,
  Chip,
  ChipProps,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { CallMerge, CallSplit, ContentCopy } from "@mui/icons-material";
import { first, last, uniq, without } from "lodash";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CustomFieldContext,
  GetOrganizationSettingsQuery,
  GetShipmentListQuery,
  ShipmentLocationType,
  ShipmentSortCriteria,
  Status,
} from "../../../graphql/generated";
import useDialog from "../../../utils/hooks/useDialog";
import { formatDateTimeForLocation } from "../../../utils/labels/formatDateTime";
import {
  EnumTableField,
  SortField,
  TableField,
} from "../../common/LynksTable/LynksTable";
import ShipmentLink from "../../common/ShipmentLink";
import SmartLynksTable, {
  QueryFn,
} from "../../common/SmartLynksTable/SmartLynksTable";
import AssignmentModalContainer from "../../trip/AssignmentModal";
import TripLink from "../../common/TripLink";
import LoadSplittingModalContainer from "../LoadSplittingModal";
import {
  shipmentLocationAddressLabel,
  shipmentLocationLabel,
} from "../../../utils/labels/shipmentLocationLabel";
import RoleGuard from "../../account/Access/RoleGuard";
import { useTranslation } from "react-i18next";
import statusLabel from "../../../utils/labels/statusLabel";
import goodUnitLabel from "../../../utils/labels/goodUnitsLabel";

type ShipmentListItem = GetShipmentListQuery["shipments"]["data"][0];

export const renderExtraLocations = (
  shipment: {
    route?: {
      locations?:
        | {
            locationType: ShipmentLocationType;
            shipper?: {
              name: string;
              address: {
                label: string;
              };
            } | null;
            receiver?: {
              name: string;
              address: {
                label: string;
              };
            } | null;
          }[]
        | null;
    } | null;
  },
  locationType: ShipmentLocationType
) => {
  const sameTypeLocations = shipment.route?.locations?.filter(
    (location) => location.locationType === locationType
  );
  if (sameTypeLocations?.length === 1) {
    return null;
  }
  const furthestLocation =
    locationType === ShipmentLocationType.Pickup
      ? first(shipment.route?.locations)
      : last(shipment.route?.locations);
  const extraDropoffLocations = without(sameTypeLocations, furthestLocation);
  if (!extraDropoffLocations.length) {
    return null;
  }
  return (
    <Tooltip
      title={extraDropoffLocations.map((location) => {
        const shipperOrReceiver =
          location?.locationType === ShipmentLocationType.Pickup
            ? location.shipper
            : location?.receiver;
        return (
          <Box>
            {shipperOrReceiver?.name} - {shipperOrReceiver?.address?.label}
          </Box>
        );
      })}
    >
      <Chip
        label={`+${extraDropoffLocations.length} other${
          extraDropoffLocations.length > 1 ? "s" : ""
        }`}
        color="honeyDew"
        sx={{
          fontWeight: "bold",
        }}
      />
    </Tooltip>
  );
};

export const shipmentStatusColors: Record<Status, ChipProps["color"]> = {
  ASSIGNED: "primary",
  ACTIVE: "primary",
  CANCELLED: "error",
  COMPLETE: "success",
  DELETED: "error",
  DELIVERED: "success",
  PENDING: "default",
  UNASSIGNED: "default",
  WAITING_FOR_APPROVAL: "default",
  WAITING_FOR_ASSIGNMENT: "default",
  PLANNED: "primary",
  IN_PROGRESS: "info",
  FORECASTED: "honeyDew",
  FORECASTED_LOCKED: "default",
  UNPLANNED: "warning",
};

export const sortFields: SortField<ShipmentSortCriteria>[] = [
  {
    criteria: ShipmentSortCriteria.ShipmentNumber,
    label: "orderNumber",
  },
  {
    criteria: ShipmentSortCriteria.PickupDate,
    label: "pickupDate",
  },
  {
    criteria: ShipmentSortCriteria.DropoffDate,
    label: "deliveryDate",
  },
  {
    criteria: ShipmentSortCriteria.PickupDistance,
    label: "pickupLocation",
  },
  {
    criteria: ShipmentSortCriteria.DropoffDistance,
    label: "deliveryLocation",
  },
  {
    criteria: ShipmentSortCriteria.Revenue,
    label: "revenue",
  },
  {
    criteria: ShipmentSortCriteria.RevenuePerMile,
    label: "revenuePerMile",
  },
];

export type ShipmentsListProps = {
  query: QueryFn<
    ShipmentListItem,
    "shipments",
    ShipmentSortCriteria,
    {
      status: Status | null;
    }
  >;
  onRefetch?: () => void;
  onSplitCancel?: (shipmentId: string) => void;
  readonly?: boolean;
  disableSearch?: boolean;
  disablePagination?: boolean;
  disableStatusTab?: boolean;
  excludePastShipments?: boolean;
  receiverId?: string;
  orgSettings?: GetOrganizationSettingsQuery["organizationSettings"];
  onFilteredShipmentsChange?: (shipments: ShipmentListItem[]) => void;
};

export enum ShipmentStatusTab {
  ALL,
  FORECASTED,
  UNASSIGNED,
  ASSIGNED,
  IN_PROGRESS,
  PAST,
  CANCELLED,
}

const statusTabToShipmentStatus: {
  [key in ShipmentStatusTab]: Status | null;
} = {
  [ShipmentStatusTab.ALL]: null,
  [ShipmentStatusTab.ASSIGNED]: Status.Assigned,
  [ShipmentStatusTab.FORECASTED]: Status.Forecasted,
  [ShipmentStatusTab.UNASSIGNED]: Status.WaitingForAssignment,
  [ShipmentStatusTab.IN_PROGRESS]: Status.InProgress,
  [ShipmentStatusTab.PAST]: Status.Complete,
  [ShipmentStatusTab.CANCELLED]: Status.Deleted,
};

export default function ShipmentsList({
  query,
  onRefetch,
  readonly,
  disableSearch,
  disablePagination,
  disableStatusTab,
  excludePastShipments,
  receiverId,
  onSplitCancel,
  orgSettings,
  onFilteredShipmentsChange,
}: ShipmentsListProps) {
  const { t } = useTranslation(["orders", "common"]);
  const statusField: EnumTableField<ShipmentListItem, Status> = {
    value: (shipment) => shipment.status,
    valueLabel: statusLabel,
    label: t("status.label"),
    type: "enum",
    colors: shipmentStatusColors,
    sortBy: "status",
    values: Object.values(Status),
  };
  const fields: TableField<
    GetShipmentListQuery["shipments"]["data"][0],
    Status
  >[] = [
    {
      value: (shipment) => <ShipmentLink shipment={shipment} />,
      label: t("orderNumber"),
      type: "number",
      sortBy: "shipmentNumber",
    },
    {
      value: (shipment) =>
        shipment.trip ? <TripLink trip={shipment.trip} /> : null,
      label: t("tripNumber"),
      type: "number",
      sortBy: "trip.tripNumber",
    },
    statusField,
    {
      value: (shipment) => {
        return (
          <Typography>
            {(shipment.shipmentLocations || [])
              .flatMap((location) =>
                location.shippedGoods?.map(
                  (good) =>
                    `${good.label} (${good.quantity} ${goodUnitLabel(
                      good.unit
                    )})`
                )
              )
              .join(", ")}
          </Typography>
        );
      },
      label: t("commodities"),
      type: "string",
    },
    {
      value: (shipment) => (
        <Typography variant="body1">
          {formatDateTimeForLocation(
            shipment.route?.firstPickupTime,
            shipment.route?.locations && shipment.route?.locations[0]
          )}
        </Typography>
      ),
      label: t("pickupDate"),
      type: "datetime",
      sortBy: "route.firstPickupTime",
    },
    {
      value: (shipment) => (
        <Typography variant="body1">
          {formatDateTimeForLocation(
            shipment.route?.lastDropoffTime,
            last(shipment.route?.locations)
          )}
        </Typography>
      ),
      label: t("deliveryDate"),
      type: "datetime",
      sortBy: "route.lastDropoffTime",
    },
    {
      value: (shipment) => {
        const lastDropoffLocation = last(shipment.route?.locations);
        return (
          <Typography variant="body1">
            {formatDateTimeForLocation(
              lastDropoffLocation?.timeWindows?.[0]?.fromDate,
              lastDropoffLocation
            )}{" "}
            -{" "}
            {formatDateTimeForLocation(
              lastDropoffLocation?.timeWindows?.[0]?.toDate,
              lastDropoffLocation
            )}
          </Typography>
        );
      },
      label: "Delivery Time Window",
      type: "datetime",
      sortBy: "route.locations.1.timeWindows.0.fromDate",
    },
    {
      value: (shipment) =>
        shipmentLocationLabel(first(shipment.route?.locations)),
      subtitle: (shipment) =>
        shipmentLocationAddressLabel(first(shipment.route?.locations)),
      extra: (shipment) => {
        return renderExtraLocations(shipment, ShipmentLocationType.Pickup);
      },
      label: t("shipper"),
      type: "string",
      sortBy: "route.locations.0.shipper.name",
    },
    {
      value: (shipment) =>
        shipmentLocationLabel(last(shipment.route?.locations)),
      subtitle: (shipment) =>
        shipmentLocationAddressLabel(last(shipment.route?.locations)),
      extra: (shipment) => {
        return renderExtraLocations(shipment, ShipmentLocationType.DropOff);
      },
      label: t("consigneeReceiver"),
      type: "string",
      sortBy: "route.locations.1.receiver.name",
    },
    {
      value: (shipment) => (
        <Stack direction="row" spacing={1}>
          {shipment.tags?.map((tag) => (
            <Chip label={tag} />
          ))}
        </Stack>
      ),
      sortBy: "tags",
      label: t("common:tags"),
      type: "string",
    },
  ];

  const translatedSortFields = sortFields.map((field) => ({
    ...field,
    // @ts-expect-error
    label: t(field.label) as string,
  }));

  const [selected, setSelected] = useState<ShipmentListItem[]>([]);

  const [assignmentModalOpen, setAssignmentModalOpen] = useState(false);
  const [loadSplittingModalOpen, setLoadSplittingModalOpen] = useState(false);
  const [loadToSplit, setLoadToSplit] = useState<ShipmentListItem | null>(null);
  const [statusTab, setStatusTab] = useState<ShipmentStatusTab>(
    ShipmentStatusTab.ALL
  );
  const { showDialog } = useDialog();

  const openAssignmentModal = () => setAssignmentModalOpen(true);
  const closeAssignmentModal = () => setAssignmentModalOpen(false);
  const navigate = useNavigate();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      {disableStatusTab ? null : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Tabs
            value={statusTab}
            onChange={(event, value) => {
              setStatusTab(value);
            }}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
          >
            <Tab
              label={t("orders:filter.all", "All")}
              value={ShipmentStatusTab.ALL}
            />
            <Tab
              label={t("orders:filter.unassigned", "Unassigned")}
              value={ShipmentStatusTab.UNASSIGNED}
            />
            <Tab
              label={t("orders:filter.assigned", "Assigned")}
              value={ShipmentStatusTab.ASSIGNED}
            />
            <Tab
              label={t("orders:filter.inProgress", "In Progress")}
              value={ShipmentStatusTab.IN_PROGRESS}
            />
            <Tab
              label={t("orders:filter.forecasted", "Forecasted")}
              value={ShipmentStatusTab.FORECASTED}
            />
            <Tab
              label={t("orders:filter.complete", "Completed")}
              value={ShipmentStatusTab.PAST}
            />
            <Tab
              label={t("orders:filter.cancelled", "Cancelled")}
              value={ShipmentStatusTab.CANCELLED}
            />
          </Tabs>
        </Box>
      )}
      {!readonly ? (
        <RoleGuard roles={["Carrier Admin", "Manager", "Dispatcher"]}>
          <Stack
            sx={{
              alignSelf: "end",
              mb: 2,
              mt: 2,
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: 1,
            }}
          >
            <Button
              id="assign-button"
              variant="outlined"
              color="primary"
              size="large"
              onClick={() => {
                if (!selected.length) {
                  if (showDialog) {
                    showDialog({
                      title: t(
                        "orders:alert.selectLoadFirst",
                        "Select a load first"
                      ),
                      description: t(
                        "orders:alert.selectLoadFirstDesc",
                        "You need to select a load first before assigning"
                      ),
                      type: "primary",
                      actions: [
                        {
                          type: "primary",
                          title: t("common:OK", "OK"),
                        },
                      ],
                    });
                  }
                  return;
                }
                const trailerTypes = uniq(
                  selected.map((shipment) => shipment.trailerType)
                );
                if (trailerTypes.length > 1) {
                  if (showDialog) {
                    showDialog({
                      title: t(
                        "orders:alert.mixedTrailer",
                        "Mixed trailer types"
                      ),
                      description: t(
                        "orders:alert.mixedTrailerDesc",
                        "You can't mix loads with different trailer types on the same trip"
                      ),
                      type: "primary",
                      actions: [
                        {
                          type: "primary",
                          title: t("common:OK", "OK"),
                        },
                      ],
                    });
                  }
                  return;
                }
                const hasShipmentInProgress =
                  selected.length > 1 &&
                  selected.some(
                    (shipment) => shipment.status === Status.InProgress
                  );
                if (
                  hasShipmentInProgress &&
                  !orgSettings?.dispatching?.canConsolidateInProgressShipments
                ) {
                  if (showDialog) {
                    showDialog({
                      title: t(
                        "orders:alert.inProgress",
                        "In progress shipments"
                      ),
                      description: t(
                        "orders:alert.inProgressDesc",
                        "You can't consolidate in progress shipments"
                      ),
                      type: "primary",
                      actions: [
                        {
                          type: "primary",
                          title: t("common:OK", "OK"),
                        },
                      ],
                    });
                  }
                  return;
                }
                openAssignmentModal();
              }}
            >
              {t("common:assign", "Assign")}
            </Button>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              href="/trips/generate"
            >
              {t("orders:suggestTrips", "Suggest trips")}
            </Button>
            <Button
              href="/orders/new"
              variant="contained"
              color="secondary"
              size="large"
              id="new-load-button"
            >
              {t("orders:new", "New Order")}
            </Button>
          </Stack>
        </RoleGuard>
      ) : null}
      <Box
        sx={{
          height: "calc(100% - 122px)",
        }}
      >
        <SmartLynksTable
          query={query}
          additionalQueryVariables={{
            status: statusTabToShipmentStatus[statusTab],
            receiverId,
            excludePastShipments,
          }}
          dataKey="shipments"
          id="shipments-table-2"
          fields={fields}
          sortFields={translatedSortFields}
          detailsUrlPrefix={(row) =>
            (row.isFromSplit || row.isFromRotation) && row.parentShipment
              ? `/orders/details/${row.parentShipment._id}`
              : `/orders/details/${row._id}`
          }
          onSelect={setSelected}
          disableSearch={disableSearch}
          disablePagination={disablePagination}
          actions={[
            {
              icon: <ContentCopy />,
              label: t("common:list.duplicate", "Duplicate"),
              tooltip: t("common:list.duplicate", "Duplicate"),
              onClick: (row) => {
                navigate(`/orders/new`, { state: { shipment: row._id } });
              },
              secondary: true,
            },
            {
              icon: <CallSplit />,
              label: t("common:list.split", "Split"),
              tooltip: t("orders:splitLoad", "Split the order"),
              secondary: true,
              onClick: (row) => {
                setLoadToSplit(row);
                setLoadSplittingModalOpen(true);
              },
              isApplicable: (row) => !row.isSplit,
            },
            {
              icon: <CallMerge />,
              label: t("common:list.cancelSplit"),
              tooltip: t("orders:cancelLoadSplit"),
              secondary: true,
              onClick: async (row) => {
                onSplitCancel?.(row._id);
              },
              isApplicable: (row) => !!row.isSplit,
            },
          ]}
          customFieldContext={[CustomFieldContext.Shipment]}
          getRecordParentId={(record) => record.parentShipment?._id}
          onFilteredRecordsChange={onFilteredShipmentsChange}
          showGroups
        />
      </Box>

      <AssignmentModalContainer
        tripId={
          uniq(selected.map((shipment) => shipment.tripId)).length === 1
            ? selected[0].tripId
            : null
        }
        shipments={selected}
        open={assignmentModalOpen}
        onClose={(tripId) => {
          closeAssignmentModal();
          if (tripId) {
            navigate(`/trips/details/${tripId}`);
          }
        }}
      />

      {loadToSplit && (
        <LoadSplittingModalContainer
          shipment={loadToSplit}
          open={loadSplittingModalOpen}
          onClose={() => {
            setLoadSplittingModalOpen(false);
            onRefetch?.();
          }}
        />
      )}
    </div>
  );
}
