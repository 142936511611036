import { MeasurementSystem } from "../../graphql/generated";
import LocaleProvider from "../../providers/LocaleProvider";
import {
  feetToFeetInches,
  metersToLocalDistance,
  mileage,
} from "../conversion/distance";

export default function formatDistance(
  distanceInMeters: number | null | undefined,
  includeUnit = true
) {
  if (distanceInMeters === null || distanceInMeters === undefined) {
    return "";
  }
  if (LocaleProvider.getMeasurementSystem() === MeasurementSystem.Imperial) {
    const feetInches = feetToFeetInches(
      metersToLocalDistance(distanceInMeters)
    );
    return `${feetInches.feet}' ${feetInches.inches}''`;
  }
  return `${metersToLocalDistance(distanceInMeters)} ${
    includeUnit ? LocaleProvider.getDistanceUnit() : ""
  }`.trim();
}

export function formatMileage(distanceInMeters: number, includeUnit = true) {
  const roundedMileage = mileage(distanceInMeters);
  return `${roundedMileage} ${
    includeUnit ? LocaleProvider.getMileageUnit() : ""
  }${includeUnit && roundedMileage > 1 ? "s" : ""}`.trim();
}
