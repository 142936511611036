import {
  useBulkAddBusinessEntitiesMutation,
  useGetBusinessEntityListQuery,
} from "../../../graphql/generated";
import SuppliersList from "./SuppliersList";

export default function SuppliersListContainer() {
  const bulkCreateBusinessEntities = useBulkAddBusinessEntitiesMutation();
  return (
    <SuppliersList
      onBulkCreate={(suppliers) =>
        bulkCreateBusinessEntities.mutateAsync({
          newBusinessEntityData: suppliers,
        })
      }
      query={useGetBusinessEntityListQuery}
    />
  );
}
