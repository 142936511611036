import React, { useCallback } from "react";
import HeaderTitle from "../../components/common/HeaderTitle";
import ShipmentInfos from "../../components/shipment/ShipmentInfos";
import { Shipment } from "../../graphql/generated";
import { useNavigationOptions } from "../LayoutRoutes";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ViewShipment = () => {
  const { setNavigationOptions } = useNavigationOptions();
  const { t } = useTranslation("orders");
  const onLoad = useCallback(
    (shipment: Pick<Shipment, "shipmentNumber" | "referenceNumbers">) => {
      setNavigationOptions({
        title: (
          <HeaderTitle
            label={t("order")}
            suffix={
              (shipment?.shipmentNumber || "") +
              (shipment?.referenceNumbers?.length
                ? ` / ${shipment.referenceNumbers.join(", ")}`
                : "")
            }
          />
        ),
        showBackIcon: true,
      });
    },
    [setNavigationOptions, t]
  );

  const { id = "" } = useParams();

  return <ShipmentInfos shipmentId={id} onLoad={onLoad} />;
};

export default ViewShipment;
