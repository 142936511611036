import Joi from "joi";
import {
  DailyForecastingModelSlotInput,
  ForecastingModelInput,
  ForecastingModelSlotInput,
  ForecastingModelSlotType,
  HourlyForecastingModelSlotInput,
  MonthlyForecastingModelSlotInput,
  NewReceiverForecastInput,
  ReadingsFileConfigurationInput,
  TimeInput,
  TrailerProfileCompartmentInput,
  TrailerProfileInput,
} from "../../../graphql/generated";

const timeSchema = Joi.object<TimeInput>({
  hour: Joi.number().required().label("Hour"),
  minute: Joi.number().required().label("Minute"),
});

const forecastingModelSlotSchema = Joi.object<ForecastingModelSlotInput>({
  type: Joi.string().required().label("Type"),
  daily: Joi.when("type", {
    is: ForecastingModelSlotType.Daily,
    then: Joi.object<DailyForecastingModelSlotInput>({
      dayOfWeek: Joi.number().required().label("Day of Week"),
    }),
  }),
  hourly: Joi.when("type", {
    is: ForecastingModelSlotType.Hourly,
    then: Joi.object<HourlyForecastingModelSlotInput>({
      dayOfWeek: Joi.number().required().label("Day of Week"),
      startTime: timeSchema.required().label("Start Time"),
      endTime: timeSchema.required().label("End Time"),
    }),
  }),
  monthly: Joi.when("type", {
    is: ForecastingModelSlotType.Monthly,
    then: Joi.object<MonthlyForecastingModelSlotInput>({
      month: Joi.number().required().label("Month"),
    }),
  }),
  salesAmount: Joi.number().required().label("Sales Amount"),
});

const forecastingModelSchema = Joi.object<ForecastingModelInput>({
  _id: Joi.string().optional().allow(null, "").label("ID"),
  storageFacilityId: Joi.string().required().label("Storage Facility ID"),
  label: Joi.string().optional().label("Label").allow(null, ""),
  slots: Joi.array()
    .items(forecastingModelSlotSchema)
    .required()
    .label("Slots"),
  contaminates: Joi.boolean().optional().allow(null, "").label("Contaminates"),
  isExclusive: Joi.boolean().optional().allow(null, "").label("Is Exclusive"),
  minCapacity: Joi.number().optional().allow(null, "").label("Min Capacity"),
  maxCapacity: Joi.number().optional().allow(null, "").label("Max Capacity"),
  target: Joi.number().optional().allow(null, "").label("Target"),
  threshold: Joi.number().optional().allow(null, "").label("Threshold"),
  weight: Joi.number().optional().allow(null, "").label("Weight"),
});

const trailerCompartmentSchema = Joi.object<TrailerProfileCompartmentInput>({
  capacity: Joi.number().required().label("Capacity").min(1),
  label: Joi.string().required().label("Label"),
  maxWeight: Joi.number().required().label("Max Weight").min(1),
  minUsage: Joi.number()
    .required()
    .label("Min Usage")
    .min(0)
    .max(Joi.ref("capacity"))
    .message("Min Usage must be less than or equal to Capacity"),
  hasSeparatorBefore: Joi.boolean()
    .optional()
    .allow(null, "")
    .label("Has Separator Before"),
  hasSeparatorAfter: Joi.boolean()
    .optional()
    .allow(null, "")
    .label("Has Separator After"),
});

const trailerProfileSchema = Joi.object<TrailerProfileInput>({
  compartments: Joi.array()
    .items(trailerCompartmentSchema)
    .required()
    .label("Compartments")
    .min(1),
});

const readingsFileConfigSchema = Joi.object<ReadingsFileConfigurationInput>({
  dateColumn: Joi.string().required().label("Date Column"),
  dateFormat: Joi.string().required().label("Date Format"),
  levelColumn: Joi.string().required().label("Level Column"),
  timeColumn: Joi.string().required().label("Time Column"),
  timeFormat: Joi.string().required().label("Time Format"),
  tankIdColumn: Joi.string().required().label("Tank ID Column"),
  storeColumn: Joi.string().optional().allow(null, "").label("Store Column"),
  readingDeviationCriticalThreshold: Joi.number()
    .optional()
    .allow(null, "")
    .label("Reading Deviation Critical Threshold"),
  readingDeviationDangerThreshold: Joi.number()
    .optional()
    .allow(null, "")
    .label("Reading Deviation Danger Threshold"),
  readingDeviationWarningThreshold: Joi.number()
    .optional()
    .allow(null, "")
    .label("Reading Deviation Warning Threshold"),
  runoutCriticalThreshold: Joi.number()
    .optional()
    .allow(null, "")
    .label("Runout Critical Threshold"),
  runoutDangerThreshold: Joi.number()
    .optional()
    .allow(null, "")
    .label("Runout Danger Threshold"),
  runoutWarningThreshold: Joi.number()
    .optional()
    .allow(null, "")
    .label("Runout Warning Threshold"),
  staleReadingCriticalThreshold: Joi.number()
    .optional()
    .allow(null, "")
    .label("Stale Reading Critical Threshold"),
  staleReadingDangerThreshold: Joi.number()
    .optional()
    .allow(null, "")
    .label("Stale Reading Danger Threshold"),
  staleReadingWarningThreshold: Joi.number()
    .optional()
    .allow(null, "")
    .label("Stale Reading Warning Threshold"),
});

const receiverForecastSchema = Joi.object<NewReceiverForecastInput>({
  models: Joi.array().items(forecastingModelSchema).required().label("Models"),
  readingsFileConfiguration: readingsFileConfigSchema
    .required()
    .label("Readings File Configuration"),
  trailerProfile: trailerProfileSchema.required().label("Trailer Profile"),
  receiverId: Joi.string().required().label("Receiver"),
});

export default receiverForecastSchema;
