import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Stack,
} from "@mui/material";
import { useEffect, useState } from "react";
import ShipmentEventForm from "../ShipmentEventForm/ShipmentEventForm";
import {
  ShipmentEventInput,
  ShipmentEventType,
} from "../../../graphql/generated";
import { Delete } from "@mui/icons-material";
import ErrorMessage from "../../common/ErrorMessage/ErrorMessage";
import LoadingOverlay from "../../common/LoadingOverlay";
import { LoadingButton } from "@mui/lab";

export interface ShipmentEventFormModalProps {
  isOpened: boolean;
  initialShipmentEvent?: ShipmentEventInput | null;
  title?: string;
  error: Error | null;
  loading: boolean;
  onClose: () => void;
  onSubmit: (shipmentEvent: ShipmentEventInput) => void;
  onDelete: (shipmentEvent: ShipmentEventInput) => void;
}

const newShipmentEvent: ShipmentEventInput = {
  type: ShipmentEventType.PickupCheckpointArrive,
  shipment: "",
  location: {
    latitude: 0,
    longitude: 0,
  },
  shipmentLocation: "",
  trip: "",
};

export default function ShipmentEventFormModal({
  isOpened,
  initialShipmentEvent,
  title,
  error,
  loading,
  onClose,
  onSubmit,
  onDelete,
}: ShipmentEventFormModalProps) {
  const [shipmentEvent, setShipmentEvent] = useState<ShipmentEventInput>(
    initialShipmentEvent || newShipmentEvent
  );

  useEffect(() => {
    if (initialShipmentEvent) {
      setShipmentEvent(initialShipmentEvent);
    } else {
      setShipmentEvent(newShipmentEvent);
    }
  }, [initialShipmentEvent]);

  const handleCancel = () => {
    onClose();
  };

  const handleSubmit = () => {
    onSubmit(shipmentEvent);
    setShipmentEvent({ ...newShipmentEvent });
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={isOpened}>
      <DialogTitle variant="h6">{title || "Event"}</DialogTitle>
      <DialogContent dividers sx={{ p: 2 }}>
        <ErrorMessage error={error} />
        <LoadingOverlay loading={loading} />
        <ShipmentEventForm
          shipmentEvent={shipmentEvent}
          onChange={(updates) =>
            setShipmentEvent({
              ...shipmentEvent,
              ...updates,
            })
          }
        />
      </DialogContent>
      <DialogActions>
        <Stack direction="row" justifyContent="space-between" flex={1}>
          <IconButton onClick={() => onDelete(shipmentEvent)}>
            <Delete />
          </IconButton>
          <Stack direction="row" spacing={2}>
            <Button
              autoFocus
              color="inherit"
              variant="contained"
              onClick={handleCancel}
            >
              Close
            </Button>
            <LoadingButton
              color="primary"
              variant="contained"
              onClick={handleSubmit}
              id="save-event-button"
              loading={loading}
            >
              Save
            </LoadingButton>
          </Stack>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
