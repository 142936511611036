import {
  Address,
  AssetStatus,
  GetLoadBoardLoadsQuery,
  LoadBoardSearchCriteria,
  LoadType,
  TrailerType,
} from "../../../graphql/generated";
import { TableField } from "../../common/LynksTable/LynksTable";
import SmartLynksTable, {
  QueryFn,
} from "../../common/SmartLynksTable/SmartLynksTable";
import trailerTypeLabel from "../../../utils/labels/trailerTypeLabel";
import { first, last, sum } from "lodash";
import { formatDate } from "../../../utils/labels/formatDateTime";
import formatPhoneNumber from "../../../utils/labels/formatPhoneNumber";
import formatWeight from "../../../utils/labels/formatWeight";
import LocaleProvider from "../../../providers/LocaleProvider";
import {
  Dialog,
  DialogContent,
  Grid,
  Link,
  Slider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import AddressInput from "../../asset-management/AddressInput";
import { useMemo, useState } from "react";
import EnumSelect from "../../common/EnumSelect";
import enumLabel from "../../../utils/labels/enumLabel";
import LoadBoardLoadDetailsContainer from "../LoadBoardLoadDetails";
import { DatePicker } from "@mui/x-date-pickers";
import { addDays, max } from "date-fns";
import { Radar } from "@mui/icons-material";
import ExtensionInstallPromptContainer from "../../extensions/ExtensionInstallPrompt";
import { utcToZonedTime } from "date-fns-tz";
import { ExtensionWithStatusListItemData } from "../../extensions/ExtensionsManager/ExtensionsManager";
import { useTranslation } from "react-i18next";
const states = require("us-state-converter");

type LoadListItem = GetLoadBoardLoadsQuery["loadBoardLoads"]["data"][0];

enum HowOld {
  ONE_DAY = "ONE_DAY",
  TWO_DAYS = "TWO_DAYS",
  THREE_DAYS = "THREE_DAYS",
  ONE_WEEK = "ONE_WEEK",
  ONE_MONTH = "ONE_MONTH",
}

const howOldToHours: Record<HowOld, number> = {
  [HowOld.ONE_DAY]: 24,
  [HowOld.TWO_DAYS]: 48,
  [HowOld.THREE_DAYS]: 72,
  [HowOld.ONE_WEEK]: 168,
  [HowOld.ONE_MONTH]: 720,
};

export default function LoadsList({
  query,
  installedExtensions,
  loadboardRequiresDestination,
}: {
  query: QueryFn<
    LoadListItem,
    "loadBoardLoads",
    string,
    {
      searchCriteria: LoadBoardSearchCriteria;
    }
  >;
  installedExtensions: ExtensionWithStatusListItemData[];
  loadboardRequiresDestination: boolean;
}) {
  const { t } = useTranslation(["loadboard", "trailers"]);
  const [originAddress, setOriginAddress] = useState<Address | null>(null);
  const [destinationAddress, setDestinationAddress] =
    useState<Address | null>(null);
  const [trailerType, setTrailerType] = useState<TrailerType | null>(null);
  const [loadType, setLoadType] = useState<LoadType | null>(null);
  const [openLoad, setOpenLoad] = useState<LoadListItem | null>(null);
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [originRadius, setOriginRadius] = useState<number>(25);
  const [destinationRadius, setDestinationRadius] = useState<number>(25);
  const [howOld, setHowOld] = useState<HowOld | null>(HowOld.ONE_WEEK);
  const fields: TableField<LoadListItem, AssetStatus>[] = useMemo(
    () => [
      {
        value: (load) =>
          [load.trailerType, ...(load.additionalTrailerTypes || [])]
            .map(trailerTypeLabel)
            .join(", "),
        subtitle: (load) => enumLabel(load.loadType),
        label: t("trailerType"),
        type: "string",
      },
      {
        value: (load) => first(load.shipmentLocations)?.addressLabel,
        subtitle: (load) =>
          formatDate(
            first(load.shipmentLocations)?.timeWindows?.[0]?.fromDate,
            null,
            false
          ),
        label: t("pickup"),
        type: "string",
      },
      {
        value: (load) => last(load.shipmentLocations)?.addressLabel,
        subtitle: () => "N/A",
        label: t("dropoff"),
        type: "string",
      },
      {
        value: (load) => load.customer.name,
        subtitle: (load) =>
          formatPhoneNumber(load.customer.contact.phoneNumber),
        label: t("customer"),
        type: "string",
      },
      {
        value: (load) =>
          formatWeight(
            sum(
              load.shipmentLocations.flatMap((location) =>
                location.shippedGoods?.map(
                  (good) => (good.weight || 0) * good.quantity
                )
              )
            )
          ),
        label: t("totalWeight"),
        type: "string",
      },
      {
        value: (load) =>
          load.charges.length
            ? `${LocaleProvider.getCurrencySymbol()} ${sum(
                load.charges.map((charge) => charge.total)
              )}`
            : "N/A",
        label: t("revenue"),
        type: "string",
      },
    ],
    [t]
  );
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <ExtensionInstallPromptContainer
        extensionNames={["TruckStop", "123Loadboard"]}
      />
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography>
            {t("searchForLoadsInYourConnectedLoadBoards")}
            {/* Search for loads in your connected load boards */}
          </Typography>
          <Typography>
            {t("poweredBy")}{" "}
            {installedExtensions.map((extension) => {
              return (
                <Link
                  href={extension.extension.websiteUrl || ""}
                  component="a"
                  target="_blank"
                >
                  <img
                    src={extension.extension.coverImage || ""}
                    alt={`${extension.extension.name} icon`}
                    style={{
                      width: 100,
                      marginRight: 8,
                    }}
                  />
                </Link>
              );
            })}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AddressInput
            label={t("form.origin")}
            name="origin"
            value={originAddress}
            disableDetails
            onChange={(updates) => {
              if (!updates) {
                setOriginAddress(null);
                return;
              }
              setOriginAddress((address) => ({
                coordinates: { latitude: 0, longitude: 0 },
                label: "",
                ...address,
                ...updates,
              }));
            }}
            searchOptions={{
              types: ["administrative_area_level_1", "locality"],
            }}
            required
          />
          <Stack spacing={2} direction="row" sx={{ mt: 1 }} alignItems="center">
            <Radar />
            <Slider
              aria-label={t("form.originRadius")}
              min={25}
              max={300}
              defaultValue={originRadius}
              valueLabelDisplay="auto"
              onChangeCommitted={(e, radius) => {
                setOriginRadius(radius as number);
              }}
            />
            <Typography
              sx={{
                width: 175,
              }}
            >
              {t("form.originRadiusLabel", { originRadius: originRadius })}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AddressInput
            label={t("form.destination")}
            name="destination"
            value={destinationAddress}
            disableDetails
            onChange={(updates) => {
              if (!updates) {
                setDestinationAddress(null);
                return;
              }
              setDestinationAddress((address) => ({
                coordinates: { latitude: 0, longitude: 0 },
                label: "",
                ...address,
                ...updates,
              }));
            }}
            searchOptions={{
              types: ["administrative_area_level_1", "locality"],
            }}
            required={loadboardRequiresDestination}
          />

          <Stack spacing={2} direction="row" sx={{ mt: 1 }} alignItems="center">
            <Radar />
            <Slider
              aria-label={t("form.destinationRadius")}
              min={25}
              max={300}
              defaultValue={destinationRadius}
              valueLabelDisplay="auto"
              onChangeCommitted={(e, radius) => {
                setDestinationRadius(radius as number);
              }}
            />
            <Typography
              sx={{
                width: 175,
              }}
            >
              {t("form.destinationRadiusLabel", {
                destinationRadius: destinationRadius,
              })}
            </Typography>
          </Stack>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <EnumSelect
            name="trailerType"
            enumObject={TrailerType}
            optionLabel={trailerTypeLabel}
            value={trailerType || null}
            onChange={(event, value) => setTrailerType(value)}
            fullWidth
            label={t("form.trailerType")}
            clearable
          />
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
          <EnumSelect
            name="loadType"
            enumObject={LoadType}
            optionLabel={(loadType) =>
              t(`trailers:loadType.${loadType}`, enumLabel(loadType) || "N/A")
            }
            value={loadType || null}
            onChange={(event, value) => setLoadType(value)}
            fullWidth
            label={t("form.loadType")}
            clearable
          />
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
          <DatePicker
            label={t("form.pickupAfter")}
            value={fromDate}
            onChange={setFromDate}
            renderInput={(params) => <TextField fullWidth {...params} />}
            minDate={max([
              toDate ? addDays(toDate, -6) : new Date(),
              new Date(),
            ])}
            maxDate={toDate || undefined}
            InputProps={{
              name: "fromDate",
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <DatePicker
            label={t("form.pickupBefore")}
            value={toDate}
            onChange={setToDate}
            renderInput={(params) => <TextField fullWidth {...params} />}
            minDate={max([fromDate || new Date(), new Date()])}
            maxDate={fromDate ? addDays(fromDate, 6) : undefined}
            InputProps={{
              name: "toDate",
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <EnumSelect
            name="howOld"
            enumObject={HowOld}
            optionLabel={(howOldType) =>
              t(`form.duration.${howOldType}`, enumLabel(loadType) || "N/A")
            }
            value={howOld || null}
            onChange={(event, value) => setHowOld(value)}
            fullWidth
            label={t("form.howOld")}
            clearable
          />
        </Grid>
      </Grid>
      {originAddress &&
      (!loadboardRequiresDestination || destinationAddress) ? (
        <SmartLynksTable
          disableSearch
          query={query}
          dataKey="loadBoardLoads"
          fields={fields}
          additionalQueryVariables={{
            searchCriteria: {
              originState: originAddress?.state
                ? states.abbr(originAddress.state)
                : null,
              originCity: originAddress?.city,
              destinationState: destinationAddress?.state
                ? states.abbr(destinationAddress.state)
                : null,
              destinationCity: destinationAddress?.city,
              trailerType,
              loadType,
              fromDate: fromDate
                ? utcToZonedTime(fromDate, LocaleProvider.getTimezone())
                : null,
              toDate: toDate
                ? utcToZonedTime(toDate, LocaleProvider.getTimezone())
                : null,
              originRadius,
              destinationRadius,
              hoursOld: howOld ? howOldToHours[howOld] : null,
            },
          }}
          onRecordClick={(record) => setOpenLoad(record)}
          id="load-board-loads-table"
        />
      ) : null}

      <Dialog
        open={!!openLoad}
        onClose={() => {
          setOpenLoad(null);
        }}
        maxWidth="lg"
      >
        <DialogContent
          sx={{
            position: "relative",
            minWidth: 100,
            minHeight: 100,
          }}
        >
          {openLoad ? (
            <LoadBoardLoadDetailsContainer loadId={openLoad._id} />
          ) : null}
        </DialogContent>
      </Dialog>
    </div>
  );
}
