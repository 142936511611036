import { useSnackbar } from "notistack";
import {
  Status,
  useAddShipmentEventMutation,
  useGetOrganizationSettingsQuery,
  useStartTripMutation,
} from "../../../graphql/generated";
import useDialog from "../../../utils/hooks/useDialog";
import TripSummary, { TripSummaryProps } from "./TripSummary";
import { useTranslation } from "react-i18next";
import { capitalize } from "lodash";

type TripSummaryContainerProps = Omit<
  TripSummaryProps,
  "onShipmentEventLog" | "onStart" | "singleClickTripCompletionEnabled"
> & {
  onRefetch: () => void;
};

const TripSummaryContainer = (props: TripSummaryContainerProps) => {
  const { t } = useTranslation(["trips", "common"]);
  const addShipmentEventMutation = useAddShipmentEventMutation();
  const settingsQuery = useGetOrganizationSettingsQuery();
  const startTripMutation = useStartTripMutation();

  const { enqueueSnackbar } = useSnackbar();
  const { showDialog } = useDialog();

  const singleClickTripCompletionEnabled =
    !!settingsQuery.data?.organizationSettings?.general
      ?.singleClickTripCompletion;

  return (
    <TripSummary
      singleClickTripCompletionEnabled={singleClickTripCompletionEnabled}
      onShipmentEventLog={async (eventData) => {
        try {
          if (addShipmentEventMutation.isLoading) {
            return;
          }
          await addShipmentEventMutation.mutateAsync({
            shipmentEventData: eventData,
          });
          enqueueSnackbar(capitalize(t("event.logged", "Event logged")));
          props.onRefetch();
        } catch (error) {
          showDialog({
            title: t("error.logEventError", "Error logging event"),
            type: "error",
            description:
              (error as Error | undefined)?.message ||
              t("common:error.unknownError", "Unknown error"),
          });
        }
      }}
      onStart={async (trip) => {
        try {
          if (trip.status === Status.WaitingForAssignment) {
            showDialog({
              title: t("common:error.title", "Error"),
              type: "error",
              description: t(
                "error.tripNotFullyAssigned",
                "The trip is not fully assigned yet. Please assign a driver, tractor and trailer before starting the trip."
              ),
              actions: [
                {
                  type: "primary",
                  title: t("common:understood", "I understand"),
                },
              ],
            });
            return;
          }

          if (startTripMutation.isLoading) {
            return;
          }
          await startTripMutation.mutateAsync({
            startTripData: {
              tripId: props.trip._id,
              location: {
                latitude: 0,
                longitude: 0,
              },
            },
          });
          enqueueSnackbar(t("event.started", "Trip started"));
          props.onRefetch();
        } catch (error) {
          showDialog({
            title: t("error.startTripError", "Error starting trip"),
            type: "error",
            description:
              (error as Error | undefined)?.message ||
              t("common:error.unknownError", "Unknown error"),
          });
        }
      }}
      loading={
        props.loading ||
        addShipmentEventMutation.isLoading ||
        startTripMutation.isLoading
      }
      {...props}
    />
  );
};

export default TripSummaryContainer;
