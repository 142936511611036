import { useGetCustomerOrderListQuery } from "../../../graphql/generated";
import CustomerOrdersList from "./CustomerOrdersList";

export default function CustomerOrdersListContainer() {
  let refetch = () => {};
  const useGetCustomerOrderListQueryCustom: typeof useGetCustomerOrderListQuery =
    (variables, options) => {
      const query = useGetCustomerOrderListQuery(variables, {
        ...options,
      });
      refetch = query.refetch;
      return query;
    };
  useGetCustomerOrderListQueryCustom.fetcher =
    useGetCustomerOrderListQuery.fetcher;
  useGetCustomerOrderListQueryCustom.getKey =
    useGetCustomerOrderListQuery.getKey;

  return (
    <CustomerOrdersList
      query={useGetCustomerOrderListQueryCustom}
      onRefetch={() => {
        refetch();
      }}
    />
  );
}
