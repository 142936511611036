import { Alert } from "@mui/material";
import { omit } from "lodash";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  BusinessEntityType,
  GetDriverDetailsQuery,
  useAddDriverMutation,
  useEditDriverMutation,
  useEditOrgUserMutation,
  useGetBusinessEntityListQuery,
  useGetDriverDetailsQuery,
  useGetOrgUserDetailsQuery,
  useGetRolesQuery,
} from "../../../graphql/generated";
import { showDialog } from "../../../redux/slices/alert/Alert.slice";
import { UserRole } from "../../account/UserList/UserList";
import LoadingOverlay from "../../common/LoadingOverlay";
import DriverForm from "./DriverForm";
import { useTranslation } from "react-i18next";

type DriverFormContainerProps = {
  driverId?: string;
  onLoad?: (driver: GetDriverDetailsQuery["driverById"]) => void;
};

function DriverFormContainer({ driverId, onLoad }: DriverFormContainerProps) {
  const { t } = useTranslation(["users", "common"]);
  const addDriverMutation = useAddDriverMutation();
  const editDriverMutation = useEditDriverMutation();
  const editUserMutation = useEditOrgUserMutation();
  const getRolesQuery = useGetRolesQuery();
  const domicilesQuery = useGetBusinessEntityListQuery({
    businessEntityTypes: [BusinessEntityType.Domicile],
  });
  const initialBusinessEntityId = useRef(driverId);
  const { state } = useLocation();
  const includeAdminRole: boolean | undefined | null = state?.includeAdminRole;

  // We call this hook conditionally because the component
  // should never rerender with a different value for
  // businessEntityId, but if it does we don't update
  // the ref anyway so this is safe
  const getDriverQuery = initialBusinessEntityId.current
    ? // eslint-disable-next-line react-hooks/rules-of-hooks
      useGetDriverDetailsQuery(
        {
          id: initialBusinessEntityId.current,
        },
        {
          refetchOnWindowFocus: false,
          retry: false,
        }
      )
    : null;
  const driver = getDriverQuery?.data?.driverById;
  const getUserQuery = useGetOrgUserDetailsQuery({
    id: driver?.userId || "",
  });

  const user = getUserQuery.data?.orgUserById;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (getDriverQuery?.data?.driverById) {
      onLoad?.(getDriverQuery.data?.driverById);
    }
  }, [onLoad, getDriverQuery?.data]);

  if (driverId) {
    if (
      getDriverQuery?.isLoading ||
      (driver?.userId && getUserQuery?.isLoading)
    ) {
      return <LoadingOverlay loading />;
    }
    if (getDriverQuery?.error || (driver?.userId && getUserQuery?.error)) {
      return (
        <Alert severity="error">
          {t(
            "error.fetchDriverError",
            "An error occurred while fetching the driver"
          )}
          .{" "}
          {getDriverQuery?.error?.message ||
            getUserQuery.error?.message ||
            t("common:error.unknownError", "Unknown error")}
        </Alert>
      );
    }
  }

  return (
    <DriverForm
      initialDriver={
        getDriverQuery?.data?.driverById
          ? {
              ...getDriverQuery.data.driverById,
              email: user?.email,
              username: user?.username,
              password: "",
            }
          : undefined
      }
      saving={addDriverMutation.isLoading || editDriverMutation.isLoading}
      domiciles={domicilesQuery.data?.businessEntities.data || []}
      onSave={async (driver) => {
        try {
          if (driverId) {
            const result = await editDriverMutation.mutateAsync({
              id: driverId,
              editDriverData: omit(driver, "_id", "userId", "domicileEntity"),
            });
            navigate(`/drivers/details/${result.editDriver._id}`);
          } else {
            const result = await addDriverMutation.mutateAsync({
              newDriverData: driver,
            });
            if (result.addDriver?.userId && !!includeAdminRole) {
              const roles = getRolesQuery.data?.roles;
              await editUserMutation.mutateAsync({
                id: result.addDriver.userId,
                editOrgUserData: {
                  roles: roles?.filter(
                    (role) =>
                      role.name === UserRole.CARRIER_ADMIN ||
                      role.name === UserRole.CARRIER_DRIVER
                  ),
                },
              });
            }
            navigate(`/drivers/details/${result.addDriver._id}`);
            window.analytics?.track("Driver Created", {
              driverId: result.addDriver._id,
            });
            window.analytics?.identify({
              driverCreated: true,
              lastDriverCreationDate: new Date(),
              lastDriverCreationDateOnly: new Date()
                .toISOString()
                .split("T")[0],
              numberOfDriversCreated:
                (window.analytics?.user?.()?.traits?.()
                  ?.numberOfDriversCreated || 0) + 1,
            });
            window.analytics?.group(window.analytics?.group?.()?.id?.(), {
              driverCreated: true,
              lastDriverCreationDate: new Date(),
              lastDriverCreationDateOnly: new Date()
                .toISOString()
                .split("T")[0],
              numberOfDriversCreated:
                (window.analytics?.group?.()?.traits?.()
                  ?.numberOfDriversCreated || 0) + 1,
            });
          }
        } catch (error) {
          dispatch(
            showDialog({
              title: t("common:error.title", "Error"),
              description:
                t("error.createEditDriverError", {
                  action: driverId
                    ? t("common:editing", "editing")
                    : t("common:creating", "creating"),
                  defaultValue: `An error occurred while ${
                    driverId ? "editing" : "creating"
                  } the driver. `,
                }) + (error as Error).message,
              type: "error",
            })
          );
        }
      }}
    />
  );
}

export default DriverFormContainer;
