import { Box, Stack, Tabs, Tab, IconButton } from "@mui/material";
import {
  ForecastingModel,
  ForecastingModelSlotType,
} from "../../../graphql/generated";
import { useState } from "react";
import { ForecastingModelSlotForm } from "./ForecastingModelSlotForm";
import LabeledAddButton from "../../common/LabeledAddButton";
import { Remove } from "@mui/icons-material";

type HourlyForecastModelFormProps = {
  model: ForecastingModel;
  onChange: (model: ForecastingModel) => void;
};

const HourlyForecastModelForm = (props: HourlyForecastModelFormProps) => {
  const [selectedDay, setSelectedDay] = useState(1);
  return (
    <Box>
      <Stack spacing={2}>
        <Tabs
          value={selectedDay}
          onChange={(event, newValue) => setSelectedDay(newValue)}
        >
          {Array.from({ length: 7 }, (_, i) => i)
            .map((day) => (day + 1) % 7)
            .map((day) => (
              <Tab value={day} label={dayOfWeekLabel(day)} />
            ))}
        </Tabs>

        <Stack spacing={1}>
          {(props.model.slots || [])
            .filter((slot) => slot.hourly?.dayOfWeek === selectedDay)
            .map((slot) => (
              <Stack direction="row" spacing={1}>
                <Box
                  sx={{
                    flex: 1,
                  }}
                >
                  <ForecastingModelSlotForm
                    slot={slot}
                    onChange={(newSlot) => {
                      const newSlots = props.model.slots?.map((s) => {
                        if (s === slot) {
                          return newSlot;
                        }
                        return s;
                      });
                      props.onChange({
                        ...props.model,
                        slots: newSlots,
                      });
                    }}
                  />
                </Box>
                <IconButton
                  onClick={() => {
                    props.onChange({
                      ...props.model,
                      slots: props.model.slots?.filter((s) => s !== slot),
                    });
                  }}
                >
                  <Remove />
                </IconButton>
              </Stack>
            ))}

          <LabeledAddButton
            label="Add Slot"
            onClick={() => {
              props.onChange({
                ...props.model,
                slots: [
                  ...(props.model.slots || []),
                  {
                    salesAmount: 0,
                    hourly: {
                      dayOfWeek: selectedDay,
                      startTime: { hour: 0, minute: 0 },
                      endTime: { hour: 23, minute: 59 },
                    },
                    type: ForecastingModelSlotType.Hourly,
                  },
                ],
              });
            }}
          />
        </Stack>
      </Stack>
    </Box>
  );
};

export default HourlyForecastModelForm;

const dayOfWeekLabel = (dayOfWeek: number) => {
  switch (dayOfWeek) {
    case 0:
      return "Sunday";
    case 1:
      return "Monday";
    case 2:
      return "Tuesday";
    case 3:
      return "Wednesday";
    case 4:
      return "Thursday";
    case 5:
      return "Friday";
    case 6:
      return "Saturday";
  }
};
