import {
  TripSortCriteria,
  GetReceiverForecastListQuery,
  AssetStatus,
  CustomFieldContext,
} from "../../../graphql/generated";
import { TableField } from "../../common/LynksTable/LynksTable";
import SmartLynksTable, {
  QueryFn,
} from "../../common/SmartLynksTable/SmartLynksTable";
import { Box, Button } from "@mui/material";
import { useMemo } from "react";

type ReceiverForecastListItem =
  GetReceiverForecastListQuery["receiverForecasts"]["data"][0];

export default function ReceiverForecastsList({
  query,
}: {
  query: QueryFn<
    ReceiverForecastListItem,
    "receiverForecasts",
    TripSortCriteria
  >;
  onBulkCreate?: (goodProfiles: any) => void;
}) {
  const fields: TableField<ReceiverForecastListItem, AssetStatus>[] = useMemo(
    () => [
      {
        value: (receiverForecast) => receiverForecast.receiver?.name,
        label: "Receiver",
        type: "string",
        sortBy: "receiver.name",
      },
      {
        value: (receiverForecast) =>
          receiverForecast.models
            .map((model) => {
              const storageFacility =
                receiverForecast.receiver?.storageFacilities?.find(
                  (sf) => sf.identifier === model.storageFacilityId
                );
              return storageFacility?.commodity?.label;
            })
            .join(", "),
        label: "Commodities",
        type: "string",
      },
    ],
    []
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignSelf: "flex-end",
          mb: 2,
        }}
      >
        <Button
          href="/forecasting/new"
          variant="contained"
          color="secondary"
          size="large"
          id="new-receiverForecast-button"
        >
          New Model
        </Button>
      </Box>

      <Box
        sx={{
          height: "calc(100% - 74px)",
        }}
      >
        <SmartLynksTable
          query={query}
          dataKey="receiverForecasts"
          fields={fields}
          detailsUrlPrefix="/forecasting/details"
          customFieldContext={[CustomFieldContext.Commodity]}
        />
      </Box>
    </div>
  );
}
