import { Status } from "../../graphql/generated";

const statusKey: {
  [key in Status]: string;
} = {
  ACTIVE: "active",
  ASSIGNED: "assigned",
  CANCELLED: "cancelled",
  COMPLETE: "complete",
  DELETED: "deleted",
  DELIVERED: "delivered",
  IN_PROGRESS: "inProgress",
  PENDING: "pending",
  PLANNED: "planned",
  UNASSIGNED: "unassigned",
  WAITING_FOR_APPROVAL: "waitingForApproval",
  WAITING_FOR_ASSIGNMENT: "waitingForAssignment",
  FORECASTED: "forecasted",
  FORECASTED_LOCKED: "forecastedLocked",
  UNPLANNED: "unplanned",
};

export default statusKey;
