import { Alert } from "@mui/material";
import { toArray } from "lodash";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  GetTractorDetailsQuery,
  useDeleteTractorMutation,
  useGetTractorDetailsQuery,
} from "../../../graphql/generated";
import {
  hideDialog,
  showDialog,
} from "../../../redux/slices/alert/Alert.slice";
import LoadingOverlay from "../../common/LoadingOverlay";
import TractorDetails from "./TractorDetails";
import { useTranslation } from "react-i18next";

type TractorDetailsContainerProps = {
  tractorId: string;
  onLoad?: (tractor: GetTractorDetailsQuery["tractorById"]) => void;
};

function TractorDetailsContainer({
  tractorId,
  onLoad,
}: TractorDetailsContainerProps) {
  const { t } = useTranslation(["assets", "common"]);
  const query = useGetTractorDetailsQuery({
    id: tractorId,
  });
  const deleteTractorMutation = useDeleteTractorMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (query.data?.tractorById) {
      onLoad?.(query.data?.tractorById);
    }
  }, [onLoad, query.data]);

  if (query.isLoading) {
    return <LoadingOverlay loading />;
  }
  if (query.error) {
    return (
      <Alert severity="error">
        {t("common:error.fetchError", "An error occurred while fetching")}.{" "}
        {(query.error as Error).message || "Unknown error"}
      </Alert>
    );
  }
  if (!toArray(query.data)[0]) {
    return (
      <Alert severity="error">{t("common:error.notFound", "Not found")}</Alert>
    );
  }
  return (
    <TractorDetails
      tractor={toArray(query.data)[0]}
      onEdit={() => {
        navigate(`/tractors/${tractorId}/edit`);
      }}
      onDelete={async () => {
        dispatch(
          showDialog({
            title: t("common:error.dangerZone", "Danger zone"),
            description: t(
              "error.deleteTractorConfirmation",
              "Do you really want to delete this tractor? This action cannot be undone."
            ),
            type: "error",
            actions: [
              {
                type: "error",
                title: t("common:error.yesDelete", "Yes, Delete"),
                onClick: async () => {
                  try {
                    await deleteTractorMutation.mutateAsync({
                      id: tractorId,
                    });
                    dispatch(hideDialog());
                    navigate("/tractors");
                  } catch (error) {
                    console.error(error);
                    dispatch(
                      showDialog({
                        title: t("common:error.title", "Error"),
                        description:
                          t(
                            "error.deleteTractorError",
                            "An error occurred while deleting the tractor."
                          ) + (deleteTractorMutation.error as Error).message ||
                          t("common:error.unknownError", "Unknown error"),
                      })
                    );
                  }
                },
              },
              {
                type: "primary",
                title: t("common:error.noCancel", "No, Cancel"),
              },
            ],
          })
        );
      }}
    />
  );
}

export default TractorDetailsContainer;
