import { CustomFieldContext } from "../../graphql/generated";

const customFieldContextLabelMapping: {
  [key in CustomFieldContext]: string;
} = {
  CARRIER: "Carrier",
  COMMODITY: "Commodity",
  CUSTOMER: "Customer",
  DRIVER: "Driver",
  EXPENSE: "Expense",
  LOCATION: "Location ( Shipper, Receiver and Domicile )",
  SHIPMENT: "Order",
  SUPPLIER: "Supplier",
  TRACTOR: "Tractor",
  TRAILER: "Trailer",
  TRIP: "Trip",
  USER: "User",
  SHIPPER: "Shipper ( Location )",
  DOMICILE: "Domicile ( Location )",
  RECEIVER: "Receiver ( Location )",
};

function customFieldContextLabel(
  context: CustomFieldContext | undefined | null
) {
  if (!context) return "N/A";
  return customFieldContextLabelMapping[context];
}

export default customFieldContextLabel;
