import { Box, Button } from "@mui/material";
import {
  GetTripForPaymentsQuery,
  TransactionInput,
  TransactionType,
} from "../../../graphql/generated";
import TransactionsList from "../TransactionsList/TransactionsList";
import { omit } from "lodash";
import { useTranslation } from "react-i18next";

export type TripPaymentProps = {
  trip: NonNullable<GetTripForPaymentsQuery["tripById"]>;
  onLineHaulCompute: () => void;
  onPaymentAdded: (payment: TransactionInput) => void;
  onPaymentEdited: (paymentId: string, payment: TransactionInput) => void;
  onPaymentDeleted: (paymentId: string) => void;
};

const TripPayments = ({
  trip,
  onLineHaulCompute,
  onPaymentAdded,
  onPaymentEdited,
  onPaymentDeleted,
}: TripPaymentProps) => {
  const { t } = useTranslation("trips");
  return (
    <Box>
      <Button
        onClick={() => {
          onLineHaulCompute();
        }}
        variant="contained"
        size="large"
        color="secondary"
      >
        {t("computeLineHaulPay", "Compute Line Haul Pay")}
      </Button>
      <TransactionsList
        query={() => {
          return {
            data: {
              transactions: {
                data: trip.payments || [],
                count: trip.payments?.length || 0,
              },
            },
            isLoading: false,
          };
        }}
        transactionType={TransactionType.Expense}
        onTransactionAdded={(transaction) =>
          onPaymentAdded(omit(transaction, "_id"))
        }
        onTransactionChanged={(id, transaction) =>
          onPaymentEdited(id, omit(transaction, "_id"))
        }
        deleteMutation={() => ({
          mutateAsync: async ({ id }) => {
            onPaymentDeleted(id);
          },
          isLoading: false,
        })}
      />
    </Box>
  );
};

export default TripPayments;
