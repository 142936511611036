import {
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import {
  GoodProfileEquivalenceCondition,
  GoodProfileEquivalenceConditionOperator,
  GoodProfileEquivalenceConditionTarget,
  BusinessEntityType,
} from "../../../graphql/generated";
import BusinessEntityInput from "../../accounting/BillingRuleForm/value-selectors/BusinessEntityInput";
import DateInput from "../../accounting/BillingRuleForm/value-selectors/DateInput";
import TimeInput from "../../accounting/BillingRuleForm/value-selectors/TimeInput";
import enumLabel from "../../../utils/labels/enumLabel";
import { ConditionValueSelectorComponent } from "../../accounting/BillingRuleForm/ConditionForm";

export type ConditionValueSelectorComponentProps = {
  value: number | string | string[] | null;
  onChange: (value: number | string | string[] | null) => void;
};

const ConditionValueSelectorComponentByTarget: {
  [key in GoodProfileEquivalenceConditionTarget]: ConditionValueSelectorComponent | null;
} = {
  [GoodProfileEquivalenceConditionTarget.Date]: DateInput,
  [GoodProfileEquivalenceConditionTarget.Receiver]: (
    props: ConditionValueSelectorComponentProps
  ) => (
    <BusinessEntityInput
      {...props}
      businessEntityType={BusinessEntityType.Receiver}
    />
  ),
  [GoodProfileEquivalenceConditionTarget.Shipper]: (
    props: ConditionValueSelectorComponentProps
  ) => (
    <BusinessEntityInput
      {...props}
      businessEntityType={BusinessEntityType.Shipper}
    />
  ),
  [GoodProfileEquivalenceConditionTarget.Time]: TimeInput,
};

type ConditionFormProps = {
  condition: GoodProfileEquivalenceCondition;
  onChange: (condition: GoodProfileEquivalenceCondition) => void;
};

const targetOperatorConstraints: {
  [key: string]: GoodProfileEquivalenceConditionOperator[] | null;
} = {
  [GoodProfileEquivalenceConditionTarget.Shipper]: [
    GoodProfileEquivalenceConditionOperator.Equals,
  ],
  [GoodProfileEquivalenceConditionTarget.Receiver]: [
    GoodProfileEquivalenceConditionOperator.Equals,
  ],
};

const GoodProfileEquivalenceConditionForm = ({
  condition,
  onChange,
}: ConditionFormProps) => {
  const ValueSelectorComponent =
    ConditionValueSelectorComponentByTarget[condition.target];
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Typography variant="body2">When</Typography>
      <Select
        label="Condition"
        size="small"
        value={condition.target}
        onChange={(event) => {
          onChange({
            ...condition,
            target: event.target.value as GoodProfileEquivalenceConditionTarget,
            value: null,
            operator: GoodProfileEquivalenceConditionOperator.Equals,
          });
        }}
      >
        {Object.values(GoodProfileEquivalenceConditionTarget).map((target) => (
          <MenuItem key={target} value={target}>
            <ListItemText primary={enumLabel(target)} />
          </MenuItem>
        ))}
      </Select>

      {condition.target ? (
        <Select
          label="Operator"
          size="small"
          value={condition.operator}
          onChange={(event) => {
            const operator = event.target
              .value as GoodProfileEquivalenceConditionOperator;
            onChange({
              ...condition,
              operator,
              value:
                operator === GoodProfileEquivalenceConditionOperator.IsBetween
                  ? [condition.value, null]
                  : condition.value,
            });
          }}
        >
          {(
            targetOperatorConstraints[condition.target] ||
            Object.values(GoodProfileEquivalenceConditionOperator)
          ).map((operator) => (
            <MenuItem key={operator} value={operator}>
              <ListItemText primary={enumLabel(operator)} />
            </MenuItem>
          ))}
        </Select>
      ) : null}
      {condition.target &&
      condition.operator &&
      condition.operator !==
        GoodProfileEquivalenceConditionOperator.IsBetween &&
      ValueSelectorComponent ? (
        <ValueSelectorComponent
          value={condition.value}
          onChange={(value) =>
            onChange({
              ...condition,
              value,
            })
          }
        />
      ) : null}
      {condition.target &&
      condition.operator ===
        GoodProfileEquivalenceConditionOperator.IsBetween &&
      ValueSelectorComponent ? (
        <>
          <ValueSelectorComponent
            value={condition.value[0]}
            onChange={(value) =>
              onChange({
                ...condition,
                value: [value, condition.value[1]],
              })
            }
          />

          <Typography variant="body2">and</Typography>
          <ValueSelectorComponent
            value={condition.value[1]}
            onChange={(value) =>
              onChange({
                ...condition,
                value: [condition.value[0], value],
              })
            }
          />
        </>
      ) : null}
    </Stack>
  );
};

export default GoodProfileEquivalenceConditionForm;
