import { ExcelMapping } from "./mappingTypes";

const adminsMapping: ExcelMapping = {
  "First Name": { key: "firstName", type: "string", required: true },
  "Last Name": { key: "lastName", type: "string", required: true },
  Email: { key: "email", type: "string" },
  Password: { key: "password", type: "string", required: true },
};

export default adminsMapping;
