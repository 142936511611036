import { min, minBy } from "lodash";
import {
  TripSortCriteria,
  GetReceiverForecastListQuery,
  AssetStatus,
  ForecastingNotificationLevel,
} from "../../../graphql/generated";
import { TableField } from "../../common/LynksTable/LynksTable";
import SmartLynksTable, {
  QueryFn,
} from "../../common/SmartLynksTable/SmartLynksTable";
import { Box, Dialog, DialogContent, Link, Stack } from "@mui/material";
import { useMemo, useState } from "react";
import { formatPercentage } from "../../../utils/labels/formatPercentage";
import formatPhoneNumber from "../../../utils/labels/formatPhoneNumber";
import ForecastingReceiverDashboardContainer from "./ForecastingReceiverDashboard";
import ForecastingNotificationsAlertsContainer from "../ForecastingNotificationsAlerts";

type ReceiverForecastListItem =
  GetReceiverForecastListQuery["receiverForecasts"]["data"][0];

export default function ForecastingDashboard({
  query,
}: {
  query: QueryFn<
    ReceiverForecastListItem,
    "receiverForecasts",
    TripSortCriteria
  >;
  onBulkCreate?: (goodProfiles: any) => void;
}) {
  const [selectedReceiverForecast, setSelectedReceiverForecast] =
    useState<ReceiverForecastListItem | null>(null);

  const fields: TableField<ReceiverForecastListItem, AssetStatus>[] = useMemo(
    () => [
      {
        value: (receiverForecast) => (
          <ForecastingNotificationsAlertsContainer
            receiverId={receiverForecast.receiverId}
            compact
          />
        ),
        label: "Alerts",
        type: "custom",
      },
      {
        value: (receiverForecast) => receiverForecast.receiver?.name,
        label: "Receiver",
        type: "string",
        sortBy: "receiver.name",
      },
      {
        value: (receiverForecast) => {
          const nextStorageFacilityToRunout = minBy(
            receiverForecast.receiver?.storageFacilities,
            (sf) => sf.runoutDate
          );

          return nextStorageFacilityToRunout?.commodity?.label;
        },
        label: "Commodity",
        type: "string",
      },

      {
        value: (receiverForecast) => {
          const nextStorageFacilityToRunout = minBy(
            receiverForecast.receiver?.storageFacilities,
            (sf) => sf.runoutDate
          );

          return nextStorageFacilityToRunout?.runoutDate || null;
        },
        label: "Runout Date",
        type: "datetime",
      },

      {
        value: (receiverForecast) =>
          min(
            receiverForecast.models.map((model) => {
              const storageFacility =
                receiverForecast.receiver?.storageFacilities?.find(
                  (sf) => sf.identifier === model.storageFacilityId
                );
              return storageFacility?.runoutDateAfterNextShipment || null;
            })
          ) || null,
        label: "Runout Date after Order",
        type: "datetime",
      },

      {
        value: (receiverForecast) => {
          const nextStorageFacilityToRunout = minBy(
            receiverForecast.receiver?.storageFacilities,
            (sf) => sf.runoutDate
          );

          return formatPercentage(
            nextStorageFacilityToRunout?.currentSalesTrend
          );
        },
        label: "Trend",
        type: "string",
      },

      {
        value: (receiverForecast) => {
          return (
            <Stack>
              <Link
                href={`tel:${receiverForecast.receiver?.contact.phoneNumber}`}
                component="a"
                onClick={(e) => {
                  e.stopPropagation();
                }}
                sx={{
                  lineHeight: "normal",
                }}
              >
                {formatPhoneNumber(
                  receiverForecast.receiver?.contact.phoneNumber
                )}
              </Link>
              <Link
                href={`mailto:${receiverForecast.receiver?.contact.email}`}
                component="a"
                onClick={(e) => {
                  e.stopPropagation();
                }}
                sx={{
                  lineHeight: "normal",
                }}
              >
                {receiverForecast.receiver?.contact.email}
              </Link>
            </Stack>
          );
        },
        label: "Contact",
        type: "custom",
      },
    ],
    []
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Stack
        sx={{
          height: "calc(100% - 74px)",
        }}
        spacing={2}
      >
        <ForecastingNotificationsAlertsContainer
          onOpen={(notification) => {
            setSelectedReceiverForecast({
              _id: notification.receiverForecastId,
              receiverId: notification.receiverId,
              models: [],
            });
          }}
          levels={[ForecastingNotificationLevel.Critical]}
        />
        <Box
          sx={{
            flex: 1,
            height: "100%",
          }}
        >
          <SmartLynksTable
            query={query}
            dataKey="receiverForecasts"
            id="forecasting-dashboard"
            fields={fields}
            onRecordClick={(receiver) => setSelectedReceiverForecast(receiver)}
          />
        </Box>
      </Stack>
      {selectedReceiverForecast ? (
        <Dialog
          open={!!selectedReceiverForecast}
          onClose={() => setSelectedReceiverForecast(null)}
          maxWidth="lg"
          fullWidth
        >
          <DialogContent>
            <ForecastingReceiverDashboardContainer
              receiverForecastId={selectedReceiverForecast._id}
              receiverId={selectedReceiverForecast.receiverId}
            />
          </DialogContent>
        </Dialog>
      ) : null}
    </div>
  );
}
