import { useEffect, useState } from "react";
import {
  Breakpoint,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  Stack,
} from "@mui/material";
import AddButton from "../../../common/AddButton";
import AddIcon from "@mui/icons-material/Add";
import {
  GetShipmentQuery,
  ShipmentNoteInput,
} from "../../../../graphql/generated";
import { defaultShipmentNote } from "../../../../redux/slices/shipment/ShipmentForm.slice";
import { FieldErrors, FormError } from "../../../../redux/slices/Types";
import { v4 as uuid } from "uuid";
import NoteForm, { NoteFormMode } from "./NoteForm";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";

interface ShipmentNoteFormProps {
  openModal: boolean;
  width?: Breakpoint;
  onClose: (notes: Array<ShipmentNoteInput>) => void;
  shipmentLocations: GetShipmentQuery["shipmentById"]["shipmentLocations"];
  shipmentNotes?: Array<ShipmentNoteInput>;
  mode?: NoteFormMode;
}

export default function ShipmentNotesForm({
  openModal,
  width,
  onClose,
  shipmentLocations,
  shipmentNotes,
  mode = "add",
}: ShipmentNoteFormProps) {
  const { t } = useTranslation(["orders", "common"]);
  const [notes, setNotes] = useState<Array<ShipmentNoteInput>>(
    shipmentNotes || []
  );

  const [maxWidth] = useState<DialogProps["maxWidth"]>(width);
  const [errors, setErrors] = useState<Array<FormError>>([]);

  useEffect(() => {
    if (openModal && !notes.length) {
      setNotes([{ _id: uuid(), ...defaultShipmentNote }]);
    }
  }, [openModal, notes]);

  const handleAddNote = () => {
    setNotes([...notes, { _id: uuid(), ...defaultShipmentNote }]);
  };

  const handleChange = (note: ShipmentNoteInput) => {
    const index = notes.findIndex((n) => n._id === note._id);
    const _notes = [...notes];
    _notes[index] = note;
    setNotes(_notes);
  };

  const handleSubmit = () => {
    const errors: Array<FormError> = [];
    for (const note of notes) {
      const err: FieldErrors = {};
      if (!note.content) {
        err["content"] = t(
          "orders:notes.errors.emptyNote",
          "Note content cannot be empty"
        );
      }

      if (!note.accessLevel.length) {
        err["accessLevel"] = t(
          "orders:notes.errors.selectVisibility",
          "Please select at least a visibility level"
        );
      }

      if (!isEmpty(err)) {
        errors.push({ formId: note._id || "", error: err });
      }
    }

    if (errors.length) {
      return setErrors(errors);
    }
    setNotes([]);
    onClose(notes);
  };

  const handleCancel = () => {
    setNotes([]);
    onClose([]);
  };

  return (
    <Dialog maxWidth={maxWidth} fullWidth open={openModal}>
      <DialogTitle variant="h6">
        {mode === "add" && <b>{t("orders:notes.add", "Add Note")}</b>}
        {mode === "edit" && <b>{t("orders:notes.edit", "Edit Note")}</b>}
        {mode === "readOnly" && (
          <b>{t("orders:notes.details", "Note details")}</b>
        )}
      </DialogTitle>
      <DialogContent dividers sx={{ p: 2 }}>
        <Stack spacing={2}>
          {notes?.map((note, index) => (
            <div key={index}>
              <NoteForm
                mode={mode ? mode : "edit"}
                key={index}
                note={note}
                shipmentLocations={shipmentLocations}
                errors={errors.find((e) => e.formId === note._id)}
                onChange={handleChange}
              />
              {mode === "add" && <Divider sx={{ mt: 5 }} />}
            </div>
          ))}
        </Stack>
        {mode === "add" && (
          <Stack
            direction="row"
            spacing={2}
            sx={{ m: 1, display: "flex", alignItems: "center" }}
          >
            <AddButton
              id="add-note-button"
              sx={{ height: 50, width: 20 }}
              onClick={handleAddNote}
            >
              <AddIcon />
            </AddButton>
            <span>{t("orders:notes.add", "Add Note")}</span>
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        {mode === "readOnly" ? (
          <Button
            autoFocus
            color="inherit"
            variant="contained"
            onClick={handleCancel}
          >
            {t("common:close", "Close")}
          </Button>
        ) : (
          <>
            <Button
              autoFocus
              color="inherit"
              variant="contained"
              onClick={handleCancel}
            >
              {t("common:cancel", "Cancel")}
            </Button>
            <Button color="primary" variant="contained" onClick={handleSubmit}>
              {t("common:validate", "Validate")}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
