import { useSnackbar } from "notistack";
import {
  useDeleteDriverSettlementMutation,
  useGenerateDriverSettlementForTripsMutation,
  useGetDriverSettlementListQuery,
  useUpdateDriverSettlementStatusMutation,
} from "../../../graphql/generated";
import useDialog from "../../../utils/hooks/useDialog";
import DriverSettlementList from "./DriverSettlementList";
import LocaleProvider from "../../../providers/LocaleProvider";
import { useTranslation } from "react-i18next";

const DriverSettlementListContainer = () => {
  const { t } = useTranslation(["finance", "common"]);
  let refetch = () => {};
  // I need a handle on refetch so I can call it
  // a new settlement has been generated
  const useGetDriverSettlementListQueryCustom: typeof useGetDriverSettlementListQuery =
    (variables, options) => {
      const query = useGetDriverSettlementListQuery(variables, {
        ...options,
      });
      refetch = query.refetch;
      return query;
    };
  useGetDriverSettlementListQueryCustom.fetcher =
    useGetDriverSettlementListQuery.fetcher;
  useGetDriverSettlementListQueryCustom.getKey =
    useGetDriverSettlementListQuery.getKey;
  const updateDriverSettlementStatusMutation =
    useUpdateDriverSettlementStatusMutation();
  const generateDriverSettlementsForTripMutation =
    useGenerateDriverSettlementForTripsMutation();
  const { enqueueSnackbar } = useSnackbar();
  const { showDialog } = useDialog();
  return (
    <DriverSettlementList
      queryHook={useGetDriverSettlementListQueryCustom}
      onProcess={async (tripIds, driverSettlementNumber) => {
        try {
          await generateDriverSettlementsForTripMutation.mutateAsync({
            tripIds,
            timezone: LocaleProvider.getTimezone(),
            driverSettlementNumber,
          });
          enqueueSnackbar(
            t("tripsProcessSuccess", "Trips successfully processed")
          );
          refetch();
        } catch (e) {
          console.error(e);
          showDialog({
            type: "error",
            title: t(
              "error.updateDriverSettlStatus",
              "Error updating driver settlement status"
            ),
            description:
              (e as Error).message ||
              t("common:error.unknownError", "Unknown error"),
          });
        }
      }}
      deleteMutation={useDeleteDriverSettlementMutation}
      onDriverSettlementStatusUpdate={async (driverSettlement, status) => {
        try {
          await updateDriverSettlementStatusMutation.mutateAsync({
            driverSettlementId: driverSettlement._id,
            status,
          });
          enqueueSnackbar(
            t(
              "driverSettlStatusUpdateSuccess",
              "Driver Settlement status successfully updated"
            )
          );
          refetch();
        } catch (e) {
          console.error(e);
          showDialog({
            type: "error",
            title: t(
              "error.updateDriverSettlStatus",
              "Error updating driver settlement status"
            ),
            description:
              (e as Error).message ||
              t("common:error.unknownError", "Unknown error"),
          });
        }
      }}
    />
  );
};

export default DriverSettlementListContainer;
