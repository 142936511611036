import { Delete } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { addYears, startOfDay } from "date-fns";
import { without } from "lodash";
import { useState } from "react";
import { v4 as uuid } from "uuid";
import {
  DeductionPeriodUnit,
  DeductionRule,
  DeductionType,
  DriverDeductionMethod,
  DriverPaymentMethod,
  PaymentPeriodUnit,
  PaymentRule,
} from "../../../graphql/generated";
import LabeledAddButton from "../../common/LabeledAddButton";
import DeductionRuleForm from "../DeductionRuleForm";
import PaymentRuleForm from "../PaymentRuleForm";
import DriverPaymentPeriodForm from "./DriverPaymentPeriodForm";
import { useTranslation } from "react-i18next";

type PaymentRulesEditorProps = {
  initialPaymentRules: PaymentRule[];
  initialDeductionRules: DeductionRule[];
  saving: boolean;
  onSave: (rules: {
    paymentRules: PaymentRule[];
    deductionRules: DeductionRule[];
  }) => void;
};

const PaymentRulesEditor = ({
  initialPaymentRules,
  initialDeductionRules,
  saving,
  onSave,
}: PaymentRulesEditorProps) => {
  const [paymentRules, setPaymentRules] = useState(initialPaymentRules);
  const [deductionRules, setDeductionRules] = useState(initialDeductionRules);
  const { t } = useTranslation("paymentRules");
  return (
    <Box>
      <Stack spacing={2}>
        {paymentRules.map((paymentRule) => (
          <Box display="flex">
            <PaymentRuleForm
              key={paymentRule._id}
              paymentRule={paymentRule}
              onChange={(updatedPaymentRule) => {
                setPaymentRules(
                  paymentRules.map((paymentRule) =>
                    paymentRule._id === updatedPaymentRule._id
                      ? updatedPaymentRule
                      : paymentRule
                  )
                );
              }}
            />

            <IconButton
              onClick={() => {
                setPaymentRules(without(paymentRules, paymentRule));
              }}
            >
              <Delete />
            </IconButton>
          </Box>
        ))}
      </Stack>
      {!paymentRules.length ? (
        <LabeledAddButton
          sx={{ height: 50, width: 20 }}
          onClick={() => {
            setPaymentRules(
              paymentRules.concat({
                _id: "NEW-" + uuid(),
                method: DriverPaymentMethod.PerMile,
                amount: 0,
                driver: null,
              })
            );
          }}
          id="add-payment-rule-button"
          label={t("addPaymentRule")}
        />
      ) : null}
      <Divider sx={{ mt: 2, mb: 2 }} />

      <Typography variant="h6">{t("paymentPeriod")}</Typography>

      {paymentRules.length ? (
        <Box>
          <Typography variant="body2">{t("driverIsPaidEvery")}</Typography>

          <DriverPaymentPeriodForm
            period={
              paymentRules[0].period || {
                repeatEvery: 0,
                start: null,
                end: null,
                unit: PaymentPeriodUnit.Day,
              }
            }
            onChange={(period) => {
              setPaymentRules(
                paymentRules.map((paymentRule) => ({ ...paymentRule, period }))
              );
            }}
          />
        </Box>
      ) : null}
      <Divider sx={{ mt: 2, mb: 2 }} />

      <Typography variant="h6">{t("deductions")}</Typography>
      <Stack spacing={2}>
        {deductionRules
          .filter(
            (deductionRule) => deductionRule.type === DeductionType.Deduction
          )
          .map((deductionRule) => (
            <Box display="flex">
              <DeductionRuleForm
                key={deductionRule._id}
                deductionRule={{
                  ...deductionRule,
                  amount: -deductionRule.amount,
                }}
                onChange={(updatedDeductionRule) => {
                  setDeductionRules(
                    deductionRules.map((deductionRule) =>
                      deductionRule._id === updatedDeductionRule._id
                        ? {
                            ...updatedDeductionRule,
                            amount: -updatedDeductionRule.amount,
                          }
                        : deductionRule
                    )
                  );
                }}
              />

              <IconButton
                onClick={() => {
                  setDeductionRules(without(deductionRules, deductionRule));
                }}
              >
                <Delete />
              </IconButton>
            </Box>
          ))}

        <LabeledAddButton
          label={t("addDeduction")}
          id="add-deduction-rule-button"
          onClick={() => {
            setDeductionRules(
              deductionRules.concat({
                _id: "NEW-" + uuid(),
                type: DeductionType.Deduction,
                label: "",
                method: DriverDeductionMethod.FlatFee,
                amount: -0,
                driver: null,
                period: {
                  start: startOfDay(new Date()),
                  repeatEvery: 1,
                  unit: DeductionPeriodUnit.Month,
                  end: addYears(startOfDay(new Date()), 1),
                },
              })
            );
          }}
        />
      </Stack>

      <Divider sx={{ mt: 2, mb: 2 }} />
      <Typography variant="h6">{t("bonuses")}</Typography>
      <Stack spacing={2}>
        {deductionRules
          .filter((deductionRule) => deductionRule.type === DeductionType.Bonus)
          .map((deductionRule) => (
            <Box display="flex">
              <DeductionRuleForm
                key={deductionRule._id}
                deductionRule={deductionRule}
                onChange={(updatedDeductionRule) => {
                  setDeductionRules(
                    deductionRules.map((deductionRule) =>
                      deductionRule._id === updatedDeductionRule._id
                        ? updatedDeductionRule
                        : deductionRule
                    )
                  );
                }}
              />

              <IconButton
                onClick={() => {
                  setDeductionRules(without(deductionRules, deductionRule));
                }}
              >
                <Delete />
              </IconButton>
            </Box>
          ))}

        <LabeledAddButton
          id="add-bonus-rule-button"
          label={t("addBonus")}
          onClick={() => {
            setDeductionRules(
              deductionRules.concat({
                _id: "NEW-" + uuid(),
                type: DeductionType.Bonus,
                label: "",
                method: DriverDeductionMethod.FlatFee,
                amount: 0,
                driver: null,
                period: {
                  start: startOfDay(new Date()),
                  repeatEvery: 1,
                  unit: DeductionPeriodUnit.Month,
                  end: addYears(startOfDay(new Date()), 1),
                },
              })
            );
          }}
        />
      </Stack>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Box display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          color="secondary"
          disabled={saving}
          onClick={() => {
            onSave({ paymentRules, deductionRules });
          }}
          id="save-driver-payments-button"
        >
          {t("saveChanges")}
        </Button>
      </Box>
    </Box>
  );
};

export default PaymentRulesEditor;
