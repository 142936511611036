import { useDispatch, useSelector } from "react-redux";
import ShipperForm, { ShipperFormProps } from "./ShipperForm";
import { ShipmentLocationInputData } from "../../../../redux/slices/Types";
import { editShipmentLocationInput } from "../../../../redux/slices/shipment/ShipmentForm.slice";
import { RootState } from "../../../../redux/store";

type ShipperFormContainerProps = Omit<
  ShipperFormProps,
  "onChange" | "customer" | "shipperId" | "shipmentLocation"
> & {
  shipper: ShipmentLocationInputData;
};
export default function ShipperFormContainer({
  shipper: shipperLocation,
  ...props
}: ShipperFormContainerProps) {
  const dispatch = useDispatch();

  const handleChange = async (
    updatedShipperLocation: ShipmentLocationInputData
  ) => {
    dispatch(
      editShipmentLocationInput({
        location: updatedShipperLocation,
      })
    );
  };

  const shipment = useSelector(
    (state: RootState) => state.shipmentReducer.shipment
  );
  return (
    <div>
      <ShipperForm
        customer={shipment.customer}
        shipperId={shipperLocation?._id}
        shipmentLocation={shipperLocation}
        onChange={handleChange}
        {...props}
      />
    </div>
  );
}
