import { Autocomplete, TextField } from "@mui/material";
import { castArray } from "lodash";
import { CustomFieldInputComponent } from "../CustomFieldsForm";
import { useEffect, useState } from "react";
import { CustomFieldSelectOption } from "../../../../graphql/generated";
import { getCustomFieldOptions } from "./SelectCustomFieldInput";

const MultiSelectCustomFieldInput: CustomFieldInputComponent = ({
  value,
  customFieldDefinition,
  onChange,
}) => {
  const [options, setOptions] = useState<CustomFieldSelectOption[]>([]);
  useEffect(() => {
    getCustomFieldOptions(customFieldDefinition).then(setOptions);
  }, [customFieldDefinition]);

  return (
    <Autocomplete
      options={options.map((option) => option.key) || []}
      getOptionLabel={(optionKey) =>
        options.find((selectOption) => selectOption.key === optionKey)?.label ||
        ""
      }
      onChange={(event, value) => {
        onChange(value.map(String));
      }}
      multiple
      value={value ? castArray(value) : []}
      size="medium"
      fullWidth
      renderInput={(inputProps) => (
        <TextField label={customFieldDefinition.label} {...inputProps} />
      )}
    />
  );
};

export default MultiSelectCustomFieldInput;
