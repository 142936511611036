import { Box } from "@mui/material";
import { useGetLoadTrackingBoardShipmentsQuery } from "../../../graphql/generated";
import ErrorMessage from "../../common/ErrorMessage/ErrorMessage";
import LoadingOverlay from "../../common/LoadingOverlay";
import LoadTrackingBoard from "./LoadTrackingBoard";

export default function LoadTrackingBoardContainer() {
  const { data, isLoading, error } = useGetLoadTrackingBoardShipmentsQuery(
    {
      plannedForToday: true,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    {
      refetchInterval: 5000,
    }
  );

  if (isLoading) {
    return (
      <Box
        sx={{
          position: "relative",
          height: "100%",
        }}
      >
        <LoadingOverlay loading />
      </Box>
    );
  }
  if (error) {
    return <ErrorMessage error={error} />;
  }
  return <LoadTrackingBoard shipments={data?.shipments.data || []} />;
}
