import { Driver } from "../../graphql/generated";

function driverLabel(
  driver: Pick<Driver, "firstname" | "lastname"> | null | undefined
) {
  if (!driver) {
    return "N/A";
  }
  return `${driver.firstname || ""} ${driver.lastname || ""}`.trim() || "N/A";
}

export default driverLabel;
