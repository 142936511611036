import { Scalars, useGetGroupsQuery } from "../../../graphql/generated";
import GroupsDisplay from "./GroupsDisplay";

type GroupsDisplayContainerProps = {
  groupIds?: Scalars["ObjectId"][] | null;
};

const GroupsDisplayContainer = ({ groupIds }: GroupsDisplayContainerProps) => {
  const { data } = useGetGroupsQuery({});

  const groups =
    data?.groups.data.filter((group) => groupIds?.includes(group._id)) || [];

  return <GroupsDisplay groups={groups} />;
};

export default GroupsDisplayContainer;
