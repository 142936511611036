import {
  RankSupplierContractsForPurchaseMutation,
  ShipmentLocationType,
  useGetBusinessEntityDetailsQuery,
  useGetBusinessStorageFacilitiesQuery,
  useGetReceiverDetailsQuery,
  useRankSupplierContractsForPurchaseMutation,
} from "../../../../graphql/generated";
import { useDispatch, useSelector } from "react-redux";
import { ShipmentLocationInputData } from "../../../../redux/slices/Types";
import ReceiverForm, { ReceiverFormProps } from "./ReceiverForm";
import {
  editShipment,
  editShipmentLocationInput,
  selectDefaultShipperForLocation,
} from "../../../../redux/slices/shipment/ShipmentForm.slice";
import { useQueryClient } from "@tanstack/react-query";
import { omit } from "lodash";
import { useEffect, useState } from "react";
import BestBuyRankingListContainer from "../../../best-buy/BestBuyRankingList";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { BestBuyRankingListItem } from "../../../best-buy/BestBuyRankingList/BestBuyRankingList";
import { RootState } from "../../../../redux/store";
import { useTranslation } from "react-i18next";

type ReceiverContainerProps = Omit<
  ReceiverFormProps,
  | "onChange"
  | "storageFacilities"
  | "shipmentLocation"
  | "onRequestBestBuyRanking"
> & {
  receiver: ShipmentLocationInputData;
  onChange?: (receiver: ShipmentLocationInputData) => void;
};

export default function ReceiverFormContainer({
  receiver: receiverLocation,
  onChange,
  ...props
}: ReceiverContainerProps) {
  const { t } = useTranslation(["orders", "common"]);
  const shipment = useSelector(
    (state: RootState) => state.shipmentReducer.shipment
  );
  const dispatch = useDispatch();

  const handleChange = async (
    updatedReceiverLocation: ShipmentLocationInputData
  ) => {
    if (onChange) {
      onChange(updatedReceiverLocation);
    } else {
      dispatch(
        editShipmentLocationInput({
          location: updatedReceiverLocation,
        })
      );

      if (updatedReceiverLocation.receiver !== receiverLocation.receiver) {
        const businessEntityDetailsResponse =
          await (updatedReceiverLocation.receiver
            ? useGetBusinessEntityDetailsQuery.fetcher({
                id: updatedReceiverLocation.receiver,
              })()
            : null);
        if (!businessEntityDetailsResponse?.businessEntityById) {
          return;
        }
        dispatch(
          selectDefaultShipperForLocation({
            locationId: updatedReceiverLocation._id,
            businessEntity: businessEntityDetailsResponse?.businessEntityById,
          })
        );
      }
    }
  };

  const receiverStorageFacilitiesQuery = useGetBusinessStorageFacilitiesQuery(
    {
      id: receiverLocation.receiver || "",
    },
    {
      enabled: !!receiverLocation.receiver,
    }
  );

  const storageFacilities =
    receiverStorageFacilitiesQuery.data?.businessEntityById.storageFacilities ||
    [];

  const [rankingList, setRankingList] =
    useState<
      RankSupplierContractsForPurchaseMutation["rankSupplierContractsForPurchase"]
    >();
  const queryClient = useQueryClient();
  const rankSupplierContractsForPurchaseMutation =
    useRankSupplierContractsForPurchaseMutation();

  const [isOpen, setIsOpen] = useState(false);
  const [bestBuyItemSelected, setBestBuyItemSelected] =
    useState<(BestBuyRankingListItem & { _id: string }) | undefined>(undefined);

  const [currentBestBuyInput, setCurrentBestBuyInput] =
    useState<
      | {
          date: string;
          goodProfileId: string;
          quantity: number;
        }
      | undefined
    >(undefined);

  useEffect(() => {
    setIsOpen(!!rankingList);
  }, [rankingList]);

  return (
    <div>
      <ReceiverForm
        shipmentLocation={receiverLocation}
        onChange={handleChange}
        storageFacilities={storageFacilities}
        storageFacilitiesLoading={
          !!receiverLocation.receiver &&
          receiverStorageFacilitiesQuery.isLoading
        }
        onRequestBestBuyRanking={async (form) => {
          setCurrentBestBuyInput(form);
          const receiverDetails = await queryClient.fetchQuery(
            useGetReceiverDetailsQuery.getKey({
              id: receiverLocation.receiver || "",
            }),
            useGetReceiverDetailsQuery.fetcher({
              id: receiverLocation.receiver || "",
            })
          );
          const rankingList =
            await rankSupplierContractsForPurchaseMutation.mutateAsync({
              purchase: {
                customerId:
                  receiverDetails.receiverById.parentBusinessEntity?._id || "",
                dropoffLocation: receiverLocation.location,
                date: receiverLocation.timeWindows[0].fromDate.toISOString(),
                ...omit(form, "receiver", "date"),
              },
            });
          setRankingList(rankingList.rankSupplierContractsForPurchase);
        }}
        {...props}
      />
      <Dialog
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
          setRankingList(undefined);
        }}
        maxWidth="xl"
      >
        <DialogTitle>
          {t("orders:bestbuy.ranking", "Best Buy Ranking")}
        </DialogTitle>
        <DialogContent
          sx={{
            width: 1000,
            height: 500,
          }}
        >
          <BestBuyRankingListContainer
            list={rankingList || []}
            onBestBuyItemSelect={(item) => setBestBuyItemSelected(item)}
            selected={bestBuyItemSelected}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              if (bestBuyItemSelected) {
                dispatch(
                  editShipment({
                    ...shipment,
                    shipmentLocations: shipment.shipmentLocations.map((sl) => {
                      if (sl.locationType === ShipmentLocationType.Pickup) {
                        return {
                          ...sl,
                          shippedGoods: sl.shippedGoods.map((sg) => {
                            if (
                              sg.goodProfileId ===
                              currentBestBuyInput?.goodProfileId
                            ) {
                              return {
                                ...sg,
                                supplierId:
                                  bestBuyItemSelected?.supplierContract.supplier
                                    ?._id,
                              };
                            }
                            return sg;
                          }),
                          shipper:
                            bestBuyItemSelected?.supplierContract.shipper?._id,
                        };
                      }
                      return sl;
                    }),
                  })
                );
              }
              setIsOpen(false);
            }}
          >
            {t("common:apply", "Appliquer")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
