import { Alert } from "@mui/material";
import { omit } from "lodash";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";
import {
  BusinessEntityType,
  GetRelationshipDetailsQuery,
  useAddRelationshipMutation,
  useEditRelationshipMutation,
  useGetBusinessEntityListQuery,
  useGetDriverListQuery,
  useGetRelationshipDetailsQuery,
} from "../../../graphql/generated";
import { showDialog } from "../../../redux/slices/alert/Alert.slice";
import LoadingOverlay from "../../common/LoadingOverlay";
import RelationshipForm from "./RelationshipForm";
import { useTranslation } from "react-i18next";

type RelationshipFormContainerProps = {
  relationshipId?: string;
  onLoad?: (
    relationship: GetRelationshipDetailsQuery["relationshipById"]
  ) => void;
  onSaved?: (
    relationship: GetRelationshipDetailsQuery["relationshipById"]
  ) => void;
};

function RelationshipFormContainer({
  relationshipId,
  onLoad,
  onSaved,
}: RelationshipFormContainerProps) {
  const { t } = useTranslation(["business", "common"]);
  const addRelationshipMutation = useAddRelationshipMutation();
  const editRelationshipMutation = useEditRelationshipMutation();
  const initialRelationshipId = useRef(relationshipId);

  const driversQuery = useGetDriverListQuery({});
  const domicilesQuery = useGetBusinessEntityListQuery({
    businessEntityTypes: [BusinessEntityType.Domicile],
  });

  // We call this hook conditionally because the component
  // should never rerender with a different value for
  // businessEntityId, but if it does we don't update
  // the ref anyway so this is safe
  const getRelationshipQuery = initialRelationshipId.current
    ? // eslint-disable-next-line react-hooks/rules-of-hooks
      useGetRelationshipDetailsQuery(
        {
          id: initialRelationshipId.current,
        },
        {
          refetchOnWindowFocus: false,
          retry: false,
        }
      )
    : null;
  // const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (getRelationshipQuery?.data?.relationshipById) {
      onLoad?.(getRelationshipQuery.data?.relationshipById);
    }
  }, [onLoad, getRelationshipQuery?.data]);

  if (relationshipId) {
    if (getRelationshipQuery?.isLoading) {
      return <LoadingOverlay loading />;
    }
    if (getRelationshipQuery?.error) {
      return (
        <Alert severity="error">
          {t(
            "error.fetchRelationError",
            "An error occurred while fetching the relationship"
          )}
          .{" "}
          {(getRelationshipQuery?.error as Error).message ||
            t("common:error.unknownError", "Unknown error")}
        </Alert>
      );
    }
  }

  return (
    <RelationshipForm
      initialRelationship={getRelationshipQuery?.data?.relationshipById}
      saving={
        addRelationshipMutation.isLoading || editRelationshipMutation.isLoading
      }
      drivers={driversQuery.data?.drivers.data || []}
      domiciles={domicilesQuery.data?.businessEntities.data || []}
      onSave={async (relationship) => {
        try {
          if (relationshipId) {
            const result = await editRelationshipMutation.mutateAsync({
              id: relationshipId,
              editRelationshipData: omit(
                relationship,
                "_id",
                "suppliers",
                "shippers",
                "commodities",
                "receivers",
                "customers"
              ),
            });
            onSaved?.({ ...relationship, _id: result.editRelationship._id });
            // navigate(`/relationships/details/${result.editRelationship._id}`);
          } else {
            const result = await addRelationshipMutation.mutateAsync({
              newRelationshipData: relationship,
            });
            onSaved?.({ ...relationship, _id: result.addRelationship._id });
            // navigate(`/relationships/details/${result.addRelationship._id}`);
            // window.analytics?.track("Relationship Created", {
            //   relationshipId: result.addRelationship._id,
            // });
            // window.analytics?.identify({
            //   relationshipCreated: true,
            //   lastRelationshipCreationDate: new Date(),
            //   lastRelationshipCreationDateOnly: new Date()
            //     .toISOString()
            //     .split("T")[0],
            //   numberOfRelationshipsCreated:
            //     (window.analytics?.user?.()?.traits?.()?.numberOfRelationshipsCreated ||
            //       0) + 1,
            // });
            // window.analytics?.group(window.analytics?.group?.()?.id?.(), {
            //   relationshipCreated: true,
            //   lastRelationshipCreationDate: new Date(),
            //   lastRelationshipCreationDateOnly: new Date()
            //     .toISOString()
            //     .split("T")[0],
            //   numberOfRelationshipsCreated:
            //     (window.analytics?.group?.()?.traits?.()?.numberOfRelationshipsCreated ||
            //       0) + 1,
            // });
          }
        } catch (error) {
          dispatch(
            showDialog({
              title: t("common:error.title", "Error"),
              description:
                t("error.createEditRelationError", {
                  action: relationshipId
                    ? t("common:editing", "editing")
                    : t("common:creating", "creating"),
                  defaultValue: `An error occurred while ${
                    relationshipId ? "editing" : "creating"
                  } the relationship. `,
                }) + (error as Error).message,
              type: "error",
            })
          );
        }
      }}
    />
  );
}

export default RelationshipFormContainer;
