import { Stack, Tooltip } from "@mui/material";
import { MaintenanceTask } from "../../../graphql/generated";
import { Warning } from "@mui/icons-material";
import { differenceInDays } from "date-fns";
import { formatDateTime } from "../../../utils/labels/formatDateTime";
import { formatMileage } from "../../../utils/labels/formatDistance";

type MaintenanceTaskItem = Pick<
  MaintenanceTask,
  | "_id"
  | "label"
  | "startDate"
  | "startMileage"
  | "endDate"
  | "required"
  | "status"
>;

export type MaintenanceWarningsProps = {
  upcomingMaintenanceTasks: MaintenanceTaskItem[];
  currentMaintenanceTasks: MaintenanceTaskItem[];
  mileage?: number;
};

const MaintenanceWarnings = ({
  upcomingMaintenanceTasks,
  currentMaintenanceTasks,
  mileage,
}: MaintenanceWarningsProps) => {
  if (!currentMaintenanceTasks.length && !upcomingMaintenanceTasks.length) {
    return null;
  }

  const shortlyUpcomingMaintenanceTasks = upcomingMaintenanceTasks.filter(
    (task) =>
      task.startDate
        ? differenceInDays(new Date(task.startDate), new Date()) <= 7
        : task.startMileage && mileage
        ? task.startMileage - mileage <= 1000 * 1000
        : false
  );

  return (
    <Stack direction="row" spacing={1}>
      {shortlyUpcomingMaintenanceTasks.length
        ? shortlyUpcomingMaintenanceTasks.map((task) => (
            <Tooltip
              title={`Upcoming ${task.label} ${
                task.startDate
                  ? `on ${formatDateTime(task.startDate)}`
                  : task.startMileage && mileage
                  ? `in ${formatMileage(task.startMileage - mileage)}`
                  : ""
              }`}
              key={task._id}
            >
              <Warning color="warning" />
            </Tooltip>
          ))
        : null}
      {currentMaintenanceTasks.map((task) => (
        <Tooltip
          title={
            task.endDate
              ? `${task.label} from ${
                  task.startDate
                    ? formatDateTime(task.startDate)
                    : task.startMileage
                    ? formatMileage(task.startMileage)
                    : ""
                } to ${formatDateTime(task.endDate)}`
              : `${task.label} started ${
                  task.startDate
                    ? `on ${formatDateTime(task.startDate)}`
                    : task.startMileage
                    ? `at ${formatMileage(task.startMileage)}`
                    : ""
                }`
          }
          key={task._id}
        >
          <Warning color={task.required ? "error" : "warning"} />
        </Tooltip>
      ))}
    </Stack>
  );
};

export default MaintenanceWarnings;
