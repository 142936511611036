import { Box, Typography } from "@mui/material";
import { sum } from "lodash";
import LocaleProvider from "../../../providers/LocaleProvider";
import driverLabel from "../../../utils/labels/driverLabel";
import tractorLabel from "../../../utils/labels/tractorLabel";
import trailerTypeLabel from "../../../utils/labels/trailerTypeLabel";
import ShipmentLink from "../../common/ShipmentLink";
import TrailerTypeIcon from "../../shipment/ShipmentDetails/TrailerFormIcon";
import { TripSummaryData } from "../TripPlanner/TripsGenerator";
import TripTimeline from "../TripTimeline";

interface TripSummaryProps {
  trip: TripSummaryData;
}

function TripSummary({ trip }: TripSummaryProps) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflow: "hidden",
        position: "relative",
        borderStyle: "dashed",
        borderWidth: 1,
        borderRadius: 5,
        backgroundColor: (theme) => theme.palette.lightGrey.main,
      }}
    >
      <Box
        sx={{
          p: 2,
        }}
      >
        {trip.trailer ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <TrailerTypeIcon type={trip.trailer.type} />
            <Typography variant="body1">
              {trailerTypeLabel(trip.trailer?.type)}
            </Typography>
            <Typography variant="subtitle1">
              {LocaleProvider.getCurrencySymbol()}
              {sum(
                trip.shipments
                  .map((shipment) =>
                    shipment.charges.map((charge) => charge.unit * charge.rate)
                  )
                  .flat()
              )}
            </Typography>
          </Box>
        ) : null}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            pt: 2,
          }}
        >
          <Typography variant="body1">
            {tractorLabel(trip.tractor)} /
          </Typography>
          <Typography variant="body1">
            &nbsp;
            {trip.trailer ? trip.trailer.serialNumber : "N/A"} /
          </Typography>
          <Typography variant="body1">
            &nbsp;
            {driverLabel(trip.driver)}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: "white",
          pl: 1,
          pr: 1,
          height: "100%",
          overflow: "auto",
        }}
      >
        <TripTimeline trip={trip} />
      </Box>
      <Box
        sx={{
          pb: 2,
          pt: 1,
          pl: 1,
          pr: 1,
        }}
      >
        {trip.shipments.map((shipment, i) => (
          <ShipmentLink shipment={shipment} />
        ))}
      </Box>
    </Box>
  );
}

export default TripSummary;
