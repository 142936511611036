import { useLocation } from "react-router-dom";
import {
  BusinessEntityType,
  useAddBusinessEntityMutation,
  useGetBusinessEntitiesQuery,
  useGetOrganizationSettingsQuery,
  useGetReceiverListQuery,
} from "../../../../graphql/generated";
import useDialog from "../../../../utils/hooks/useDialog";
import BusinessEntitySelect, {
  BusinessEntitySelectProps,
  SelectBusinessEntity,
} from "./BusinessEntitySelect";
import { useTranslation } from "react-i18next";

type BusinessEntitySelectContainerProps = Omit<
  BusinessEntitySelectProps,
  "businessEntities" | "onBusinessEntitiesRefetch" | "createBusinessEntity"
> & {
  filterBusinessEntityIds?: string[];
};

const BusinessEntitySelectContainer = (
  props: BusinessEntitySelectContainerProps
) => {
  const { pathname } = useLocation();
  const isPortalAccess = pathname.includes("customer-portal");

  const { t } = useTranslation(["orders", "business", "common"]);
  const addBusinessEntityMutation = useAddBusinessEntityMutation();
  const businessEntitiesQuery = useGetBusinessEntitiesQuery({
    businessEntityTypes: props.businessEntityTypes
      ? props.businessEntityTypes
      : props.businessEntityType
      ? [props.businessEntityType]
      : undefined,
  });
  const getReceiverListQuery = useGetReceiverListQuery();

  const { showDialog } = useDialog();

  const receiverListAsBusinessEntities: SelectBusinessEntity[] =
    getReceiverListQuery.data
      ? getReceiverListQuery.data.receivers.data.map((r) => ({
          name: r.name,
          _id: r._id,
          address: { ...r.address, coordinates: { latitude: 0, longitude: 0 } },
          openingSchedules: [],
          code: r.code,
          mcNumber: "",
          type: BusinessEntityType.Receiver,
          additionalTypes: [],
          addressTimezone: "",
          storageFacilities: [],
        }))
      : [];

  const businessEntities = isPortalAccess
    ? receiverListAsBusinessEntities
    : businessEntitiesQuery.data?.businessEntities.data || [];

  const orgSettingsQuery = useGetOrganizationSettingsQuery();

  return (
    <BusinessEntitySelect
      {...props}
      businessEntities={businessEntities.filter((be) => {
        if (props.filterBusinessEntityIds?.length) {
          return props.filterBusinessEntityIds.includes(be._id);
        }
        return true;
      })}
      onBusinessEntitiesRefetch={async () => {
        await businessEntitiesQuery.refetch();
      }}
      createBusinessEntity={async (newBusinessEntityData) => {
        try {
          const result = await addBusinessEntityMutation.mutateAsync({
            newBusinessEntityData,
          });
          return result.addBusinessEntity;
        } catch (error) {
          showDialog({
            title: t("common:error.title", "Error"),
            description:
              t(
                "business:createLocationError",
                "An error occurred while creating the location."
              ) + (error as Error)?.message ||
              t("common:error.unknownError", "Unknown error"),
          });
          return null;
        }
      }}
      allowAddress={
        !!orgSettingsQuery.data?.organizationSettings?.dispatching
          ?.canUseAdHocAddress
      }
    />
  );
};

export default BusinessEntitySelectContainer;
