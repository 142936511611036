import { useCallback } from "react";
import { useParams } from "react-router-dom";
import BusinessEntityDetailsContainer from "../../../components/asset-management/BusinessEntityDetails";
import HeaderTitle from "../../../components/common/HeaderTitle";
import { BusinessEntity } from "../../../graphql/generated";
import { useNavigationOptions } from "../../LayoutRoutes";
import { useTranslation } from "react-i18next";

function ViewCarrier() {
  const { t } = useTranslation("business");
  const { id } = useParams();
  const { setNavigationOptions } = useNavigationOptions();
  const onLoad = useCallback(
    (businessEntity: Pick<BusinessEntity, "code">) => {
      setNavigationOptions({
        title: (
          <HeaderTitle
            label={t("carrier.one", "Carrier")}
            suffix={businessEntity?.code || ""}
          />
        ),
        showBackIcon: true,
      });
    },
    [setNavigationOptions, t]
  );
  if (!id) {
    return null;
  }
  return (
    <BusinessEntityDetailsContainer
      businessEntityId={id}
      onLoad={onLoad}
      urlPrefix="/carriers"
    />
  );
}

export default ViewCarrier;
