import { Box, Button } from "@mui/material";
import { InfoWindowF, MarkerClustererF, MarkerF } from "@react-google-maps/api";
import { GetAllAssetsLocationsQuery, Status } from "../../../graphql/generated";
import toLatLng from "../../../utils/geo/toLaLng";
import Map from "../../common/Map/Map";
import trailerPin from "../../../assets/icons/trailer-pin.svg";
import tractorPin from "../../../assets/icons/tractor-pin.svg";
import truckPin from "../../../assets/icons/truck-pin.svg";
import driverPin from "../../../assets/icons/driver-pin.svg";
import { useEffect, useMemo, useRef, useState } from "react";
import { groupBy, map, minBy, uniq, without } from "lodash";
import { differenceInSeconds } from "date-fns";
import { LiveTrackerTripData } from "../LiveTripsTracker/LiveTripsTracker";
import { TripMarkersAndPath } from "../../trip-planning/TripPlanner/TripRoute/TripRoute";
import MapControl from "./MapControl";
import { usePrevious } from "../../../utils/hooks/usePrevious";
import LinkedAssetHeader from "../LinkedAssetHeader/LinkedAssetHeader";
import TripLiveSummary from "../TripLiveSummary/TripLiveSummary";

type TrailerIdentificationLocationData =
  GetAllAssetsLocationsQuery["trailers"]["data"][0];
type TractorIdentificationLocationData =
  GetAllAssetsLocationsQuery["tractors"]["data"][0] & {
    type?: never;
  };
type DriverIdentificationLocationData =
  GetAllAssetsLocationsQuery["drivers"]["data"][0] & {
    type?: never;
    serialNumber?: never;
  };

export type AssetTrackingMapProps = {
  trailers: TrailerIdentificationLocationData[];
  tractors: TractorIdentificationLocationData[];
  drivers: DriverIdentificationLocationData[];
  trips: LiveTrackerTripData[];
  selectedTrip?: LiveTrackerTripData | null;
  selectedDriverId?: string | null;
  selectedTractorId?: string | null;
  selectedTrailerId?: string | null;
  onTripSelect?: (trip: LiveTrackerTripData) => void;
  onTripUnselect?: () => void;
};

const AssetTrackingMap = ({
  trailers,
  tractors,
  drivers,
  trips,
  selectedTrip,
  selectedDriverId,
  selectedTractorId,
  selectedTrailerId,
  onTripSelect,
  onTripUnselect,
}: AssetTrackingMapProps) => {
  const [openAssetIds, setOpenAssetIds] = useState<string[]>([]);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [isSummarizedView, setIsSummarizedView] = useState(false);
  const mapRef = useRef<google.maps.Map | null>(null);

  const center = useMemo(
    () => ({
      lat: 49.90243189340297,
      lng: -100.95859946285935,
    }),
    []
  );

  // Consider assets linked if they have the same last known location
  // and last known location date
  const linkedAssets = useMemo(() => {
    return map(
      groupBy(
        [
          ...trailers.map((trailer) => ({ ...trailer, kind: "TRAILER" })),
          ...tractors.map((tractor) => ({ ...tractor, kind: "TRACTOR" })),
          ...drivers.map((driver) => ({ ...driver, kind: "DRIVER" })),
        ],
        (asset) =>
          `${asset.lastKnownLocation?.latitude}::${asset.lastKnownLocation?.longitude}::${asset.lastKnownLocationDate}`
      ),
      (assetGroup) => {
        const trailer = assetGroup.find((asset) => asset.kind === "TRAILER") as
          | TrailerIdentificationLocationData
          | null
          | undefined;
        const tractor = assetGroup.find((asset) => asset.kind === "TRACTOR") as
          | TractorIdentificationLocationData
          | null
          | undefined;
        const driver = assetGroup.find((asset) => asset.kind === "DRIVER") as
          | DriverIdentificationLocationData
          | null
          | undefined;
        const trailerTrip = trailer
          ? getAssetCurrentOrNextTrip(trips, trailer, "TRAILER")
          : null;
        const tractorTrip = tractor
          ? getAssetCurrentOrNextTrip(trips, tractor, "TRACTOR")
          : null;
        const driverTrip = driver
          ? getAssetCurrentOrNextTrip(trips, driver, "DRIVER")
          : null;
        return {
          _id: [trailer?._id, tractor?._id, driver?._id]
            .filter(Boolean)
            .join("::"),
          trailer,
          tractor,
          driver,
          lastKnownLocation:
            trailer?.lastKnownLocation ||
            tractor?.lastKnownLocation ||
            driver?.lastKnownLocation,
          lastKnownLocationDate:
            trailer?.lastKnownLocationDate ||
            tractor?.lastKnownLocationDate ||
            driver?.lastKnownLocationDate,
          trip: trailerTrip || tractorTrip || driverTrip,
        };
      }
    );
  }, [trailers, tractors, drivers, trips]);

  const previousSelectedTrip = usePrevious(selectedTrip);
  useEffect(() => {
    if (selectedTrip) {
      const selectedTripLinkedAssets = linkedAssets.filter(
        (linkedAsset) =>
          linkedAsset.trailer?._id === selectedTrip.trailer?._id ||
          linkedAsset.driver?._id === selectedTrip.driver?._id ||
          linkedAsset.tractor?._id === selectedTrip.tractor?._id
      );
      const selectedTripLinkedAssetIds = selectedTripLinkedAssets.map(
        (linkedAsset) => linkedAsset._id
      );
      setOpenAssetIds((openAssetIds) =>
        uniq(openAssetIds.concat(selectedTripLinkedAssetIds))
      );
      if (
        selectedTripLinkedAssets &&
        selectedTripLinkedAssets[0] &&
        selectedTripLinkedAssets[0].lastKnownLocation
      ) {
        mapRef.current?.panTo({
          lat: selectedTripLinkedAssets[0].lastKnownLocation?.latitude,
          lng: selectedTripLinkedAssets[0].lastKnownLocation?.longitude,
        });
        const zoom = mapRef.current?.getZoom();
        if (zoom && zoom < 10) {
          mapRef.current?.setZoom(10);
        }
      }
    } else if (previousSelectedTrip) {
      const previousSelectedTripLinkedAssetIds = linkedAssets
        .filter(
          (linkedAsset) =>
            linkedAsset.trailer?._id === previousSelectedTrip.trailer?._id ||
            linkedAsset.driver?._id === previousSelectedTrip.driver?._id ||
            linkedAsset.tractor?._id === previousSelectedTrip.tractor?._id
        )
        .map((linkedAsset) => linkedAsset._id);
      setOpenAssetIds((openAssetIds) =>
        without(openAssetIds, ...previousSelectedTripLinkedAssetIds)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTrip, previousSelectedTrip]);

  const previousSelectedDriver = usePrevious(selectedDriverId);
  useEffect(() => {
    if (selectedDriverId) {
      const selectedDriverLinkedAssets = linkedAssets.filter(
        (linkedAsset) => linkedAsset.driver?._id === selectedDriverId
      );
      const selectedDriverLinkedAssetIds = selectedDriverLinkedAssets.map(
        (linkedAsset) => linkedAsset._id
      );
      setOpenAssetIds((openAssetIds) =>
        uniq(openAssetIds.concat(selectedDriverLinkedAssetIds))
      );
      if (
        selectedDriverLinkedAssets &&
        selectedDriverLinkedAssets[0] &&
        selectedDriverLinkedAssets[0].lastKnownLocation
      ) {
        mapRef.current?.panTo({
          lat: selectedDriverLinkedAssets[0].lastKnownLocation?.latitude,
          lng: selectedDriverLinkedAssets[0].lastKnownLocation?.longitude,
        });
        const zoom = mapRef.current?.getZoom();
        if (zoom && zoom < 10) {
          mapRef.current?.setZoom(10);
        }
      }
    } else if (previousSelectedDriver) {
      const previousSelectedDriverLinkedAssetIds = linkedAssets
        .filter(
          (linkedAsset) => linkedAsset.driver?._id === previousSelectedDriver
        )
        .map((linkedAsset) => linkedAsset._id);
      setOpenAssetIds((openAssetIds) =>
        without(openAssetIds, ...previousSelectedDriverLinkedAssetIds)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDriverId, previousSelectedDriver]);

  const previousSelectedTractor = usePrevious(selectedTractorId);
  useEffect(() => {
    if (selectedTractorId) {
      const selectedTractorLinkedAssets = linkedAssets.filter(
        (linkedAsset) => linkedAsset.tractor?._id === selectedTractorId
      );
      const selectedTractorLinkedAssetIds = selectedTractorLinkedAssets.map(
        (linkedAsset) => linkedAsset._id
      );
      setOpenAssetIds((openAssetIds) =>
        uniq(openAssetIds.concat(selectedTractorLinkedAssetIds))
      );
      if (
        selectedTractorLinkedAssets &&
        selectedTractorLinkedAssets[0] &&
        selectedTractorLinkedAssets[0].lastKnownLocation
      ) {
        mapRef.current?.panTo({
          lat: selectedTractorLinkedAssets[0].lastKnownLocation?.latitude,
          lng: selectedTractorLinkedAssets[0].lastKnownLocation?.longitude,
        });
        const zoom = mapRef.current?.getZoom();
        if (zoom && zoom < 10) {
          mapRef.current?.setZoom(10);
        }
      }
    } else if (previousSelectedTractor) {
      const previousSelectedTractorLinkedAssetIds = linkedAssets
        .filter(
          (linkedAsset) => linkedAsset.tractor?._id === previousSelectedTractor
        )
        .map((linkedAsset) => linkedAsset._id);
      setOpenAssetIds((openAssetIds) =>
        without(openAssetIds, ...previousSelectedTractorLinkedAssetIds)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTractorId, previousSelectedTractor]);

  const previousSelectedTrailer = usePrevious(selectedTrailerId);
  useEffect(() => {
    if (selectedTrailerId) {
      const selectedTrailerLinkedAssets = linkedAssets.filter(
        (linkedAsset) => linkedAsset.trailer?._id === selectedTrailerId
      );
      const selectedTrailerLinkedAssetIds = selectedTrailerLinkedAssets.map(
        (linkedAsset) => linkedAsset._id
      );
      setOpenAssetIds((openAssetIds) =>
        uniq(openAssetIds.concat(selectedTrailerLinkedAssetIds))
      );
      if (
        selectedTrailerLinkedAssets &&
        selectedTrailerLinkedAssets[0] &&
        selectedTrailerLinkedAssets[0].lastKnownLocation
      ) {
        mapRef.current?.panTo({
          lat: selectedTrailerLinkedAssets[0].lastKnownLocation?.latitude,
          lng: selectedTrailerLinkedAssets[0].lastKnownLocation?.longitude,
        });
        const zoom = mapRef.current?.getZoom();
        if (zoom && zoom < 10) {
          mapRef.current?.setZoom(10);
        }
      }
    } else if (previousSelectedTrailer) {
      const previousSelectedTrailerLinkedAssetIds = linkedAssets
        .filter(
          (linkedAsset) => linkedAsset.trailer?._id === previousSelectedTrailer
        )
        .map((linkedAsset) => linkedAsset._id);
      setOpenAssetIds((openAssetIds) =>
        without(openAssetIds, ...previousSelectedTrailerLinkedAssetIds)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTrailerId, previousSelectedTrailer]);

  return (
    <Box
      sx={{
        height: "100%",
      }}
      id="asset-tracking-map-container"
    >
      <Map
        containerStyle={{
          height: "100%",
        }}
        center={center}
        onLoad={(map) => {
          mapRef.current = map;
          setMapLoaded(true);
        }}
        zoom={4}
      >
        {linkedAssets.length ? (
          <MarkerClustererF>
            {(clusterer) => (
              <>
                {linkedAssets.map((linkedAsset) => {
                  if (!linkedAsset.lastKnownLocation) {
                    return null;
                  }
                  if (isSummarizedView && !linkedAsset.trip) {
                    return null;
                  }
                  const linkedAssetTrip = linkedAsset.trip;
                  const otherLinkedAssetsForTrip = linkedAssets.filter(
                    (otherLinkedAsset) =>
                      otherLinkedAsset.trip &&
                      otherLinkedAsset.trip._id === linkedAssetTrip?._id &&
                      otherLinkedAsset._id !== linkedAsset._id
                  );
                  let shouldDisplayTrip = true;
                  if (otherLinkedAssetsForTrip.length > 0) {
                    if (
                      otherLinkedAssetsForTrip.find(
                        (otherLinkedAsset) =>
                          otherLinkedAsset.lastKnownLocationDate >
                          linkedAsset.lastKnownLocationDate
                      )
                    ) {
                      shouldDisplayTrip = false;
                    }
                  }
                  return (
                    <MarkerF
                      position={toLatLng(linkedAsset.lastKnownLocation)}
                      clusterer={clusterer}
                      key={linkedAsset._id}
                      options={{
                        icon: {
                          url:
                            linkedAsset.trailer && linkedAsset.tractor
                              ? truckPin
                              : linkedAsset.trailer
                              ? trailerPin
                              : linkedAsset.tractor
                              ? tractorPin
                              : linkedAsset.driver
                              ? driverPin
                              : truckPin,
                          anchor: new window.google.maps.Point(17, 17),
                        },
                      }}
                      onClick={() => {
                        setOpenAssetIds(
                          uniq(openAssetIds.concat(linkedAsset._id))
                        );
                        if (linkedAsset.trip) {
                          onTripSelect?.(linkedAsset.trip);
                        }
                      }}
                    >
                      {isSummarizedView ||
                      openAssetIds.includes(linkedAsset._id) ? (
                        <InfoWindowF
                          position={toLatLng(linkedAsset.lastKnownLocation)}
                          onCloseClick={() => {
                            setOpenAssetIds(
                              openAssetIds.filter(
                                (id) => id !== linkedAsset._id
                              )
                            );
                            if (selectedTrip) {
                              onTripUnselect?.();
                            }
                          }}
                          options={{
                            disableAutoPan: true,
                          }}
                        >
                          <>
                            <LinkedAssetHeader
                              linkedAsset={linkedAsset}
                              isSummarizedView={isSummarizedView}
                            />
                            {linkedAsset.trip && shouldDisplayTrip ? (
                              <TripLiveSummary
                                trip={linkedAsset.trip}
                                isSummarizedView={isSummarizedView}
                              />
                            ) : null}
                          </>
                        </InfoWindowF>
                      ) : null}
                    </MarkerF>
                  );
                })}
              </>
            )}
          </MarkerClustererF>
        ) : null}
        {!isSummarizedView && selectedTrip && mapLoaded ? (
          <TripMarkersAndPath trip={selectedTrip} />
        ) : null}
        <MapControl position={window.google.maps.ControlPosition.TOP_RIGHT}>
          <Button
            variant="contained"
            color="lightOrange"
            onClick={() => {
              setIsSummarizedView(!isSummarizedView);
            }}
            sx={{
              mr: 1,
              mt: 1,
            }}
          >
            {!isSummarizedView ? "Summary View" : "Detail View"}
          </Button>
        </MapControl>
      </Map>
    </Box>
  );
};

const getAssetCurrentOrNextTrip = (
  trips: LiveTrackerTripData[],
  asset:
    | TractorIdentificationLocationData
    | TrailerIdentificationLocationData
    | DriverIdentificationLocationData,
  type: "TRACTOR" | "TRAILER" | "DRIVER"
): LiveTrackerTripData | null => {
  const assetTrips = trips.filter(
    (trip) =>
      (type === "TRACTOR" && trip.tractor?._id === asset._id) ||
      (type === "TRAILER" && trip.trailer?._id === asset._id) ||
      (type === "DRIVER" && trip.driver?._id === asset._id)
  );
  const closestTrip = minBy(assetTrips, (trip) => {
    // By default return the first trip we find in progress
    // otherwise return the next trip that is yet to start
    // otherwise return the trip that finished last
    if (trip.status === Status.InProgress) {
      return 0;
    }
    const firstPickupTime = new Date(trip.shipmentLocations[0].arrivalTime);
    const now = new Date();
    if (firstPickupTime < now) {
      return 86400 + differenceInSeconds(now, firstPickupTime);
    }
    return differenceInSeconds(firstPickupTime, now);
  });
  return closestTrip || null;
};

export default AssetTrackingMap;
