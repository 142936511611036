import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { useTranslation } from 'react-i18next';

export type AlertDialogueProps = {
    changeDriver : boolean;
    onClose: () => void;
}

export default function AlertDialog({changeDriver=false, onClose }: AlertDialogueProps) {
  const [open, setOpen] = React.useState(changeDriver);

  const { t } = useTranslation(["common"]);

  const onYes = () =>{
        setOpen(false);
    }

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Change Driver in Progress?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("unassign","You are about to unassign a driver on an active order. Are you sure you want to do that?")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("onClose","No")}</Button>
          <Button onClick={onYes} autoFocus>
           { t("onYes","Yes I am sure")}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

