import { Container } from "@mui/material";
import TrailerForm from "../../../components/asset-management/TrailerForm";

function CreateTrailer() {
  return (
    <Container>
      <TrailerForm />
    </Container>
  );
}

export default CreateTrailer;
