import { Box, Grid, Avatar } from "@mui/material";
import Divider from "@mui/material/Divider/Divider";
import Stack from "@mui/material/Stack/Stack";
import Typography from "@mui/material/Typography";
import { capitalize } from "lodash";
import { ReactNode } from "react";
import { Status } from "../../../graphql/generated";
import ShipmentSummariesList from "../../shipment/ShipmentSummariesList";
import { ShipmentDataSummary } from "../../shipment/ShipmentSummary/ShipmentSummary";
import { useTranslation } from "react-i18next";
import statusKey from "../../../utils/i18n/statusKey";
import enumLabel from "../../../utils/labels/enumLabel";

const LoadSection = ({
  children,
  statuses = [],
  title,
  numberOfLoads,
}: {
  children: ReactNode;
  statuses?: Status[];
  title?: string;
  numberOfLoads: number;
}) => {
  const { t } = useTranslation("trips");
  let style = {
    border: "black",
    bgColor: "lightGrey",
    textColor: "white",
  };

  switch (statuses[0]) {
    case Status.Unassigned:
      style = {
        bgColor: "lightPrimary.light",
        border: "black",
        textColor: "white",
      };
      break;
    case Status.InProgress:
      style = {
        bgColor: "lightOrange.light",
        border: "primary.main",
        textColor: "primary.contrastText",
      };
      break;
    case Status.Complete:
      style = {
        bgColor: "success.light",
        border: "success.contrastText",
        textColor: "success.light",
      };
  }
  return (
    <Box
      sx={{
        height: "100%",
        border: `1px solid black`,
        backgroundColor: style.bgColor,
        borderRadius: 2,
      }}
    >
      <Box>
        <Stack
          direction="row"
          spacing={2}
          sx={{ justifyContent: "center", alignItems: "center" }}
        >
          <Typography
            variant="h5"
            sx={{ color: style.border, textAlign: "center" }}
          >
            {title ||
              statuses
                .map((status) =>
                  capitalize(
                    t("statusLabel", {
                      status: statusKey[status],
                      defaultValue: enumLabel(status),
                    })
                  )
                )
                .join(" / ")}
          </Typography>
          <Box>
            <Avatar
              sx={{
                backgroundColor: style.border,
                width: 24,
                height: 24,
                color: style.textColor,
              }}
            >
              {numberOfLoads}
            </Avatar>
          </Box>
        </Stack>
        <Divider sx={{ backgroundColor: style.border }} />
      </Box>
      <Box sx={{ p: 1 }}>{children}</Box>
    </Box>
  );
};

export default function LoadTrackingBoard({
  shipments,
}: {
  shipments: ShipmentDataSummary[];
}) {
  const { t } = useTranslation("trips");
  const filterByStatus = (
    shipments: ShipmentDataSummary[],
    status: Status[]
  ) => {
    return shipments.filter((shipment) => status.includes(shipment.status));
  };

  return (
    <Grid
      sx={{
        height: "100%",
      }}
      container
      columnSpacing={2}
      rowSpacing={2}
    >
      <Grid sx={{ minHeight: "200px" }} item xs={12} lg={4}>
        {(() => {
          const unstartedLoads = shipments.filter(
            (shipment) =>
              shipment.status !== Status.InProgress &&
              shipment.status !== Status.Cancelled &&
              shipment.status !== Status.Complete
          );
          return (
            <LoadSection
              title={t("status.notStarted", "Not started")}
              numberOfLoads={unstartedLoads.length}
            >
              <ShipmentSummariesList
                direction="column"
                groupByTrailer={false}
                shipments={unstartedLoads}
                dragable={false}
              />
            </LoadSection>
          );
        })()}
      </Grid>
      <Grid sx={{ minHeight: "200px" }} item xs={12} lg={4}>
        {(() => {
          const inProgressLoads = filterByStatus(shipments, [
            Status.InProgress,
          ]);
          return (
            <LoadSection
              statuses={[Status.InProgress]}
              numberOfLoads={inProgressLoads.length}
            >
              <ShipmentSummariesList
                direction="column"
                groupByTrailer={false}
                shipments={inProgressLoads}
                dragable={false}
              />
            </LoadSection>
          );
        })()}
      </Grid>
      <Grid sx={{ minHeight: "200px" }} item xs={12} lg={4}>
        {(() => {
          const deliveredLoads = filterByStatus(shipments, [Status.Complete]);
          return (
            <LoadSection
              statuses={[Status.Complete]}
              numberOfLoads={deliveredLoads.length}
            >
              <ShipmentSummariesList
                direction="column"
                groupByTrailer={false}
                shipments={deliveredLoads}
                dragable={false}
              />
            </LoadSection>
          );
        })()}
      </Grid>
    </Grid>
  );
}
