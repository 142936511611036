import { useGetCustomFieldDefinitionsQuery } from "../../../graphql/generated";
import CustomFieldsDisplay, {
  CustomFieldsDisplayProps,
} from "./CustomFieldsDisplay";

type CustomFieldsDisplayContainerProps = Omit<
  CustomFieldsDisplayProps,
  "customFieldDefinitions"
>;

const CustomFieldsDisplayContainer = (
  props: CustomFieldsDisplayContainerProps
) => {
  const customFieldDefinitionsQuery = useGetCustomFieldDefinitionsQuery(
    {},
    {
      enabled: !!props.customFields?.length,
    }
  );

  const customFieldDefinitions =
    customFieldDefinitionsQuery.data?.cutomFieldDefinitions.data || [];

  return (
    <CustomFieldsDisplay
      customFieldDefinitions={customFieldDefinitions}
      {...props}
    />
  );
};

export default CustomFieldsDisplayContainer;
