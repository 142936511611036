import {
  IconButton,
  IconButtonProps,
  Tooltip,
  TooltipProps,
} from "@mui/material";
import { HTMLAttributeAnchorTarget } from "react";

const LynksIconButton = (
  props: IconButtonProps & {
    href?: string;
    target?: HTMLAttributeAnchorTarget;
    tooltip?: string;
    tooltipPlacement?: TooltipProps["placement"];
  }
) => (
  <Tooltip
    placement={props.tooltipPlacement || "bottom"}
    disableHoverListener={!props.tooltip}
    title={props.tooltip}
  >
    <IconButton
      style={{
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: props.color,
        borderRadius: 9,
      }}
      {...props}
    ></IconButton>
  </Tooltip>
);

export default LynksIconButton;
