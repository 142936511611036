import { Button, Stack } from "@mui/material";
import {
  useRestoreLatestSnapshotMutation,
  useSaveDataSnapshotMutation,
} from "../../../graphql/generated";
import useDialog from "../../../utils/hooks/useDialog";
import { useTranslation } from "react-i18next";

const DemoUtils = () => {
  const { t } = useTranslation(["settings", "common"]);
  const saveSnapshotMutation = useSaveDataSnapshotMutation();
  const restoreSnapshotMutation = useRestoreLatestSnapshotMutation();

  const { showDialog, hideDialog } = useDialog();
  return (
    <Stack direction="column" alignItems="center" spacing={2}>
      <Button
        variant="contained"
        onClick={() => {
          showDialog({
            type: "primary",
            title: t("demoUtils.saveSnapshot", "Save snapshot"),
            description: t(
              "demoUtils.saveSnapshotConfirm",
              "Are you sure you want to save a snapshot of the current data?"
            ),
            actions: [
              {
                title: t("cancel", "Cancel"),
                type: "secondary",
              },
              {
                title: t("save", "Save"),
                type: "primary",
                onClick: async () => {
                  try {
                    await saveSnapshotMutation.mutateAsync({});
                    hideDialog();
                    showDialog({
                      type: "primary",
                      title: t("success", "Success"),
                      description: t(
                        "demoUtils.snapshotSuccess",
                        "Snapshot saved successfully"
                      ),
                    });
                  } catch (error) {
                    showDialog({
                      type: "error",
                      title: t("common:error.title", "Error"),
                      description:
                        "An error occurred while saving the snapshot. " +
                        (error as Error).message,
                    });
                  }
                },
              },
            ],
          });
        }}
      >
        {t("demoUtils.saveSnapshot", "Save snapshot")}
      </Button>
      <Button
        variant="outlined"
        onClick={() => {
          showDialog({
            type: "primary",
            title: t("demoUtils.restoreSnapshot", "Restore snapshot"),
            description: t(
              "demoUtils.restoreSnapshotConfirm",
              "Are you sure you want to restore the latest snapshot?. This will reset your data to the state it was when the snapshot was taken."
            ),
            actions: [
              {
                title: t("cancel", "Cancel"),
                type: "secondary",
              },
              {
                title: t("restore", "Restore"),
                type: "primary",
                onClick: async () => {
                  try {
                    await restoreSnapshotMutation.mutateAsync({});
                    hideDialog();
                    showDialog({
                      type: "primary",
                      title: t("success", "Success"),
                      description: t(
                        "demoUtils.restoreSuccess",
                        "Snapshot restored successfully"
                      ),
                    });
                  } catch (error) {
                    showDialog({
                      type: "error",
                      title: t("common:error.title", "Error"),
                      description:
                        t(
                          "demoUtils.error.generic",
                          "An error occurred while restoring the snapshot. "
                        ) + (error as Error).message,
                    });
                  }
                },
              },
            ],
          });
        }}
      >
        {t("demoUtils.restoreSnapshot", "Restore snapshot")}
      </Button>
    </Stack>
  );
};

export default DemoUtils;
