import { Settings } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { ExtensionConfigurationValue } from "../../../graphql/generated";
import { ExtensionWithStatusListItemData } from "../ExtensionsManager/ExtensionsManager";
import { useTranslation } from "react-i18next";

type ExtensionCardProps = {
  extension: ExtensionWithStatusListItemData;
  installing: boolean;
  uninstalling: boolean;
  installExtension: (
    extensionId: string,
    configurationValues: ExtensionConfigurationValue[]
  ) => void;
  uninstallExtension: (extensionId: string) => void;
};

const ExtensionCard = ({
  extension: extensionWithStatus,
  installing,
  uninstalling,
  installExtension,
  uninstallExtension,
}: ExtensionCardProps) => {
  const { t } = useTranslation("common");
  return (
    <Card>
      <CardHeader
        title={extensionWithStatus.extension.name}
        action={
          extensionWithStatus.installed &&
          extensionWithStatus.extension.settings.configureEndpoint ? (
            <Tooltip title="Configure the extension">
              <IconButton
                onClick={() => {
                  window.open(
                    `${extensionWithStatus.extension.settings.rootUrl}${extensionWithStatus.extension.settings.configureEndpoint}`,
                    `${extensionWithStatus.extension.name} configuration`,
                    `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=992,height=680,left=100,top=100`
                  );
                }}
                id={`configure-extension-${extensionWithStatus.extension.name}`}
              >
                <Settings />
              </IconButton>
            </Tooltip>
          ) : null
        }
      />
      <CardMedia
        sx={{
          height: 140,
          backgroundSize: "contain",
          paddingLeft: 2,
          paddingRight: 2,
          backgroundOrigin: "content-box",
        }}
        image={
          extensionWithStatus.extension.coverImage ||
          "/static/images/cards/contemplative-reptile.jpg"
        }
        title={extensionWithStatus.extension.name + " cover image"}
      />
      <CardContent>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            height: 80,
          }}
        >
          {extensionWithStatus.extension.description}
        </Typography>
      </CardContent>
      <CardActions sx={{ justifyContent: "space-between" }}>
        {extensionWithStatus.extension.websiteUrl ? (
          <Button
            size="small"
            LinkComponent="a"
            href={extensionWithStatus.extension.websiteUrl}
            target="_blank"
          >
            {t("learnMore", "Learn More")}
          </Button>
        ) : null}
        {!extensionWithStatus.installed ? (
          <LoadingButton
            loading={installing}
            size="small"
            variant="contained"
            onClick={() => {
              installExtension(extensionWithStatus.extension._id, []);
            }}
            id={`install-extension-${extensionWithStatus.extension.name}`}
          >
            {t("install", "Install")}
          </LoadingButton>
        ) : (
          <LoadingButton
            size="small"
            loading={uninstalling}
            onClick={() => {
              uninstallExtension(extensionWithStatus.extension._id);
            }}
            color="error"
            id={`uninstall-extension-${extensionWithStatus.extension.name}`}
          >
            {t("uninstall", "Uninstall")}
          </LoadingButton>
        )}
      </CardActions>
    </Card>
  );
};

export default ExtensionCard;
