import { Alert } from "@mui/material";
import { omit } from "lodash";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";
import {
  GetQualificationDetailsQuery,
  useAddQualificationMutation,
  useEditQualificationMutation,
  useGetQualificationDetailsQuery,
} from "../../../graphql/generated";
import { showDialog } from "../../../redux/slices/alert/Alert.slice";
import LoadingOverlay from "../../common/LoadingOverlay";
import QualificationForm, { QualificationFormProps } from "./QualificationForm";

type QualificationFormContainerProps = Omit<
  QualificationFormProps,
  "onSave" | "saving"
> & {
  qualificationId?: string;
  onLoad?: (
    qualification: GetQualificationDetailsQuery["qualificationById"]
  ) => void;
  onSaved?: (
    qualification: GetQualificationDetailsQuery["qualificationById"]
  ) => void;
};

function QualificationFormContainer({
  qualificationId,
  onLoad,
  onSaved,
  ...props
}: QualificationFormContainerProps) {
  const addQualificationMutation = useAddQualificationMutation();
  const editQualificationMutation = useEditQualificationMutation();
  const initialQualificationId = useRef(qualificationId);

  // We call this hook conditionally because the component
  // should never rerender with a different value for
  // businessEntityId, but if it does we don't update
  // the ref anyway so this is safe
  const getQualificationQuery = initialQualificationId.current
    ? // eslint-disable-next-line react-hooks/rules-of-hooks
      useGetQualificationDetailsQuery(
        {
          id: initialQualificationId.current,
        },
        {
          refetchOnWindowFocus: false,
          retry: false,
        }
      )
    : null;
  // const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (getQualificationQuery?.data?.qualificationById) {
      onLoad?.(getQualificationQuery.data?.qualificationById);
    }
  }, [onLoad, getQualificationQuery?.data]);

  if (qualificationId) {
    if (getQualificationQuery?.isLoading || getQualificationQuery?.isFetching) {
      return <LoadingOverlay loading />;
    }
    if (getQualificationQuery?.error) {
      return (
        <Alert severity="error">
          An error occurred while fetching the qualification.{" "}
          {(getQualificationQuery?.error as Error).message || "Unknown error"}
        </Alert>
      );
    }
  }

  return (
    <QualificationForm
      initialQualification={getQualificationQuery?.data?.qualificationById}
      saving={
        addQualificationMutation.isLoading ||
        editQualificationMutation.isLoading
      }
      onSave={async (qualification) => {
        try {
          if (qualificationId) {
            const result = await editQualificationMutation.mutateAsync({
              id: qualificationId,
              editQualificationData: omit(qualification, "_id"),
            });
            onSaved?.({
              ...qualification,
              _id: result.editQualification._id,
            });
            // navigate(`/qualifications/details/${result.editQualification._id}`);
          } else {
            const result = await addQualificationMutation.mutateAsync({
              newQualificationData: {
                ...qualification,
              },
            });
            onSaved?.({
              ...qualification,
              _id: result.addQualification._id,
            });
            // navigate(`/qualifications/details/${result.addQualification._id}`);
            // window.analytics?.track("Qualification Created", {
            //   qualificationId: result.addQualification._id,
            // });
            // window.analytics?.identify({
            //   qualificationCreated: true,
            //   lastQualificationCreationDate: new Date(),
            //   lastQualificationCreationDateOnly: new Date()
            //     .toISOString()
            //     .split("T")[0],
            //   numberOfQualificationsCreated:
            //     (window.analytics?.user?.()?.traits?.()?.numberOfQualificationsCreated ||
            //       0) + 1,
            // });
            // window.analytics?.group(window.analytics?.group?.()?.id?.(), {
            //   qualificationCreated: true,
            //   lastQualificationCreationDate: new Date(),
            //   lastQualificationCreationDateOnly: new Date()
            //     .toISOString()
            //     .split("T")[0],
            //   numberOfQualificationsCreated:
            //     (window.analytics?.group?.()?.traits?.()?.numberOfQualificationsCreated ||
            //       0) + 1,
            // });
          }
        } catch (error) {
          dispatch(
            showDialog({
              title: "Error",
              description:
                `An error occurred while ${
                  qualificationId ? "editing" : "creating"
                } the qualification. ` + (error as Error).message,
              type: "error",
            })
          );
        }
      }}
      {...props}
    />
  );
}

export default QualificationFormContainer;
