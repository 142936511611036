import {
  useGetExtensionsQuery,
  useGetLoadBoardLoadsQuery,
} from "../../../graphql/generated";
import LoadBoardLoadsList from "./LoadBoardLoadsList";

export default function LoadBoardLoadsListContainer() {
  const getExtensionsQuery = useGetExtensionsQuery();

  const installedLoadboardExtensions = getExtensionsQuery.data?.extensions.data
    .filter((extension) => extension.installed)
    .filter((extension) =>
      ["TruckStop", "123Loadboard"].includes(extension.extension.name)
    );

  const loadboardRequiresDestination = installedLoadboardExtensions?.some(
    (extension) => extension.extension.name === "TruckStop"
  );
  return (
    <LoadBoardLoadsList
      query={useGetLoadBoardLoadsQuery}
      installedExtensions={installedLoadboardExtensions || []}
      loadboardRequiresDestination={loadboardRequiresDestination || false}
    />
  );
}
