import {
  useBulkAddTrailersMutation,
  useGetTrailerListQuery,
} from "../../../graphql/generated";
import TrailersList from "./TrailersList";

export default function TrailersListContainer() {
  const bulkCreateTrailers = useBulkAddTrailersMutation();
  return (
    <TrailersList
      onBulkCreate={async (data) => {
        bulkCreateTrailers.mutateAsync({ newTrailerData: data });
      }}
      query={useGetTrailerListQuery}
    />
  );
}
