import { toArray } from "lodash";
import { useRef } from "react";
import {
  BusinessEntityType,
  useGetReceiverListQuery,
  useGetShipperListQuery,
} from "../../../../../graphql/generated";
import { ConditionValueSelectorComponentProps } from "../../ConditionForm";
import BusinessEntityInput from "./BusinessEntityInput";
import { AutocompleteProps } from "@mui/material";

type BusinessEntityInputContainerProps =
  ConditionValueSelectorComponentProps & {
    businessEntityType: BusinessEntityType;
  } & Pick<AutocompleteProps<string, true, undefined, undefined>, "sx">;

const BusinessEntityInputContainer = ({
  businessEntityType,
  ...props
}: BusinessEntityInputContainerProps) => {
  const initialBusinessEntityType = useRef(businessEntityType);
  const queryHook =
    initialBusinessEntityType.current === BusinessEntityType.Shipper
      ? useGetShipperListQuery
      : useGetReceiverListQuery;
  const query = queryHook();
  const businessEntities = toArray(query.data)[0]?.data;
  return (
    <BusinessEntityInput {...props} businessEntities={businessEntities || []} />
  );
};

export default BusinessEntityInputContainer;
