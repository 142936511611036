import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { BackupOutlined } from "@mui/icons-material";

import type { DropTargetMonitor } from "react-dnd";
import { useDrop } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";
import { useRef, useState } from "react";
import { FormHelperText } from "@mui/material";
import { useTranslation } from "react-i18next";

interface UploadButtonProps {
  onUpload: (file: File | null) => void;
  multiple?: boolean;
  accept?: string;
}

export default function UploadButton({
  onUpload,
  multiple,
  accept,
}: UploadButtonProps) {
  const { t } = useTranslation("users");
  const [error, setError] = useState("");
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item: { files: FileList }) {
        setUploadedFile(item.files[0]);
        onUpload(item.files[0]);
      },
      canDrop(item) {
        if (!multiple && item.files.length > 1) {
          setError("Only one document allowed per upload");
          onUpload(null);
          return false;
        }
        if (
          item.files[0] &&
          accept &&
          !verifyAccept(item.files[0].type, accept)
        ) {
          setError("Unsupported file type");
          return false;
        }
        setError("");
        return true;
      },
      collect: (monitor: DropTargetMonitor) => {
        return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        };
      },
    }),
    []
  );

  const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { files },
    } = event;
    if (files?.length) {
      setUploadedFile(files[0]);
      onUpload(files[0]);
    }
  };
  const isActive = canDrop && isOver;
  return (
    <Box sx={{ flexDirection: "column" }}>
      <Box
        sx={{
          display: "flex",
          width: "100%",
        }}
      >
        <Stack
          spacing={1}
          direction="row"
          sx={{
            p: 1,
            backgroundColor: isActive
              ? "success.light"
              : isOver && !canDrop
              ? "error.light"
              : "lightgray",
            flex: 1,
            borderTopLeftRadius: 4,
            borderBottomLeftRadius: 4,
            cursor: "pointer",
          }}
          ref={drop}
          onClick={() => inputRef.current?.click()}
        >
          <BackupOutlined />
          <Typography>
            {canDrop && isOver
              ? t("driver.document.releaseToDrop", "Release to drop")
              : canDrop
              ? t("driver.document.dropHere", "Drop the file here")
              : isOver && !canDrop
              ? t("driver.document.unsupportedFile", "Unsupported file")
              : uploadedFile
              ? uploadedFile.name
              : t(
                  "driver.document.dndDescription",
                  "Drag and drop or select a file"
                )}
          </Typography>
        </Stack>
        <Button
          sx={{
            borderRadius: 0,
            borderTopRightRadius: 4,
            borderBottomRightRadius: 4,
          }}
          variant="contained"
          size="small"
          component="label"
        >
          <input
            ref={inputRef}
            hidden
            onChange={handleUpload}
            type="file"
            accept={accept}
          />
          {t("driver.document.selectFile", "Select File")}
        </Button>
      </Box>
      {error && <FormHelperText error> {error}</FormHelperText>}
    </Box>
  );
}

/**
 * Check if a mime type matches the set given in accept
 *
 * @param type the mime type to test, ex image/png
 * @param accept the mime types to accept, ex audio/*,video/*,image/png
 * @returns true if the mime is accepted, false otherwise
 */
function verifyAccept(type: string, accept: string): boolean {
  const allowed = accept.split(",").map((x) => x.trim());
  return allowed.includes(type) || allowed.includes(type.split("/")[0] + "/*");
}
