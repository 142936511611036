import {
  useBulkAddBusinessEntitiesMutation,
  useGetBusinessEntityListQuery,
} from "../../../graphql/generated";
import CarriersList from "./CarriersList";

export default function CarriersListContainer() {
  const bulkCreate = useBulkAddBusinessEntitiesMutation();
  return (
    <CarriersList
      onBulkCreate={async (locations) => {
        await bulkCreate.mutateAsync({ newBusinessEntityData: locations });
      }}
      query={useGetBusinessEntityListQuery}
    />
  );
}
