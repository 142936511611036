import { capitalize } from "lodash";

function enumLabel<T extends string>(value: T | undefined | null) {
  if (!value) {
    return null;
  }
  return value.split("_").map(capitalize).join(" ").trim();
}

export default enumLabel;
