import {
  Grid,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { ComponentType } from "react";
import {
  BillingRuleCondition,
  BillingRuleConditionOperator,
  BillingRuleConditionTarget,
  BusinessEntityType,
  GetCustomerDetailsQuery,
  SupplierContractCondition,
  SupplierContractConditionTarget,
} from "../../../graphql/generated";
import billingRuleConditionOperatorLabel from "../../../utils/labels/billingRule/billingRuleConditionOperatorLabel";
import billingRuleConditionTargetLabel from "../../../utils/labels/billingRule/billingRuleConditionTargetLabel";
import BusinessEntityInput from "./value-selectors/BusinessEntityInput";
import DateInput from "./value-selectors/DateInput";
import DurationInput from "./value-selectors/DurationInput";
import GoodsInput from "./value-selectors/GoodsInput";
import MileageInput from "./value-selectors/MileageInput";
import TimeInput from "./value-selectors/TimeInput";
import TrailerInput from "./value-selectors/TrailerInput";
import TrailerTypeSelect from "./value-selectors/TrailerTypeSelect";
import { useTranslation } from "react-i18next";
import enumLabel from "../../../utils/labels/enumLabel";
import NumberInput from "./value-selectors/NumberInput";

export type ConditionValueSelectorComponentProps = {
  value: number | string | string[] | null;
  onChange: (value: number | string | string[] | null) => void;
  customer?: GetCustomerDetailsQuery["customerById"];
};

export type ConditionValueSelectorComponent =
  ComponentType<ConditionValueSelectorComponentProps>;

const ConditionValueSelectorComponentByTarget: {
  [key in
    | BillingRuleConditionTarget
    | SupplierContractConditionTarget]: ConditionValueSelectorComponent | null;
} = {
  [BillingRuleConditionTarget.TrailerType]: (props) => (
    <TrailerTypeSelect {...props} sx={{ maxWidth: 300, display: "flex" }} />
  ),
  [BillingRuleConditionTarget.Date]: DateInput,
  [BillingRuleConditionTarget.Otherwise]: null,
  [BillingRuleConditionTarget.Mileage]: MileageInput,
  [BillingRuleConditionTarget.Receiver]: (props) => (
    <BusinessEntityInput
      {...props}
      businessEntityType={BusinessEntityType.Receiver}
      sx={{ maxWidth: 300, display: "flex" }}
    />
  ),
  [BillingRuleConditionTarget.Shipper]: (props) => (
    <BusinessEntityInput
      {...props}
      businessEntityType={BusinessEntityType.Shipper}
      sx={{ maxWidth: 300, display: "flex" }}
    />
  ),
  [BillingRuleConditionTarget.Time]: TimeInput,
  [BillingRuleConditionTarget.Trailer]: (props) => (
    <TrailerInput {...props} sx={{ maxWidth: 300, display: "flex" }} />
  ),
  [BillingRuleConditionTarget.DetentionTime]: DurationInput,
  [BillingRuleConditionTarget.Goods]: (
    props: ConditionValueSelectorComponentProps
  ) => <GoodsInput {...props} sx={{ maxWidth: 300, display: "flex" }} />,
  [SupplierContractConditionTarget.PurchaseTime]: TimeInput,
  [SupplierContractConditionTarget.PurchaseQuantity]: NumberInput,
};

type BillingRuleConditionFormProps = {
  condition: BillingRuleCondition;
  onChange: (condition: BillingRuleCondition) => void;
  entity: "billingRule";
  customer?: GetCustomerDetailsQuery["customerById"];
};

type SupplierContractConditionFormProps = {
  condition: SupplierContractCondition;
  entity: "supplierContract";
  onChange: (condition: SupplierContractCondition) => void;
  customer?: undefined;
};

type ConditionFormProps =
  | BillingRuleConditionFormProps
  | SupplierContractConditionFormProps;

const targetOperatorConstraints: {
  [key: string]: BillingRuleConditionOperator[] | null;
} = {
  [BillingRuleConditionTarget.Shipper]: [
    BillingRuleConditionOperator.Equals,
    BillingRuleConditionOperator.NotEquals,
  ],
  [BillingRuleConditionTarget.Receiver]: [
    BillingRuleConditionOperator.Equals,
    BillingRuleConditionOperator.NotEquals,
  ],
  [BillingRuleConditionTarget.TrailerType]: [
    BillingRuleConditionOperator.Equals,
    BillingRuleConditionOperator.NotEquals,
  ],
  [BillingRuleConditionTarget.Trailer]: [
    BillingRuleConditionOperator.Equals,
    BillingRuleConditionOperator.NotEquals,
  ],
};

const ConditionForm = ({
  condition,
  customer,
  onChange: propsOnChange,
  entity,
}: ConditionFormProps) => {
  const ValueSelectorComponent =
    ConditionValueSelectorComponentByTarget[condition.target];
  const { t } = useTranslation("billingRules");
  const onChange: (
    condition: BillingRuleCondition | SupplierContractCondition
  ) => void = propsOnChange as (
    condition: BillingRuleCondition | SupplierContractCondition
  ) => void;
  return (
    <Grid container direction="row" alignItems="center" spacing={1} flex={1}>
      <Grid item xs={12} sm={9} lg={5}>
        {entity === "billingRule" ? (
          <Select
            label="Condition"
            size="small"
            value={condition.target}
            onChange={(event) => {
              onChange({
                ...condition,
                target: event.target.value as BillingRuleConditionTarget,
                value: null,
                operator: BillingRuleConditionOperator.Equals,
              });
            }}
            sx={{ display: "flex" }}
          >
            {Object.values(BillingRuleConditionTarget).map((target) => (
              <MenuItem key={target} value={target}>
                <ListItemText
                  primary={billingRuleConditionTargetLabel(target)}
                />
              </MenuItem>
            ))}
          </Select>
        ) : entity === "supplierContract" ? (
          <Select
            label="Condition"
            size="small"
            value={condition.target}
            onChange={(event) => {
              onChange({
                ...condition,
                target: event.target.value as SupplierContractConditionTarget,
                value: null,
                operator: BillingRuleConditionOperator.Equals,
              });
            }}
            sx={{ display: "flex" }}
          >
            {Object.values(SupplierContractConditionTarget).map((target) => (
              <MenuItem key={target} value={target}>
                <ListItemText primary={enumLabel(target)} />
              </MenuItem>
            ))}
          </Select>
        ) : null}
      </Grid>
      <Grid item xs={12} sm={3} lg={2}>
        {condition.target &&
        condition.target !== BillingRuleConditionTarget.Otherwise ? (
          <Select
            label="Operator"
            size="small"
            value={condition.operator}
            onChange={(event) => {
              const operator = event.target
                .value as BillingRuleConditionOperator;
              onChange({
                ...condition,
                operator,
                value:
                  operator === BillingRuleConditionOperator.IsBetween
                    ? [condition.value, null]
                    : condition.value,
              });
            }}
            sx={{ display: "flex" }}
          >
            {(
              targetOperatorConstraints[condition.target] ||
              Object.values(BillingRuleConditionOperator)
            ).map((operator) => (
              <MenuItem key={operator} value={operator}>
                <ListItemText
                  primary={billingRuleConditionOperatorLabel(operator)}
                />
              </MenuItem>
            ))}
          </Select>
        ) : null}
      </Grid>
      {condition.target &&
      condition.operator &&
      condition.operator !== BillingRuleConditionOperator.IsBetween &&
      ValueSelectorComponent ? (
        <Grid item xs={12} sm={9} lg={5}>
          <ValueSelectorComponent
            customer={customer}
            value={condition.value}
            onChange={(value) =>
              onChange({
                ...condition,
                value,
              })
            }
          />
        </Grid>
      ) : null}
      {condition.target &&
      condition.operator === BillingRuleConditionOperator.IsBetween &&
      ValueSelectorComponent ? (
        <Grid item xs={12} sm={9} lg={5}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <ValueSelectorComponent
              customer={customer}
              value={condition.value[0]}
              onChange={(value) =>
                onChange({
                  ...condition,
                  value: [value, condition.value[1]],
                })
              }
            />

            <Typography variant="body2">{t("form.and")}</Typography>
            <ValueSelectorComponent
              customer={customer}
              value={condition.value[1]}
              onChange={(value) =>
                onChange({
                  ...condition,
                  value: [condition.value[0], value],
                })
              }
            />
          </Stack>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default ConditionForm;
