import { Container, Typography } from "@mui/material";
import ExtensionsManager from "../../components/extensions/ExtensionsManager";
import { useTranslation } from "react-i18next";

function ViewExtensions() {
  const { t } = useTranslation("common");
  return (
    <Container>
      <Typography variant="h6" sx={{ mb: 2 }}>
        {t(
          "installExtensionsDescription",
          "Install extensions to augment your TMS capabilities or connect it with external services"
        )}
      </Typography>
      <ExtensionsManager />
    </Container>
  );
}

export default ViewExtensions;
