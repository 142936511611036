import { useGetAllAssetsLocationsQuery } from "../../../graphql/generated";
import { LiveTrackerTripData } from "../LiveTripsTracker/LiveTripsTracker";
import AssetTrackingMap, { AssetTrackingMapProps } from "./AssetTrackingMap";

type AssetTrackingMapContainerProps = {
  trips: LiveTrackerTripData[];
  selectedTrip?: AssetTrackingMapProps["selectedTrip"];
  selectedDriverId?: AssetTrackingMapProps["selectedDriverId"];
  selectedTrailerId?: AssetTrackingMapProps["selectedTrailerId"];
  selectedTractorId?: AssetTrackingMapProps["selectedTractorId"];
  onTripSelect?: (trip: LiveTrackerTripData) => void;
  onTripUnselect?: () => void;
};

const AssetTrackingMapContainer = ({
  trips,
  selectedTrip,
  selectedDriverId,
  selectedTrailerId,
  selectedTractorId,
  onTripSelect,
  onTripUnselect,
}: AssetTrackingMapContainerProps) => {
  const getAllAssetsLocationsQuery = useGetAllAssetsLocationsQuery(
    {},
    {
      refetchInterval: 5000,
    }
  );
  return (
    <AssetTrackingMap
      trailers={getAllAssetsLocationsQuery.data?.trailers.data || []}
      tractors={getAllAssetsLocationsQuery.data?.tractors.data || []}
      drivers={getAllAssetsLocationsQuery.data?.drivers.data || []}
      trips={trips}
      selectedTrip={selectedTrip}
      selectedDriverId={selectedDriverId}
      selectedTractorId={selectedTractorId}
      selectedTrailerId={selectedTrailerId}
      onTripSelect={onTripSelect}
      onTripUnselect={onTripUnselect}
    />
  );
};

export default AssetTrackingMapContainer;
