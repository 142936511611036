import { Alert } from "@mui/material";
import { omit } from "lodash";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  GetOrgUserDetailsQuery,
  useAddOrgUserMutation,
  useEditOrgUserMutation,
  useGetOrgUserDetailsQuery,
  useGetRolesQuery,
} from "../../../graphql/generated";
import { showDialog } from "../../../redux/slices/alert/Alert.slice";
import LoadingOverlay from "../../common/LoadingOverlay";
import UserForm from "./UserForm";
import { useTranslation } from "react-i18next";

type UserFormContainerProps = {
  userId?: string;
  onLoad?: (user: GetOrgUserDetailsQuery["orgUserById"]) => void;
};

function UserFormContainer({ userId, onLoad }: UserFormContainerProps) {
  const { t } = useTranslation(["users", "common"]);
  const addUserMutation = useAddOrgUserMutation();
  const editUserMutation = useEditOrgUserMutation();
  const initialBusinessEntityId = useRef(userId);

  // We call this hook conditionally because the component
  // should never rerender with a different value for
  // businessEntityId, but if it does we don't update
  // the ref anyway so this is safe
  const getUserQuery = initialBusinessEntityId.current
    ? // eslint-disable-next-line react-hooks/rules-of-hooks
      useGetOrgUserDetailsQuery(
        {
          id: initialBusinessEntityId.current,
        },
        {
          refetchOnWindowFocus: false,
          retry: false,
        }
      )
    : null;
  const getRolesQuery = useGetRolesQuery();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (getUserQuery?.data?.orgUserById) {
      onLoad?.(getUserQuery.data?.orgUserById);
    }
  }, [onLoad, getUserQuery?.data]);

  if (userId) {
    if (getUserQuery?.isLoading) {
      return <LoadingOverlay loading />;
    }
    if (getUserQuery?.error) {
      return (
        <Alert severity="error">
          {t("error.fetchError", "An error occurred while fetching the user")}.{" "}
          {(getUserQuery?.error as Error).message ||
            t("common:error.unknownError", "Unknown error")}
        </Alert>
      );
    }
  }

  return (
    <UserForm
      initialUser={getUserQuery?.data?.orgUserById}
      availableRoles={getRolesQuery.data?.roles || []}
      saving={addUserMutation.isLoading || editUserMutation.isLoading}
      onSave={async (user) => {
        try {
          if (userId) {
            const result = await editUserMutation.mutateAsync({
              id: userId,
              editOrgUserData: omit(user, "_id", "status"),
            });
            navigate(`/users/details/${result.editOrgUser._id}`);
          } else {
            const result = await addUserMutation.mutateAsync({
              newOrgUserData: user,
            });
            navigate(`/users/details/${result.addOrgUser._id}`);
            window.analytics?.track("User Created", {
              userId: result.addOrgUser._id,
            });
            window.analytics?.identify({
              userCreated: true,
              lastUserCreationDate: new Date(),
              lastUserCreationDateOnly: new Date().toISOString().split("T")[0],
              numberOfUsersCreated:
                (window.analytics?.user?.()?.traits?.()?.numberOfUsersCreated ||
                  0) + 1,
            });
            window.analytics?.group(window.analytics?.group?.()?.id?.(), {
              userCreated: true,
              lastUserCreationDate: new Date(),
              lastUserCreationDateOnly: new Date().toISOString().split("T")[0],
              numberOfUsersCreated:
                (window.analytics?.group?.()?.traits?.()
                  ?.numberOfUsersCreated || 0) + 1,
            });
          }
        } catch (error) {
          dispatch(
            showDialog({
              title: t("common:error.title", "Error"),
              description:
                t("error.createEditError", {
                  action: userId
                    ? t("common:editing", "editing")
                    : t("common:creating", "creating"),
                  defaultValue: `An error occurred while ${
                    userId ? "editing" : "creating"
                  } the user. `,
                }) + (error as Error).message,
              type: "error",
            })
          );
        }
      }}
    />
  );
}

export default UserFormContainer;
