import { TextField } from "@mui/material";
import { ConditionValueSelectorComponent } from "../ConditionForm";
import { TimePicker } from "@mui/x-date-pickers";
import { format, isValid, parse } from "date-fns";

const TimeInput: ConditionValueSelectorComponent = ({ value, onChange }) => {
  const parsedDate = parse(String(value), "HH:mm", new Date());
  return (
    <TimePicker
      value={value && isValid(parsedDate) ? parsedDate : null}
      ampm={false}
      onChange={(value) => {
        if (!value) {
          return;
        }
        if (!isValid(value)) {
          return;
        }
        onChange(format(value, "HH:mm"));
      }}
      renderInput={(params) => <TextField {...params} />}
    />
  );
};

export default TimeInput;
