import { toArray } from "lodash";
import { ProductFruits } from "react-product-fruits";
import { getOrganizationId, useAuth } from "../../../providers/AuthProvider";
import { useEffect } from "react";

type PFTour = {
  id: number;
  name: string;
  userState: "not_finished" | "skipped" | "finished";
  isRunning: boolean;
  rulesValid: () => boolean;
};

const ProductFruitsProvider = () => {
  const { userInfo, roles } = useAuth();
  function logTourCompletionAttributes() {
    console.debug("updating completing attributes");
    const tours = window.productFruits?.api.tours.getTours() as
      | PFTour[]
      | undefined;
    if (!tours) {
      console.debug("no tours found, skipping completion attribute update");
      return;
    }
    const completedTours = tours.filter(
      (tour) => tour.userState === "finished"
    );
    const skippedTours = tours.filter((tour) => tour.userState === "skipped");
    const notFinishedTours = tours.filter(
      (tour) => tour.userState === "not_finished"
    );
    window.analytics?.identify({
      numberOfToursCompleted: completedTours.length,
      numberOfToursSkipped: skippedTours.length,
      numberOfToursNotFinished: notFinishedTours.length,
    });
    console.debug("tour completion attributes updated");
  }
  useEffect(() => {
    window.addEventListener("productfruits_ready", function () {
      logTourCompletionAttributes();
      window.productFruits?.api.tours.listen(
        "tour-skipped",
        logTourCompletionAttributes
      );
      window.productFruits?.api.tours.listen(
        "tour-finished",
        logTourCompletionAttributes
      );
    });
  }, []);
  if (!userInfo) {
    return null;
  }

  return (
    <ProductFruits
      workspaceCode={
        window.location.host === "app.truetms.com"
          ? "LgAfW9SAGZYocLzf"
          : "weGNFVzztgS1QPHz"
      }
      language="en"
      user={{
        username: userInfo.email,
        firstname: userInfo.given_name,
        lastname: userInfo.family_name,
        email: userInfo.email,
        group: {
          groupId: getOrganizationId(userInfo),
          props: {
            name: toArray(userInfo.organization)[0]?.name || "N/A",
          },
        },
        role: roles.join(" / "),
        props: {
          userId: userInfo.sub,
        },
      }}
      lifeCycle="neverUnmount"
    />
  );
};

export default ProductFruitsProvider;
