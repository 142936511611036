import { Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import {
  CustomFieldContext,
  NewShipmentInput,
  ShipmentReferenceNumberType,
} from "../../../../graphql/generated";
import EnumSelect from "../../../common/EnumSelect";
import { Add, RemoveCircle } from "@mui/icons-material";
import {
  FieldErrors,
  NewShipmentInputData,
} from "../../../../redux/slices/Types";
import { fromPairs, without } from "lodash";
import { useTranslation } from "react-i18next";
import enumLabel from "../../../../utils/labels/enumLabel";
import ChipTagsInput from "../../../common/ChipTagsInput/ChipTagsInput";
import CustomFieldsFormContainer from "../../../extensions/CustomFieldsForm";
import GroupSelectContainer from "../../../asset-management/GroupSelect";

type ShipmentReferencesFormProps = {
  shipment: NewShipmentInput;
  errors?: FieldErrors;

  onUpdate: (updates: Partial<NewShipmentInputData>) => void;
};

const ShipmentReferencesForm = ({
  shipment,
  errors,
  onUpdate,
}: ShipmentReferencesFormProps) => {
  const { t } = useTranslation(["common"]);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <EnumSelect
          label={t("orders:orderReferences", "Order References")}
          fullWidth
          size="small"
          multiple
          enumObject={ShipmentReferenceNumberType}
          value={Object.values(ShipmentReferenceNumberType).filter(
            (type) =>
              shipment[referenceNumberTypeToShipmentField[type]] &&
              shipment[referenceNumberTypeToShipmentField[type]]?.length
          )}
          onChange={(_, referenceNumberTypes) => {
            const nonSelectedReferenceNumberFields = without(
              referenceNumberFields,
              ...referenceNumberTypes.map(
                (type) => referenceNumberTypeToShipmentField[type]
              )
            );
            const removedReferencesUpdate = fromPairs(
              nonSelectedReferenceNumberFields.map((field) => [field, []])
            );

            const referenceNumberUpdates = fromPairs(
              referenceNumberTypes.map((referenceNumberType) => {
                const referenceNumberField =
                  referenceNumberTypeToShipmentField[referenceNumberType];
                const existing = shipment[referenceNumberField];
                return [
                  referenceNumberField,
                  existing && existing.length ? existing : [""],
                ];
              })
            );
            onUpdate({
              ...referenceNumberUpdates,
              ...removedReferencesUpdate,
            });
          }}
          optionLabel={(type) =>
            // @ts-ignore
            t(`orders:referenceNumberType.${type}`, enumLabel(String(type)))
          }
        />
      </Grid>
      <Grid item id="reference-numbers-container" xs={12} md={12}>
        <Grid
          container
          direction={{
            sm: "row",
            xs: "column",
          }}
          spacing={3}
          justifyContent="stretch"
          sx={{
            width: "100%",
          }}
        >
          {referenceNumberFields
            .filter(
              (referenceNumberField) =>
                shipment[referenceNumberField] &&
                shipment[referenceNumberField]?.length
            )
            .map((referenceNumberField) => {
              const referenceNumberFieldLabel =
                referenceNumberFieldLabels[referenceNumberField];

              const existingReferenceNumbers =
                shipment[referenceNumberField] || [];
              return (
                <Grid
                  item
                  container
                  xs={6}
                  spacing={1}
                  sx={{
                    width: "100%",
                  }}
                  key={referenceNumberField}
                >
                  {shipment[referenceNumberField]?.map(
                    (referenceNumber, index) => {
                      const isLast =
                        index === existingReferenceNumbers.length - 1;
                      return (
                        <Grid
                          item
                          direction="row"
                          spacing={1}
                          sx={{
                            width: "100%",
                          }}
                          key={`${referenceNumberField}-${index}`}
                        >
                          <TextField
                            fullWidth
                            label={referenceNumberFieldLabel}
                            value={referenceNumber || ""}
                            error={
                              errors && errors[referenceNumberField]
                                ? true
                                : false
                            }
                            helperText={errors && errors[referenceNumberField]}
                            name={referenceNumberField}
                            size="small"
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              onUpdate({
                                [referenceNumberField]: [
                                  ...existingReferenceNumbers.slice(0, index),
                                  event.target.value,
                                  ...existingReferenceNumbers.slice(index + 1),
                                ],
                              })
                            }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() => {
                                      onUpdate({
                                        [referenceNumberField]: [
                                          ...existingReferenceNumbers.slice(
                                            0,
                                            index
                                          ),
                                          ...existingReferenceNumbers.slice(
                                            index + 1
                                          ),
                                        ],
                                      });
                                    }}
                                  >
                                    <RemoveCircle />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                          {isLast && (
                            <IconButton
                              onClick={() => {
                                onUpdate({
                                  [referenceNumberField]:
                                    existingReferenceNumbers.concat(""),
                                });
                              }}
                            >
                              <Add />
                            </IconButton>
                          )}
                        </Grid>
                      );
                    }
                  )}
                </Grid>
              );
            })}
        </Grid>
      </Grid>

      <Grid item xs={12} sm={6}>
        <ChipTagsInput
          value={shipment?.tags || []}
          onChange={(tags) => {
            onUpdate({ tags });
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <CustomFieldsFormContainer
          context={CustomFieldContext.Shipment}
          customFields={shipment?.customFields || []}
          onChange={(customFields) => {
            onUpdate({ customFields });
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <GroupSelectContainer
          value={shipment?.groupIds || []}
          onChange={(groupIds) => {
            onUpdate({ groupIds });
          }}
        />
      </Grid>
    </Grid>
  );
};

export default ShipmentReferencesForm;

export const referenceNumberTypeToShipmentField: Record<
  ShipmentReferenceNumberType,
  ShipmentReferenceNumberField
> = {
  BILL_OF_LADING_NUMBER: "billOfLadingNumbers",
  PURCHASE_ORDER_NUMBER: "purchaseOrderNumbers",
  REFERENCE_NUMBER: "referenceNumbers",
  TICKET_NUMBER: "ticketNumbers",
};

export type ShipmentReferenceNumberField =
  | "billOfLadingNumbers"
  | "purchaseOrderNumbers"
  | "referenceNumbers"
  | "ticketNumbers";

export const referenceNumberFields: ShipmentReferenceNumberField[] = [
  "billOfLadingNumbers",
  "purchaseOrderNumbers",
  "referenceNumbers",
  "ticketNumbers",
];

export const referenceNumberFieldLabels: Record<
  ShipmentReferenceNumberField,
  string
> = {
  billOfLadingNumbers: "BOL#",
  purchaseOrderNumbers: "PO#",
  referenceNumbers: "Reference #",
  ticketNumbers: "Ticket #",
};
