import {
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { PaymentPeriod, PaymentPeriodUnit } from "../../../graphql/generated";
import { useTranslation } from "react-i18next";

type DriverPaymentPeriodFormProps = {
  period: PaymentPeriod;
  onChange: (period: PaymentPeriod) => void;
};

const DriverPaymentPeriodForm = ({
  period,
  onChange,
}: DriverPaymentPeriodFormProps) => {
  const { t } = useTranslation("paymentRules");
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={2}
      id="driver-payment-period-form"
    >
      <TextField
        type="number"
        name="repeatEvery"
        value={period.repeatEvery || ""}
        onChange={(event) => {
          onChange({
            ...period,
            repeatEvery: parseFloat(event.target.value) || 1,
          });
        }}
        size="small"
        label={t("form.period")}
        inputProps={{
          type: "number",
          min: 1,
        }}
      />

      <Select
        size="small"
        name="unit"
        value={period.unit}
        onChange={(event) => {
          onChange({
            ...period,
            unit: event.target.value as PaymentPeriodUnit,
          });
        }}
        sx={{ maxWidth: 120, minWidth: 120 }}
      >
        {Object.values(PaymentPeriodUnit).map((periodUnit) => (
          <MenuItem key={periodUnit} value={periodUnit}>
            <ListItemText primary={t(`periodUnit.${periodUnit}`)} />
          </MenuItem>
        ))}
      </Select>

      <Typography variant="body2">{t("form.starting")}</Typography>
      <DatePicker
        label={t("form.starting")}
        value={period.start}
        onChange={(value) => {
          onChange({
            ...period,
            start: value,
          });
        }}
        renderInput={(params) => <TextField {...params} name="periodStart" />}
      />
      <Typography variant="body2">{t("form.until")}</Typography>
      <DatePicker
        label={t("form.ending")}
        value={period.end}
        onChange={(value) => {
          onChange({
            ...period,
            end: value,
          });
        }}
        renderInput={(params) => <TextField {...params} name="periodEnd" />}
      />
    </Stack>
  );
};

export default DriverPaymentPeriodForm;
