import LocaleProvider, { Locale } from "../../providers/LocaleProvider";

export function localTemperatureToCelsius(valueInLocalTemperature: number) {
  if (LocaleProvider.getLocale() === Locale.EN_US) {
    return ((valueInLocalTemperature - 32) * 5) / 9;
  }
  return valueInLocalTemperature;
}

export function celsiusToLocalTemperature(valueInCelsius: number) {
  if (LocaleProvider.getLocale() === Locale.EN_US) {
    return (valueInCelsius * 9) / 5 + 32;
  }
  return valueInCelsius;
}
