
// Function to check and append protocol if needed.
export default function formatUrl(
  url: string |null |undefined
) {
  // Check if the URL starts with "http://" or "https://"
  if (!url){
    return ""
  }
  if (!/^https?:\/\//i.test(url)) {
    return "https://" + url; // Default to "https://" if missing.
  }
  return url;
}