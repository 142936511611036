import { useEffect } from "react";
import {
  DeductionType,
  TransactionInput,
  useEditDriverSettlementMutation,
  useGetDriverSettlementDetailsQuery,
} from "../../../graphql/generated";
import useDialog from "../../../utils/hooks/useDialog";
import ErrorMessage from "../../common/ErrorMessage/ErrorMessage";
import LoadingOverlay from "../../common/LoadingOverlay";
import DriverSettlementDetails, {
  DriverSettlementDetailsProps,
} from "./DriverSettlementDetails";
import { useTranslation } from "react-i18next";

type DriverSettlementDetailsContainerProps = Omit<
  DriverSettlementDetailsProps,
  | "driverSettlement"
  | "onDeductionAdded"
  | "onDeductionChanged"
  | "onDeductionDeleted"
> & {
  driverSettlementId: string;
  onLoad?: (
    driverSettlement: DriverSettlementDetailsProps["driverSettlement"]
  ) => void;
};

const DriverSettlementDetailsContainer = ({
  driverSettlementId,
  onLoad,
  ...rest
}: DriverSettlementDetailsContainerProps) => {
  const { t } = useTranslation(["finance", "common"]);
  const getDriverSettlementDetailsQuery = useGetDriverSettlementDetailsQuery({
    id: driverSettlementId,
  });

  const editDriverSettlementMutation = useEditDriverSettlementMutation();
  const { showDialog } = useDialog();

  useEffect(() => {
    if (getDriverSettlementDetailsQuery.data?.driverSettlementById) {
      onLoad?.(getDriverSettlementDetailsQuery.data.driverSettlementById);
    }
  }, [getDriverSettlementDetailsQuery.data?.driverSettlementById, onLoad]);

  if (getDriverSettlementDetailsQuery.isLoading) {
    return <LoadingOverlay loading />;
  }
  if (getDriverSettlementDetailsQuery.error) {
    return <ErrorMessage error={getDriverSettlementDetailsQuery.error} />;
  }
  if (
    !getDriverSettlementDetailsQuery.data ||
    !getDriverSettlementDetailsQuery.data.driverSettlementById
  ) {
    return <ErrorMessage error={{ message: "Not found" }} />;
  }
  const driverSettlement =
    getDriverSettlementDetailsQuery.data.driverSettlementById;
  return (
    <DriverSettlementDetails
      driverSettlement={driverSettlement}
      onDeductionAdded={async (
        deductionType: DeductionType,
        transaction: TransactionInput
      ) => {
        try {
          await editDriverSettlementMutation.mutateAsync({
            id: driverSettlementId,
            editDriverSettlementData: {
              deductions: [
                ...driverSettlement.deductions,
                {
                  label: transaction.label,
                  amount: transaction.rate,
                  type: deductionType,
                  date: new Date(),
                },
              ],
            },
          });
          await getDriverSettlementDetailsQuery.refetch();
        } catch (error) {
          showDialog({
            type: "error",
            title: t("error.addDeduction", "Error adding deduction"),
            description:
              (error as Error).message ||
              t("common:error.unknownError", "Unknown error"),
          });
        }
      }}
      onDeductionChanged={async (id, transaction) => {
        try {
          await editDriverSettlementMutation.mutateAsync({
            id: driverSettlementId,
            editDriverSettlementData: {
              deductions: driverSettlement.deductions.map((deduction) =>
                deduction._id === id
                  ? {
                      _id: deduction._id,
                      label: transaction.label,
                      amount: transaction.rate,
                      type: deduction.type,
                      date: deduction.date,
                    }
                  : deduction
              ),
            },
          });
          await getDriverSettlementDetailsQuery.refetch();
        } catch (error) {
          showDialog({
            type: "error",
            title: t("error.updateDeduction", "Error updating deduction"),
            description:
              (error as Error).message ||
              t("common:error.unknownError", "Unknown error"),
          });
        }
      }}
      onDeductionDeleted={async (id) => {
        try {
          await editDriverSettlementMutation.mutateAsync({
            id: driverSettlementId,
            editDriverSettlementData: {
              deductions: driverSettlement.deductions.filter(
                (deduction) => deduction._id !== id
              ),
            },
          });
          await getDriverSettlementDetailsQuery.refetch();
        } catch (error) {
          showDialog({
            type: "error",
            title: t("error.deleteDeduction", "Error deleting deduction"),
            description:
              (error as Error).message ||
              t("common:error.unknownError", "Unknown error"),
          });
        }
      }}
      {...rest}
    />
  );
};

export default DriverSettlementDetailsContainer;
