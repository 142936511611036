import { ValidationResult } from "joi";

const getFieldError = (
  validationResult: ValidationResult | undefined | null,
  field: string,
  partialPathMatch = false
) =>
  validationResult?.error?.details.find((error) =>
    partialPathMatch
      ? error.path.join(".").startsWith(field)
      : error.path.join(".") === field
  )?.message;

export default getFieldError;
