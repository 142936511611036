import Joi from "joi";
import {
  KeycloakUserRole,
  NewOrgUserInput,
  UserAttributes,
} from "../../../graphql/generated";

const userSchema = Joi.object<NewOrgUserInput>({
  firstName: Joi.string().label("First name").required(),
  lastName: Joi.string().label("Last name").required(),
  username: Joi.string().min(3).max(50).label("Email").required(),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .label("Email")
    .allow(null, ""),
  password: Joi.string().label("Password"),
  roles: Joi.array()
    .items(
      Joi.object<KeycloakUserRole>({
        id: Joi.string().required(),
        name: Joi.string().required(),
      })
    )
    .min(1)
    .label("Roles"),
  attributes: Joi.object<UserAttributes>({
    driverId: Joi.alternatives()
      .conditional(Joi.ref("...roles"), {
        is: Joi.array().items(
          Joi.object<KeycloakUserRole>({
            id: Joi.string().required(),
            name: Joi.string().required().valid("Carrier Driver"),
          }).required(),
          Joi.any()
        ),
        then: Joi.string().required().messages({
          "any.required": '"Driver profile" is required',
          "string.base": '"Driver profile" is required',
        }),
        otherwise: Joi.string().allow(null),
      })
      .label("Driver Profile"),
    tags: Joi.string().label("Tags").allow(null, ""),
    customFields: Joi.string().label("Custom Fields").allow(null),
  }),
});

export default userSchema;
