import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import { differenceInCalendarDays } from "date-fns";
import { useEffect, useState, useRef } from "react";
import {
  DateTimeWindow,
  GetAssignmentAssetsQuery,
  GetTripQuery,
  Status,
  TrailerCompartmentAssignment,
  TripAssetTypes,
} from "../../../graphql/generated";
import driverLabel from "../../../utils/labels/driverLabel";
import tractorLabel from "../../../utils/labels/tractorLabel";
import trailerLabel from "../../../utils/labels/trailerLabel";
import LoadingOverlay from "../../common/LoadingOverlay";
import AssetTrackingMap from "../../tracking/AssetTrackingMap";
import carrierLabel from "../../../utils/labels/carrierLabel";
import { isBefore } from "date-fns";
import { capitalize, flatten } from "lodash";
import { ShipmentInfo } from "../../shipment/ShipmentInfos";
import TrailerCompartmentAssignmentFormContainer from "./TrailerCompartmentAssignmentForm";
import { useTranslation } from "react-i18next";
import MaintenanceWarningsContainer from "../../maintenance/MaintenanceWarnings";
import QualificationWarningsContainer from "../../qualifications/QualificationWarnings";

import Alert2 from "./Alert"

type AssetLinkingListData = GetAssignmentAssetsQuery["assetLinkings"]["data"];

export type TrailerAssignmentData =
  GetAssignmentAssetsQuery["trailers"]["data"][0];
export type TractorAssignmentData =
  GetAssignmentAssetsQuery["tractors"]["data"][0];
export type DriverAssignmentData =
  GetAssignmentAssetsQuery["drivers"]["data"][0];
export type CarrierAssignmentData =
  GetAssignmentAssetsQuery["carriers"]["data"][0];

export type AssignmentData = {
  trailer: TrailerAssignmentData | null;
  tractor: TractorAssignmentData | null;
  driver: DriverAssignmentData | null;
  carrier: CarrierAssignmentData | null;
  additionalTrailers: TrailerAssignmentData[] | null;
  compartmentAssignments?: TrailerCompartmentAssignment[];
};
export type AssignmentModalProps = {
  trip?: GetTripQuery["tripById"] | null;
  shipments?: (Pick<
    ShipmentInfo,
    "_id" | "trailerType" | "additionalTrailerTypes" | "isFromSplit"
  > & {
    shipmentLocations: {
      _id: ShipmentInfo["shipmentLocations"][0]["_id"];
      receiver?: {
        _id: NonNullable<
          ShipmentInfo["shipmentLocations"][0]["receiver"]
        >["_id"];
      } | null;
      timeWindows: DateTimeWindow[];
      shippedGoods?: ShipmentInfo["shipmentLocations"][0]["shippedGoods"];
      receivedGoods?: ShipmentInfo["shipmentLocations"][0]["receivedGoods"];
    }[];
    route?: {
      firstPickupTime: any;
    } | null;
  })[];
  open: boolean;
  loading?: boolean;
  assigning: boolean;
  error?: Error | null;
  warning?: string;
  trailers: TrailerAssignmentData[];
  allTrailers: TrailerAssignmentData[];
  tractors: TractorAssignmentData[];
  drivers: DriverAssignmentData[];
  carriers: CarrierAssignmentData[];
  assetLinkings?: AssetLinkingListData;
  isPowerOnly?: boolean;
  onClose: () => void;
  onSubmit: (assignments: AssignmentData) => void;
  onChange: (assignments: AssignmentData) => void;
};

function AssignmentModal({
  shipments,
  trip,
  open,
  loading,
  assigning,
  error,
  warning,
  trailers,
  allTrailers,
  tractors,
  drivers,
  carriers,
  isPowerOnly,
  assetLinkings,
  onClose,
  onSubmit,
  onChange,
}: AssignmentModalProps) {
  const { t } = useTranslation(["common", "assets", "business", "users"]);
  const [assignment, setAssignment] = useState<AssignmentData>({
    trailer: null,
    tractor: null,
    driver: null,
    carrier: null,
    additionalTrailers: null,
  });

  const [changeDriver, setChangeDriver] = useState(false);

  const isInitialized = useRef(false);
  const hasSplitShipment = shipments?.some((s) => s.isFromSplit) || false;

  useEffect(() => {
    if (loading) {
      return;
    }
    if (isInitialized.current) {
      return;
    }
    setAssignment({
      trailer: trailers.find((t) => t._id === trip?.trailer?._id) || null,
      tractor: tractors.find((t) => t._id === trip?.tractor?._id) || null,
      driver: drivers.find((d) => d._id === trip?.driver?._id) || null,
      carrier: carriers.find((c) => c._id === trip?.carrier?._id) || null,
      compartmentAssignments: trip?.trailerCompartmentAssignments || [],
      additionalTrailers: trailers.filter((trailer) =>
        trip?.additionalTrailers?.map((t) => t._id).includes(trailer._id)
      ),
    });
    isInitialized.current = true;
  }, [drivers, tractors, trailers, carriers, trip, loading]);

  const onAssignmentChange = (change: Partial<AssignmentData>) => {
    const updatedAssignment: AssignmentData = {
      ...assignment,
      ...change,
    };

    if (updatedAssignment.driver !== assignment.driver && trip?.status === Status.InProgress) {
            setChangeDriver(true)
        }

    if (!updatedAssignment.trailer) {
      updatedAssignment.additionalTrailers = [];
    }
    const linkedAssetAssignment = selectLinkedAssets(change);
    const updatedAssignmentWithLinkedAssets = {
      ...updatedAssignment,
      ...linkedAssetAssignment,
    };
    setAssignment(updatedAssignmentWithLinkedAssets);
    onChange(updatedAssignmentWithLinkedAssets);
  };

  const selectLinkedAssets = (
    newAssignment: Partial<AssignmentData>
  ): AssignmentData | null => {
    const assetLinking = assetLinkings?.find(
      (al) =>
        (al.trailer?._id && al.trailer?._id === newAssignment?.trailer?._id) ||
        (al.tractor?._id && al.tractor?._id === newAssignment?.tractor?._id) ||
        (al.driver?._id && al.driver?._id === newAssignment?.driver?._id)
    );
    if (!assetLinking) {
      return null;
    }
    return {
      trailer: assetLinking.isHardLinked
        ? trailers.find((t) => t._id === assetLinking.trailer?._id) || null
        : assignment.trailer ||
          trailers.find((t) => t._id === assetLinking.trailer?._id) ||
          null,
      tractor: assetLinking.isHardLinked
        ? tractors.find((t) => t._id === assetLinking.tractor?._id) || null
        : assignment.tractor ||
          tractors.find((t) => t._id === assetLinking.tractor?._id) ||
          null,
      driver: assignment.carrier
        ? null
        : assignment.driver ||
          drivers.find((d) => d._id === assetLinking.driver?._id) ||
          null,
      carrier: assignment.carrier || null,
      additionalTrailers: assignment.additionalTrailers?.length
        ? assignment.additionalTrailers
        : allTrailers.filter((trailer) =>
            assetLinking.additionalTrailers
              ?.map((t) => t._id)
              .includes(trailer._id)
          ) || [],
      ...newAssignment,
    };
  };

  const willDriverLicenseExpire = (driver: DriverAssignmentData) => {
    const visitDates = trip
      ? flatten(
          trip?.shipmentLocations.map((location) => {
            return location.arrivalTime;
          })
        )
      : flatten(
          shipments?.map((shipment) => {
            return shipment.route?.firstPickupTime;
          })
        );
    return !!visitDates?.find(
      (visitDate) =>
        driver.license?.expiryDate &&
        isBefore(new Date(driver.license?.expiryDate), new Date(visitDate))
    );
  };

  return (
    <>
    <Dialog fullWidth maxWidth="md" open={open} onClose={() => onClose()}>
      <LoadingOverlay loading={loading || assigning} />
      <DialogTitle>Assignments</DialogTitle>
      <DialogContent dividers>
        {error ? (
          <Alert severity="error">
            {error.message.split("\n").map((message) => (
              <span>
                {message}
                <br />
              </span>
            ))}
          </Alert>
        ) : null}
        {warning ? <Alert severity="warning">{warning}</Alert> : null}
        {isPowerOnly ? (
          <Alert severity="info">This is a power only load</Alert>
        ) : null}
        {hasSplitShipment ? (
          <Alert severity="info">
            Assigning a trailer to a split shipment assigns the same trailers to
            all shipments in the split.
          </Alert>
        ) : null}
        <Stack direction="row" spacing={3}>
          <Stack
            direction="column"
            justifyContent="space-between"
            sx={{
              pt: 2,
              flex: 1,
            }}
            spacing={1}
            id="assignment-modal-form"
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <Autocomplete
                fullWidth
                options={[...drivers, ...carriers]}
                getOptionLabel={(driverOrCarrier) => {
                  if ("firstname" in driverOrCarrier) {
                    const driverExpiryRemainingDays = driverOrCarrier.license
                      ?.expiryDate
                      ? differenceInCalendarDays(
                          new Date(driverOrCarrier.license.expiryDate),
                          new Date()
                        )
                      : Infinity;
                    return `${driverLabel(driverOrCarrier)} ${
                      driverExpiryRemainingDays <= 0
                        ? "- (License Expired)"
                        : willDriverLicenseExpire(driverOrCarrier)
                        ? "- (License will expire before the latest shipment location date)"
                        : driverExpiryRemainingDays < 7
                        ? `- (License expires in ${driverExpiryRemainingDays} day${
                            driverExpiryRemainingDays > 1 ? "s" : ""
                          })`
                        : ""
                    }`.trim();
                  } else {
                    const carrier = driverOrCarrier as CarrierAssignmentData;
                    return carrierLabel(carrier);
                  }
                }}
                getOptionDisabled={(driver) => {
                  if ("firstname" in driver) {
                    const driverExpiryRemainingDays = driver.license?.expiryDate
                      ? differenceInCalendarDays(
                          new Date(driver.license.expiryDate),
                          new Date()
                        )
                      : Infinity;
                    return (
                      willDriverLicenseExpire(driver) ||
                      driverExpiryRemainingDays <= 0
                    );
                  }
                  return false;
                }}
                onChange={(e, driverOrCarrier) => {
                  if (!driverOrCarrier) {
                    onAssignmentChange({ driver: null, carrier: null });
                    return;
                  }
                  if ("firstname" in driverOrCarrier) {
                    const driver = driverOrCarrier as DriverAssignmentData;
                    onAssignmentChange({ driver, carrier: null });
                  } else {
                    const carrier = driverOrCarrier as CarrierAssignmentData;
                    onAssignmentChange({ driver: null, carrier });
                  }
                }}
                value={assignment.driver || assignment.carrier || null}
                size="small"
                renderInput={(inputProps) => (
                  <TextField
                    {...inputProps}
                    label={`${capitalize(
                      t("users:driver.one", "Driver")
                    )} / ${capitalize(t("business:carrier.one", "Carrier"))}`}
                    name="driver"
                  />
                )}
                groupBy={
                  carriers.length
                    ? (driverOrCarrier) =>
                        "firstname" in driverOrCarrier ? "Drivers" : "Carriers"
                    : undefined
                }
              />
              {assignment.driver?._id ? (
                <Stack direction="row" spacing={1}>
                  <MaintenanceWarningsContainer
                    assetType={TripAssetTypes.Driver}
                    assetId={assignment.driver._id}
                  />
                  <QualificationWarningsContainer
                    assetType={TripAssetTypes.Driver}
                    assetId={assignment.driver._id}
                  />
                </Stack>
              ) : null}
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <Autocomplete
                fullWidth
                options={tractors}
                getOptionLabel={(tractor) => tractorLabel(tractor)}
                onChange={(e, tractor) => {
                  onAssignmentChange({ tractor });
                }}
                value={assignment.tractor || null}
                size="small"
                renderInput={(inputProps) => (
                  <TextField
                    {...inputProps}
                    label={capitalize(t("assets:tractor.one", "Tractor"))}
                    name="tractor"
                  />
                )}
              />

              {assignment.tractor?._id ? (
                <MaintenanceWarningsContainer
                  assetType={TripAssetTypes.Tractor}
                  assetId={assignment.tractor._id}
                />
              ) : null}
            </Stack>
            {isPowerOnly ? null : (
              <Stack direction="row" spacing={1} alignItems="center">
                <Autocomplete
                  fullWidth
                  options={trailers}
                  getOptionLabel={(trailer) => trailerLabel(trailer)}
                  onChange={(e, trailer) => {
                    onAssignmentChange({ trailer });
                  }}
                  value={assignment.trailer || null}
                  size="small"
                  renderInput={(inputProps) => (
                    <TextField
                      {...inputProps}
                      label={capitalize(t("assets:trailer.one", "Tractor"))}
                      name="trailer"
                    />
                  )}
                />
                {assignment.trailer?._id ? (
                  <MaintenanceWarningsContainer
                    assetType={TripAssetTypes.Trailer}
                    assetId={assignment.trailer._id}
                  />
                ) : null}
              </Stack>
            )}
            {isPowerOnly || !assignment.trailer ? null : (
              <Stack direction="row" spacing={1} alignItems="center">
                <Autocomplete
                  fullWidth
                  options={allTrailers}
                  getOptionLabel={(trailer) => trailerLabel(trailer)}
                  getOptionDisabled={(trailer) =>
                    assignment.trailer?._id === trailer._id
                  }
                  onChange={(e, trailers) => {
                    onAssignmentChange({ additionalTrailers: trailers });
                  }}
                  multiple={true}
                  value={assignment.additionalTrailers || []}
                  size="small"
                  renderInput={(inputProps) => (
                    <TextField
                      {...inputProps}
                      label={capitalize(t("assets:pupTrailers.many"))}
                      name="additionalTrailers"
                    />
                  )}
                />
                {assignment.additionalTrailers?.map((trailer) => (
                  <MaintenanceWarningsContainer
                    assetType={TripAssetTypes.Trailer}
                    assetId={trailer._id}
                  />
                ))}
              </Stack>
            )}
            <Box sx={{ height: 400 }}>
              <AssetTrackingMap
                trips={[]}
                selectedDriverId={assignment.driver?._id}
                selectedTractorId={assignment.tractor?._id}
                selectedTrailerId={assignment.trailer?._id}
              />
            </Box>
          </Stack>
          {assignment.trailer && assignment.trailer.compartments?.length ? (
            <Stack
              sx={{
                flex: 1,
                pt: 2,
              }}
            >
              <TrailerCompartmentAssignmentFormContainer
                trailer={assignment.trailer}
                additionalTrailers={assignment.additionalTrailers || []}
                compartmentAssignments={assignment.compartmentAssignments || []}
                onCompartmentAssignmentsChange={(compartmentAssignments) => {
                  console.log("compartmentAssignments", compartmentAssignments);
                  onAssignmentChange({ compartmentAssignments });
                }}
                shipments={shipments}
                trip={trip}
              />
            </Stack>
          ) : null}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          color="inherit"
          variant="contained"
          onClick={() => onClose()}
        >
          {t("common:cancel", "Cancel")}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            onSubmit({
              trailer: assignment.trailer || null,
              tractor: assignment.tractor || null,
              driver: assignment.driver || null,
              carrier: assignment.carrier || null,
              additionalTrailers: assignment.additionalTrailers || [],
              compartmentAssignments: assignment.compartmentAssignments || [],
            });
          }}
          id="vaidate-assignment-button"
        >
          {t("common:validate", "Validate")}
        </Button>
      </DialogActions>
    </Dialog>
    {changeDriver && <Alert2 changeDriver={changeDriver} onClose={onClose}  />}
    </>
  );
}

export default AssignmentModal;
