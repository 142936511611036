import { useGetOrgUsersListQuery } from "../../../graphql/generated";
import { UserRole } from "../UserList/UserList";
import UserSelect, { UserSelectProps } from "./UserSelect";

type UserSelectContainerProps = Omit<UserSelectProps, "users">;

const UserSelectContainer = (props: UserSelectContainerProps) => {
  const usersQuery = useGetOrgUsersListQuery({
    roles: [
      UserRole.MANAGER,
      UserRole.DISPATCHER,
      UserRole.CLERICAL,
      UserRole.ACCOUNTING,
    ],
  });
  const users = usersQuery.data?.orgUsers.data || [];
  return <UserSelect {...props} users={users} />;
};

export default UserSelectContainer;
