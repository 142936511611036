import Joi from "joi";
import { DriverShift, Time } from "../../../graphql/generated";

const timeSchema = Joi.object<Time>({
  hour: Joi.number().min(0).max(23).label("Hour").required(),
  minute: Joi.number().min(0).max(59).label("Minute").required(),
});
const driverShiftSchema = Joi.object<DriverShift>({
  days: Joi.array().items(Joi.number()).label("Days").required(),
  startTime: timeSchema.label("Start Time").required(),
  endTime: timeSchema.label("End Time").required(),
  driverIds: Joi.array().items(Joi.string()).label("Drivers").allow(null),
  domicileId: Joi.string().label("Domicile").allow(null),
});

export default driverShiftSchema;
