import {
  SubscriptionEntitlementAvailability,
  useGetSubscriptionInfoQuery,
} from "../../../graphql/generated";

export enum Feature {
  CommodityManagement = "commodity-management",
  DriverShifts = "driver-shifts",
  CustomerPortal = "customer-portal",
  Relationships = "relationships",
}

export type FeatureGuardProps = {
  feature?: Feature;
  features?: (Feature | null)[] | null;
  children: React.ReactNode;
};

const FeatureGuard = (props: FeatureGuardProps) => {
  const features = props.features || (props.feature ? [props.feature] : null);
  const subscriptionInfoQuery = useGetSubscriptionInfoQuery();

  const subscriptionInfo = subscriptionInfoQuery.data?.subscriptionInfo;

  if (
    !features ||
    (subscriptionInfo?.entitlements || [])
      .filter(
        (entitlement) =>
          entitlement.value === SubscriptionEntitlementAvailability.Available
      )
      .some((entitlement) =>
        (features || []).includes(entitlement.featureId as Feature)
      )
  ) {
    return <>{props.children}</>;
  }

  return null;
};

export const useFeature = (feature: Feature) => {
  const subscriptionInfoQuery = useGetSubscriptionInfoQuery();
  const subscriptionInfo = subscriptionInfoQuery.data?.subscriptionInfo;

  return {
    isAvailable: (subscriptionInfo?.entitlements || [])
      .filter(
        (entitlement) =>
          entitlement.value === SubscriptionEntitlementAvailability.Available
      )
      .some((entitlement) => entitlement.featureId === feature),
  };
};

export default FeatureGuard;
