import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Step,
  StepButton,
  StepLabel,
  Stepper,
} from "@mui/material";
import {
  BusinessEntityType,
  ForecastingModel,
  ForecastingModelSlotType,
  NewReceiverForecastInput,
  StorageFacility,
  useGetBusinessStorageFacilitiesQuery,
} from "../../../graphql/generated";
import { useEffect, useState } from "react";
import BusinessEntitySelectContainer from "../../shipment/ShipmentForm/BusinessEntitySelect";
import NumberTextField from "../../common/NumberTextField";
import LabeledAddButton from "../../common/LabeledAddButton";
import ObjectID from "bson-objectid";
import { WeeklyForecastModelSlotForm } from "./WeeklyForecastModelSlotForm";
import { LoadingButton } from "@mui/lab";
import TrailerProfileForm from "./TrailerProfileForm";
import ReadingsFileConfigurationForm from "./ReadingsFileConfigurationForm";
import { Delete } from "@mui/icons-material";
import DailyForecastModelForm from "./DailyForecastModelForm";
import HourlyForecastModelForm from "./HourlyForecastModelForm";
import EnumSelect from "../../common/EnumSelect";
import { ValidationResult } from "joi";
import receiverForecastSchema from "./receiverForecastFormSchema";
import ErrorMessage from "../../common/ErrorMessage/ErrorMessage";
import { omit } from "lodash";
import MonthlyForecastModelForm from "./MonthlyForecastModelForm";

export type ReceiverForecastFormProps = {
  initialReceiverForecast?: NewReceiverForecastInput;
  onSave: (forecast: NewReceiverForecastInput) => void;
  saving: boolean;
};

enum FormSteps {
  ForecastingModels = 0,
  TrailerProfile = 1,
  ReadingsFileConfiguration = 2,
}

const stepLabels: Record<FormSteps, string> = {
  [FormSteps.ForecastingModels]: "Sales Models",
  [FormSteps.TrailerProfile]: "Trailer Profile",
  [FormSteps.ReadingsFileConfiguration]: "Readings File Configuration",
};

const ReceiverForecastForm = (props: ReceiverForecastFormProps) => {
  const [localForecast, setLocalForecast] = useState<
    Partial<NewReceiverForecastInput>
  >(
    props.initialReceiverForecast || {
      trailerProfile: {
        compartments: [],
      },
    }
  );

  const updateLocalForecast = (
    newForecast: Partial<NewReceiverForecastInput>
  ) => {
    setLocalForecast((prev) => ({
      ...prev,
      ...newForecast,
    }));
  };

  const storageFacilitiesQuery = useGetBusinessStorageFacilitiesQuery(
    {
      id: localForecast.receiverId || "",
    },
    {
      enabled: !!localForecast.receiverId,
    }
  );

  const storageFacilities =
    storageFacilitiesQuery.data?.businessEntityById.storageFacilities || [];

  const [activeStep, setActiveStep] = useState<FormSteps>(
    FormSteps.ForecastingModels
  );

  const [validationResult, setValidationResult] =
    useState<ValidationResult<NewReceiverForecastInput> | null>(null);

  const validate = () => {
    const schema = receiverForecastSchema;
    const validationResult = schema.validate(
      omit(localForecast, "_id", "receiver"),
      {
        abortEarly: false,
      }
    );
    setValidationResult(validationResult);
    return validationResult;
  };

  const getFieldError = (
    field: string,
    partialPathMatch = false,
    result = validationResult
  ) =>
    result?.error?.details.find((error) =>
      partialPathMatch
        ? error.path.join(".").startsWith(field)
        : error.path.join(".") === field
    )?.message;

  useEffect(() => {
    if (validationResult) {
      validate();
    }
    // We don't want to run everytime validationResult changes
    // otherwise we ill have an infinite update loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localForecast]);

  const currentErrorMessage = [
    [getFieldError("models", true), getFieldError("receiverId")],
    getFieldError("trailerProfile", true),
    getFieldError("readingsFileConfiguration", true),
  ]
    .filter((error, stepIndex) => stepIndex <= activeStep)
    .flat()
    .filter(Boolean)
    .join(", ");

  console.log([
    [getFieldError("models", true), getFieldError("receiverId")],
    getFieldError("trailerProfile", true),
    getFieldError("readingsFileConfiguration", true),
  ]);

  return (
    <Stack spacing={2}>
      <Stepper
        activeStep={activeStep}
        sx={{
          mb: 2,
        }}
      >
        {Object.values(FormSteps)
          .filter((step) => typeof step === "string")
          .map((step) => (
            <Step key={step}>
              <StepButton
                onClick={() => {
                  if (typeof step === "number") {
                    setActiveStep(step);
                  } else {
                    setActiveStep(FormSteps[step as keyof typeof FormSteps]);
                  }
                }}
              >
                <StepLabel>
                  {stepLabels[FormSteps[step as keyof typeof FormSteps]]}
                </StepLabel>
              </StepButton>
            </Step>
          ))}
      </Stepper>

      {activeStep === FormSteps.ForecastingModels && (
        <>
          <BusinessEntitySelectContainer
            businessEntityType={BusinessEntityType.Receiver}
            value={localForecast.receiverId}
            onChange={(receiverId) =>
              updateLocalForecast({ receiverId: receiverId || undefined })
            }
          />

          <Stack spacing={2}>
            {localForecast.models?.map((model) => (
              <Box
                sx={{
                  position: "relative",
                }}
                key={model._id}
              >
                <ForecastingModelForm
                  storageFacilities={storageFacilities}
                  model={model}
                  onChange={(newModel) => {
                    updateLocalForecast({
                      models: localForecast.models?.map((m) =>
                        m._id === model._id ? newModel : m
                      ),
                    });
                  }}
                />
                <IconButton
                  sx={{
                    position: "absolute",
                    top: -20,
                    right: -20,
                  }}
                  onClick={() => {
                    updateLocalForecast({
                      models: localForecast.models?.filter(
                        (m) => m._id !== model._id
                      ),
                    });
                  }}
                >
                  <Delete />
                </IconButton>
              </Box>
            ))}
          </Stack>

          <LabeledAddButton
            label="Add Tank"
            onClick={() => {
              updateLocalForecast({
                models: [
                  ...(localForecast.models || []),
                  {
                    _id: new ObjectID().toHexString(),
                    storageFacilityId: "",
                  },
                ],
              });
            }}
          />
        </>
      )}

      {activeStep === FormSteps.TrailerProfile && (
        <Card>
          <CardHeader title="Trailer Profile" />
          <CardContent>
            {localForecast.trailerProfile ? (
              <TrailerProfileForm
                trailerProfile={localForecast.trailerProfile}
                onChange={(trailerProfile) => {
                  updateLocalForecast({ trailerProfile });
                }}
              />
            ) : null}
          </CardContent>
        </Card>
      )}

      {activeStep === FormSteps.ReadingsFileConfiguration && (
        <Card>
          <CardHeader title="Readings File Configuration" />
          <CardContent>
            <ReadingsFileConfigurationForm
              readingsFileConfiguration={
                localForecast.readingsFileConfiguration || {
                  tankIdColumn: "",
                  dateColumn: "",
                  dateFormat: "",
                  timeColumn: "",
                  timeFormat: "",
                  levelColumn: "",
                  storeColumn: "",
                }
              }
              onChange={(readingsFileConfiguration) => {
                updateLocalForecast({ readingsFileConfiguration });
              }}
            />
          </CardContent>
        </Card>
      )}

      <Box sx={{ mr: 1 }}>
        <ErrorMessage message={currentErrorMessage || null} />
      </Box>
      <Stack direction="row" justifyContent="flex-end" spacing={2}>
        <Button
          disabled={activeStep === FormSteps.ForecastingModels}
          onClick={() => {
            setActiveStep(activeStep - 1);
          }}
        >
          Previous
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          onClick={() => {
            if (activeStep !== FormSteps.ReadingsFileConfiguration) {
              const result = validate();
              if (
                activeStep === FormSteps.ForecastingModels &&
                getFieldError("models", true, result)
              ) {
                return;
              }

              if (
                activeStep === FormSteps.TrailerProfile &&
                getFieldError("trailerProfile", true, result)
              ) {
                return;
              }

              setActiveStep(activeStep + 1);
              return;
            }
            console.log(validate());
            if (validate().error) {
              return;
            }
            props.onSave(localForecast as NewReceiverForecastInput);
          }}
          loading={props.saving}
          disabled={!!currentErrorMessage}
        >
          {activeStep === FormSteps.ReadingsFileConfiguration ? "Save" : "Next"}
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

type ForecastingModelFormProps = {
  model: ForecastingModel;
  storageFacilities: StorageFacility[];
  onChange: (model: ForecastingModel) => void;
};

const ForecastingModelForm = (props: ForecastingModelFormProps) => {
  return (
    <Card>
      <CardContent>
        <Stack spacing={2}>
          <Stack direction="row" spacing={2}>
            <Select
              value={props.model.storageFacilityId}
              onChange={(event) => {
                props.onChange({
                  ...props.model,
                  storageFacilityId: event.target.value,
                });
              }}
              fullWidth
            >
              {props.storageFacilities.map((storageFacility) => (
                <MenuItem
                  key={storageFacility.identifier}
                  value={storageFacility.identifier}
                >
                  {storageFacility.identifier} -{" "}
                  {storageFacility.commodity?.label}
                </MenuItem>
              ))}
            </Select>
            <NumberTextField
              label="Threshold"
              value={props.model.threshold}
              onChange={(event) => {
                props.onChange({
                  ...props.model,
                  threshold: Number(event.target.value),
                });
              }}
              fullWidth
            />
            <NumberTextField
              label="Target"
              value={props.model.target}
              onChange={(event) => {
                props.onChange({
                  ...props.model,
                  target: Number(event.target.value),
                });
              }}
              fullWidth
            />
          </Stack>

          <Stack spacing={1}>
            <EnumSelect
              enumObject={ForecastingModelSlotType}
              label="Periodicity"
              value={props.model.slots?.[0]?.type || ""}
              onChange={(event, value) => {
                if (!value) {
                  return;
                }
                const type = value;
                props.onChange({
                  ...props.model,
                  slots: [defaultSlotForType(type)],
                });
              }}
            />

            {props.model.slots?.[0]?.type ===
              ForecastingModelSlotType.Daily && (
              <DailyForecastModelForm
                model={props.model}
                onChange={props.onChange}
              />
            )}

            {props.model.slots?.[0]?.type ===
              ForecastingModelSlotType.Hourly && (
              <HourlyForecastModelForm
                model={props.model}
                onChange={props.onChange}
              />
            )}

            {props.model.slots?.[0]?.type ===
              ForecastingModelSlotType.Monthly && (
              <MonthlyForecastModelForm
                model={props.model}
                onChange={props.onChange}
              />
            )}

            {props.model.slots?.[0]?.type ===
              ForecastingModelSlotType.Weekly && (
              <WeeklyForecastModelSlotForm
                model={props.model}
                onChange={props.onChange}
              />
            )}
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default ReceiverForecastForm;

const defaultSlotForType = (type: ForecastingModelSlotType) => {
  return {
    type,
    salesAmount: 0,
    hourly:
      type === ForecastingModelSlotType.Hourly
        ? {
            dayOfWeek: 1,
            startTime: {
              hour: 0,
              minute: 0,
            },
            endTime: {
              hour: 0,
              minute: 0,
            },
          }
        : undefined,
    daily:
      type === ForecastingModelSlotType.Daily
        ? {
            dayOfWeek: 1,
          }
        : undefined,
    monthly:
      type === ForecastingModelSlotType.Monthly
        ? {
            month: 0,
          }
        : undefined,
  };
};
