import {
  Address,
  BusinessEntity,
  ShipmentLocation,
} from "../../graphql/generated";

export function shipmentLocationLabel(
  shipmentLocation:
    | (Pick<ShipmentLocation, "name"> & {
        shipper?: Pick<BusinessEntity, "name"> | null;
        receiver?: Pick<BusinessEntity, "name"> | null;
      })
    | null
    | undefined
) {
  if (!shipmentLocation) {
    return "N/A";
  }
  return (
    shipmentLocation.name ||
    shipmentLocation.shipper?.name ||
    shipmentLocation.receiver?.name ||
    "N/A"
  );
}

export function shipmentLocationAddressLabel(
  shipmentLocation:
    | (Pick<ShipmentLocation, "addressLabel"> & {
        shipper?: {
          address?: Pick<Address, "label"> | null;
        } | null;
        receiver?: {
          address?: Pick<Address, "label"> | null;
        } | null;
      })
    | null
    | undefined
) {
  if (!shipmentLocation) {
    return "N/A";
  }
  return (
    shipmentLocation.addressLabel ||
    shipmentLocation.shipper?.address?.label ||
    shipmentLocation.receiver?.address?.label ||
    "N/A"
  );
}
