import { Box, Button, Stack } from "@mui/material";
import { GetLoadBoardLoadDetailsQuery } from "../../../graphql/generated";
import EmptyState from "../../common/EmptyState";
import ShipmentDetails from "../../shipment/ShipmentDetails";

export type LoadBoardLoadDetailsProps = {
  load: GetLoadBoardLoadDetailsQuery["loadBoardLoadById"];
  onImport: () => void;
};

const LoadBoardLoadDetails = ({
  load,
  onImport,
}: LoadBoardLoadDetailsProps) => {
  if (!load) {
    return <EmptyState message="Order not found" />;
  }
  return (
    <Box>
      <ShipmentDetails
        shipment={load}
        onDocumentsUploaded={() => {}}
        onNotesAdded={() => {}}
        onNoteEdited={() => {}}
        onNoteDeleted={() => {}}
        onDocumentDeleted={() => {}}
        onDocumentsChanged={() => {}}
        onChargeAdded={() => {}}
        onChargeDeleted={() => {}}
        onChargesChanged={() => {}}
        onExpenseAdded={() => {}}
        onExpenseDeleted={() => {}}
        onExpensesChanged={() => {}}
        onRefresh={() => {}}
        summaryOnly
        routeDistance={load.route?.routeDistance || 0}
      />
      <Stack
        direction="row"
        justifyContent="flex-end"
        spacing={2}
        sx={{
          mt: 2,
        }}
      >
        <Button variant="contained" onClick={() => onImport()} id="import-load">
          Import Order
        </Button>
      </Stack>
    </Box>
  );
};

export default LoadBoardLoadDetails;
