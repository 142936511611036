import { useMemo, useState } from "react";
import {
  EditShipmentNoteInput,
  GetShipmentQuery,
  GetTripQuery,
  ShipmentLocationType,
  ShipmentNoteInput,
  Status,
  TransactionInput,
  TransactionType,
} from "../../../graphql/generated";
import {
  Grid,
  Box,
  Stack,
  Button,
  List,
  Container,
  Divider,
} from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useAuth } from "../../../providers/AuthProvider";
import {
  Add,
  AttachMoney,
  GroupWork,
  LocationOn,
  Phone,
  Portrait,
  Remove,
  Style,
  Tag,
  Wallet,
} from "@mui/icons-material";
import TrailerTypeIcon from "./TrailerFormIcon";
import { getConstraintValue } from "../ShipmentForm/TrailerRequirementsForm/TrailerRequirementsForm";
import trailerTypeLabel from "../../../utils/labels/trailerTypeLabel";
import ShipmentDocumentsForm from "../ShipmentForm/ShipmentDocuments/";
import {
  ShipmentChargeInputData,
  ShipmentDocumentInputData,
} from "../../../redux/slices/Types";
import InfoBlock from "../../common/InfoBlock";
import LabeledInfo from "../../common/LabeledInfo/LabeledInfo";
import EditIcon from "@mui/icons-material/Edit";
import ShipmentNotesForm from "../ShipmentForm/ShipmentNotes";
import ShipmentNoteList from "../ShipmentForm/ShipmentNotesList";
import ShipmentDocumentsList from "../ShipmentForm/ShipmentDocumentsList";
import { capitalize, sum } from "lodash";
import formatWeight from "../../../utils/labels/formatWeight";
import formatDistance from "../../../utils/labels/formatDistance";
import formatTemperature from "../../../utils/labels/formatTemperature";
import ShipmentTransactionsList from "../ShipmentForm/ShipmentTransactionsList";
import TransactionFormModal from "../ShipmentForm/TransactionFormModal";
import { LoadingButton } from "@mui/lab";
import { ShipmentTransactionsListProps } from "../ShipmentForm/ShipmentTransactionsList/ShipmentTransactionsList";
import { DocumentsListProps } from "../ShipmentForm/ShipmentDocumentsList/DocumentsList";
import EventReport, {
  EventReportData,
} from "../../tracking/EventReport/EventReport";
import { getAllEventReports } from "../../tracking/ShipmentTracker/ShipmentTracker";
import EmptyState from "../../common/EmptyState";
import ShipmentEventFormModalContainer from "../../tracking/ShipmentEventFormModal";
import ShipmentSummary from "../ShipmentSummary";
import ShipmentSummaryContainer from "../ShipmentSummary/ShipmentSummaryContainer";
import formatPhoneNumber from "../../../utils/labels/formatPhoneNumber";
import {
  referenceNumberFieldLabels,
  referenceNumberFields,
} from "../ShipmentForm/ShipmentReferencesForm/ShipmentReferencesForm";
import LynksTabs from "../../common/LynksTabs";
import DownloadSendInvoiceButtonsContainer from "../../accounting/DownloadSendInvoiceButtons";
import TripDetailsContainer from "../../trip/TripDetails";
import RoleGuard from "../../account/Access/RoleGuard";
import { useTranslation } from "react-i18next";
import ChipTagsList from "../../common/ChipTagsList/ChipTagsList";
import GroupsDisplayContainer from "../../asset-management/GroupsDisplay";
import CustomFieldsDisplayContainer from "../../custom-field-definitions/CustomFieldsDisplay";

export interface ShipmentDetailsProps {
  shipment: GetShipmentQuery["shipmentById"];
  trip?: GetTripQuery["tripById"] | null | undefined;
  uploadingDocuments?: boolean;
  onDocumentsUploaded: (documents: Array<ShipmentDocumentInputData>) => void;
  onNotesAdded: (notes: Array<ShipmentNoteInput>) => void;
  onNoteEdited: (notes: EditShipmentNoteInput) => void;
  onNoteDeleted: (id: string) => void;
  onDocumentDeleted: (id: string) => void;
  onDocumentsChanged: DocumentsListProps["onChange"];
  onChargeAdded: (charge: ShipmentChargeInputData) => void;
  onChargeDeleted: (id: string) => void;
  onChargesChanged: ShipmentTransactionsListProps["onChange"];
  onExpenseAdded: (expense: TransactionInput) => void;
  onExpenseDeleted: (id: string) => void;
  onExpensesChanged: ShipmentTransactionsListProps["onChange"];
  onRefresh: () => void;
  isInputView?: boolean;
  routeDistance: number;
  summaryOnly?: boolean;
  canInvoice?: boolean;
  hideTabs?: boolean;
}

export enum LoadDetailsTab {
  GeneralInfos,
  Trip,
  DocumentsAndNotes,
  Invoicing,
  History,
}

export default function ShipmentDetails({
  shipment,
  trip,
  uploadingDocuments,
  onDocumentsUploaded,
  onNotesAdded,
  onNoteEdited,
  onNoteDeleted,
  onDocumentDeleted,
  onDocumentsChanged,
  onChargeAdded,
  onChargeDeleted,
  onChargesChanged,
  onExpenseAdded,
  onExpenseDeleted,
  onExpensesChanged,
  onRefresh,
  isInputView = true,
  routeDistance,
  summaryOnly = false,
  canInvoice = false,
  hideTabs = false,
}: ShipmentDetailsProps) {
  const { t } = useTranslation(["orders", "common"]);
  const [activeTab, setActiveTab] = useState(LoadDetailsTab.GeneralInfos);
  const { userInfo } = useAuth();

  const [isDocumentModalOpen, setDocumentModalOpen] = useState(false);
  const handleDocumentModalOpen = () => setDocumentModalOpen(true);

  const [isNoteModalOpen, setNoteModalOpen] = useState(false);
  const handleNotesModalOpen = () => setNoteModalOpen(true);

  const [isChargeModalOpen, setChargeModalOpen] = useState(false);
  const [chargeToEdit, setChargeToEdit] =
    useState<ShipmentChargeInputData | null>(null);
  const handleChargeModalOpen = () => setChargeModalOpen(true);

  const [isExpenseModalOpen, setExpenseModalOpen] = useState(false);
  const [expenseToEdit, setExpenseToEdit] =
    useState<TransactionInput | null>(null);
  const handleExpenseModalOpen = () => setExpenseModalOpen(true);

  const handleDocumentModalClose = (
    documents: Array<ShipmentDocumentInputData>
  ) => {
    if (documents.length) {
      onDocumentsUploaded(documents);
    }
    setDocumentModalOpen(false);
  };

  const handleNotesModalClose = (notes: Array<ShipmentNoteInput>) => {
    if (notes.length) {
      onNotesAdded(notes);
    }
    setNoteModalOpen(false);
  };

  const handleChargeModalClose = () => {
    setChargeModalOpen(false);
  };

  const handleExpenseModalClose = () => {
    setExpenseModalOpen(false);
  };

  const [invoiceHasChanged, setInvoiceHasChanged] = useState(false);

  const refreshInvoice = () => {
    setInvoiceHasChanged(true);
    setTimeout(() => {
      setInvoiceHasChanged(false);
    }, 1000);
  };

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState<string | null>(null);

  const customer = shipment.customer;

  const pickupLocations = shipment.shipmentLocations.filter(
    (location) => location.locationType === ShipmentLocationType.Pickup
  );

  const allEventReports: EventReportData[] = useMemo(() => {
    return getAllEventReports(
      {
        ...shipment,
        issues: shipment.issues?.map((issue) => ({
          _id: issue._id,
          type: issue.type,
          content: issue.content,
          date: issue.date,
          shipmentLocation: issue.shipmentLocation || "",
          delayAmount: issue.delayAmount || 0,
        })),
      },
      trip,
      true
    ).reverse();
  }, [shipment, trip]);

  const tabs = useMemo(
    () => [
      {
        value: LoadDetailsTab.GeneralInfos,
        label: t("generalInfo"),
      },
      {
        value: LoadDetailsTab.Trip,
        label: t("trip"),
        hidden: !trip || isInputView,
      },
      {
        value: LoadDetailsTab.DocumentsAndNotes,
        label: t("documentsAndNotes"),
      },
      {
        value: LoadDetailsTab.Invoicing,
        label: t("invoicing"),
      },
      {
        value: LoadDetailsTab.History,
        label: t("history"),
        hidden: isInputView,
      },
    ],
    [isInputView, trip, t]
  );

  return (
    <Container sx={{ width: "100%" }}>
      {hideTabs ? null : (
        <LynksTabs
          tabs={tabs}
          onSelectTab={(tab) => setActiveTab(tab)}
          value={activeTab}
        />
      )}
      <Box mt={1}>
        {activeTab === LoadDetailsTab.GeneralInfos && (
          <Grid container mt={1} spacing={5}>
            <Grid item xs={12} lg={6}>
              <InfoBlock title={t("orderInformation")}>
                <Grid container>
                  <Grid item md={6}>
                    <LabeledInfo
                      icon={<Tag />}
                      title={t("orderNumber")}
                      value={shipment.shipmentNumber}
                    />
                  </Grid>

                  <Grid item md={6}>
                    <LabeledInfo
                      icon={<Portrait />}
                      title={t("common:dispatcher")}
                      value={userInfo?.name}
                    />
                  </Grid>

                  <Grid item md={6}>
                    <LabeledInfo
                      title={t("common:tags")}
                      value={<ChipTagsList value={shipment.tags || []} />}
                      icon={<Style />}
                    />
                  </Grid>

                  <Grid item md={6}>
                    <LabeledInfo
                      title={t("common:groups")}
                      value={
                        <GroupsDisplayContainer groupIds={shipment.groupIds} />
                      }
                      icon={<GroupWork />}
                    />
                  </Grid>
                </Grid>
              </InfoBlock>
            </Grid>
            <Grid item md={12} lg={6}>
              <InfoBlock title={t("common:brokerCustomer")}>
                <Grid container>
                  <Grid item md={6}>
                    <LabeledInfo
                      icon={<Portrait />}
                      title={
                        shipment.customer
                          ? t("common:customer")
                          : t("common:broker")
                      }
                      value={customer?.name}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <LabeledInfo
                      icon={<LocationOn />}
                      title={t("customerAddress")}
                      value={customer?.address?.label}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <LabeledInfo
                      icon={<Phone />}
                      title={t("common:phoneNumber")}
                      value={formatPhoneNumber(
                        customer?.contact.phoneNumber || ""
                      )}
                    />
                  </Grid>

                  {referenceNumberFields
                    .filter(
                      (field) => shipment[field] && shipment[field]?.length
                    )
                    .map((referenceNumberField) => {
                      const referenceNumberFieldLabel =
                        referenceNumberFieldLabels[referenceNumberField];
                      return (
                        <Grid item xs={12} sm={6} key={referenceNumberField}>
                          <LabeledInfo
                            title={referenceNumberFieldLabel}
                            value={shipment[referenceNumberField]?.join(", ")}
                          />
                        </Grid>
                      );
                    })}
                </Grid>
              </InfoBlock>
            </Grid>
            <Grid item xs={12} md={6}>
              <InfoBlock title={t("pickupDelivery")}>
                {(shipment.isSplit || shipment.dailyRotationCount) &&
                (shipment.childShipmentIds || []).length > 0 ? (
                  <Stack spacing={2}>
                    {(shipment.childShipmentIds || []).map(
                      (childShipmentId) => (
                        <ShipmentSummaryContainer
                          shipmentId={childShipmentId}
                          assignable
                        />
                      )
                    )}
                  </Stack>
                ) : (
                  <ShipmentSummary
                    shipment={{
                      ...shipment,
                      route: shipment.route
                        ? {
                            ...shipment.route,
                            routeDistance,
                          }
                        : null,
                    }}
                  />
                )}
              </InfoBlock>
            </Grid>
            <Grid item xs={12} md={6}>
              <InfoBlock title={t("orderTrailerRequirements")}>
                <Stack
                  direction="row"
                  flexWrap={"wrap"}
                  spacing={4}
                  alignItems="center"
                  justifyContent="center"
                  sx={{ pt: 1 }}
                >
                  <LabeledInfo
                    icon={
                      <TrailerTypeIcon type={shipment.trailerType} alt="" />
                    }
                    title=""
                    value={[
                      shipment.trailerType,
                      ...(shipment.additionalTrailerTypes || []),
                    ]
                      .map(trailerTypeLabel)
                      .join(", ")}
                    centered
                  />
                  <LabeledInfo
                    title={t("length")}
                    value={`${
                      getConstraintValue("length", shipment.constraints || [])
                        ? formatDistance(
                            getConstraintValue(
                              "length",
                              shipment.constraints || []
                            )
                          )
                        : ""
                    }`}
                  />
                  <LabeledInfo
                    title={t("width")}
                    value={`${
                      getConstraintValue("width", shipment.constraints || [])
                        ? formatDistance(
                            getConstraintValue(
                              "width",
                              shipment.constraints || []
                            )
                          )
                        : ""
                    }`}
                  />
                  <LabeledInfo
                    title={t("height")}
                    value={`${
                      getConstraintValue("height", shipment.constraints || [])
                        ? formatDistance(
                            getConstraintValue(
                              "height",
                              shipment.constraints || []
                            )
                          )
                        : ""
                    }`}
                  />
                  <LabeledInfo
                    title={t("weight")}
                    value={formatWeight(
                      sum(
                        pickupLocations
                          ?.map((location) =>
                            location.shippedGoods?.map(
                              (g) => (g.weight || 0) * g.quantity
                            )
                          )
                          .flat()
                      )
                    )}
                  />
                  <LabeledInfo
                    title={t("temperatureSensitive")}
                    value={((): string => {
                      const minTemp = getConstraintValue(
                        "min_temperature",
                        shipment.constraints || []
                      );
                      const maxTemp = getConstraintValue(
                        "max_temperature",
                        shipment.constraints || []
                      );
                      if (minTemp === null || maxTemp === null) {
                        return "No";
                      }
                      return `${`Min Temp: ${formatTemperature(
                        minTemp
                      )}`} - ${`Max Temp: ${formatTemperature(maxTemp)}`}`;
                    })()}
                  />
                </Stack>
              </InfoBlock>
            </Grid>

            <Grid item xs={12}>
              <CustomFieldsDisplayContainer
                customFields={shipment.customFields}
              />
            </Grid>
          </Grid>
        )}
        {activeTab === LoadDetailsTab.Trip && trip && (
          <TripDetailsContainer tripId={trip._id} />
        )}
        {activeTab === LoadDetailsTab.DocumentsAndNotes && (
          <>
            <InfoBlock
              title={t("notes.label")}
              action={
                shipment.status !== Status.Deleted && !summaryOnly ? (
                  <RoleGuard roles={["Carrier Admin", "Manager", "Dispatcher"]}>
                    <Button
                      variant="outlined"
                      onClick={handleNotesModalOpen}
                      startIcon={<EditIcon />}
                      id="add-note-button"
                    >
                      {capitalize(t("orders:notes.add"))}
                    </Button>
                  </RoleGuard>
                ) : null
              }
            >
              <ShipmentNoteList
                notes={shipment?.notes || []}
                onEdit={onNoteEdited}
                onDelete={onNoteDeleted}
                shipmentLocations={shipment.shipmentLocations}
                readonly={shipment.status === Status.Deleted}
              />
            </InfoBlock>
            <InfoBlock
              title={t("documents.label")}
              action={
                shipment.status !== Status.Deleted && !summaryOnly ? (
                  <LoadingButton
                    variant="outlined"
                    onClick={handleDocumentModalOpen}
                    startIcon={<FileUploadIcon />}
                    loading={uploadingDocuments}
                  >
                    {t("uploadDocument")}
                  </LoadingButton>
                ) : null
              }
            >
              <ShipmentDocumentsList
                documents={shipment?.documents || []}
                onDelete={onDocumentDeleted}
                onChange={onDocumentsChanged}
                readonly={shipment.status === Status.Deleted}
              />
            </InfoBlock>
          </>
        )}
        {activeTab === LoadDetailsTab.Invoicing && (
          <>
            <InfoBlock
              title={t("charges")}
              action={
                summaryOnly ? null : (
                  <Stack direction={{ xs: "column", md: "row" }} spacing={1}>
                    {canInvoice ? (
                      <DownloadSendInvoiceButtonsContainer
                        shipmentId={shipment._id}
                        canGenerateInvoice={
                          shipment.status !== Status.Deleted && !isInputView
                        }
                        invoiceHasChanged={invoiceHasChanged}
                      />
                    ) : null}
                    {shipment.status !== Status.Deleted ? (
                      <RoleGuard
                        roles={["Carrier Admin", "Manager", "Dispatcher"]}
                      >
                        <Button
                          variant="outlined"
                          onClick={handleChargeModalOpen}
                          startIcon={<AttachMoney />}
                          id="add-charge-button"
                        >
                          {t("addCharge")}
                        </Button>
                      </RoleGuard>
                    ) : null}
                  </Stack>
                )
              }
            >
              <ShipmentTransactionsList
                transactions={shipment?.charges || []}
                onDelete={onChargeDeleted}
                onChange={onChargesChanged}
                onEdit={(id) => {
                  const charge = shipment.charges.find((c) => c._id === id);
                  if (charge) {
                    setChargeToEdit({
                      ...charge,
                      billingRule: charge.billingRule?._id,
                    });
                    handleChargeModalOpen();
                  }
                }}
                readonly={shipment.status === Status.Deleted}
                isReimbursable={false}
                id="shipment-charges-list"
              />
            </InfoBlock>
            <Divider
              variant="fullWidth"
              sx={{
                my: 2,
              }}
            />
            <InfoBlock
              title={t("expenses")}
              action={
                <>
                  {shipment.status !== Status.Deleted ? (
                    <RoleGuard
                      roles={["Carrier Admin", "Manager", "Dispatcher"]}
                    >
                      <Button
                        variant="outlined"
                        onClick={handleExpenseModalOpen}
                        startIcon={<Wallet />}
                        id="add-expense-button"
                      >
                        {t("addExpense")}
                      </Button>
                    </RoleGuard>
                  ) : null}
                </>
              }
            >
              <ShipmentTransactionsList
                id="shipment-expenses-list"
                transactions={shipment?.expenses || []}
                onDelete={onExpenseDeleted}
                onChange={onExpensesChanged}
                onEdit={(id) => {
                  const expense = shipment.expenses?.find((c) => c._id === id);
                  if (expense) {
                    setExpenseToEdit({
                      ...expense,
                    });
                    handleExpenseModalOpen();
                  }
                }}
                readonly={shipment.status === Status.Deleted}
                actions={[
                  {
                    icon: <Add />,
                    tooltip:
                      "Include this expense in the charges invoiced to the client",
                    label: "Add to invoice",
                    onClick(record) {
                      onChargeAdded({
                        ...record,
                        billingRule: undefined,
                        type: TransactionType.Income,
                        relatedTransactionId: record._id,
                      });
                    },
                    isApplicable: (record) =>
                      !shipment.charges.find(
                        (charge) => charge.relatedTransactionId === record._id
                      ),
                  },
                  {
                    icon: <Remove />,
                    tooltip:
                      "Remove this expense from the charges invoiced to the client",
                    label: "Remove from invoice",
                    onClick(record) {
                      const relatedCharge = shipment.charges.find(
                        (charge) => charge.relatedTransactionId === record._id
                      );
                      if (relatedCharge) {
                        onChargeDeleted(relatedCharge._id);
                      }
                    },
                    isApplicable: (record) =>
                      !!shipment.charges.find(
                        (charge) => charge.relatedTransactionId === record._id
                      ),
                  },
                ]}
              />
            </InfoBlock>
          </>
        )}

        {activeTab === LoadDetailsTab.History && (
          <InfoBlock title={t("events")}>
            {(shipment.events || [])?.length > 0 ? (
              <List sx={{ pt: 0 }}>
                {(allEventReports || []).map((eventReport) => {
                  const shipmentEvent = shipment.events?.find(
                    (e) => e?._id === eventReport._id
                  );
                  return (
                    <List>
                      <EventReport
                        key={eventReport._id}
                        eventReport={eventReport}
                        onClick={() => {
                          if (shipmentEvent) {
                            setSelectedEventId(shipmentEvent._id);
                            setIsEditModalOpen(true);
                          }
                        }}
                        showShipmentLink={false}
                      />

                      <ShipmentEventFormModalContainer
                        isOpened={
                          isEditModalOpen && selectedEventId === eventReport._id
                        }
                        onClose={() => {
                          setIsEditModalOpen(false);
                          onRefresh();
                        }}
                        shipmentId={eventReport.shipment._id}
                        initialShipmentEvent={
                          shipmentEvent
                            ? {
                                _id: shipmentEvent._id,
                                type: shipmentEvent.type,
                                date: shipmentEvent.date,
                                location: shipmentEvent.coordinates || {
                                  latitude: 0,
                                  longitude: 0,
                                },
                                shipment: eventReport.shipment._id,
                                trip: shipment.tripId || "",
                                shipmentLocation:
                                  shipmentEvent.shipmentLocation || "",
                              }
                            : null
                        }
                      />
                    </List>
                  );
                })}
              </List>
            ) : (
              <EmptyState />
            )}
          </InfoBlock>
        )}

        <ShipmentNotesForm
          width="md"
          openModal={isNoteModalOpen}
          onClose={handleNotesModalClose}
          shipmentLocations={shipment?.shipmentLocations || []}
        />
        <ShipmentDocumentsForm
          width="md"
          openModal={isDocumentModalOpen}
          onClose={handleDocumentModalClose}
          shipmentLocations={shipment.shipmentLocations || []}
        />
        <TransactionFormModal
          routeDistance={routeDistance}
          routeDistanceWithDeadhead={trip?.totalDistance || routeDistance}
          initialTransaction={chargeToEdit}
          isOpened={isChargeModalOpen}
          onClose={() => {
            setChargeToEdit(null);
            handleChargeModalClose();
          }}
          transactionType={TransactionType.Income}
          onSubmit={(charge) => {
            if (chargeToEdit) {
              onChargesChanged(
                shipment.charges
                  .map((c) => ({
                    ...c,
                    billingRule: c.billingRule?._id,
                  }))
                  .map((c) => {
                    if (c._id === chargeToEdit._id) {
                      return charge;
                    }
                    return c;
                  })
              );
            } else {
              onChargeAdded(charge);
            }
            setChargeToEdit(null);
            handleChargeModalClose();
            refreshInvoice();
          }}
        />
        <TransactionFormModal
          routeDistance={routeDistance * (shipment.dailyRotationCount || 1)}
          routeDistanceWithDeadhead={trip?.totalDistance || routeDistance}
          initialTransaction={expenseToEdit}
          isOpened={isExpenseModalOpen}
          transactionType={TransactionType.Expense}
          onClose={() => {
            setExpenseToEdit(null);
            handleExpenseModalClose();
          }}
          onSubmit={(expense) => {
            if (expenseToEdit) {
              onExpensesChanged(
                (shipment.expenses || []).map((c) => {
                  if (c._id === expenseToEdit._id) {
                    return expense;
                  }
                  return c;
                })
              );
            } else {
              onExpenseAdded(expense);
            }
            setExpenseToEdit(null);
            handleExpenseModalClose();
          }}
        />
      </Box>
    </Container>
  );
}
