import { Navigate } from "react-router-dom";
import { useAuth } from "../../../providers/AuthProvider";
import { Role } from "../../../graphql/generated";

type RoleGuardProps = {
  roles?: (string | null)[] | null;
  children: React.ReactNode;
};

const HrPathToCheck = ["/users", "/drivers"];
const RhRoles = [Role.Hr, Role.Accounting];

const RoleGuard = (props: RoleGuardProps) => {
  const { roles } = useAuth();
  const { pathname } = window.location;

  if (
    !props.roles ||
    roles.some((role) => (props.roles || []).includes(role))
  ) {
    return <>{props.children}</>;
  }

  if (
    RhRoles.some((rhRole) => roles.includes(rhRole)) &&
    HrPathToCheck.every((path) => !pathname.startsWith(path))
  ) {
    return <Navigate to="/users" />;
  }

  return null;
};

export const useRoles = () => {
  const { roles } = useAuth();
  return roles;
};

export default RoleGuard;
