import { InputAdornment, TextField } from "@mui/material";
import { ConditionValueSelectorComponent } from "../ConditionForm";

const DurationInput: ConditionValueSelectorComponent = ({
  value,
  onChange,
}) => {
  return (
    <TextField
      type="number"
      value={value ? String(value) : ""}
      onChange={(event) => {
        onChange(parseInt(event.target.value) || 0);
      }}
      size="small"
      label="Duration"
      inputProps={{
        type: "number",
        min: 0,
      }}
      InputProps={{
        endAdornment: <InputAdornment position="start">mns</InputAdornment>,
      }}
    />
  );
};

export default DurationInput;
