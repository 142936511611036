import { Container } from "@mui/material";
import BusinessEntityForm from "../../../components/asset-management/BusinessEntityForm";
import { BusinessEntityType } from "../../../graphql/generated";

function CreateCarrier() {
  return (
    <Container>
      <BusinessEntityForm
        urlPrefix="/carriers"
        availableTypes={[BusinessEntityType.Carrier]}
        defaultType={BusinessEntityType.Carrier}
      />
    </Container>
  );
}

export default CreateCarrier;
