import { Alert, Stack } from "@mui/material";
import { Trip, TripShipmentLocation } from "../../../graphql/generated";
import enumLabel from "../../../utils/labels/enumLabel";
import { formatDuration } from "../../../utils/labels/formatDateTime";

const TripViolations = ({
  trip,
}: {
  trip: {
    shipmentLocations: Pick<TripShipmentLocation, "name" | "_id">[];
    violations?: Trip["violations"];
  };
}) => {
  return (
    <Stack>
      {trip?.violations?.map((violation) => {
        const location = trip.shipmentLocations.find(
          (location) => location._id === violation.locationId
        );
        return (
          <Alert severity="warning">
            {enumLabel(violation.cause)}{" "}
            {violation.duration
              ? `of ${formatDuration(violation.duration)}`
              : ""}{" "}
            {location ? `at ${location.name}` : ""}
          </Alert>
        );
      })}
    </Stack>
  );
};

export default TripViolations;
