import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Status,
  useAddTripMutation,
  useGetShipmentSummariesQuery,
} from "../../../graphql/generated";
import TripCreator from "./TripCreator";

function TripCreatorContainer() {
  const pendingShipmentsQuery = useGetShipmentSummariesQuery({
    status: Status.WaitingForAssignment,
  });
  const addTripMutation = useAddTripMutation();
  const navigate = useNavigate();

  const onShipmentsChange = useCallback(() => {
    addTripMutation.reset();
    // Avoids maximum update depth exceeded
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <TripCreator
      shipments={pendingShipmentsQuery.data?.shipments.data || []}
      creating={addTripMutation.isLoading}
      loading={pendingShipmentsQuery.isLoading}
      error={addTripMutation.error as Error}
      onCreate={async (shipments) => {
        await addTripMutation.mutateAsync({
          newTripData: {
            shipments: shipments.map((shipment) => shipment._id),
          },
        });
        navigate("/trips");
      }}
      onShipmentsChange={onShipmentsChange}
    />
  );
}

export default TripCreatorContainer;
