import { IconButton, MenuItem, Stack, TextField } from "@mui/material";
import {
  GetGoodProfileListQuery,
  GoodProfileEquivalence,
  GoodProfileEquivalenceConditionOperator,
  GoodProfileEquivalenceConditionTarget,
} from "../../../graphql/generated";
import GoodProfileEquivalenceConditionForm from "./GoodProfileEquivalenceConditionForm";
import LabeledAddButton from "../../common/LabeledAddButton";
import { Delete } from "@mui/icons-material";

export type GoodProfileEquivalenceFormProps = {
  equivalence: GoodProfileEquivalence;
  onChange: (equivalence: GoodProfileEquivalence) => void;
  goodProfiles: GetGoodProfileListQuery["goodProfiles"]["data"];
};

const GoodProfileEquivalenceForm = ({
  equivalence,
  onChange,
  goodProfiles,
}: GoodProfileEquivalenceFormProps) => {
  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      sx={{
        width: "100%",
      }}
    >
      <TextField
        select
        sx={{
          flex: 1,
          minWidth: 200,
        }}
        label="Equivalent Commodity"
        value={equivalence.equivalentGoodId}
        onChange={(event) => {
          onChange({
            ...equivalence,
            equivalentGoodId: event.target.value as string,
          });
        }}
      >
        {goodProfiles.map((goodProfile) => (
          <MenuItem key={goodProfile._id} value={goodProfile._id}>
            {goodProfile.code} - {goodProfile.label}
          </MenuItem>
        ))}
      </TextField>
      <Stack
        direction="column"
        sx={{
          flex: 3,
        }}
      >
        {equivalence.conditions.map((condition, index) => (
          <Stack direction="row" justifyContent="space-between">
            <GoodProfileEquivalenceConditionForm
              key={index}
              condition={condition}
              onChange={(changedCondition) => {
                onChange({
                  ...equivalence,
                  conditions: equivalence.conditions.map((c, i) =>
                    i === index ? changedCondition : c
                  ),
                });
              }}
            />
            <IconButton
              onClick={() => {
                onChange({
                  ...equivalence,
                  conditions: equivalence.conditions.filter(
                    (c, i) => i !== index
                  ),
                });
              }}
            >
              <Delete />
            </IconButton>
          </Stack>
        ))}
      </Stack>
      <LabeledAddButton
        label="Add Condition"
        onClick={() => {
          onChange({
            ...equivalence,
            conditions: equivalence.conditions.concat({
              target: GoodProfileEquivalenceConditionTarget.Date,
              operator:
                GoodProfileEquivalenceConditionOperator.IsGreaterThanOrEqual,
              value: new Date(),
            }),
          });
        }}
        containerSx={{
          alignSelf: "flex-end",
        }}
      />
    </Stack>
  );
};

export default GoodProfileEquivalenceForm;
