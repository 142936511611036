import { AutocompleteProps } from "@mui/material";
import { useGetTrailerListQuery } from "../../../../../graphql/generated";
import { ConditionValueSelectorComponentProps } from "../../ConditionForm";
import TrailerInput from "./TrailerInput";

type TrailerInputContainerProps = ConditionValueSelectorComponentProps;

const TrailerInputContainer = ({
  ...props
}: TrailerInputContainerProps &
  Pick<AutocompleteProps<string, true, undefined, undefined>, "sx">) => {
  const query = useGetTrailerListQuery();
  return <TrailerInput {...props} trailers={query.data?.trailers.data || []} />;
};

export default TrailerInputContainer;
