import { useEffect, useMemo, useRef, useState } from "react";
import {
  AssetStatus,
  BusinessEntityType,
  LoadType,
  ShipmentLocationType,
  ShipmentSortCriteria,
  Status,
  TripAssetTypes,
  TripForPlanningInput,
  useAddTripMutation,
  useAssignTripAssetsMutation,
  useChangeTripStartTimeMutation,
  useDeleteTripMutation,
  useGenerateTripsForShipmentsMutation,
  useGetAllDriverShiftsQuery,
  useGetAllMaintenanceTasksQuery,
  useGetAssignmentAssetsQuery,
  useGetBusinessEntityListQuery,
  useGetDriverListQuery,
  useGetOrganizationSettingsQuery,
  useGetShipmentListForPlanningQuery,
  usePlanTripsForDriverMutation,
  useUpdateShipmentMutation,
} from "../../../graphql/generated";
import PlanningTool, {
  AugmentedShipment,
  PlanningToolProps,
} from "./PlanningTool";
import {
  addSeconds,
  differenceInSeconds,
  endOfDay,
  isEqual,
  startOfDay,
} from "date-fns";
import { Box } from "@mui/material";
import LoadingOverlay from "../../common/LoadingOverlay";
import { AssetFiltersValue } from "./AssetFilters";
// @ts-ignore
import parseAddress from "parse-address";
import { shipmentLocationAddressLabel } from "../../../utils/labels/shipmentLocationLabel";
import { ParsedAddressString } from "../../common/ExcelImporter";
import {
  groupBy,
  last,
  map,
  max,
  maxBy,
  min,
  minBy,
  sortBy,
  sum,
  toLower,
  uniq,
} from "lodash";
import { LoadFiltersValue } from "./LoadFilters";
import { mileage } from "../../../utils/conversion/distance";
import useDialog from "../../../utils/hooks/useDialog";
import { useTranslation } from "react-i18next";
const states = require("us-state-converter");

type PlanningToolContainerProps = Omit<
  PlanningToolProps,
  | "drivers"
  | "tractors"
  | "trailers"
  | "shipments"
  | "filteredShipments"
  | "assetFilters"
  | "loadFilters"
  | "driverShifts"
  | "maintenanceTasks"
  | "domiciles"
  | "onPeriodChange"
  | "onSubmit"
  | "onAssetSearchChange"
  | "onAssetFiltersChange"
  | "onLoadSearchChange"
  | "onLoadFiltersChange"
  | "onSortChange"
  | "onSortOrderDescChange"
  | "onShipmentsChange"
  | "onShipmentsCombine"
  | "onRefresh"
  | "driverIdBeingPlanned"
  | "onFilteredShipmentsChange"
  | "onAutoPlanSubmit"
  | "isAutoPlanning"
>;

const PlanningToolContainer = (props: PlanningToolContainerProps) => {
  const { t } = useTranslation(["planning", "common"]);
  const getDriverListQuery = useGetDriverListQuery({
    where: {
      status: AssetStatus.Active,
    },
  });
  const assignmentAssetsQuery = useGetAssignmentAssetsQuery();
  const getAllDriverShiftsQuery = useGetAllDriverShiftsQuery();
  const getAllMaintenanceTasksQuery = useGetAllMaintenanceTasksQuery();
  const domicilesQuery = useGetBusinessEntityListQuery({
    businessEntityTypes: [BusinessEntityType.Domicile],
  });

  const getShipmentListsQuery = useGetShipmentListForPlanningQuery();
  const addTripMutation = useAddTripMutation();
  const deleteTripMutation = useDeleteTripMutation();
  const assignTripAssetsMutation = useAssignTripAssetsMutation();
  const changeTripStartTimeMutation = useChangeTripStartTimeMutation();
  const updateShipmentMutation = useUpdateShipmentMutation();
  const planTripsForDriverMutation = usePlanTripsForDriverMutation();
  const generateTripsForShipmentsMutation =
    useGenerateTripsForShipmentsMutation();

  const shipments = useMemo(
    () => getShipmentListsQuery.data?.shipments.data || [],
    [getShipmentListsQuery.data?.shipments.data]
  );

  const [localShipments, setLocalShipments] = useState(shipments);
  const [hasChanged, setHasChanged] = useState(false);
  const localFilteredShipments = useRef(shipments);

  useEffect(() => {
    if (hasChanged) {
      return;
    }
    setLocalShipments(shipments);
  }, [hasChanged, shipments]);

  const shipmentsWithStatesAndCities = localShipments.map((shipment) => {
    const parsedOrigin: ParsedAddressString | null = parseAddress.parseLocation(
      shipmentLocationAddressLabel(shipment.route?.locations?.[0])
    );
    const parsedDestination: ParsedAddressString | null =
      parseAddress.parseLocation(
        shipmentLocationAddressLabel(
          shipment.route?.locations?.[shipment.route.locations.length - 1]
        )
      );

    return {
      ...shipment,
      originState: parsedOrigin?.state || "",
      originCity:
        parsedOrigin?.city ||
        shipmentLocationAddressLabel(shipment.route?.locations?.[0]),
      destinationState: parsedDestination?.state || "",
      destinationCity:
        parsedDestination?.city ||
        shipmentLocationAddressLabel(
          shipment.route?.locations?.[shipment.route.locations.length - 1]
        ),
    };
  });

  const localGroupedShipments = useMemo(() => {
    return map(
      groupBy(
        shipmentsWithStatesAndCities,
        (shipment) => shipment.tripId || shipment._id
      ),
      (shipmentGroup, tripId) => ({
        ...shipmentGroup[0],
        otherShipmentsInTrip: (shipmentGroup as AugmentedShipment[]).slice(1),
      })
    );
  }, [shipmentsWithStatesAndCities]);

  const shipmentsWithEarliestLatestPickup = localGroupedShipments.map(
    (shipment) => {
      const firstPickupLocation =
        shipment.trip?.shipmentLocations[0] || shipment.route?.locations?.[0];

      if (!firstPickupLocation) {
        throw new Error(
          "No pickup location found for shipment " + shipment._id
        );
      }

      const earliestPickupByTimeWindow = min(
        firstPickupLocation?.timeWindows.map((tw) => tw.fromDate)
      );
      const latestPickupByTimeWindow = max(
        firstPickupLocation?.timeWindows.map((tw) => tw.toDate)
      );

      const locationsWithTimeWindows = (
        shipment.trip?.shipmentLocations ||
        shipment.route?.locations ||
        []
      ).filter(
        (location) =>
          location !== firstPickupLocation && location.timeWindows.length > 0
      );

      const earliestPickupToGetToAllLocations = max(
        locationsWithTimeWindows.map((locationToVisit) => {
          const locationVisitMinTime = new Date(
            min(locationToVisit.timeWindows.map((tw) => tw.fromDate))
          );

          const duration = differenceInSeconds(
            new Date(locationToVisit.arrivalTime),
            new Date(firstPickupLocation.arrivalTime)
          );

          const earliestPickup = addSeconds(locationVisitMinTime, -duration);

          return earliestPickup;
        })
      );

      const latestPickupToGetToAllLocations = min(
        locationsWithTimeWindows.map((locationToVisit) => {
          const locationVisitMaxTime = new Date(
            max(locationToVisit.timeWindows.map((tw) => tw.toDate))
          );

          const duration = differenceInSeconds(
            new Date(locationToVisit.arrivalTime),
            new Date(firstPickupLocation.arrivalTime)
          );

          const latestPickup = addSeconds(locationVisitMaxTime, -duration);

          return latestPickup;
        })
      );

      const earliestPickup = max([
        earliestPickupByTimeWindow,
        earliestPickupToGetToAllLocations,
      ]);
      const latestPickup = min([
        latestPickupByTimeWindow,
        latestPickupToGetToAllLocations,
      ]);

      const lastDropoffLocation = last(
        shipment.trip?.shipmentLocations || shipment.route?.locations || []
      );

      const earliestDropoff = min(
        lastDropoffLocation?.timeWindows.map((tw) => tw.fromDate)
      );
      const latestDropoff = max(
        lastDropoffLocation?.timeWindows.map((tw) => tw.toDate)
      );

      return {
        ...shipment,
        earliestPickup,
        latestPickup,
        earliestDropoff,
        latestDropoff,
      };
    }
  );

  const [currentPeriod, setCurrentPeriod] = useState({
    start: startOfDay(new Date()),
    end: endOfDay(new Date()),
  });
  const [assetSearch, setAssetSearch] = useState("");
  const [assetFilters, setAssetFilters] = useState<AssetFiltersValue>({
    trailerTypes: null,
    originStates: null,
    destinationStates: null,
    originCities: null,
    destinationCities: null,
    hosRange: null,
    hosStatus: null,
    domicileIds: null,
  });

  const [loadSearch, setLoadSearch] = useState("");
  const [loadFilters, setLoadFilters] = useState<LoadFiltersValue>({
    trailerTypes: null,
    originStates: null,
    destinationStates: null,
    originCities: null,
    destinationCities: null,
    customer: null,
    shipper: null,
    receiver: null,
    minRevenue: null,
    minRevenuePerMile: null,
  });

  const [sortCriteria, setSortCriteria] = useState<ShipmentSortCriteria | null>(
    ShipmentSortCriteria.PickupDate
  );
  const [sortOrderDesc, setSortOrderDesc] = useState(false);
  const shipmentsToDisplay = shipmentsWithEarliestLatestPickup.filter(
    (shipment) => {
      if (shipment.status !== Status.Complete) {
        return true;
      }
      const shipmentStart = new Date(
        shipment.trip?.firstPickupTime || shipment.route?.firstPickupTime
      );
      const shipmentEnd = new Date(
        shipment.trip?.lastDropoffTime || shipment.route?.lastDropoffTime
      );

      return (
        (shipmentStart >= currentPeriod.start &&
          shipmentEnd <= currentPeriod.end) ||
        (shipmentStart <= currentPeriod.start &&
          shipmentEnd >= currentPeriod.end) ||
        (shipmentStart >= currentPeriod.start &&
          shipmentStart <= currentPeriod.end) ||
        (shipmentEnd >= currentPeriod.start && shipmentEnd <= currentPeriod.end)
      );
    }
  );

  let filteredShipments = sortBy(
    shipmentsToDisplay
      .filter((shipment) => {
        if (loadSearch) {
          const loadSearchText = `${
            shipment.shipmentNumber
          } ${shipment.referenceNumbers?.join(" ")} ${shipment.originState} ${
            shipment.originCity
          } ${shipment.destinationState} ${shipment.destinationCity} ${
            shipment.customer.name
          } ${shipment.shipmentLocations.map(
            (location) =>
              `${location.name} ${location.addressLabel} ${location.shippedGoods
                ?.map((good) => good.label)
                .join(" ")}`
          )}`;
          return loadSearchText
            .toLowerCase()
            .includes(loadSearch.toLowerCase());
        }
        return true;
      })
      .filter((shipment) => {
        if (!loadFilters.statuses?.length) {
          return true;
        }
        return loadFilters.statuses.includes(shipment.status);
      })
      .filter((shipment) => {
        if (!loadFilters.trailerTypes?.length) {
          return true;
        }
        if (!shipment.trailerType) {
          return false;
        }
        return loadFilters.trailerTypes.includes(shipment.trailerType);
      })
      .filter((shipment) => {
        if (loadFilters.originStates?.length) {
          const shipmentOriginState = shipment.originState;
          if (!shipmentOriginState) {
            return false;
          }
          return loadFilters.originStates
            .map(states.abbr)
            .includes(shipmentOriginState);
        }
        return true;
      })
      .filter((shipment) => {
        if (loadFilters.destinationStates?.length) {
          const shipmentDestinationState = shipment.destinationState;
          if (!shipmentDestinationState) {
            return false;
          }
          return loadFilters.destinationStates
            .map(states.abbr)
            .includes(shipmentDestinationState);
        }
        return true;
      })
      .filter((shipment) => {
        if (loadFilters.originCities?.length) {
          const shipmentOriginCity = shipment.originCity;
          if (!shipmentOriginCity) {
            return false;
          }
          return loadFilters.originCities
            .map(toLower)
            .includes(shipmentOriginCity.toLowerCase());
        }
        return true;
      })
      .filter((shipment) => {
        if (loadFilters.destinationCities?.length) {
          const shipmentDestinationCity = shipment.destinationCity;
          if (!shipmentDestinationCity) {
            return false;
          }
          return loadFilters.destinationCities
            .map(toLower)
            .includes(shipmentDestinationCity.toLowerCase());
        }
        return true;
      })
      .filter((shipment) => {
        if (loadFilters.customer) {
          return shipment.customer._id === loadFilters.customer;
        }
        return true;
      })
      .filter((shipment) => {
        if (loadFilters.shipper) {
          return shipment.shipmentLocations.some(
            (shipmentLocation) =>
              shipmentLocation.locationType === ShipmentLocationType.Pickup &&
              shipmentLocation.shipper?._id === loadFilters.shipper
          );
        }
        return true;
      })
      .filter((shipment) => {
        if (loadFilters.receiver) {
          return shipment.shipmentLocations.some(
            (shipmentLocation) =>
              shipmentLocation.locationType === ShipmentLocationType.DropOff &&
              shipmentLocation.receiver?._id === loadFilters.receiver
          );
        }
        return true;
      })
      .filter((shipment) => {
        if (loadFilters.minRevenue) {
          return (
            sum(shipment.charges.map((charge) => charge.total)) >=
            loadFilters.minRevenue
          );
        }
        return true;
      })
      .filter((shipment) => {
        if (loadFilters.minRevenuePerMile) {
          return (
            sum(shipment.charges.map((charge) => charge.total)) /
              mileage(shipment.route?.routeDistance || 0) >=
            loadFilters.minRevenuePerMile
          );
        }
        return true;
      })
      .filter((shipment) => {
        if (loadFilters.firstPickupTime || loadFilters.lastDropoffTime) {
          const filterInterval = {
            start: loadFilters.firstPickupTime
              ? startOfDay(new Date(loadFilters.firstPickupTime))
              : undefined,
            end: loadFilters.lastDropoffTime
              ? endOfDay(new Date(loadFilters.lastDropoffTime))
              : undefined,
          };

          const shipmentStart = new Date(
            shipment.route?.firstPickupTime || shipment.trip?.firstPickupTime
          );
          const shipmentEnd = new Date(
            shipment.route?.lastDropoffTime || shipment.trip?.lastDropoffTime
          );

          return (
            (filterInterval.start
              ? shipmentStart >= filterInterval.start
              : true) ||
            (filterInterval.end ? shipmentEnd <= filterInterval.end : true)
          );
        }
        return true;
      }),
    (shipment) => {
      if (sortCriteria === ShipmentSortCriteria.PickupDate) {
        return (
          shipment.trip?.firstPickupTime || shipment.route?.firstPickupTime
        );
      }
      if (sortCriteria === ShipmentSortCriteria.DropoffDate) {
        return (
          shipment.trip?.lastDropoffTime || shipment.route?.lastDropoffTime
        );
      }
      if (sortCriteria === ShipmentSortCriteria.PickupDistance) {
        return shipment.route?.routeDistance;
      }
      if (sortCriteria === ShipmentSortCriteria.ShipmentNumber) {
        // shipmentNumber is a number, but it's stored as a string
        return Number(shipment.shipmentNumber);
      }
      if (sortCriteria === ShipmentSortCriteria.Revenue) {
        return -sum(shipment.charges.map((charge) => charge.total));
      }
      if (sortCriteria === ShipmentSortCriteria.RevenuePerMile) {
        return (
          -sum(shipment.charges.map((charge) => charge.total)) /
          mileage(shipment.route?.routeDistance || 0)
        );
      }
      // Default to sorting by pickup date
      return shipment.trip?.firstPickupTime || shipment.route?.firstPickupTime;
    }
  );

  filteredShipments = sortOrderDesc
    ? filteredShipments.reverse()
    : filteredShipments;

  const assetLinkings = useMemo(
    () => assignmentAssetsQuery.data?.assetLinkings.data || [],
    [assignmentAssetsQuery.data?.assetLinkings.data]
  );

  const driversWithAssociatedAssets = useMemo(
    () =>
      (assignmentAssetsQuery.data?.drivers.data || []).map((driver) => {
        const lastDriverShipmentBeforePeriod = shipments.find(
          (shipment) =>
            shipment.trip?.driver === driver._id &&
            new Date(
              shipment.trip?.lastDropoffTime || shipment.route?.lastDropoffTime
            ) < currentPeriod.start
        );
        const lastUsedTractor = assignmentAssetsQuery.data?.tractors.data.find(
          (tractor) =>
            lastDriverShipmentBeforePeriod?.trip?.tractor === tractor._id
        );
        const linkedTractor = assetLinkings.find(
          (assetLinking) => assetLinking.driver?._id === driver._id
        )?.tractor;
        const lastUsedTrailer = assignmentAssetsQuery.data?.trailers.data.find(
          (trailer) =>
            lastDriverShipmentBeforePeriod?.trip?.trailer === trailer._id
        );
        const linkedTrailer = assetLinkings.find(
          (assetLinking) => assetLinking.driver?._id === driver._id
        )?.trailer;
        const linkedPupTrailer = assetLinkings.find(
          (assetLinking) => assetLinking.driver?._id === driver._id
        )?.additionalTrailers;

        const lastShipmentWithSameTractorBeforePeriod = shipments.find(
          (shipment) =>
            shipment.trip?.tractor === lastUsedTractor?._id &&
            new Date(
              shipment.trip?.lastDropoffTime || shipment.route?.lastDropoffTime
            ) < currentPeriod.start
        );
        const lastShipmentWithSameTrailerBeforePeriod = shipments.find(
          (shipment) =>
            shipment.trip?.trailer === lastUsedTrailer?._id &&
            new Date(
              shipment.trip?.lastDropoffTime || shipment.route?.lastDropoffTime
            ) < currentPeriod.start
        );
        const tractorWasLastUsedByDriver =
          !lastShipmentWithSameTractorBeforePeriod ||
          lastShipmentWithSameTractorBeforePeriod?.trip?.driver === driver._id;
        const trailerWasLastUsedByDriver =
          !lastShipmentWithSameTrailerBeforePeriod ||
          lastShipmentWithSameTrailerBeforePeriod?.trip?.driver === driver._id;

        return {
          ...driver,
          associatedTractor: linkedTractor
            ? linkedTractor
            : tractorWasLastUsedByDriver && lastUsedTractor
            ? lastUsedTractor
            : null,
          associatedTrailer: linkedTrailer
            ? linkedTrailer
            : trailerWasLastUsedByDriver && lastUsedTrailer
            ? lastUsedTrailer
            : null,
          associatedAdditionalTrailers: linkedPupTrailer
            ? linkedPupTrailer
            : [],
          firstShipmentInPeriod:
            minBy(
              shipmentsToDisplay.filter(
                (shipment) => shipment.trip?.driver === driver._id
              ),
              "trip.firstPickupTime"
            ) || null,
          lastShipmentInPeriod:
            maxBy(
              shipmentsToDisplay.filter(
                (shipment) => shipment.trip?.driver === driver._id
              ),
              "trip.lastDropoffTime"
            ) || null,
        };
      }),
    [
      assetLinkings,
      assignmentAssetsQuery.data?.drivers.data,
      assignmentAssetsQuery.data?.tractors.data,
      assignmentAssetsQuery.data?.trailers.data,
      currentPeriod.start,
      shipments,
      shipmentsToDisplay,
    ]
  );

  const filteredDrivers = useMemo(
    () =>
      driversWithAssociatedAssets
        .filter((driver) => {
          if (assetSearch) {
            const driverSearchText = `${driver.firstname} ${driver.lastname} ${driver.associatedTractor?.serialNumber} ${driver.associatedTrailer?.serialNumber}`;
            return driverSearchText
              .toLowerCase()
              .includes(assetSearch.toLowerCase());
          }
          return true;
        })
        .filter((driver) => {
          if (!assetFilters.trailerTypes?.length) {
            return true;
          }
          if (!driver.associatedTrailer?.type) {
            return false;
          }
          return assetFilters.trailerTypes.includes(
            driver.associatedTrailer.type
          );
        })
        .filter((driver) => {
          if (assetFilters.originStates?.length) {
            const driverOriginState = driver.firstShipmentInPeriod?.originState;
            if (!driverOriginState) {
              return false;
            }
            return assetFilters.originStates
              .map(states.abbr)
              .includes(driverOriginState);
          }
          return true;
        })
        .filter((driver) => {
          if (assetFilters.destinationStates?.length) {
            const driverDestinationState =
              driver.lastShipmentInPeriod?.destinationState;
            if (!driverDestinationState) {
              return false;
            }
            return assetFilters.destinationStates
              .map(states.abbr)
              .includes(driverDestinationState);
          }
          return true;
        })
        .filter((driver) => {
          if (assetFilters.originCities?.length) {
            const driverOriginCity = driver.firstShipmentInPeriod?.originCity;
            if (!driverOriginCity) {
              return false;
            }
            return assetFilters.originCities
              .map(toLower)
              .includes(driverOriginCity.toLowerCase());
          }
          return true;
        })
        .filter((driver) => {
          if (assetFilters.destinationCities?.length) {
            const driverDestinationCity =
              driver.lastShipmentInPeriod?.destinationCity;
            if (!driverDestinationCity) {
              return false;
            }
            return assetFilters.destinationCities
              .map(toLower)
              .includes(driverDestinationCity.toLowerCase());
          }
          return true;
        })
        .filter((driver) => {
          if (assetFilters.domicileIds?.length) {
            return assetFilters.domicileIds.includes(driver.domicileId);
          }
          return true;
        }),
    [
      assetFilters.destinationCities,
      assetFilters.destinationStates,
      assetFilters.domicileIds,
      assetFilters.originCities,
      assetFilters.originStates,
      assetFilters.trailerTypes,
      assetSearch,
      driversWithAssociatedAssets,
    ]
  );

  const loading =
    getDriverListQuery.isLoading ||
    getShipmentListsQuery.isLoading ||
    assignmentAssetsQuery.isLoading ||
    addTripMutation.isLoading ||
    deleteTripMutation.isLoading ||
    assignTripAssetsMutation.isLoading ||
    changeTripStartTimeMutation.isLoading ||
    getAllDriverShiftsQuery.isLoading ||
    getAllMaintenanceTasksQuery.isLoading ||
    domicilesQuery.isLoading;

  const { showDialog } = useDialog();

  const planDriver = async (
    driverId: string,
    currentShipments: AugmentedShipment[]
  ) => {
    const driverShipments = currentShipments.filter(
      (shipment) => shipment.trip?.driver === driverId
    );

    let earliestChange: Date = new Date(
      driverShipments[0]?.trip?.firstPickupTime
    );
    driverShipments.forEach(async (localShipment) => {
      const originalShipment = shipments.find(
        (shipment) => shipment._id === localShipment._id
      );

      if (!originalShipment) {
        return;
      }

      const tripHasChanged =
        originalShipment.trip?.driver !== localShipment.trip?.driver ||
        originalShipment.trip?.tractor !== localShipment.trip?.tractor ||
        originalShipment.trip?.trailer !== localShipment.trip?.trailer ||
        originalShipment.trip?.firstPickupTime !==
          localShipment.trip?.firstPickupTime;

      if (tripHasChanged && localShipment.trip?.driver) {
        earliestChange = earliestChange
          ? min([
              earliestChange,
              new Date(localShipment.trip.firstPickupTime),
            ]) || new Date(localShipment.trip.firstPickupTime)
          : new Date(localShipment.trip.firstPickupTime);
      }
    });

    if (!earliestChange) {
      return;
    }

    const shipmentsFromEarliestChange = driverShipments.filter(
      (shipment) =>
        new Date(shipment.trip?.firstPickupTime) >= startOfDay(earliestChange)
    );

    const sortedShipmentsFromEarliestChange = sortBy(
      shipmentsFromEarliestChange,
      (shipment) =>
        shipment.trip?.firstPickupTime || shipment.route?.firstPickupTime
    );

    if (shipmentsFromEarliestChange.length <= 0) {
      return;
    }

    const tripsForPlanning: TripForPlanningInput[] =
      sortedShipmentsFromEarliestChange.map((shipment) => {
        const hasMovedForward =
          shipment.previousPickupTime &&
          shipment.trip &&
          shipment.trip.firstPickupTime > shipment.previousPickupTime;
        const hasMovedBackward =
          shipment.previousPickupTime &&
          shipment.trip &&
          shipment.trip.firstPickupTime < shipment.previousPickupTime;
        return {
          shipmentIds: [shipment._id],
          firstPickupTime: shipment.trip?.firstPickupTime,
          lastDropoffTime: shipment.trip?.lastDropoffTime,
          shipmentLocations: (shipment.trip?.shipmentLocations || []).map(
            (location) => ({
              _id: location._id,
              locationType: location.locationType,
              arrivalTime: location.arrivalTime,
              shipmentId: location.shipmentId,
            })
          ),
          canMoveForward: !shipment.previousPickupTime || hasMovedForward,
          canMoveBackward: hasMovedBackward,
        };
      });

    try {
      const { planTripsForDriver: trips } =
        await planTripsForDriverMutation.mutateAsync({
          driverId,
          trips: tripsForPlanning,
        });

      setLocalShipments((localShipments) =>
        localShipments.map((localShipment) => {
          if (driverShipments.some((s) => s._id === localShipment._id)) {
            const tripForShipment = trips.find((trip) =>
              trip.shipments.map((s) => s._id).includes(localShipment._id)
            );
            if (!tripForShipment) {
              return localShipment;
            }
            return {
              ...localShipment,
              trip: {
                ...tripForShipment,
                _id: localShipment.trip?._id || "",
                tripNumber:
                  tripForShipment.tripNumber ||
                  localShipment.trip?.tripNumber ||
                  "NEW",
                driver: tripForShipment.driver?._id,
                tractor: tripForShipment.tractor?._id,
                trailer: tripForShipment.trailer?._id,
                additionalTrailers: tripForShipment.additionalTrailers?.map(
                  (trailer) => trailer._id
                ),
              },
            };
          }
          return localShipment;
        })
      );
    } catch (error) {
      showDialog({
        title: t("common:error.title", "Error"),
        type: "error",
        description: `${t(
          "error.planShipmentError",
          "An error occurred while planning shipments for driver"
        )}. ${
          (error as Error).message ||
          t("common:error.unknownError", "Unknown error")
        }`,
      });
      console.error(error);
    }
  };

  const driverIdBeingPlanned = planTripsForDriverMutation.isLoading
    ? planTripsForDriverMutation.variables?.driverId
    : null;
  const orgSettingsQuery = useGetOrganizationSettingsQuery();

  const orgSettings = orgSettingsQuery.data?.organizationSettings;
  return (
    <Box
      sx={{
        height: "100%",
      }}
    >
      <LoadingOverlay loading={loading} />
      <PlanningTool
        drivers={filteredDrivers}
        tractors={assignmentAssetsQuery.data?.tractors.data || []}
        trailers={assignmentAssetsQuery.data?.trailers.data || []}
        shipments={shipmentsWithEarliestLatestPickup}
        filteredShipments={filteredShipments}
        assetFilters={assetFilters}
        loadFilters={loadFilters}
        driverShifts={getAllDriverShiftsQuery.data?.driverShifts.data || []}
        maintenanceTasks={
          getAllMaintenanceTasksQuery.data?.maintenanceTasks.data.filter(
            (task) => task.required
          ) || []
        }
        domiciles={domicilesQuery.data?.businessEntities.data || []}
        driverIdBeingPlanned={driverIdBeingPlanned}
        onPeriodChange={(period) => {
          setCurrentPeriod(period);
        }}
        onAssetSearchChange={(search) => {
          setAssetSearch(search);
        }}
        onAssetFiltersChange={(filters) => {
          setAssetFilters(filters);
        }}
        onLoadSearchChange={(search) => {
          setLoadSearch(search);
        }}
        onLoadFiltersChange={(filters) => {
          setLoadFilters(filters);
        }}
        onSortChange={(sortCriteria) => {
          setSortCriteria(sortCriteria);
        }}
        onSortOrderDescChange={(sortDesc) => {
          setSortOrderDesc(sortDesc);
        }}
        onShipmentsChange={(augmentedShipments, changedDriver) => {
          const updatedLocalShipments = augmentedShipments.flatMap((s) => [
            s,
            ...s.otherShipmentsInTrip.map((os) => ({
              ...os,
              tripId: s.tripId,
              trip: s.trip,
            })),
          ]);
          setLocalShipments(updatedLocalShipments);
          setHasChanged(true);
          if (changedDriver) {
            planDriver(changedDriver, augmentedShipments);
          }
        }}
        onShipmentsCombine={async (shipmentsToCombine) => {
          try {
            if (
              uniq(shipmentsToCombine.map((shipment) => shipment.trailerType))
                .length > 1
            ) {
              throw new Error(
                t(
                  "error.combineOrdersWithDifferentTrailersError",
                  "Cannot combine orders with different trailer types"
                )
              );
            }
            if (
              shipmentsToCombine.some(
                (shipment) => shipment.loadType === LoadType.FullTruckLoad
              )
            ) {
              throw new Error(
                t(
                  "error.combineFullTruckerLoadError",
                  "Cannot combine full truck load orders"
                )
              );
            }

            const hasShipmentInProgress =
              shipmentsToCombine.length > 1 &&
              shipmentsToCombine.some(
                (shipment) => shipment.status === Status.InProgress
              );

            if (
              hasShipmentInProgress &&
              !orgSettings?.dispatching?.canConsolidateInProgressShipments
            ) {
              if (showDialog) {
                showDialog({
                  title: t("orders:alert.inProgress", "In progress shipments"),
                  description: t(
                    "orders:alert.inProgressDesc",
                    "You can't consolidate in progress shipments"
                  ),
                  type: "primary",
                  actions: [
                    {
                      type: "primary",
                      title: t("common:OK", "OK"),
                    },
                  ],
                });
              }
              return;
            }

            await addTripMutation.mutateAsync({
              newTripData: {
                shipments: shipmentsToCombine.map((shipment) => shipment._id),
              },
            });

            const shipmentListResponse = await getShipmentListsQuery.refetch();

            const newShipments =
              shipmentListResponse.data?.shipments.data || [];

            setLocalShipments((localShipments) =>
              localShipments.map((localShipment) => {
                if (
                  shipmentsToCombine
                    .map((s) => s._id)
                    .includes(localShipment._id)
                ) {
                  return (
                    newShipments.find(
                      (newShipment) => newShipment._id === localShipment._id
                    ) || localShipment
                  );
                }
                return localShipment;
              })
            );
          } catch (error) {
            showDialog({
              title: t("common:error.title", "Error"),
              type: "error",
              description: `${t(
                "error.combineOrdersError",
                "An error occurred while combining orders"
              )}. ${
                (error as Error).message ||
                t("common:error.unknownError", "Unknown error")
              }`,
            });
            console.error(error);
          }
        }}
        onRefresh={async (shipmentIds) => {
          const shipmentListResponse = await getShipmentListsQuery.refetch();

          const newShipments = shipmentListResponse.data?.shipments.data || [];

          setLocalShipments(
            (localShipments) =>
              localShipments
                .map((localShipment) => {
                  if (shipmentIds?.includes(localShipment._id)) {
                    return (
                      newShipments.find(
                        (newShipment) => newShipment._id === localShipment._id
                      ) || null
                    );
                  }
                  return localShipment;
                })
                .filter(Boolean) as (
                | typeof newShipments[0]
                | typeof localShipments[0]
              )[]
          );
        }}
        onSubmit={async () => {
          const earliestChangesByDriver: Record<string, Date> = {};
          try {
            await Promise.all(
              localGroupedShipments.map(async (localShipment) => {
                const originalShipment = shipments.find(
                  (shipment) => shipment._id === localShipment._id
                );

                if (!originalShipment) {
                  return;
                }

                const tripHasChanged =
                  originalShipment.trip?.driver !==
                    localShipment.trip?.driver ||
                  originalShipment.trip?.tractor !==
                    localShipment.trip?.tractor ||
                  originalShipment.trip?.trailer !==
                    localShipment.trip?.trailer ||
                  originalShipment.trip?.firstPickupTime !==
                    localShipment.trip?.firstPickupTime;

                if (tripHasChanged && localShipment.trip?.driver) {
                  earliestChangesByDriver[localShipment.trip.driver] =
                    min([
                      earliestChangesByDriver[localShipment.trip.driver],
                      new Date(localShipment.trip.firstPickupTime),
                    ]) || new Date(localShipment.trip.firstPickupTime);
                }

                if (originalShipment.trip && localShipment.trip) {
                  // If the driver has changed, assign the new driver to the trip
                  if (
                    originalShipment.trip.driver !== localShipment.trip?.driver
                  ) {
                    await assignTripAssetsMutation.mutateAsync({
                      assignmentData: [
                        {
                          tripId: localShipment.trip._id,
                          assetType: TripAssetTypes.Driver,
                          assetId: localShipment.trip.driver,
                        },
                        {
                          tripId: localShipment.trip._id,
                          assetType: TripAssetTypes.Tractor,
                          assetId: localShipment.trip.tractor,
                        },
                        {
                          tripId: localShipment.trip._id,
                          assetType: TripAssetTypes.Trailer,
                          assetId: localShipment.trip.trailer,
                        },
                        {
                          tripId: localShipment.trip._id,
                          assetType: TripAssetTypes.AdditionalTrailers,
                          assetId:
                            localShipment.trip.additionalTrailers?.join(","),
                        },
                      ],
                      ignoreDriverShifts: true,
                    });
                  }

                  // If the pickup time has changed, change the trip's start time
                  if (
                    originalShipment.trip?.firstPickupTime &&
                    localShipment.trip?.firstPickupTime &&
                    !isEqual(
                      new Date(originalShipment.trip.firstPickupTime),
                      new Date(localShipment.trip.firstPickupTime)
                    )
                  ) {
                    await changeTripStartTimeMutation.mutateAsync({
                      changeTripStartTimeData: {
                        tripId: localShipment.trip._id,
                        startTime: localShipment.trip.firstPickupTime,
                        etaFromPreviousTrip:
                          localShipment.trip.etaFromPreviousTrip,
                        etaToNextTrip: localShipment.trip.etaToNextTrip,
                        violations: localShipment.trip.violations,
                      },
                    });
                  }
                } else if (!originalShipment?.trip && localShipment.trip) {
                  // If the driver has been added, assign the driver to the trip
                  await addTripMutation.mutateAsync({
                    newTripData: {
                      shipments: [
                        localShipment._id,
                        ...localShipment.otherShipmentsInTrip.map((s) => s._id),
                      ],
                      driver: localShipment.trip.driver,
                      tractor: localShipment.trip.tractor,
                      trailer: localShipment.trip.trailer,
                      additionalTrailers: localShipment.trip.additionalTrailers,
                      startTime: localShipment.trip.firstPickupTime,
                      etaFromPreviousTrip:
                        localShipment.trip.etaFromPreviousTrip,
                      etaToNextTrip: localShipment.trip.etaToNextTrip,
                      violations: localShipment.trip.violations,
                    },
                    ignoreDriverShifts: true,
                  });
                } else if (originalShipment.trip && !localShipment.trip) {
                  // If the driver has been removed, unassign the driver from the trip
                  await deleteTripMutation.mutateAsync({
                    id: originalShipment.trip._id,
                  });
                } else if (originalShipment.status !== localShipment.status) {
                  await updateShipmentMutation.mutateAsync({
                    id: localShipment._id,
                    editShipmentData: {
                      status: localShipment.status,
                    },
                  });
                }
              })
            );

            await Promise.all([
              getShipmentListsQuery.refetch(),
              getDriverListQuery.refetch(),
            ]);
            setHasChanged(false);
          } catch (error) {
            showDialog({
              title: t("common:error.title", "Error"),
              type: "error",
              description: `${t(
                "error.saveAssignmentsError",
                "An error occurred while saving assignments"
              )}. ${
                (error as Error).message ||
                t("common:error.unknownError", "Unknown error")
              }`,
            });
            console.error(error);
          }
        }}
        onFilteredShipmentsChange={(filteredShipments) => {
          localFilteredShipments.current = filteredShipments;
        }}
        onAutoPlanSubmit={async (result) => {
          const trips = result.trips;
          const unplannedShipments = result.unplannedShipments;
          const unplannedShipmentsIds = unplannedShipments.map(
            (shipment) => shipment._id
          );

          setLocalShipments((localShipments) =>
            localShipments.map((localShipment) => {
              if (unplannedShipmentsIds.includes(localShipment._id)) {
                return {
                  ...localShipment,
                  status: Status.Unplanned,
                };
              }
              const tripForShipment = trips.find((trip) =>
                trip.shipments.map((s) => s._id).includes(localShipment._id)
              );
              if (!tripForShipment) {
                return localShipment;
              }
              return {
                ...localShipment,
                tripId: tripForShipment._id || localShipment.trip?._id || null,
                trip: {
                  ...tripForShipment,
                  _id: tripForShipment._id || localShipment.trip?._id || "",
                  tripNumber:
                    tripForShipment.tripNumber ||
                    localShipment.trip?.tripNumber ||
                    "NEW",
                  driver: tripForShipment.driver?._id,
                  tractor: tripForShipment.tractor?._id,
                  trailer: tripForShipment.trailer?._id,
                },
                status: tripForShipment
                  ? Status.Assigned
                  : localShipment.status,
              };
            })
          );
        }}
        isAutoPlanning={generateTripsForShipmentsMutation.isLoading}
        {...props}
      />
    </Box>
  );
};

export default PlanningToolContainer;
