import {
  Box,
  Checkbox,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import {
  GoodDistributionInput,
  GoodInput,
  StorageFacility,
} from "../../../../graphql/generated";
import IconButton from "@mui/material/IconButton";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import InputAdornment from "@mui/material/InputAdornment";
import { ShipmentLocationInputData } from "../../../../redux/slices/Types";
import { kgToLocalWeight } from "../../../../utils/conversion/weight";
import LocaleProvider from "../../../../providers/LocaleProvider";
import { sum } from "lodash";
import goodUnitLabel from "../../../../utils/labels/goodUnitsLabel";
import { useTranslation } from "react-i18next";
import LoadingOverlay from "../../../common/LoadingOverlay";
import useDialog from "../../../../utils/hooks/useDialog";

export interface ReceiverGoodsListProps {
  shippedGoods: Array<GoodInput>;
  receivedGoods: Array<GoodDistributionInput>;
  shipmentLocation: ShipmentLocationInputData & {
    persistedReceivedGoods?: Array<GoodDistributionInput>;
  };
  storageFacilities?: StorageFacility[];
  onChange: (receivedGoods: Array<GoodDistributionInput>) => void;
  storageFacilitiesLoading?: boolean;
  onRequestBestBuyRanking?: (input: {
    date: string;
    goodProfileId: string;
    quantity: number;
  }) => void;
}

export interface Columns {
  label: string;
  field: string;
  align?: boolean;
  disablePadding?: boolean;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export default function ReceiverGoodsList({
  shippedGoods,
  receivedGoods,
  shipmentLocation,
  storageFacilities,
  onChange,
  storageFacilitiesLoading,
  onRequestBestBuyRanking,
}: ReceiverGoodsListProps) {
  const { t } = useTranslation(["common", "orders"]);
  const { showDialog } = useDialog();

  const columns: Array<Columns> = [
    {
      label: t("common:goods", "Goods"),
      field: "label",
    },
    {
      label: t(`common:weightWithUnit`, {
        unit: LocaleProvider.getWeightUnit(),
        defaultValue: `Weight (${LocaleProvider.getWeightUnit()})`,
      }),
      field: "weight",
      align: true,
    },
    {
      label: t("common:quantity", "Quantity"),
      field: "quantity",
      align: true,
    },
    {
      label: t("common:unit", "Unit"),
      field: "unit",
    },
  ];

  const toggleGood = (goodId: string, toggled: boolean) => {
    const shippedGood = shippedGoods.find((good) => good._id === goodId);
    if (!shippedGood) {
      return;
    }
    if (toggled) {
      const shippedQuantity = shippedGood.quantity;
      const alreadyDeliveredQuantity = sum(
        receivedGoods
          .filter((rg) => rg.goodId === goodId)
          .map((receivedGood) => receivedGood.quantity || shippedGood.quantity)
      );
      const myReceivedGood = shipmentLocation.persistedReceivedGoods?.find(
        (receivedGood) => receivedGood.goodId === goodId
      );
      const myReceivedQty = myReceivedGood
        ? myReceivedGood.quantity || shippedGood.quantity
        : 0;
      onChange(
        shipmentLocation.receivedGoods.concat({
          goodId,
          quantity:
            shippedQuantity === Infinity
              ? 1
              : Math.max(
                  shippedQuantity - alreadyDeliveredQuantity + myReceivedQty,
                  0
                ),
          goodProfileId: shippedGood.goodProfileId,
        })
      );
    } else {
      onChange(
        shipmentLocation.receivedGoods.filter(
          (receivedGood) => receivedGood.goodId !== goodId
        )
      );
    }
  };

  const setReceivedGoodQuantity = (goodId: string, quantity: number | null) => {
    const shippedGood = shippedGoods.find((good) => good._id === goodId);
    if (!shippedGood) {
      return;
    }
    onChange(
      shipmentLocation.receivedGoods.map((receivedGood) => {
        if (receivedGood.goodId === goodId) {
          return {
            ...receivedGood,
            quantity:
              quantity === null
                ? null
                : Math.max(Math.min(quantity, shippedGood.quantity), 1),
          };
        }
        return receivedGood;
      })
    );
  };

  if (storageFacilitiesLoading) {
    return <LoadingOverlay loading={storageFacilitiesLoading} />;
  }

  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby="goodList">
          <TableHead>
            <TableRow>
              <StyledTableCell padding="checkbox"></StyledTableCell>
              {columns.map((col, index) => (
                <StyledTableCell
                  key={index}
                  align={col.align ? "right" : "left"}
                  padding={col.disablePadding ? "none" : "normal"}
                >
                  {col.label}
                </StyledTableCell>
              ))}
              <StyledTableCell
                key={columns.length - 1}
                align="right"
                padding="normal"
              >
                Actions
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {shippedGoods.map((good, goodIndex) => {
              const receivedGood = shipmentLocation.receivedGoods.find(
                (receivedGood) => receivedGood.goodId === good._id
              );
              const hasStorageFacility =
                !storageFacilities?.length ||
                storageFacilities.some(
                  (storageFacility) =>
                    storageFacility.commodityId === good.goodProfileId
                );
              if (good.goodProfileId && !hasStorageFacility) {
                return null;
              }
              return (
                <TableRow
                  key={good._id}
                  role="checkbox"
                  tabIndex={-1}
                  selected={!!receivedGood}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={!!receivedGood}
                      onChange={(event, checked) =>
                        toggleGood(good._id, checked)
                      }
                      inputProps={{
                        "aria-label": `${good.label} checkbox`,
                      }}
                    />
                  </TableCell>
                  <TableCell scope="row" padding="none">
                    {good.label}
                  </TableCell>
                  <TableCell align="right">
                    {kgToLocalWeight(good.weight || 0).toFixed(2)}
                  </TableCell>
                  <TableCell align="right" sx={{ width: "30%" }}>
                    <TextField
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton
                              disabled={!receivedGood}
                              onClick={() => {
                                if (!receivedGood) {
                                  return;
                                }
                                setReceivedGoodQuantity(
                                  good._id,
                                  (receivedGood.quantity || good.quantity) - 1
                                );
                              }}
                              aria-label={`Decrease ${good.label} quantity`}
                              color="inherit"
                            >
                              <RemoveIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              disabled={!receivedGood}
                              onClick={() => {
                                if (!receivedGood) {
                                  return;
                                }
                                setReceivedGoodQuantity(
                                  good._id,
                                  (receivedGood.quantity || good.quantity) + 1
                                );
                              }}
                              aria-label={`Increase ${good.label} quantity`}
                              color="inherit"
                            >
                              <AddIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      size="small"
                      disabled={!receivedGood}
                      value={receivedGood?.quantity || ""}
                      placeholder={
                        good.quantity === Infinity
                          ? undefined
                          : String(good.quantity)
                      }
                      inputProps={{
                        "aria-label": `${good.label} quantity`,
                      }}
                      onChange={(event) => {
                        setReceivedGoodQuantity(
                          good._id,
                          parseInt(event.target.value) || null
                        );
                      }}
                      name="commodity-quantity"
                      id={`commodity-quantity-${goodIndex}`}
                    />
                  </TableCell>
                  <TableCell>{goodUnitLabel(good.unit)}</TableCell>
                  <TableCell>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                      {!!receivedGood ? (
                        <Button
                          variant="contained"
                          color="accent"
                          onClick={() => {
                            if (
                              shipmentLocation.receiver &&
                              good.goodProfileId &&
                              shipmentLocation.timeWindows[0]?.fromDate
                            ) {
                              onRequestBestBuyRanking?.({
                                date: shipmentLocation.timeWindows[0].fromDate,
                                goodProfileId: good.goodProfileId,
                                quantity: receivedGood.quantity || 0,
                              });
                            } else {
                              showDialog({
                                type: "primary",
                                title: t("common:error.title", "Error"),
                                description: t(
                                  "orders:bestbuy.requirementError",
                                  "Please, make sure to select a delivery date"
                                ),
                              });
                            }
                          }}
                        >
                          {t("orders:bestbuy.title", "Best Buy")}
                        </Button>
                      ) : null}
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
