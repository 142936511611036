import { GoodUnits } from "../../graphql/generated";
import LocaleProvider, { Locale } from "../../providers/LocaleProvider";

export function localWeightToKg(valueInLocalWeight: number) {
  if (LocaleProvider.getLocale() === Locale.EN_US) {
    return valueInLocalWeight * 0.453592;
  }
  return valueInLocalWeight;
}

export function kgToLocalWeight(valueInKg: number) {
  if (LocaleProvider.getLocale() === Locale.EN_US) {
    return valueInKg / 0.453592;
  }
  return valueInKg;
}

export function tonnage(valueInKg: number) {
  // US Ton
  if (LocaleProvider.getLocale() === Locale.EN_US) {
    return Number.parseFloat((valueInKg / 907.185).toFixed(2));
  }
  // Metric ton
  return valueInKg / 1000;
}

export function poundsToKg(valueInPounds: number) {
  return valueInPounds * 0.453592;
}

export function kgToPounds(valueInKg: number) {
  return valueInKg / 0.453592;
}

export function formatWeightForUnit(valueInKg: number, unit: GoodUnits) {
  if (unit === GoodUnits.Gallons || unit === GoodUnits.Pounds) {
    return kgToPounds(valueInKg).toFixed(2);
  }
  return String(valueInKg);
}
