import { useSnackbar } from "notistack";
import {
  useGetOrganizationSettingsQuery,
  useSaveOrganizationSettingsMutation,
} from "../../../graphql/generated";
import useDialog from "../../../utils/hooks/useDialog";
import ErrorMessage from "../../common/ErrorMessage/ErrorMessage";
import LoadingOverlay from "../../common/LoadingOverlay";
import OrgSettingsEditor from "./OrgSettingsEditor";
import LocaleProvider from "../../../providers/LocaleProvider";
import { useTranslation } from "react-i18next";

const OrgSettingsEditorContainer = () => {
  const { t } = useTranslation(["settings", "common"]);
  const organizationSettingsQuery = useGetOrganizationSettingsQuery(
    {},
    {
      refetchOnWindowFocus: false,
    }
  );

  const saveOrganizationSettingsMutation =
    useSaveOrganizationSettingsMutation();

  const organizationSettings =
    organizationSettingsQuery.data?.organizationSettings;

  const { showDialog } = useDialog();
  const { enqueueSnackbar } = useSnackbar();

  if (organizationSettingsQuery.isLoading) {
    return <LoadingOverlay loading />;
  }

  if (organizationSettingsQuery.error) {
    return <ErrorMessage error={organizationSettingsQuery.error} />;
  }

  return (
    <OrgSettingsEditor
      organizationSettings={organizationSettings || null}
      onSave={async (settings) => {
        if (!settings) {
          return;
        }
        try {
          await saveOrganizationSettingsMutation.mutateAsync({
            orgSettings: settings,
          });
          enqueueSnackbar(t("settingsSaved", "Settings saved"));
          if (
            settings.general?.measurementSystem !==
            organizationSettings?.general?.measurementSystem
          ) {
            if (settings.general?.measurementSystem) {
              LocaleProvider.setMeasurementSystem(
                settings.general.measurementSystem
              );
            }
            window.location.reload();
          }
          if (
            settings.general?.language !==
            organizationSettings?.general?.language
          ) {
            if (settings.general?.language) {
              LocaleProvider.setLanguage(settings.general.language);
            }
            window.location.reload();
          }
          await organizationSettingsQuery.refetch();
        } catch (error) {
          console.error(error);
          showDialog({
            title: t("common:error.title", "Error"),
            description:
              t("saveError", "Unable to save organization settings. ") +
                (error as Error | undefined)?.message || "Unknown error",
            type: "error",
          });
        }
      }}
    />
  );
};

export default OrgSettingsEditorContainer;
