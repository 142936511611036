import { Autocomplete, TextField } from "@mui/material";
import { isString } from "lodash";
import {
  CustomFieldInputComponent,
  CustomFieldInputProps,
} from "../CustomFieldsForm";
import {
  CustomFieldSelectOption,
  CustomFieldSelectOptionEntity,
  useGetDriverListQuery,
  useGetTractorListQuery,
  useGetTrailerListQuery,
} from "../../../../graphql/generated";
import driverLabel from "../../../../utils/labels/driverLabel";
import trailerLabel from "../../../../utils/labels/trailerLabel";
import tractorLabel from "../../../../utils/labels/tractorLabel";
import { useEffect, useState } from "react";

export const getCustomFieldOptions = async (
  customFieldDefinition: CustomFieldInputProps["customFieldDefinition"]
): Promise<CustomFieldSelectOption[]> => {
  if (customFieldDefinition.selectOptionsEntity) {
    if (
      customFieldDefinition.selectOptionsEntity ===
      CustomFieldSelectOptionEntity.Driver
    ) {
      const {
        drivers: { data },
      } = await useGetDriverListQuery.fetcher()();
      return data.map((driver) => ({
        key: driver._id,
        label: driverLabel(driver),
      }));
    }

    if (
      customFieldDefinition.selectOptionsEntity ===
      CustomFieldSelectOptionEntity.Tractor
    ) {
      const {
        tractors: { data },
      } = await useGetTractorListQuery.fetcher()();
      return data.map((tractor) => ({
        key: tractor._id,
        label: tractorLabel(tractor),
      }));
    }

    if (
      customFieldDefinition.selectOptionsEntity ===
      CustomFieldSelectOptionEntity.Trailer
    ) {
      const {
        trailers: { data },
      } = await useGetTrailerListQuery.fetcher()();
      return data.map((trailer) => ({
        key: trailer._id,
        label: trailerLabel(trailer),
      }));
    }
  }

  return customFieldDefinition.selectOptions || [];
};

const SelectCustomFieldInput: CustomFieldInputComponent = ({
  value,
  customFieldDefinition,
  onChange,
}) => {
  const [options, setOptions] = useState<CustomFieldSelectOption[]>([]);

  useEffect(() => {
    getCustomFieldOptions(customFieldDefinition).then(setOptions);
  }, [customFieldDefinition]);

  return (
    <Autocomplete
      options={options.map((option) => option.key) || []}
      getOptionLabel={(optionKey) =>
        options.find((selectOption) => selectOption.key === optionKey)?.label ||
        ""
      }
      onChange={(event, value) => {
        onChange(value);
      }}
      value={isString(value) ? value : null}
      size="medium"
      fullWidth
      renderInput={(inputProps) => (
        <TextField label={customFieldDefinition.label} {...inputProps} />
      )}
    />
  );
};

export default SelectCustomFieldInput;
