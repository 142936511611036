import { useGetOwnedTrailerTypesQuery } from "../../../../graphql/generated";
import TrailerRequirementsForm, {
  TrailerRequirementsFormProps,
} from "./TrailerRequirementsForm";

type TrailerRequirementsFormContainerProps = Omit<
  TrailerRequirementsFormProps,
  "ownedTrailerTypes"
>;

const TrailerRequirementsFormContainer = (
  props: TrailerRequirementsFormContainerProps
) => {
  const ownedTrailerTypesQuery = useGetOwnedTrailerTypesQuery();
  return (
    <TrailerRequirementsForm
      {...props}
      ownedTrailerTypes={ownedTrailerTypesQuery.data?.ownedTrailerTypes || []}
    />
  );
};

export default TrailerRequirementsFormContainer;
