import {
  useGetGoodProfileListQuery,
  useGetReceiverListWithStorageFacilitiesQuery,
} from "../../../../graphql/generated";
import TrailerCompartmentAssignmentForm, {
  TrailerCompartmentAssignmentFormProps,
} from "./TrailerCompartmentAssignmentForm";

type TrailerCompartmentAssignmentFormContainerProps = Omit<
  TrailerCompartmentAssignmentFormProps,
  "receivers" | "goodProfiles"
>;

const TrailerCompartmentAssignmentFormContainer: React.FC<TrailerCompartmentAssignmentFormContainerProps> =
  (props) => {
    const receiversListWithStorageFacilitiesQuery =
      useGetReceiverListWithStorageFacilitiesQuery();

    const goodProfilesQuery = useGetGoodProfileListQuery();

    return (
      <TrailerCompartmentAssignmentForm
        receivers={
          receiversListWithStorageFacilitiesQuery.data?.businessEntities.data ||
          []
        }
        goodProfiles={goodProfilesQuery.data?.goodProfiles.data || []}
        {...props}
      />
    );
  };

export default TrailerCompartmentAssignmentFormContainer;
