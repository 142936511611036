import { useGetTodayTripsQuery } from "../../../graphql/generated";
import LiveTripsTracker from "./LiveTripsTracker";

const LiveTripsTrackerContainer = () => {
  const getTodayTripsQuery = useGetTodayTripsQuery(
    {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    {
      refetchInterval: 5000,
    }
  );

  const onRefetch = () => {
    getTodayTripsQuery.refetch();
  };

  return (
    <LiveTripsTracker
      trips={getTodayTripsQuery.data?.trips.data || []}
      onRefetch={onRefetch}
    />
  );
};

export default LiveTripsTrackerContainer;
