import { Autocomplete, Chip, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

type TagsInputProps = {
  value: string[];
  onChange: (value: string[]) => void;
};

const ChipTagsInput = ({ value, onChange }: TagsInputProps) => {
  const { t } = useTranslation("common");
  return (
    <Autocomplete<string, true, false, true>
      value={value}
      clearIcon={false}
      options={[]}
      filterOptions={(options, params) => {
        const { inputValue } = params;
        if (inputValue !== "") {
          return [inputValue];
        }

        return [];
      }}
      freeSolo
      multiple
      getOptionLabel={(option) => {
        return `${t("add")} "${option}"`;
      }}
      renderTags={(value, props) =>
        value.map((option, index) => (
          <Chip label={option} {...props({ index })} />
        ))
      }
      renderInput={(params) => (
        <TextField
          name="addTags"
          label={t("addTags", "Add tags")}
          {...params}
        />
      )}
      onChange={(_, newValue) => {
        onChange(newValue as string[]);
      }}
    />
  );
};

export default ChipTagsInput;
