import { TrailerType } from "../../../../graphql/generated";
import conestoga from "../../../../assets/icons/conestoga.svg";

interface trailerIconType {
  label: TrailerType;
  icon: string;
}

const trailerIcons: Array<trailerIconType> = [
  {
    label: TrailerType.Conestoga,
    icon: conestoga,
  },
];

export default function TrailerTypeIcon({
  type,
  alt,
}: {
  type: TrailerType;
  alt?: string;
}) {
  // todo : add a default icon to return when trailer type icon is not available
  return (
    <>
      <img
        alt={alt || `${type} trailer icon`}
        src={
          trailerIcons.find((l) => l.label === type)?.icon ||
          trailerIcons[0].icon
        }
      />
    </>
  );
}
