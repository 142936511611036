import { toArray } from "lodash";
import LogRocket from "logrocket";
import { useEffect } from "react";
import { getOrganizationId, useAuth } from "../../../providers/AuthProvider";
import setupLogRocketReact from "logrocket-react";

const LogRocketProvider = () => {
  const { userInfo } = useAuth();
  useEffect(() => {
    if (window.origin.includes("localhost")) {
      return;
    }
    LogRocket.init("r10sep/truetms-web", {
      rootHostname: window.location.host.includes("truetms.com")
        ? "truetms.com"
        : "tecafrik.com",
    });
    setupLogRocketReact(LogRocket);
  }, []);
  useEffect(() => {
    if (window.origin.includes("localhost")) {
      return;
    }
    if (userInfo) {
      LogRocket.identify(userInfo.sub, {
        email: userInfo.email,
        name: userInfo.name,
        organizationId: getOrganizationId(userInfo),
        organizationName: toArray(userInfo.organization)[0]?.name || "N/A",
      });
    }
  }, [userInfo]);
  if (!userInfo) {
    return null;
  }
  return <></>;
};

export default LogRocketProvider;
