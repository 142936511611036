import {
  DoneAll,
  ForwardToInbox,
  Loop,
  PanTool,
  RestorePage,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Stack,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import { round, sum } from "lodash";
import { useMemo, useState } from "react";
import {
  ExtensionOperationContext,
  GetInvoiceListQuery,
  InvoiceStatus,
} from "../../../graphql/generated";
import LocaleProvider from "../../../providers/LocaleProvider";
import useDialog from "../../../utils/hooks/useDialog";
import { TableField } from "../../common/LynksTable/LynksTable";
import ShipmentLink from "../../common/ShipmentLink";
import SmartLynksTable, {
  DeleteMutation,
  QueryFn,
} from "../../common/SmartLynksTable/SmartLynksTable";
import { useTranslation } from "react-i18next";

export type InvoiceListItemData = GetInvoiceListQuery["invoices"]["data"][0];
type InvoiceListProps = {
  queryHook: QueryFn<InvoiceListItemData, "invoices">;
  deleteMutation: DeleteMutation;
  onSendInvoice: (invoice: InvoiceListItemData) => void;
  onInvoiceStatusUpdate: (
    invoice: InvoiceListItemData,
    status: InvoiceStatus,
    closingNote?: string
  ) => void;
};

export enum InvoiceStatusTab {
  NOT_INVOICED,
  IN_PROCESS,
  PROCESSED,
  ON_HOLD,
  HISTORY,
}

const statusToInvoiceStatus: {
  [key in InvoiceStatusTab]: InvoiceStatus | null;
} = {
  [InvoiceStatusTab.NOT_INVOICED]: InvoiceStatus.NotInvoiced,
  [InvoiceStatusTab.IN_PROCESS]: InvoiceStatus.Draft,
  [InvoiceStatusTab.PROCESSED]: InvoiceStatus.Sent,
  [InvoiceStatusTab.ON_HOLD]: InvoiceStatus.OnHold,
  [InvoiceStatusTab.HISTORY]: InvoiceStatus.Closed,
};

const InvoiceList = ({
  queryHook: query,
  deleteMutation,
  onSendInvoice,
  onInvoiceStatusUpdate,
}: InvoiceListProps) => {
  const { showDialog, hideDialog } = useDialog();
  const [closingNoteModalOpen, setClosingNoteModalOpen] = useState(false);
  const [selectedInvoiceForClosing, setSelectedInvoiceForClosing] =
    useState<InvoiceListItemData | null>(null);
  const [closingNote, setClosingNote] = useState("");
  const [statusTab, setStatusTab] = useState<InvoiceStatusTab>(
    InvoiceStatusTab.NOT_INVOICED
  );
  const { t } = useTranslation(["finance", "common"]);

  const fields: Array<TableField<InvoiceListItemData, InvoiceStatus>> = useMemo(
    () => [
      {
        label: t("order"),
        type: "custom",
        value: (invoice) =>
          invoice.shipment ? (
            <ShipmentLink shipment={invoice.shipment} />
          ) : null,
      },
      {
        label: t("invoiceNumber"),
        type: "string",
        value: (invoice) =>
          invoice.status !== InvoiceStatus.NotInvoiced
            ? invoice.invoiceNumber
            : "",
      },
      {
        label: t("amount"),
        type: "string",
        value: (invoice) =>
          invoice.status !== InvoiceStatus.NotInvoiced
            ? `${LocaleProvider.getCurrencySymbol()} ${round(
                sum(invoice.charges.map((charge) => charge.unit * charge.rate)),
                2
              )}`
            : "",
      },
      {
        label: t("customer"),
        type: "custom",
        value: (invoice) =>
          invoice.customer ? (
            <Link href={`/customers/details/${invoice.customer._id}`}>
              {invoice.customer.name}
            </Link>
          ) : null,
      },
      {
        label: t("document"),
        type: "custom",
        value: (invoice) =>
          invoice.status !== InvoiceStatus.NotInvoiced &&
          invoice.pdfDocument.url ? (
            <>
              <Link
                target="_blank"
                component="a"
                href={invoice.pdfDocument.url}
              >
                Open
              </Link>
            </>
          ) : null,
      },
      // {
      //   label: "Status",
      //   type: "enum",
      //   value: (invoice) => invoice.status,
      //   colors: {
      //     [InvoiceStatus.Draft]: "warning",
      //     [InvoiceStatus.Sent]: "primary",
      //     [InvoiceStatus.Paid]: "success",
      //     [InvoiceStatus.Cancelled]: "error",
      //     [InvoiceStatus.Closed]: "success",
      //   },
      // },
      {
        label: t("closingNote"),
        type: "string",
        value: "closingNote",
      },
    ],
    [t]
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Tabs
          value={statusTab}
          onChange={(event, value) => {
            setStatusTab(value);
          }}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
        >
          <Tab label={t("notInvoiced")} value={InvoiceStatusTab.NOT_INVOICED} />
          <Tab label={t("inProcess")} value={InvoiceStatusTab.IN_PROCESS} />
          <Tab label={t("processed")} value={InvoiceStatusTab.PROCESSED} />
          <Tab label={t("onHold")} value={InvoiceStatusTab.ON_HOLD} />
          <Tab label={t("history")} value={InvoiceStatusTab.HISTORY} />
        </Tabs>
      </Box>
      <SmartLynksTable
        query={query}
        deleteMutation={deleteMutation}
        dataKey="invoices"
        id="invoice-list"
        fields={fields}
        extensionOperationContext={ExtensionOperationContext.InvoiceDetails}
        additionalQueryVariables={{
          status: statusToInvoiceStatus[statusTab],
        }}
        actions={[
          {
            icon: <ForwardToInbox />,
            tooltip: t("sendInvoice"),
            isApplicable: (invoice) =>
              invoice.status !== InvoiceStatus.NotInvoiced,
            onClick: (invoice) => {
              if (!invoice.customer?.contact.email) {
                showDialog({
                  type: "error",
                  title: t("common:error.title"),
                  description: t("customerNoEmail"),
                });
                return;
              }
              showDialog({
                type: "primary",
                title: t("sendInvoice"),
                description: t("sendInvoiceDescription", {
                  customerName: invoice.customer.name,
                  customerEmail: invoice.customer.contact.email,
                }),
                actions: [
                  {
                    title: t("common:cancel"),
                    type: "secondary",
                  },
                  {
                    title: t("common:send", "Send"),
                    type: "primary",
                    onClick: () => {
                      onSendInvoice(invoice);
                      hideDialog();
                    },
                  },
                ],
              });
            },
          },
          {
            icon: <Loop />,
            tooltip: t("processInvoice"),
            isApplicable: (invoice) =>
              invoice.status === InvoiceStatus.Draft ||
              invoice.status === InvoiceStatus.OnHold,
            onClick: (invoice) => {
              onInvoiceStatusUpdate(invoice, InvoiceStatus.Sent);
            },
          },
          {
            icon: <PanTool />,
            tooltip: t("putInvoiceOnHold"),
            isApplicable: (invoice) =>
              invoice.status === InvoiceStatus.Draft ||
              invoice.status === InvoiceStatus.Sent,
            onClick: (invoice) => {
              onInvoiceStatusUpdate(invoice, InvoiceStatus.OnHold);
            },
          },
          {
            icon: <DoneAll />,
            tooltip: t("closeInvoice"),
            isApplicable: (invoice) =>
              invoice.status !== InvoiceStatus.Closed &&
              invoice.status !== InvoiceStatus.NotInvoiced,
            onClick: (invoice) => {
              setSelectedInvoiceForClosing(invoice);
              setClosingNoteModalOpen(true);
            },
          },
          {
            icon: <RestorePage />,
            isApplicable: (invoice) => invoice.status === InvoiceStatus.Closed,
            tooltip: t("reopenInvoice"),
            onClick: (invoice) => {
              onInvoiceStatusUpdate(invoice, InvoiceStatus.Draft, "");
            },
          },
        ]}
      />
      <Dialog open={closingNoteModalOpen}>
        <DialogTitle>{t("invoiceClosingNote")}</DialogTitle>
        <DialogContent
          sx={{
            pt: 1,
          }}
        >
          <Stack sx={{ mt: 1 }}>
            <TextField
              label={t("closingNote")}
              value={closingNote}
              onChange={(e) => {
                setClosingNote(e.target.value);
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setClosingNoteModalOpen(false);
              setClosingNote("");
            }}
          >
            {t("common:cancel")}
          </Button>
          <Button
            onClick={() => {
              if (!selectedInvoiceForClosing) {
                return;
              }
              setClosingNoteModalOpen(false);
              onInvoiceStatusUpdate(
                selectedInvoiceForClosing,
                InvoiceStatus.Closed,
                closingNote
              );
              setClosingNote("");
            }}
            variant="contained"
            id="save-closing-note"
          >
            {t("common:save")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default InvoiceList;
