import { RankSupplierContractsForPurchaseMutation } from "../../../graphql/generated";
import LynksTable from "../../common/LynksTable";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TableField } from "../../common/LynksTable/LynksTable";
import LocaleProvider from "../../../providers/LocaleProvider";
import { capitalize } from "lodash";

export type BestBuyRankingListItem =
  RankSupplierContractsForPurchaseMutation["rankSupplierContractsForPurchase"][0];

export type BestBuyRankingListProps = {
  list: RankSupplierContractsForPurchaseMutation["rankSupplierContractsForPurchase"];
  onBestBuyItemSelect: (item: BestBuyRankingListItem & { _id: string }) => void;
  selected?: BestBuyRankingListItem & { _id: string };
};

export default function BestBuyRankingList({
  list,
  onBestBuyItemSelect,
  selected,
}: BestBuyRankingListProps) {
  const { t } = useTranslation(["orders", "business", "common"]);

  const fields: Array<TableField<BestBuyRankingListItem & { _id: string }>> =
    useMemo(
      () => [
        {
          value: (item) => String(Number(item._id) + 1),
          label: t("orders:bestbuy.costPerMile", "Rank"),
          type: "string",
        },
        {
          value: (item) => String(item.supplierContract.supplier?.name),
          label: capitalize(t("business:supplier.one", "Supplier")),
          type: "string",
        },
        {
          value: (item) => String(item.supplierContract.shipper?.name),
          label: capitalize(t("business:shipper.one", "Shipper")),
          type: "string",
        },
        {
          // Need backend to return this in meters instead of miles
          value: (item) => item.mileage.toFixed(2),
          label: t("orders:distance", "Distance"),
          type: "string",
        },
        {
          value: (item) =>
            `${Math.round(
              item.productCost
            )} ${LocaleProvider.getCurrencySymbol()}`,
          label: t("orders:bestbuy.productCost", "Product Cost"),
          type: "string",
        },
        {
          value: (item) =>
            `${Math.round(
              item.shippingCost
            )} ${LocaleProvider.getCurrencySymbol()}`,
          label: t("orders:bestbuy.shippingCost", "Shipping Cost"),
          type: "string",
        },
        {
          value: (item) =>
            `${Math.round(
              item.totalCost
            )} ${LocaleProvider.getCurrencySymbol()}`,
          label: t("orders:bestbuy.totalCost", "Total Cost"),
          type: "string",
        },
      ],
      [t]
    );

  return (
    <LynksTable
      fields={fields}
      id="best-buy-ranking-list"
      data={(list || []).map((el, index) => ({
        _id: String(index),
        ...el,
      }))}
      onSelect={(records) => onBestBuyItemSelect(records[0])}
      disableMultipleRowSelection
      selected={selected ? [selected] : undefined}
    />
  );
}
