import { Stack, TextField } from "@mui/material";
import { NewStorageFacilityReadingInput } from "../../../graphql/generated";
import { DateTimePicker } from "@mui/x-date-pickers";
import NumberTextField from "../../common/NumberTextField";

type ReadingFormProps = {
  reading: NewStorageFacilityReadingInput;
  onReadingChange: (reading: NewStorageFacilityReadingInput) => void;
};

const ReadingForm = ({ reading, onReadingChange }: ReadingFormProps) => {
  return (
    <Stack spacing={2}>
      <DateTimePicker
        label="Reading Date"
        ampm={false}
        value={reading.date}
        onChange={(value) => onReadingChange({ ...reading, date: value })}
        renderInput={(params) => <TextField {...params} />}
      />
      <NumberTextField
        label="Reading Value"
        value={reading.level}
        onChange={(event) =>
          onReadingChange({ ...reading, level: parseFloat(event.target.value) })
        }
      />
    </Stack>
  );
};

export default ReadingForm;
