import {
  AssetStatus,
  DriverLicenseClassification,
  DriverLicenseEndorsement,
} from "../../graphql/generated";
import domicileMapping from "./domicile";
import { ExcelMapping } from "./mappingTypes";

const driversMapping: ExcelMapping = {
  "First Name": { key: "firstname", type: "string", required: true },
  "Last Name": { key: "lastname", type: "string", required: true },
  "Middle Name": { key: "middlename", type: "string" },
  "Phone Number": { key: "phoneNumber", type: "string" },
  ...domicileMapping,
  Status: {
    key: "status",
    type: "enum",
    enumOptions: Object.values(AssetStatus),
  },
  "Date of birth": { key: "dateOfBirth", type: "date" },
  "Date of physical examination": {
    key: "dateOfPhysicalExamination",
    type: "date",
  },
  "Owner operator": { key: "isOwnerOperator", type: "boolean" },

  "License number": {
    key: "license.licenseNumber",
    type: "string",
    required: true,
  },
  "License issued state": {
    key: "license.issuedState",
    type: "string",
    required: true,
  },
  "License issued date": {
    key: "license.issuedDate",
    type: "date",
    required: true,
  },
  "License expiry date": {
    key: "license.expiryDate",
    type: "date",
    required: true,
  },
  "License classification": {
    key: "license.classification",
    type: "enum",
    enumOptions: Object.values(DriverLicenseClassification),
    required: true,
  },
  "License endorsements": {
    key: "license.endorsements",
    type: "[enum]",
    enumOptions: Object.values(DriverLicenseEndorsement),
  },
  "License restriction": {
    key: "license.restriction",
    type: "enum",
  },
  "License address": { key: "license.address", type: "textualAddress" },

  "Medical number": { key: "medical.number", type: "string" },
  "Medical ID issued date": { key: "medical.issuedDate", type: "date" },
  "Medical ID expiry date": { key: "medical.expiryDate", type: "date" },
  "Medical ID restriction": { key: "medical.restriction", type: "string" },

  Email: { key: "email", type: "string" },
  Password: { key: "password", type: "string", required: true },
};

export default driversMapping;
