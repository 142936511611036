import {
  TripSortCriteria,
  AssetStatus,
  GetQualificationsQuery,
  TripAssetTypes,
  Scalars,
} from "../../../graphql/generated";
import { TableField } from "../../common/LynksTable/LynksTable";
import SmartLynksTable, {
  DeleteMutation,
  QueryFn,
} from "../../common/SmartLynksTable/SmartLynksTable";
// import qualificationSchema from "../QualificationForm/qualificationSchema";
// import qualificationsMapping from "../../../utils/mappin-s/qualifications";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
} from "@mui/material";
import { useState } from "react";
import { useRoles } from "../../account/Access/RoleGuard";
import { capitalize } from "lodash";
import QualificationFormContainer from "../QualificationForm";

type QualificationListItem =
  GetQualificationsQuery["qualifications"]["data"][0];

enum QualificationStatus {
  CURRENT = "CURRENT",
  COMPLETED = "COMPLETED",
}

export type QualificationListProps = {
  query: QueryFn<QualificationListItem, "qualifications", TripSortCriteria>;
  deleteMutation: DeleteMutation;
  onRefresh: () => void;
  assetType: TripAssetTypes;
  assetId: Scalars["ObjectId"];
};

export default function QualificationsList({
  query,
  deleteMutation,
  onRefresh,
  assetType,
  assetId,
}: QualificationListProps) {
  // const { t } = useTranslation("users");
  const [formOpen, setFormOpen] = useState(false);
  const [selectedQualification, setSelectedQualification] =
    useState<QualificationListItem | null>(null);

  const roles = useRoles();

  const hasWriteAccess = roles.some((role) =>
    ["Carrier Admin", "Manager", "Dispatcher"].includes(role)
  );

  const fields: TableField<QualificationListItem, AssetStatus>[] = [
    {
      value: "label",
      label: "Type",
      type: "string",
    },
    {
      value: "number",
      label: "Number",
      type: "string",
    },
    {
      value: "startDate",
      label: "Issue Date",
      type: "datetime",
    },
    {
      value: "endDate",
      label: "Expiration Date",
      type: "datetime",
    },
    {
      value: "required",
      label: "Required",
      type: "boolean",
    },
  ];

  const [status, setStatus] = useState<QualificationStatus | null>(null);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <Tabs
          variant="standard"
          value={status}
          onChange={(event, value) => {
            setStatus(value);
          }}
        >
          <Tab label="Current" value={QualificationStatus.CURRENT} />
          <Tab label="Expired" value={QualificationStatus.COMPLETED} />
        </Tabs>
        {/* <ExcelImporter
            mapping={qualificationsMapping}
            schema={qualificationSchema}
            onBulkCreate={onBulkCreate}
          /> */}
        <Button
          variant="contained"
          color="secondary"
          size="medium"
          id="new-qualification-button"
          onClick={() => {
            setFormOpen(true);
            setSelectedQualification(null);
          }}
        >
          New Qualification
        </Button>
      </Box>
      <SmartLynksTable
        query={query}
        dataKey="qualifications"
        fields={fields}
        onRecordClick={(qualification) => {
          if (!hasWriteAccess) return;
          setFormOpen(true);
          setSelectedQualification(qualification);
        }}
        disableSearch
        additionalQueryVariables={{
          status: status,
        }}
        deleteMutation={hasWriteAccess ? deleteMutation : undefined}
      />
      <Dialog
        open={formOpen}
        onClose={() => {
          setFormOpen(false);
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          {capitalize(
            selectedQualification ? "Edit Qualification" : "New Qualification"
          )}
        </DialogTitle>
        <DialogContent>
          {formOpen ? (
            <QualificationFormContainer
              qualificationId={selectedQualification?._id}
              onSaved={() => {
                setFormOpen(false);
                setSelectedQualification(null);
                onRefresh();
              }}
              assetType={assetType}
              assetId={assetId}
            />
          ) : null}
        </DialogContent>
      </Dialog>
    </div>
  );
}
