import { useTranslation } from "react-i18next";
import {
  useAddTripPaymentMutation,
  useComputeLineHaulForTripMutation,
  useDeleteTripPaymentMutation,
  useEditTripPaymentMutation,
  useGetTripForPaymentsQuery,
} from "../../../graphql/generated";
import useDialog from "../../../utils/hooks/useDialog";
import TripPayments, { TripPaymentProps } from "./TripPayments";

type TripPaymentsContainerProps = Omit<
  TripPaymentProps,
  | "trip"
  | "onPaymentAdded"
  | "onPaymentEdited"
  | "onPaymentDeleted"
  | "onLineHaulCompute"
> & {
  tripId: string;
};

const TripPaymentsContainer = ({
  tripId,
  ...props
}: TripPaymentsContainerProps) => {
  const { t } = useTranslation(["finance", "common"]);
  const getTripForPaymentsQuery = useGetTripForPaymentsQuery({
    id: tripId,
  });
  const addTripPaymentMutation = useAddTripPaymentMutation();
  const editTripPaymentMutation = useEditTripPaymentMutation();
  const deleteTripPaymentMutation = useDeleteTripPaymentMutation();
  const computeLineHaulMutation = useComputeLineHaulForTripMutation();
  const { showDialog } = useDialog();

  const trip = getTripForPaymentsQuery.data?.tripById;
  if (!trip) {
    return null;
  }
  return (
    <TripPayments
      trip={trip}
      onLineHaulCompute={async () => {
        try {
          await computeLineHaulMutation.mutateAsync({
            tripId,
          });
          await getTripForPaymentsQuery.refetch();
        } catch (error) {
          showDialog({
            type: "error",
            title: t(
              "error.computeLineHaulPayError",
              "Error while computing line haul pay"
            ),
            description:
              (error as Error).message ||
              t("common:error.unknownError", "Unknown error"),
          });
        }
      }}
      onPaymentAdded={async (payment) => {
        try {
          await addTripPaymentMutation.mutateAsync({
            tripId,
            payment,
          });
          await getTripForPaymentsQuery.refetch();
        } catch (error) {
          showDialog({
            type: "error",
            title: t("error.addPayment", "Error while adding payment"),
            description:
              (error as Error).message ||
              t("common:error.unknownError", "Unknown error"),
          });
        }
      }}
      onPaymentEdited={async (paymentId, payment) => {
        try {
          await editTripPaymentMutation.mutateAsync({
            tripId,
            paymentId,
            payment,
          });
          await getTripForPaymentsQuery.refetch();
        } catch (error) {
          showDialog({
            type: "error",
            title: t("error.editPayment", "Error while editing payment"),
            description:
              (error as Error).message ||
              t("common:error.unknownError", "Unknown error"),
          });
        }
      }}
      onPaymentDeleted={async (paymentId) => {
        try {
          await deleteTripPaymentMutation.mutateAsync({
            tripId,
            paymentId,
          });
          await getTripForPaymentsQuery.refetch();
        } catch (error) {
          showDialog({
            type: "error",
            title: t("error.deletePayment", "Error while deleting payment"),
            description:
              (error as Error).message ||
              t("common:error.unknownError", "Unknown error"),
          });
        }
      }}
      {...props}
    />
  );
};

export default TripPaymentsContainer;
