import { Alert } from "@mui/material";

type ErrorMessageProps = {
  error?: Error | unknown | null;
  message?: string | null;
};

const ErrorMessage = ({ message, error }: ErrorMessageProps) => {
  if (!message && !error) {
    return null;
  }
  return (
    <Alert sx={{ mb: 2 }} severity="error">
      {message || null}
      {error && message ? <br /> : null}
      {error ? (error as Error | undefined)?.message || "Unknown error" : null}
    </Alert>
  );
};

export default ErrorMessage;
