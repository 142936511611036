import { Navigate, useParams } from "react-router-dom";

function EditOrder() {
  const { id } = useParams();
  if (!id) {
    return null;
  }

  return <Navigate replace to={`/orders/edit/${id}`} />;
}

export default EditOrder;
