import { Alert } from "@mui/material";
import { omit } from "lodash";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";
import {
  BusinessEntityType,
  GetCustomFieldDefinitionDetailsQuery,
  useAddCustomFieldDefinitionMutation,
  useEditCustomFieldDefinitionMutation,
  useGetBusinessEntityListQuery,
  useGetDriverListQuery,
  useGetCustomFieldDefinitionDetailsQuery,
} from "../../../graphql/generated";
import { showDialog } from "../../../redux/slices/alert/Alert.slice";
import LoadingOverlay from "../../common/LoadingOverlay";
import CustomFieldDefinitionForm from "./CustomFieldDefinitionForm";

type CustomFieldDefinitionFormContainerProps = {
  customFieldDefinitionId?: string;
  onLoad?: (
    customFieldDefinition: GetCustomFieldDefinitionDetailsQuery["customFieldDefinitionById"]
  ) => void;
  onSaved?: (
    customFieldDefinition: GetCustomFieldDefinitionDetailsQuery["customFieldDefinitionById"]
  ) => void;
};

function CustomFieldDefinitionFormContainer({
  customFieldDefinitionId,
  onLoad,
  onSaved,
}: CustomFieldDefinitionFormContainerProps) {
  const addCustomFieldDefinitionMutation =
    useAddCustomFieldDefinitionMutation();
  const editCustomFieldDefinitionMutation =
    useEditCustomFieldDefinitionMutation();
  const initialCustomFieldDefinitionId = useRef(customFieldDefinitionId);

  const driversQuery = useGetDriverListQuery({});
  const domicilesQuery = useGetBusinessEntityListQuery({
    businessEntityTypes: [BusinessEntityType.Domicile],
  });

  // We call this hook conditionally because the component
  // should never rerender with a different value for
  // businessEntityId, but if it does we don't update
  // the ref anyway so this is safe
  const getCustomFieldDefinitionQuery = initialCustomFieldDefinitionId.current
    ? // eslint-disable-next-line react-hooks/rules-of-hooks
      useGetCustomFieldDefinitionDetailsQuery(
        {
          id: initialCustomFieldDefinitionId.current,
        },
        {
          refetchOnWindowFocus: false,
          retry: false,
        }
      )
    : null;
  // const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (getCustomFieldDefinitionQuery?.data?.customFieldDefinitionById) {
      onLoad?.(getCustomFieldDefinitionQuery.data?.customFieldDefinitionById);
    }
  }, [onLoad, getCustomFieldDefinitionQuery?.data]);

  if (customFieldDefinitionId) {
    if (getCustomFieldDefinitionQuery?.isLoading) {
      return <LoadingOverlay loading />;
    }
    if (getCustomFieldDefinitionQuery?.error) {
      return (
        <Alert severity="error">
          An error occurred while fetching the customFieldDefinition.{" "}
          {(getCustomFieldDefinitionQuery?.error as Error).message ||
            "Unknown error"}
        </Alert>
      );
    }
  }

  return (
    <CustomFieldDefinitionForm
      initialCustomFieldDefinition={
        getCustomFieldDefinitionQuery?.data?.customFieldDefinitionById
      }
      saving={
        addCustomFieldDefinitionMutation.isLoading ||
        editCustomFieldDefinitionMutation.isLoading
      }
      drivers={driversQuery.data?.drivers.data || []}
      domiciles={domicilesQuery.data?.businessEntities.data || []}
      onSave={async (customFieldDefinition) => {
        try {
          if (customFieldDefinitionId) {
            const result = await editCustomFieldDefinitionMutation.mutateAsync({
              id: customFieldDefinitionId,
              editCustomFieldDefinitionData: omit(customFieldDefinition, "_id"),
            });
            onSaved?.({
              ...customFieldDefinition,
              _id: result.editCustomFieldDefinition._id,
            });
            // navigate(`/customFieldDefinitions/details/${result.editCustomFieldDefinition._id}`);
          } else {
            const result = await addCustomFieldDefinitionMutation.mutateAsync({
              newCustomFieldDefinitionData: {
                ...customFieldDefinition,
                key: customFieldDefinition.label
                  .toLowerCase()
                  .replace(/ /g, "_"),
                description: "",
              },
            });
            onSaved?.({
              ...customFieldDefinition,
              _id: result.addCustomFieldDefinition._id,
            });
            // navigate(`/customFieldDefinitions/details/${result.addCustomFieldDefinition._id}`);
            // window.analytics?.track("CustomFieldDefinition Created", {
            //   customFieldDefinitionId: result.addCustomFieldDefinition._id,
            // });
            // window.analytics?.identify({
            //   customFieldDefinitionCreated: true,
            //   lastCustomFieldDefinitionCreationDate: new Date(),
            //   lastCustomFieldDefinitionCreationDateOnly: new Date()
            //     .toISOString()
            //     .split("T")[0],
            //   numberOfCustomFieldDefinitionsCreated:
            //     (window.analytics?.user?.()?.traits?.()?.numberOfCustomFieldDefinitionsCreated ||
            //       0) + 1,
            // });
            // window.analytics?.group(window.analytics?.group?.()?.id?.(), {
            //   customFieldDefinitionCreated: true,
            //   lastCustomFieldDefinitionCreationDate: new Date(),
            //   lastCustomFieldDefinitionCreationDateOnly: new Date()
            //     .toISOString()
            //     .split("T")[0],
            //   numberOfCustomFieldDefinitionsCreated:
            //     (window.analytics?.group?.()?.traits?.()?.numberOfCustomFieldDefinitionsCreated ||
            //       0) + 1,
            // });
          }
        } catch (error) {
          dispatch(
            showDialog({
              title: "Error",
              description:
                `An error occurred while ${
                  customFieldDefinitionId ? "editing" : "creating"
                } the customFieldDefinition. ` + (error as Error).message,
              type: "error",
            })
          );
        }
      }}
    />
  );
}

export default CustomFieldDefinitionFormContainer;
