import { AssetStatus, TrailerType } from "../../graphql/generated";
import domicileMapping from "./domicile";
import { ExcelMapping } from "./mappingTypes";

const trailersMapping: ExcelMapping = {
  "Asset Code": { key: "serialNumber", type: "string", required: true },
  "License Number": { key: "licenseNumber", type: "string" },
  "Trailer Type": {
    key: "type",
    type: "enum",
    required: true,
    enumOptions: Object.values(TrailerType),
  },
  Status: {
    key: "status",
    type: "enum",
    enumOptions: Object.values(AssetStatus),
  },
  "Max Weight": { key: "maxWeight", type: "number", unitType: "weight" },
  Width: { key: "width", type: "number", unitType: "distance" },
  Height: { key: "height", type: "number", unitType: "distance" },
  Length: { key: "length", type: "number", unitType: "distance" },
  "Number of axles": { key: "numberOfAxles", type: "number" },
  "Min temperature": {
    key: "minTemperature",
    type: "number",
    unitType: "temperature",
  },
  "Max temperature": {
    key: "maxTemperature",
    type: "number",
    unitType: "temperature",
  },
  ...domicileMapping,
};

export default trailersMapping;
