import {
  ShipmentEventInput,
  useDeleteShipmentEventMutation,
  useEditShipmentEventMutation,
} from "../../../graphql/generated";
import ShipmentEventFormModal, {
  ShipmentEventFormModalProps,
} from "./ShipmentEventFormModal";

type ShipmentEventFormModalContainerProps = Omit<
  ShipmentEventFormModalProps,
  "onSubmit" | "onDelete" | "error" | "loading"
> & {
  shipmentId: string;
};

const ShipmentEventFormModalContainer = ({
  shipmentId,
  ...props
}: ShipmentEventFormModalContainerProps) => {
  const editShipmentEventMutation = useEditShipmentEventMutation();
  const deleteShipmentEventMutation = useDeleteShipmentEventMutation();

  const handleSubmit = async (shipmentEvent: ShipmentEventInput) => {
    if (props.initialShipmentEvent?._id) {
      await editShipmentEventMutation.mutateAsync({
        shipmentId: shipmentId,
        eventId: props.initialShipmentEvent._id,
        shipmentEventData: {
          date: shipmentEvent.date,
        },
      });
    }
    props.onClose();
  };

  const handleDelete = async () => {
    if (props.initialShipmentEvent?._id) {
      await deleteShipmentEventMutation.mutateAsync({
        shipmentId: shipmentId,
        eventId: props.initialShipmentEvent._id,
      });
    }
    props.onClose();
  };

  return (
    <ShipmentEventFormModal
      {...props}
      onSubmit={handleSubmit}
      onDelete={handleDelete}
      error={
        editShipmentEventMutation.error || deleteShipmentEventMutation.error
      }
      loading={
        editShipmentEventMutation.isLoading ||
        deleteShipmentEventMutation.isLoading
      }
    />
  );
};

export default ShipmentEventFormModalContainer;
