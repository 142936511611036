import { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  Stack,
  TextField,
} from "@mui/material";
import ErrorMessage from "../../common/ErrorMessage/ErrorMessage";
import { ValidationResult } from "joi";
import {
  NewQualificationInput,
  GetQualificationDetailsQuery,
  TripAssetTypes,
  Scalars,
} from "../../../graphql/generated";
import { isArray, mergeWith, omit } from "lodash";
import qualificationSchema from "./qualificationSchema";
import useConfirmBeforeLeave from "../../../utils/hooks/useConfirmBeforeLeave";
import { FormDivider } from "../../asset-management/BusinessEntityForm/BusinessEntityForm";
import { useTranslation } from "react-i18next";
import { DatePicker } from "@mui/x-date-pickers";
import DocumentsUploadModalContainer from "../../common/DocumentsUploadModal";
import { Close, OpenInNew } from "@mui/icons-material";

type Qualification = GetQualificationDetailsQuery["qualificationById"];

export type QualificationFormProps = {
  assetType: TripAssetTypes;
  assetId: Scalars["ObjectId"];
  initialQualification?: Qualification;
  saving: boolean;
  onSave: (qualification: NewQualificationInput) => void;
};
type PartialQualification = Partial<Qualification>;
type DeepPartialQualification = {
  [key in keyof PartialQualification]: Partial<PartialQualification[key]>;
};

const QualificationForm = ({
  initialQualification,
  assetType,
  assetId,
  saving,
  onSave,
}: QualificationFormProps) => {
  const { t } = useTranslation(["users", "common"]);
  const [validationResult, setValidationResult] =
    useState<ValidationResult<Qualification> | null>(null);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [localQualification, setLocalQualification] = useState<
    Partial<NewQualificationInput>
  >(
    initialQualification || {
      assetType,
      assetId,
    }
  );

  const getFieldError = (field: string, partialPathMatch = false) =>
    validationResult?.error?.details.find((error) =>
      partialPathMatch
        ? error.path.join(".").startsWith(field)
        : error.path.join(".") === field
    )?.message;

  const onChange = useCallback((changes: DeepPartialQualification) => {
    setLocalQualification((localQualification) =>
      mergeWith({}, localQualification, changes, (objValue, srcValue) => {
        if (isArray(srcValue)) {
          return srcValue;
        }
      })
    );
  }, []);

  const validate = () => {
    const validationResult = qualificationSchema.validate(
      omit(localQualification, "_id"),
      {
        abortEarly: false,
      }
    );
    setValidationResult(validationResult);
    return !validationResult.error;
  };

  useEffect(() => {
    if (validationResult) {
      validate();
    }
    // We don't want to run everytime validationResult changes
    // otherwise we ill have an infinite update loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localQualification]);

  const { cancelConfirm } = useConfirmBeforeLeave(localQualification);

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item sm={12}>
          <FormDivider variant="fullWidth" text="Qualification Details" />
          <ErrorMessage message={validationResult?.error?.message} />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Stack direction="row" spacing={2}>
                <TextField
                  label={t("common:type", "Type")}
                  fullWidth
                  required
                  value={localQualification.label || ""}
                  error={!!getFieldError("label")}
                  helperText={getFieldError("label")}
                  onChange={(event) => {
                    onChange({ label: event.target.value });
                  }}
                />
                <TextField
                  label={t("common:number", "Number")}
                  fullWidth
                  value={localQualification.number || ""}
                  error={!!getFieldError("number")}
                  helperText={getFieldError("number")}
                  onChange={(event) => {
                    onChange({ number: event.target.value });
                  }}
                />
                <FormControlLabel
                  label={"Required"}
                  control={
                    <Checkbox
                      checked={localQualification.required || false}
                      onChange={(event, checked) => {
                        onChange({ required: checked });
                      }}
                    />
                  }
                />
              </Stack>
            </Grid>

            <Grid item xs={12} sm={4.5}>
              <DatePicker
                label="Issued Date"
                value={localQualification?.startDate || null}
                onChange={(date) => {
                  onChange({ startDate: date });
                }}
                maxDate={localQualification?.endDate || null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    fullWidth
                    error={!!getFieldError("startDate")}
                    helperText={getFieldError("startDate")}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={4.5}>
              <DatePicker
                label="Expiration Date"
                value={localQualification?.endDate || null}
                onChange={(date) => {
                  onChange({ endDate: date });
                }}
                minDate={localQualification?.startDate || null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    error={!!getFieldError("endDate")}
                    helperText={getFieldError("endDate")}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <FormDivider variant="fullWidth" text="Documents" />
              <Stack spacing={1}>
                {localQualification.documents?.map((document, index) => (
                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    key={document.url}
                  >
                    <Link
                      href={document.url as string}
                      target="_blank"
                      component="a"
                    >
                      <OpenInNew /> {document.name}
                    </Link>

                    <IconButton
                      onClick={() => {
                        onChange({
                          documents: localQualification.documents?.filter(
                            (doc) => doc.url !== document.url
                          ),
                        });
                      }}
                    >
                      <Close />
                    </IconButton>
                  </Stack>
                ))}
                <Button
                  variant="outlined"
                  onClick={() => setUploadModalOpen(true)}
                  id="uploadDocumentsButton"
                >
                  Upload Documents
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row-reverse",
          pt: 3,
        }}
      >
        <Button
          variant="contained"
          disabled={saving || !!validationResult?.error}
          size="large"
          onClick={() => {
            if (!localQualification) {
              return;
            }
            if (validate()) {
              cancelConfirm();
              onSave(localQualification as NewQualificationInput);
            }
          }}
          id="saveQualificationButton"
        >
          {t("common:save", "Save")}
        </Button>
      </Box>

      <DocumentsUploadModalContainer
        isOpen={uploadModalOpen}
        onCancel={() => setUploadModalOpen(false)}
        multiple
        onSubmit={(documents) => {
          setUploadModalOpen(false);
          onChange({
            documents: (localQualification.documents || []).concat(documents),
          });
        }}
      />
    </Box>
  );
};

export default QualificationForm;
