import { Box, Fab } from "@mui/material";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import ShipmentSummary, {
  ShipmentDataSummary,
  ShipmentSummaryProps,
} from "../../shipment/ShipmentSummary/ShipmentSummary";
import { arrayMoveImmutable } from "array-move";
import { DeleteOutline } from "@mui/icons-material";
import { Fragment } from "react";

interface ReorderableShipmentsProps {
  shipments: ShipmentDataSummary[];
  onChange: (shipments: ShipmentDataSummary[]) => void;
}

const SortableShipmentSummary = SortableElement(
  (props: ShipmentSummaryProps) => (
    <Box>
      <ShipmentSummary {...props} />
    </Box>
  )
);

const SortableShipmentSummaryList = SortableContainer(
  ({
    shipments,
    onRemove,
  }: {
    shipments: ShipmentDataSummary[];
    onRemove: (shipment: ShipmentDataSummary) => void;
  }) => (
    <Box sx={{ margin: 4 }}>
      {shipments.map((shipment, index) => (
        <Fragment key={shipment._id}>
          <Box sx={{ position: "relative", mb: 4 }}>
            <SortableShipmentSummary index={index} shipment={shipment} />
            <Fab
              size="small"
              color="error"
              sx={{
                position: "absolute",
                top: -20,
                right: -20,
              }}
              onClick={() => onRemove(shipment)}
            >
              <DeleteOutline sx={{ color: "white" }} />
            </Fab>
          </Box>
        </Fragment>
      ))}
    </Box>
  )
);

function ReorderableShipments({
  shipments,
  onChange,
}: ReorderableShipmentsProps) {
  return (
    <Box>
      <SortableShipmentSummaryList
        shipments={shipments}
        onSortEnd={({ oldIndex, newIndex }) =>
          onChange(arrayMoveImmutable(shipments, oldIndex, newIndex))
        }
        onRemove={(shipmentToRemove) =>
          onChange(
            shipments.filter(
              (shipment) => shipment._id !== shipmentToRemove._id
            )
          )
        }
      />
    </Box>
  );
}

export default ReorderableShipments;
