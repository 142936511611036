import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import {
  BusinessEntityType,
  DateTimeWindowInput,
  GetShipmentListQuery,
  ShipmentLocationType,
  SplitShipmentLocationInput,
} from "../../../graphql/generated";
import {
  shipmentLocationLabel,
  shipmentLocationAddressLabel,
} from "../../../utils/labels/shipmentLocationLabel";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import {
  AddCircle,
  CircleOutlined,
  Delete,
  DragIndicator,
} from "@mui/icons-material";
import { useEffect, useState } from "react";
import { arrayMoveImmutable } from "array-move";
import { LoadingButton } from "@mui/lab";
import { without } from "lodash";
import { DateTimePicker } from "@mui/x-date-pickers";
import BusinessEntitySelectContainer from "../ShipmentForm/BusinessEntitySelect";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { fallbackTimezone } from "../../../utils/labels/formatDateTime";

type LoadSplittingShipment = GetShipmentListQuery["shipments"]["data"][0];

export type LoadSplittingModalProps = {
  open: boolean;
  splitting: boolean;
  onClose: () => void;
  onSubmit: (
    shipmentId: string,
    locations: SplitShipmentLocationInput[]
  ) => void;
  shipment: LoadSplittingShipment;
};

const LoadSplittingModal = ({
  shipment,
  splitting,
  open,
  onSubmit,
  onClose,
}: LoadSplittingModalProps) => {
  const [locations, setLocations] = useState<
    SplitShipmentLocationInput[] | null
  >(
    shipment.route?.locations?.map((location) => ({
      location: location.location,
      locationType: location.locationType,
      _id: location._id,
      addressLabel: shipmentLocationAddressLabel(location),
      name: shipmentLocationLabel(location),
      arrivalTimeWindows: location.timeWindows,
      addressTimezone: location.addressTimezone,
    })) || null
  );

  useEffect(() => {
    setLocations(
      shipment.route?.locations?.map((location) => ({
        location: location.location,
        locationType: location.locationType,
        _id: location._id,
        addressLabel: shipmentLocationAddressLabel(location),
        name: shipmentLocationLabel(location),
        arrivalTimeWindows: location.timeWindows,
        addressTimezone: location.addressTimezone,
      })) || null
    );
  }, [shipment]);

  if (!locations) {
    return null;
  }
  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={() => onClose()}>
      <DialogTitle>Split Order</DialogTitle>
      <DialogContent dividers>
        <SortableRouteLocations
          locations={locations}
          onSortEnd={({ oldIndex, newIndex }) => {
            setLocations(arrayMoveImmutable(locations, oldIndex, newIndex));
          }}
          onChange={(newLocations) => {
            setLocations(newLocations);
          }}
          helperClass="sortableZindexHelper"
          shouldCancelStart={(event) => {
            if (event.target instanceof SVGPathElement) {
              return !event.target.parentElement?.parentElement?.classList.contains(
                "drag-handle"
              );
            }
            if (event.target instanceof SVGElement) {
              return !event.target.parentElement?.classList.contains(
                "drag-handle"
              );
            }
            return !(event.target as HTMLElement).classList.contains(
              "drag-handle"
            );
          }}
        />
        <Button
          onClick={() => {
            setLocations(
              locations.concat({
                location: {
                  latitude: 0,
                  longitude: 0,
                },
                locationType: ShipmentLocationType.DropForHook,
                addressLabel: "",
                name: "",
              })
            );
          }}
          startIcon={<AddCircle />}
          sx={{
            mt: 3,
          }}
        >
          Add a splitting point
        </Button>
      </DialogContent>

      <DialogActions>
        <Button
          autoFocus
          color="inherit"
          variant="contained"
          onClick={() => onClose()}
        >
          Cancel
        </Button>
        <LoadingButton
          color="primary"
          variant="contained"
          onClick={() => {
            onSubmit(shipment._id, locations);
          }}
          id="vaidate-split-button"
          loading={splitting}
        >
          Split Order
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const SortableRouteLocations = SortableContainer(
  ({
    locations,
    onChange,
  }: {
    locations: SplitShipmentLocationInput[];
    onChange: (locations: SplitShipmentLocationInput[]) => void;
  }) => {
    return (
      <Stack spacing={2}>
        {locations.map((location, index) => (
          <SortableRouteLocationItem
            disabled={
              location.locationType !== ShipmentLocationType.DropForHook
            }
            location={location}
            key={location._id}
            index={index}
            onChange={(changedLocation) =>
              onChange(
                locations.map((loc) =>
                  loc === location ? changedLocation : loc
                )
              )
            }
            onDelete={() => {
              onChange(without(locations, location));
            }}
          />
        ))}
      </Stack>
    );
  }
);

const SortableRouteLocationItem = SortableElement(
  ({
    location,
    onChange,
    onDelete,
  }: {
    location: SplitShipmentLocationInput;
    onChange: (location: SplitShipmentLocationInput) => void;
    onDelete: () => void;
  }) => {
    const arrivalFromDate = location.arrivalTimeWindows?.[0]?.fromDate;
    const arrivalToDate = location.arrivalTimeWindows?.[0]?.toDate;
    const departureFromDate = location.departureTimeWindows?.[0]?.fromDate;
    const departureToDate = location.departureTimeWindows?.[0]?.toDate;

    return (
      <Stack direction="row" alignItems={"center"} spacing={2}>
        <SortableRouteLocationItemDragHandle location={location} />
        {location.locationType === ShipmentLocationType.DropForHook ? (
          <>
            <BusinessEntitySelectContainer
              businessEntityTypes={[
                BusinessEntityType.Domicile,
                BusinessEntityType.GeneralLocation,
                BusinessEntityType.TruckStop,
                BusinessEntityType.Port,
                BusinessEntityType.Terminal,
              ]}
              onChange={(businessEntityId, businessEntity) => {
                if (!businessEntityId) {
                  onChange({
                    ...location,
                    addressLabel: "",
                    name: "",
                    location: {
                      latitude: 0,
                      longitude: 0,
                    },
                    _id: "",
                    arrivalTimeWindows: [],
                    departureTimeWindows: [],
                  });
                  return;
                }
                if (!businessEntity) {
                  return;
                }
                const convertTimeWindow = (tw: DateTimeWindowInput) => ({
                  fromDate: zonedTimeToUtc(
                    utcToZonedTime(
                      tw.fromDate,
                      location.addressTimezone || fallbackTimezone
                    ),
                    businessEntity?.addressTimezone || fallbackTimezone
                  ),
                  toDate: zonedTimeToUtc(
                    utcToZonedTime(
                      tw.toDate,
                      location.addressTimezone || fallbackTimezone
                    ),
                    businessEntity?.addressTimezone || fallbackTimezone
                  ),
                });
                const convertedArrivalTimeWindows =
                  location.arrivalTimeWindows?.map(convertTimeWindow);
                const convertedDepartureTimeWindows =
                  location.departureTimeWindows?.map(convertTimeWindow);
                onChange({
                  ...location,
                  addressTimezone: businessEntity.addressTimezone,
                  addressLabel: businessEntity.address?.label || "",
                  name: businessEntity.name,
                  location:
                    businessEntity.address?.coordinates || location.location,
                  _id: businessEntityId,
                  arrivalTimeWindows: convertedArrivalTimeWindows,
                  departureTimeWindows: convertedDepartureTimeWindows,
                });
              }}
              // onAddressSelect={handleAddressChange}
              // initialAddressLabel={
              //   data.shipper || data.receiver ? null : data.addressLabel
              // }
              value={location._id}
              allowAddress={false}
              inputProps={{
                // error: formErrors && formErrors.error["name"] ? true : false,
                // helperText:
                //   formErrors && formErrors.error["name"] !== undefined
                //     ? formErrors.error["name"]
                //     : getSelectedBusinessEntity()?.address?.label || "",
                fullWidth: true,
                label: "Location",
              }}
              fullWidth
              sx={{
                flex: 1,
              }}
            />
            {/* <AddressInput
              value={{
                label: location.addressLabel || "",
                coordinates: location.location,
              }}
              onChange={(address) => {
                onChange({
                  ...location,
                  addressLabel: address?.label,
                  name: address?.label,
                  location: address?.coordinates || location.location,
                });
              }}
              disableDetails
            /> */}
            <Stack spacing={1}>
              <Stack direction="row" spacing={1}>
                <DateTimePicker
                  label="Arrival time start"
                  ampm={false}
                  value={
                    arrivalFromDate
                      ? utcToZonedTime(
                          arrivalFromDate,
                          location.addressTimezone || fallbackTimezone
                        )
                      : null
                  }
                  onChange={(date) => {
                    onChange({
                      ...location,
                      arrivalTimeWindows: [
                        {
                          fromDate: date
                            ? zonedTimeToUtc(
                                date,
                                location.addressTimezone || fallbackTimezone
                              )
                            : null,
                          toDate: location.arrivalTimeWindows?.[0]?.toDate,
                        },
                      ],
                    });
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
                <DateTimePicker
                  label="Arrival time end"
                  ampm={false}
                  value={
                    arrivalToDate
                      ? utcToZonedTime(
                          arrivalToDate,
                          location.addressTimezone || fallbackTimezone
                        )
                      : null
                  }
                  onChange={(date) => {
                    onChange({
                      ...location,
                      arrivalTimeWindows: [
                        {
                          fromDate: location.arrivalTimeWindows?.[0]?.fromDate,
                          toDate: date
                            ? zonedTimeToUtc(
                                date,
                                location.addressTimezone || fallbackTimezone
                              )
                            : null,
                        },
                      ],
                    });
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Stack>

              <Stack direction="row" spacing={1}>
                <DateTimePicker
                  label="Departure time start"
                  ampm={false}
                  value={
                    departureFromDate
                      ? utcToZonedTime(
                          departureFromDate,
                          location.addressTimezone || fallbackTimezone
                        )
                      : null
                  }
                  onChange={(date) => {
                    onChange({
                      ...location,
                      departureTimeWindows: [
                        {
                          fromDate: date
                            ? zonedTimeToUtc(
                                date,
                                location.addressTimezone || fallbackTimezone
                              )
                            : null,
                          toDate: location.departureTimeWindows?.[0]?.toDate,
                        },
                      ],
                    });
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
                <DateTimePicker
                  label="Departure time end"
                  ampm={false}
                  value={
                    departureToDate
                      ? utcToZonedTime(
                          departureToDate,
                          location.addressTimezone || fallbackTimezone
                        )
                      : null
                  }
                  onChange={(date) => {
                    onChange({
                      ...location,
                      departureTimeWindows: [
                        {
                          fromDate:
                            location.departureTimeWindows?.[0]?.fromDate,
                          toDate: date
                            ? zonedTimeToUtc(
                                date,
                                location.addressTimezone || fallbackTimezone
                              )
                            : null,
                        },
                      ],
                    });
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Stack>
            </Stack>
            <IconButton
              onClick={() => {
                onDelete();
              }}
            >
              <Delete />
            </IconButton>
          </>
        ) : (
          <>
            <TextField
              value={shipmentLocationAddressLabel(location)}
              disabled
              sx={{
                flex: 1,
                mr: 5,
              }}
              fullWidth
            />
            <Stack direction="row" spacing={1}>
              <DateTimePicker
                label={
                  location.locationType === ShipmentLocationType.Pickup
                    ? "Pickup time start"
                    : "Delivery time start"
                }
                value={
                  arrivalFromDate
                    ? utcToZonedTime(
                        arrivalFromDate,
                        location.addressTimezone || fallbackTimezone
                      )
                    : null
                }
                ampm={false}
                renderInput={(params) => <TextField {...params} />}
                onChange={(date) => {
                  onChange({
                    ...location,
                    arrivalTimeWindows: [
                      {
                        fromDate: date
                          ? zonedTimeToUtc(
                              date,
                              location.addressTimezone || fallbackTimezone
                            )
                          : null,
                        toDate: location.arrivalTimeWindows?.[0]?.toDate,
                      },
                    ],
                  });
                }}
              />

              <DateTimePicker
                label={
                  location.locationType === ShipmentLocationType.Pickup
                    ? "Pickup time end"
                    : "Delivery time end"
                }
                ampm={false}
                value={
                  arrivalToDate
                    ? utcToZonedTime(
                        arrivalToDate,
                        location.addressTimezone || fallbackTimezone
                      )
                    : null
                }
                renderInput={(params) => <TextField {...params} />}
                onChange={(date) => {
                  onChange({
                    ...location,
                    arrivalTimeWindows: [
                      {
                        fromDate: location.arrivalTimeWindows?.[0]?.fromDate,
                        toDate: date
                          ? zonedTimeToUtc(
                              date,
                              location.addressTimezone || fallbackTimezone
                            )
                          : null,
                      },
                    ],
                  });
                }}
              />
            </Stack>
          </>
        )}
      </Stack>
    );
  }
);

const SortableRouteLocationItemDragHandle = SortableHandle(
  ({ location }: { location: SplitShipmentLocationInput }) => (
    <Box
      className="drag-handle"
      sx={{
        cursor: "move",
        mr: 2,
      }}
    >
      {location.locationType === ShipmentLocationType.DropForHook ? (
        <DragIndicator color="primary" />
      ) : (
        <CircleOutlined color="disabled" />
      )}
    </Box>
  )
);

export default LoadSplittingModal;
