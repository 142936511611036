import React, { ReactNode } from "react";
import { Box } from "@mui/system";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import theme from "../../../theme";
import { TimelineOppositeContent } from "@mui/lab";

export type Step = {
  hideFirstLine?: boolean;
  hideLastLine?: boolean;
  content?: ReactNode;
  oppositeContent?: ReactNode;
  header?: ReactNode;
  color?: string;
  indicator?: ReactNode;
  onIndicatorClick?: (event: React.MouseEvent<HTMLSpanElement>) => void;
  separatorLength?: number;
  id: string;
};

type StepIndicatorProps = {
  steps: Step[];
  horizontal?: boolean;
};

const StepIndicator = ({ steps, horizontal }: StepIndicatorProps) => {
  return (
    <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
        "&": {
          margin: 0,
          ...(horizontal
            ? {
                flexDirection: "row",
              }
            : {}),
        },
      }}
    >
      {(steps || []).map((step) => {
        const {
          hideFirstLine,
          hideLastLine,
          content,
          oppositeContent,
          color,
          indicator,
          onIndicatorClick,
          separatorLength,
        } = step;
        return (
          <TimelineItem
            key={step.id}
            sx={{
              ...(horizontal
                ? {
                    flexDirection: "column",
                  }
                : {}),
            }}
          >
            {step.header}
            {oppositeContent && (
              <TimelineOppositeContent
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
                color="text.secondary"
              >
                {oppositeContent}
              </TimelineOppositeContent>
            )}
            <TimelineSeparator
              sx={{
                ...(horizontal
                  ? {
                      flexDirection: "row",
                    }
                  : {}),
              }}
            >
              <TimelineConnector
                sx={{
                  borderColor: hideFirstLine
                    ? "transparent"
                    : theme.palette.primary.main,
                  borderStyle: "dashed",
                  bgcolor: "transparent",
                  borderWidth: 0,
                  ...(horizontal
                    ? {
                        borderTopWidth: 1,
                        height: 2,
                        flex: 1,
                      }
                    : {
                        height: separatorLength,
                        borderLeftWidth: 1,
                      }),
                }}
              />
              <TimelineDot
                onClick={onIndicatorClick}
                sx={{
                  marginLeft: 0,
                  marginRight: 0,
                  marginTop: 0.2,
                  marginBottom: 0.2,
                  cursor: onIndicatorClick ? "pointer" : "default",
                  width: 20,
                  height: 20,
                  borderRadius: 10,
                  backgroundColor: "white",
                  borderColor: color,
                  borderWidth: 2,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 0,
                }}
              >
                {indicator || (
                  <Box
                    sx={{
                      width: 12,
                      height: 12,
                      borderRadius: 6,
                      backgroundColor: color,
                    }}
                  />
                )}
              </TimelineDot>
              <TimelineConnector
                sx={{
                  borderColor: hideLastLine
                    ? "transparent"
                    : theme.palette.primary.main,
                  borderStyle: "dashed",
                  bgcolor: "transparent",
                  borderWidth: 0,
                  ...(horizontal
                    ? {
                        borderTopWidth: 1,
                        height: 2,
                        flex: 1,
                      }
                    : {
                        height: separatorLength,
                        borderLeftWidth: 1,
                      }),
                }}
              />
            </TimelineSeparator>
            <TimelineContent
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {content}
            </TimelineContent>
          </TimelineItem>
        );
      })}
    </Timeline>
  );
};

export default StepIndicator;
