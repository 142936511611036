import {
  useDeleteCustomFieldDefinitionMutation,
  useGetCustomFieldDefinitionsQuery,
} from "../../../graphql/generated";
import CustomFieldDefinitionsList from "./CustomFieldsList";

export default function CustomFieldDefinitionsListContainer() {
  // const bulkCreateCustomFieldDefinitions = useBulkAddCustomFieldDefinitionsMutation();
  let refetch = () => {};
  // I need a handle on refetch so I can call it
  // a new settlement has been generated
  const useGetCustomFieldDefinitionListQueryCustom: typeof useGetCustomFieldDefinitionsQuery =
    (variables, options) => {
      const query = useGetCustomFieldDefinitionsQuery(variables, {
        ...options,
      });
      refetch = query.refetch;
      return query;
    };
  useGetCustomFieldDefinitionListQueryCustom.fetcher =
    useGetCustomFieldDefinitionsQuery.fetcher;
  useGetCustomFieldDefinitionListQueryCustom.getKey =
    useGetCustomFieldDefinitionsQuery.getKey;

  return (
    <CustomFieldDefinitionsList
      // onBulkCreate={(customFieldDefinitions) =>
      //   bulkCreateCustomFieldDefinitions.mutateAsync({ newCustomFieldDefinitionsData: customFieldDefinitions })
      // }
      query={useGetCustomFieldDefinitionListQueryCustom}
      deleteMutation={useDeleteCustomFieldDefinitionMutation}
      onRefresh={() => {
        refetch();
      }}
    />
  );
}
