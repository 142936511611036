import { useDispatch } from "react-redux";
import {
  DialogState,
  hideDialog,
  showDialog,
} from "../../redux/slices/alert/Alert.slice";

export default function useDialog() {
  const dispatch = useDispatch();
  return {
    showDialog: (dialog: DialogState) => dispatch(showDialog(dialog)),
    hideDialog: () => dispatch(hideDialog()),
  };
}
