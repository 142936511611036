import { useGetAllDriversAndShiftsQuery } from "../../../graphql/generated";
import DriverShiftsCalendar, {
  DriverShiftsCalendarProps,
} from "./DriverShiftsCalendar";

type DriverShiftsCalendarContainerProps = Omit<
  DriverShiftsCalendarProps,
  "drivers" | "driverShifts"
>;

const DriverShiftsCalendarContainer: React.FC<DriverShiftsCalendarContainerProps> =
  (props: DriverShiftsCalendarContainerProps) => {
    const getDriversAndShiftsQuery = useGetAllDriversAndShiftsQuery();
    return (
      <DriverShiftsCalendar
        drivers={getDriversAndShiftsQuery.data?.drivers.data || []}
        driverShifts={getDriversAndShiftsQuery.data?.driverShifts.data || []}
        {...props}
      />
    );
  };

export default DriverShiftsCalendarContainer;
