import { useTranslation } from "react-i18next";
import { queryClient } from "../../../App";
import {
  useActivateTrialMutation,
  useCheckSubscriptionQuery,
  useGetSubscriptionInfoQuery,
  useGetSubscriptionPortalSessionMutation,
  useSetupOrganizationMutation,
} from "../../../graphql/generated";
import { getOrganizationId, useAuth } from "../../../providers/AuthProvider";
import useDialog from "../../../utils/hooks/useDialog";
import AccountSetupModal from "./AccountSetupModal";

const AccountSetupModalContainer = () => {
  const { t } = useTranslation(["account", "common"]);
  const { userInfo, refreshToken } = useAuth();
  const checkSubscriptionQuery = useCheckSubscriptionQuery(
    {},
    {
      refetchOnWindowFocus: false,
      refetchInterval(data, query) {
        if (data?.isSubscribed) {
          return false;
        }
        return 1000 * 2;
      },
    }
  );
  const getPortalSessionMutation = useGetSubscriptionPortalSessionMutation();
  const getSubscriptionInfoQuery = useGetSubscriptionInfoQuery();
  const activateTrialMutation = useActivateTrialMutation();

  const setupOrganizationMutation = useSetupOrganizationMutation();
  const { showDialog } = useDialog();
  const cameFromChargebee = document.referrer.includes("chargebee");
  const subscriptionSuccess =
    new URL(window.location.href).searchParams.get("state") === "succeeded";
  const isSubscribed =
    !checkSubscriptionQuery.data ||
    checkSubscriptionQuery.data.isSubscribed ||
    (cameFromChargebee && subscriptionSuccess);
  const currentSetupStep = userInfo
    ? !getOrganizationId(userInfo)
      ? "ORGANIZATION"
      : !isSubscribed
      ? "SUBSCRIPTION"
      : null
    : null;
  const needsSetup = !!currentSetupStep;

  if (!userInfo) {
    return null;
  }

  const hasTrial = !getSubscriptionInfoQuery.data?.subscriptionInfo;

  const onSubscribe = async () => {
    if (hasTrial) {
      await activateTrialMutation.mutateAsync({});
      await getSubscriptionInfoQuery.refetch();
      await checkSubscriptionQuery.refetch();
      await queryClient.refetchQueries();
    } else {
      const portalSession = await getPortalSessionMutation.mutateAsync({});
      window.location.href = portalSession.getSubscriptionPortalSession.url;
    }
  };

  return (
    <AccountSetupModal
      setupStep={currentSetupStep}
      needsSetup={needsSetup}
      hasTrial={hasTrial}
      isSettingUpOrganization={setupOrganizationMutation.isLoading}
      isSubscribing={
        activateTrialMutation.isLoading || getPortalSessionMutation.isLoading
      }
      error={
        checkSubscriptionQuery.error ||
        setupOrganizationMutation.error ||
        activateTrialMutation.error ||
        getPortalSessionMutation.error
      }
      onOrganizationSetup={async (orgName, dotNumber) => {
        try {
          if (!orgName) {
            showDialog({
              type: "error",
              title: t("error.setupOrgTitle", "Error setting up organization"),
              description: t(
                "error.setupOrgDesc",
                "Please indicate your organization name"
              ),
            });
            return;
          }
          await setupOrganizationMutation.mutateAsync({
            organizationSetupData: {
              name: orgName,
              dotNumber,
            },
          });
          await refreshToken();
          if (hasTrial) {
            await onSubscribe();
          }
        } catch (error) {
          showDialog({
            type: "error",
            title: t("error.setupOrgTitle", "Error setting up organization"),
            description:
              (error as Error).message ||
              t("common:error.unknownError", "Unknown error"),
          });
        }
      }}
      onSubscribe={onSubscribe}
    />
  );
};

export default AccountSetupModalContainer;
