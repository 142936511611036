import { useNavigate } from "react-router-dom";
import {
  Scalars,
  useAddReceiverForecastMutation,
  useEditReceiverForecastMutation,
  useGetReceiverForecastDetailsQuery,
} from "../../../graphql/generated";
import ReceiverForecastForm, {
  ReceiverForecastFormProps,
} from "./ReceiverForecastForm";
import { useDispatch } from "react-redux";
import { omit } from "lodash";
import { showDialog } from "../../../redux/slices/alert/Alert.slice";
import LoadingOverlay from "../../common/LoadingOverlay";
import { Alert } from "@mui/material";

type ReceiverForecastFormContainerProps = Omit<
  ReceiverForecastFormProps,
  "onSave" | "saving" | "initialReceiverForecast"
> & {
  receiverForecastId?: Scalars["ObjectId"];
};

const ReceiverForecastFormContainer = (
  props: ReceiverForecastFormContainerProps
) => {
  const addReceiverForecastMutation = useAddReceiverForecastMutation();
  const editReceiverForecastMutation = useEditReceiverForecastMutation();
  const { receiverForecastId } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getReceiverForecastQuery = useGetReceiverForecastDetailsQuery(
    {
      id: props.receiverForecastId || "",
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!props.receiverForecastId,
    }
  );

  const initialReceiverForecast =
    getReceiverForecastQuery.data?.receiverForecastById;

  if (props.receiverForecastId) {
    if (getReceiverForecastQuery?.isLoading) {
      return <LoadingOverlay loading />;
    }
    if (getReceiverForecastQuery?.error) {
      return (
        <Alert severity="error">
          An error occurred while fetching the receiver forecast.{" "}
          {(getReceiverForecastQuery?.error as Error).message ||
            "Unknown error"}
        </Alert>
      );
    }
  }

  return (
    <ReceiverForecastForm
      saving={
        addReceiverForecastMutation.isLoading ||
        editReceiverForecastMutation.isLoading
      }
      onSave={async (receiverForecast) => {
        try {
          if (receiverForecastId) {
            const result = await editReceiverForecastMutation.mutateAsync({
              id: receiverForecastId,
              editReceiverForecastData: omit(
                receiverForecast,
                "_id",
                "receiver"
              ),
            });
            navigate(`/forecasting/details/${result.editReceiverForecast._id}`);
          } else {
            const result = await addReceiverForecastMutation.mutateAsync({
              newReceiverForecastData: receiverForecast,
            });
            navigate(`/forecasting/details/${result.addReceiverForecast._id}`);
            window.analytics?.track("ReceiverForecast Created", {
              receiverForecastId: result.addReceiverForecast._id,
            });
          }
        } catch (error) {
          dispatch(
            showDialog({
              title: "Error",
              description:
                `An error occurred while ${
                  receiverForecastId ? "editing" : "creating"
                } the model. ` + (error as Error).message,
              type: "error",
            })
          );
        }
      }}
      initialReceiverForecast={initialReceiverForecast}
      {...props}
    />
  );
};

export default ReceiverForecastFormContainer;
