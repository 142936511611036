import { CustomFieldInputComponent } from "../CustomFieldsForm";
import { TextField } from "@mui/material";

const StringCustomFieldInput: CustomFieldInputComponent = ({
  value,
  customFieldDefinition,
  onChange,
}) => {
  return (
    <TextField
      onChange={(event) => {
        onChange(event.target.value);
      }}
      value={String(value || "")}
      size="medium"
      fullWidth
      label={customFieldDefinition.label}
    />
  );
};

export default StringCustomFieldInput;
