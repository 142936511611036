import { useGetTrailersQuery } from "../../../graphql/generated";
import TrailerSelect, { TrailerSelectProps } from "./TrailerSelect";

export default function TrailerSelectContainer(
  props: Omit<TrailerSelectProps, "trailers">
) {
  const getTrailersQuery = useGetTrailersQuery();
  return (
    <TrailerSelect
      trailers={getTrailersQuery.data?.trailers.data || []}
      {...props}
    />
  );
}
