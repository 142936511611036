import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import {
  endOfDay,
  endOfMonth,
  isValid,
  startOfDay,
  startOfMonth,
} from "date-fns";
import { useState } from "react";
import { Driver, Period, ReportType } from "../../../graphql/generated";
import driverLabel from "../../../utils/labels/driverLabel";
import reportTypeLabel from "../../../utils/labels/reportTypeLabel";
import { useTranslation } from "react-i18next";

export type ReportFilter = {
  driver?: string | null;
};

type ReportGeneratorProps = {
  onGenerateAndDownloadReport: (
    reportType: ReportType,
    period: Period,
    filter: ReportFilter
  ) => void;
  onSendReport: (
    reportType: ReportType,
    period: Period,
    filter: ReportFilter,
    email: string
  ) => void;
  generating: boolean;
  sending: boolean;
  drivers: Pick<Driver, "_id" | "firstname" | "lastname">[];
};

const ReportGenerator = ({
  generating,
  onGenerateAndDownloadReport,
  drivers,
  onSendReport,
  sending,
}: ReportGeneratorProps) => {
  const { t } = useTranslation("finance");
  const [reportType, setReportType] = useState<ReportType | null>(
    ReportType.RevenuePerDay
  );
  const [periodStart, setPeriodStart] = useState(startOfMonth(new Date()));
  const [periodEnd, setPeriodEnd] = useState(endOfMonth(new Date()));
  const [filter, setFilter] = useState<ReportFilter>({});
  const [emailDialogOpen, setEmailDialogOpen] = useState(false);
  const [email, setEmail] = useState("");

  return (
    <Box>
      <Typography variant="h6" sx={{ mb: 2 }}>
        {t(
          "reportSelectDescription",
          "Select a report you want to generate and download"
        )}
      </Typography>
      <Stack direction="row" spacing={2}>
        <Stack direction="column" spacing={2}>
          <Select
            label="Report type"
            size="small"
            value={reportType}
            onChange={(event) => {
              setReportType(event.target.value as ReportType);
              setFilter({});
              if (event.target.value === ReportType.TripSheet) {
                setPeriodStart(startOfDay(new Date()));
                setPeriodEnd(endOfDay(new Date()));
              }
            }}
          >
            {Object.values(ReportType).map((target) => (
              <MenuItem key={target} value={target}>
                <ListItemText primary={t(reportTypeLabel(target),reportTypeLabel(target))} />
              </MenuItem>
            ))}
          </Select>
          {reportType &&
          [ReportType.DriverMileagePerTrip, ReportType.TripSheet].includes(
            reportType
          ) ? (
            <Autocomplete
              disablePortal
              fullWidth
              options={drivers}
              getOptionLabel={driverLabel}
              onChange={(event, driver) => {
                setFilter({
                  driver: driver?._id,
                });
              }}
              value={
                drivers.find((driver) => driver._id === filter.driver) || null
              }
              size="small"
              renderInput={(inputProps) => (
                <TextField {...inputProps} label="Driver" />
              )}
            />
          ) : null}
        </Stack>
        <DatePicker
          value={periodStart}
          onChange={(value) => {
            if (!value) {
              return;
            }
            if (!isValid(value)) {
              return;
            }
            setPeriodStart(value);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
        <DatePicker
          value={periodEnd}
          onChange={(value) => {
            if (!value) {
              return;
            }
            if (!isValid(value)) {
              return;
            }
            setPeriodEnd(value);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
        <Stack spacing={1}>
          <LoadingButton
            variant="outlined"
            loading={generating}
            onClick={() => {
              if (!reportType) {
                return;
              }
              onGenerateAndDownloadReport(
                reportType,
                {
                  start: periodStart,
                  end: periodEnd,
                },
                filter
              );
            }}
            disabled={!reportType || generating}
          >
            {t("generateAndDownload", "Generate and download")}
          </LoadingButton>

          <LoadingButton
            variant="outlined"
            loading={sending}
            onClick={() => setEmailDialogOpen(true)}
          >
            {t("sendReportByEmail", "Send by email")}
          </LoadingButton>
        </Stack>
      </Stack>

      <Dialog
        open={emailDialogOpen}
        onClose={() => setEmailDialogOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          {t("sendReportByEmail", "Send report by email")}
        </DialogTitle>
        <DialogContent>
          <Stack
            direction="column"
            spacing={2}
            sx={{ p: 2 }}
            component="form"
            onSubmit={(event) => {
              event.preventDefault();
              if (!reportType) {
                return;
              }
              onSendReport(
                reportType,
                {
                  start: periodStart,
                  end: periodEnd,
                },
                filter,
                email
              );
              setEmail("");
              setEmailDialogOpen(false);
            }}
          >
            <TextField
              label="Email"
              type="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
            <LoadingButton type="submit" loading={sending} disabled={!email}>
              {t("send", "Send")}
            </LoadingButton>
          </Stack>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default ReportGenerator;
