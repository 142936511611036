import { Box } from "@mui/material";
import { ShipmentLocationType } from "../../../graphql/generated";

type LocationTypeIndicatorProps = {
  type: ShipmentLocationType;
};

const locationTypeToColor: Record<ShipmentLocationType, string> = {
  PICKUP: "primary.main",
  DROP_OFF: "accent.main",
  DROP_FOR_HOOK: "accent.main",
  TRAILER_PICKUP: "primary.main",
  BREAK: "info.main",
};

const LocationTypeIndicator = ({ type }: LocationTypeIndicatorProps) => {
  return (
    <Box
      sx={{
        width: 20,
        height: 20,
        borderRadius: 10,
        backgroundColor: "white",
        borderColor: locationTypeToColor[type],
        borderWidth: 2,
        borderStyle: "solid",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 0,
      }}
    >
      <Box
        sx={{
          width: 12,
          height: 12,
          borderRadius: 6,
          backgroundColor: locationTypeToColor[type],
        }}
      />
    </Box>
  );
};

export default LocationTypeIndicator;
