import { Autocomplete, Grid, TextField } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import { Address, TextualAddress } from "../../../graphql/generated";
import { useTranslation } from "react-i18next";

type AddressInputProps = {
  value:
    | (TextualAddress & { label?: never; coordinates?: never })
    | Address
    | null;
  onChange: (address: Partial<Address> | null) => void;
  disableAutocomplete?: boolean;
  disableDetails?: boolean;
  disabled?: boolean;
  required?: boolean;
  label?: string;
  name?: string;
  errors?: {
    label?: string;
    coordinates?: string;
    line1?: string;
    line2?: string;
    city?: string;
    postalCode?: string;
    state?: string;
    country?: string;
  };
  horizontal?: boolean;
  searchOptions?: PlacesAutocomplete["props"]["searchOptions"];
};

export const gmapsPlaceToAddress = (
  place: google.maps.places.PlaceResult,
  searchedAddress?: string
): Address | null => {
  const location = place.geometry?.location;
  const lat = location?.lat();
  const lng = location?.lng();
  if (!lat || !lng) {
    return null;
  }
  return {
    label: place.formatted_address || place.name || searchedAddress || "N/A",
    coordinates: {
      latitude: lat,
      longitude: lng,
    },
    country: findComponentInPlace(place, "country"),
    state: findComponentInPlace(place, "administrative_area_level_1"),
    city: findComponentInPlace(place, "locality"),
    postalCode: findComponentInPlace(place, "postal_code"),
    line1:
      `${findComponentInPlace(place, "street_number") || ""} ${
        findComponentInPlace(place, "route") || ""
      }`.trim() || place.name?.split(",")[0],
    line2: findComponentInPlace(place, "subpremise"),
    googlePlaceId: place.place_id,
  };
};

export default function AddressInput({
  value,
  onChange,
  errors,
  disableAutocomplete,
  disableDetails,
  disabled,
  required,
  label,
  name,
  horizontal,
  searchOptions,
}: AddressInputProps) {
  const { t } = useTranslation(["common"]);
  const [address, setAddress] = useState(value?.label || "");

  useEffect(() => {
    const latLngRegex = /(-*[0-9]+\.[0-9]+),\s*(-*[0-9]+\.[0-9]+)/;
    const parseResult = latLngRegex.exec(address);
    if (!parseResult) {
      return;
    }
    onChange({
      ...value,
      coordinates: {
        latitude: parseFloat(parseResult[1]),
        longitude: parseFloat(parseResult[2]),
      },
      label: address,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  const onSelect = (address: string, placeID: string) => {
    const placesService = new google.maps.places.PlacesService(
      new google.maps.Map(document.createElement("div"))
    );
    placesService.getDetails(
      {
        placeId: placeID,
      },
      (place) => {
        if (!place) {
          return;
        }
        const parsedAddress = gmapsPlaceToAddress(place, address);
        onChange(parsedAddress);
      }
    );
  };

  const showComponents =
    !disableDetails &&
    (disableAutocomplete || !!(value?.label && value.coordinates));

  const onAutocompleteInputChange = useCallback(
    (address: string) => {
      setAddress(address);
      if (!address) {
        onChange(null);
      }
    },
    [onChange]
  );

  return (
    <Grid container spacing={!showComponents ? 0 : 3}>
      {disableAutocomplete ? null : (
        <Grid item xs={12}>
          <PlacesAutocomplete
            value={address}
            onChange={onAutocompleteInputChange}
            onSelect={onSelect}
            searchOptions={searchOptions}
          >
            {({ getInputProps, suggestions, loading }) => {
              const inputProps = getInputProps();
              return (
                <Autocomplete
                  options={suggestions}
                  fullWidth
                  filterOptions={(x) => x}
                  disabled={disabled}
                  clearOnEscape={false}
                  clearOnBlur={false}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required={required}
                      label={
                        label ||
                        t("common:address.searchPlaces", "Search places")
                      }
                      placeholder="e.g. 5315 FM Road or 39.9671, -82.9967"
                      error={!!errors?.label}
                      helperText={errors?.label}
                      name={`${name || "addressInput"}.label`}
                    />
                  )}
                  inputValue={inputProps.value || ""}
                  onInputChange={(event, textValue) => {
                    inputProps.onChange({
                      target: {
                        value: textValue,
                      },
                    });
                  }}
                  loading={loading}
                  getOptionLabel={(option) => option.description}
                  onChange={(event, suggestion) => {
                    if (!suggestion) {
                      return;
                    }
                    onSelect(address, suggestion.placeId);
                  }}
                  noOptionsText={t("common:address.noOptions", "No options")}
                />
              );
            }}
          </PlacesAutocomplete>
        </Grid>
      )}
      {!disableDetails ? (
        <>
          <Grid item xs={12} sm={horizontal ? 6 : 12}>
            {showComponents ? (
              <TextField
                label={t("address.line1")}
                required={required}
                fullWidth
                name={`${name || "addressInput"}.line1`}
                value={value?.line1 || ""}
                disabled={disabled}
                error={!!errors?.line1}
                helperText={errors?.line1}
                onChange={(event) =>
                  onChange({
                    ...value,
                    line1: event.target.value,
                  })
                }
              />
            ) : null}
          </Grid>
          <Grid item xs={12} sm={horizontal ? 6 : 12}>
            {showComponents ? (
              <TextField
                label={t("address.line2")}
                fullWidth
                name="addressInput.line2"
                value={value?.line2 || ""}
                disabled={disabled}
                error={!!errors?.line2}
                helperText={errors?.line2}
                onChange={(event) =>
                  onChange({
                    ...value,
                    line2: event.target.value,
                  })
                }
              />
            ) : null}
          </Grid>
          <Grid item xs={12} sm={horizontal ? 3 : 12}>
            {showComponents ? (
              <TextField
                label={t("address.city")}
                required={required}
                fullWidth
                name={`${name || "addressInput"}.city`}
                value={value?.city || ""}
                disabled={disabled}
                error={!!errors?.city}
                helperText={errors?.city}
                onChange={(event) =>
                  onChange({
                    ...value,
                    city: event.target.value,
                  })
                }
              />
            ) : null}
          </Grid>
          <Grid item xs={12} sm={horizontal ? 3 : 12}>
            {showComponents ? (
              <TextField
                label={t("address.state")}
                required={required}
                fullWidth
                name={`${name || "addressInput"}.state`}
                value={value?.state || ""}
                disabled={disabled}
                error={!!errors?.state}
                helperText={errors?.state}
                onChange={(event) =>
                  onChange({
                    ...value,
                    state: event.target.value,
                  })
                }
              />
            ) : null}
          </Grid>
          <Grid item xs={12} sm={horizontal ? 3 : 12}>
            {showComponents ? (
              <TextField
                label={t("address.postalCode")}
                required={required}
                fullWidth
                name={`${name || "addressInput"}.postalCode`}
                value={value?.postalCode || ""}
                disabled={disabled}
                error={!!errors?.postalCode}
                helperText={errors?.postalCode}
                onChange={(event) =>
                  onChange({
                    ...value,
                    postalCode: event.target.value,
                  })
                }
              />
            ) : null}
          </Grid>
          <Grid item xs={12} sm={horizontal ? 3 : 12}>
            {showComponents ? (
              <TextField
                label={t("address.country")}
                required={required}
                fullWidth
                name={`${name || "addressInput"}.country`}
                value={value?.country || ""}
                error={!!errors?.country}
                helperText={errors?.country}
                disabled={disabled}
                onChange={(event) =>
                  onChange({
                    ...value,
                    country: event.target.value,
                  })
                }
              />
            ) : null}
          </Grid>
        </>
      ) : null}
    </Grid>
  );
}

const findComponentInPlace = (
  place: google.maps.places.PlaceResult,
  componentType: string
) => {
  return (
    place.address_components?.find((component) =>
      component.types.includes(componentType)
    )?.long_name || null
  );
};
