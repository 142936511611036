import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { GetTripListQuery, Scalars } from "../../../graphql/generated";
import {
  shipmentLocationAddressLabel,
  shipmentLocationLabel,
} from "../../../utils/labels/shipmentLocationLabel";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { CircleOutlined, DragIndicator } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { arrayMoveImmutable } from "array-move";
import { LoadingButton } from "@mui/lab";
import LocationTypeIndicator from "../../common/LocationTypeIndicator/LocationTypeIndicator";
import { useTranslation } from "react-i18next";

type OrderEditTrip = GetTripListQuery["trips"]["data"][0];

export type TripOrderEditModalProps = {
  open: boolean;
  editing: boolean;
  onClose: () => void;
  onSubmit: (tripId: string, locationsOrder: Scalars["ObjectId"][]) => void;
  trip: OrderEditTrip;
};

const TripOrderEditModal = ({
  trip,
  editing,
  open,
  onSubmit,
  onClose,
}: TripOrderEditModalProps) => {
  const [locations, setLocations] = useState<
    typeof trip.shipmentLocations[0][]
  >(trip.shipmentLocations);
  const { t } = useTranslation(["common", "trips"]);

  useEffect(() => {
    setLocations(trip.shipmentLocations);
  }, [trip]);

  if (!locations) {
    return null;
  }
  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={() => onClose()}>
      <DialogTitle>{t("trips:reorder.title")}</DialogTitle>
      <DialogContent dividers>
        <SortableRouteLocations
          locations={locations}
          onSortEnd={({ oldIndex, newIndex }) => {
            const newLocations = arrayMoveImmutable(
              locations,
              oldIndex,
              newIndex
            );
            setLocations(newLocations);
          }}
          onChange={(newLocations) => {
            setLocations(newLocations);
          }}
          helperClass="sortableZindexHelper"
        />
      </DialogContent>

      <DialogActions>
        <Button
          autoFocus
          color="inherit"
          variant="contained"
          onClick={() => onClose()}
        >
          {t("cancel")}
        </Button>
        <LoadingButton
          color="primary"
          variant="contained"
          onClick={() => {
            onSubmit(
              trip._id,
              locations.map((location) => location._id)
            );
          }}
          id="vaidate-reorder-button"
          loading={editing}
        >
          {t("save")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const SortableRouteLocations = SortableContainer(
  ({
    locations,
    onChange,
  }: {
    locations: OrderEditTrip["shipmentLocations"];
    onChange: (locations: OrderEditTrip["shipmentLocations"]) => void;
  }) => {
    return (
      <Stack spacing={2} divider={<Divider />}>
        {locations.map((location, index) => (
          <SortableRouteLocationItem
            location={location}
            key={location._id}
            index={index}
            onChange={(changedLocation) =>
              onChange(
                locations.map((loc) =>
                  loc === location ? changedLocation : loc
                )
              )
            }
          />
        ))}
      </Stack>
    );
  }
);

const SortableRouteLocationItem = SortableElement(
  ({
    location,
    onChange,
  }: {
    location: OrderEditTrip["shipmentLocations"][0];
    onChange: (location: OrderEditTrip["shipmentLocations"][0]) => void;
  }) => {
    // const arrivalFromDate = location.arrivalTimeWindows?.[0]?.fromDate;
    // const arrivalToDate = location.arrivalTimeWindows?.[0]?.toDate;
    // const departureFromDate = location.departureTimeWindows?.[0]?.fromDate;
    // const departureToDate = location.departureTimeWindows?.[0]?.toDate;

    return (
      <Stack direction="row" alignItems={"center"} spacing={2}>
        <SortableRouteLocationItemDragHandle location={location} />
        <LocationTypeIndicator type={location.locationType} />
        <Stack>
          <Typography
            sx={{
              flex: 1,
              mr: 5,
            }}
          >
            {shipmentLocationLabel(location)}
          </Typography>
          <Typography variant="caption">
            {shipmentLocationAddressLabel(location)}
          </Typography>
          {/* <Stack direction="row" spacing={1}>
            <DateTimePicker
              label={
                location.locationType === ShipmentLocationType.Pickup
                  ? "Pickup time start"
                  : "Delivery time start"
              }
              value={
                arrivalFromDate
                  ? utcToZonedTime(
                      arrivalFromDate,
                      location.addressTimezone || fallbackTimezone
                    )
                  : null
              }
              disabled
              renderInput={(params) => <TextField {...params} />}
              onChange={() => {}}
            />

            <DateTimePicker
              label={
                location.locationType === ShipmentLocationType.Pickup
                  ? "Pickup time end"
                  : "Delivery time end"
              }
              value={
                arrivalToDate
                  ? utcToZonedTime(
                      arrivalToDate,
                      location.addressTimezone || fallbackTimezone
                    )
                  : null
              }
              disabled
              renderInput={(params) => <TextField {...params} />}
              onChange={() => {}}
            />
          </Stack> */}
        </Stack>
      </Stack>
    );
  }
);

const SortableRouteLocationItemDragHandle = SortableHandle(
  ({ location }: { location: OrderEditTrip["shipmentLocations"][0] }) => (
    <Box
      className="drag-handle"
      sx={{
        cursor: "move",
        mr: 2,
      }}
    >
      {true ? (
        <DragIndicator color="primary" />
      ) : (
        <CircleOutlined color="disabled" />
      )}
    </Box>
  )
);

export default TripOrderEditModal;
