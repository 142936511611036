import { useTranslation } from "react-i18next";
import {
  useUpdateTripOrderMutation,
  useValidateTripOrderMutation,
  ViolationCause,
} from "../../../graphql/generated";
import useDialog from "../../../utils/hooks/useDialog";
import TripOrderEditModal, {
  TripOrderEditModalProps,
} from "./TripOrderEditModal";
import { useConfirm } from "material-ui-confirm";
import { Stack, Typography } from "@mui/material";
import TripViolations from "../../trip/TripDetails/TripViolations";

type TripOrderEditModalContainerProps = Omit<
  TripOrderEditModalProps,
  "onSubmit" | "editing"
> & {};

const TripOrderEditModalContainer = ({
  ...props
}: TripOrderEditModalContainerProps) => {
  const { t } = useTranslation(["orders", "common", "trips"]);
  const validateTripOrderMutation = useValidateTripOrderMutation();
  const updateTripOrderMutation = useUpdateTripOrderMutation();
  const { showDialog } = useDialog();
  const confirm = useConfirm();
  return (
    <TripOrderEditModal
      {...props}
      editing={
        updateTripOrderMutation.isLoading || validateTripOrderMutation.isLoading
      }
      onSubmit={async (tripId, locationsOrder) => {
        try {
          const { validateTripOrder: violations } =
            await validateTripOrderMutation.mutateAsync({
              data: {
                tripId,
                shipmentLocationsOrder: locationsOrder,
              },
            });
          if (violations?.length) {
            if (violations.find((v) => v.cause === ViolationCause.Precedence)) {
              throw new Error(
                `Invalid trip order. Make sure deliveries don't occur before pickups`
              );
            }
            try {
              await confirm({
                title: "Violations Detected",
                description: (
                  <Stack spacing={2}>
                    <Typography>
                      Some violations were detected when validating the trip:{" "}
                      <br />
                      <TripViolations
                        trip={{
                          shipmentLocations: props.trip.shipmentLocations,
                          violations,
                        }}
                      />
                    </Typography>
                  </Stack>
                ),
                confirmationText: "Continue with errors",
                cancellationText: "Cancel",
                confirmationButtonProps: {
                  color: "secondary",
                  variant: "contained",
                },
              });
            } catch (error) {
              // If the user cancels the confirmation, we should not proceed
              if (!error) {
                return;
              }
              throw error;
            }
          }
          await updateTripOrderMutation.mutateAsync({
            data: {
              tripId,
              shipmentLocationsOrder: locationsOrder,
            },
          });
          props.onClose();
        } catch (error) {
          showDialog({
            title: t("common:error.title", "Error"),
            description:
              t("trips:reorder.error") + (error as Error)?.message ||
              t("common:error.unknownError", "Unknown error"),
            type: "error",
          });
        }
      }}
    />
  );
};

export default TripOrderEditModalContainer;
