import { Tractor } from "../../graphql/generated";

function tractorLabel(
  tractor: Pick<Tractor, "serialNumber"> | null | undefined
) {
  if (!tractor) {
    return "N/A";
  }
  return tractor.serialNumber || "N/A";
}

export default tractorLabel;
