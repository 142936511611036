import { Alert } from "@mui/material";
import { omit, toArray } from "lodash";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  GetTrailerDetailsQuery,
  useAddTrailerMutation,
  useEditTrailerMutation,
  useGetOwnedTrailerTypesQuery,
  useGetTrailerDetailsQuery,
} from "../../../graphql/generated";
import { showDialog } from "../../../redux/slices/alert/Alert.slice";
import LoadingOverlay from "../../common/LoadingOverlay";
import TrailerForm from "./TrailerForm";
import { useTranslation } from "react-i18next";

type TrailerFormContainerProps = {
  trailerId?: string;
  onLoad?: (trailer: GetTrailerDetailsQuery["trailerById"]) => void;
};

function TrailerFormContainer({
  trailerId,
  onLoad,
}: TrailerFormContainerProps) {
  const { t } = useTranslation(["assets", "common"]);
  const initialTrailerId = useRef(trailerId);
  const ownedTrailerTypesQuery = useGetOwnedTrailerTypesQuery();
  const addTrailerMutation = useAddTrailerMutation();
  const editTrailerMutation = useEditTrailerMutation();
  // We call this hook conditionally because the component
  // should never rerender with a different value for
  // businessEntityId, but if it does we don't update
  // the ref anyway so this is safe
  const getTrailerQuery = initialTrailerId.current
    ? // eslint-disable-next-line react-hooks/rules-of-hooks
      useGetTrailerDetailsQuery(
        {
          id: initialTrailerId.current,
        },
        {
          refetchOnWindowFocus: false,
          retry: false,
        }
      )
    : null;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (getTrailerQuery?.data?.trailerById) {
      onLoad?.(getTrailerQuery.data?.trailerById);
    }
  }, [onLoad, getTrailerQuery?.data]);

  if (trailerId) {
    if (getTrailerQuery?.isLoading) {
      return <LoadingOverlay loading />;
    }
    if (getTrailerQuery?.error) {
      return (
        <Alert severity="error">
          {t(
            "error.fetchTrailerError",
            "An error occurred while fetching the trailer"
          )}
          .{" "}
          {(getTrailerQuery?.error as Error).message ||
            t("common:error.unknownError", "Unknown error")}
        </Alert>
      );
    }
  }

  return (
    <TrailerForm
      ownedTrailerTypes={ownedTrailerTypesQuery.data?.ownedTrailerTypes || []}
      initialTrailer={getTrailerQuery?.data?.trailerById}
      saving={addTrailerMutation.isLoading || editTrailerMutation.isLoading}
      onSave={async (trailer) => {
        try {
          if (trailerId) {
            const result = await editTrailerMutation.mutateAsync({
              id: trailerId,
              editTrailerData: omit(trailer, "_id"),
            });
            navigate(`/trailers/details/${toArray(result)[0]._id}`);
          } else {
            const result = await addTrailerMutation.mutateAsync({
              newTrailerData: trailer,
            });
            navigate(`/trailers/details/${toArray(result)[0]._id}`);
            window.analytics?.track("Trailer Created", {
              trailerId: result.addTrailer._id,
            });
            window.analytics?.identify({
              trailerCreated: true,
              lastTrailerCreationDate: new Date(),
              lastTrailerCreationDateOnly: new Date()
                .toISOString()
                .split("T")[0],
              numberOfTrailersCreated:
                (window.analytics?.user?.()?.traits?.()
                  ?.numberOfTrailersCreated || 0) + 1,
            });
            window.analytics?.group(window.analytics?.group?.()?.id?.(), {
              trailerCreated: true,
              lastTrailerCreationDate: new Date(),
              lastTrailerCreationDateOnly: new Date()
                .toISOString()
                .split("T")[0],
              numberOfTrailersCreated:
                (window.analytics?.group?.()?.traits?.()
                  ?.numberOfTrailersCreated || 0) + 1,
            });
          }
        } catch (error) {
          dispatch(
            showDialog({
              title: t("common:error.title", "Error"),
              description:
                t("error.createEditTrailer", {
                  action: trailerId
                    ? t("common:editing", "editing")
                    : t("common:creating", "creating"),
                  defaultValue: `An error occurred while ${
                    trailerId ? "editing" : "creating"
                  } the trailer. `,
                }) + (error as Error).message,
              type: "error",
            })
          );
        }
      }}
    />
  );
}

export default TrailerFormContainer;
