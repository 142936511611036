import { TextField, TextFieldProps } from "@mui/material";
import { isNaN, omit } from "lodash";
import { useEffect, useState } from "react";

const NumberTextField = ({
  allowDecimal = true,
  ...props
}: TextFieldProps & { allowDecimal?: boolean }) => {
  const { value, onChange, inputProps, ...rest } = props;
  const [localValue, setLocalValue] = useState(value);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      !allowDecimal &&
      (event.target.value.includes(".") || event.target.value.includes(","))
    ) {
      return;
    }
    if (event.target.value === "." || event.target.value === ",") {
      setLocalValue(`0${event.target.value}`);
      onChange?.(event);
      return;
    }
    if (!event.target.value) {
      setLocalValue(event.target.value);
      onChange?.(event);
    } else if (
      event.target.value === "-" &&
      ([null, undefined].includes(inputProps?.min) || inputProps?.min < 0)
    ) {
      setLocalValue(event.target.value);
    } else if (/^[-]*[0-9]+[.,]*[0-9]*$/.test(event.target.value)) {
      const parsedNumber = Number.parseFloat(
        event.target.value.replace(/,/g, ".")
      );
      if (isNaN(parsedNumber)) {
        return;
      }
      if (
        ![null, undefined].includes(inputProps?.min) &&
        parsedNumber < inputProps?.min
      ) {
        return;
      }
      setLocalValue(event.target.value);
      event.target.value = event.target.value.replace(/,/g, ".");
      onChange?.(event);
    }
  };

  useEffect(() => {
    if (localValue === "-") {
      return;
    }
    if (!areEquivalentValues(value as string, localValue as string)) {
      setLocalValue(value);
    }
  }, [value, localValue]);
  return (
    <TextField
      value={localValue}
      onChange={handleChange}
      inputProps={omit(inputProps, "type", "min")}
      {...rest}
    />
  );
};

const areEquivalentValues = (a: string, b: string) => {
  if (a === b) {
    return true;
  }
  if (Number.parseFloat(a) === Number.parseFloat(b)) {
    return true;
  }
  if (
    Number.parseFloat(String(a)?.replace(/,/g, ".")) ===
    Number.parseFloat(String(b)?.replace(/,/g, "."))
  ) {
    return true;
  }
  if (!a && !b) {
    return true;
  }
  if (
    (Number.parseFloat(a) === 0 && !b) ||
    (Number.parseFloat(b) === 0 && !a)
  ) {
    return true;
  }
  return false;
};

export default NumberTextField;
