import {
  ShipmentLocation,
  ShipmentLocationType,
} from "../../graphql/generated";

export const isPickupLocation = (
  location: Pick<ShipmentLocation, "locationType">
) => {
  return [
    ShipmentLocationType.Pickup,
    ShipmentLocationType.TrailerPickup,
  ].includes(location.locationType);
};

export const isDropoffLocation = (
  location: Pick<ShipmentLocation, "locationType">
) => {
  return [
    ShipmentLocationType.DropOff,
    ShipmentLocationType.DropForHook,
  ].includes(location.locationType);
};
