import { Tab, Tabs, Typography } from "@mui/material";
import React, { memo } from "react";
import theme from "../../theme";

type LynksTab = {
  value: any;
  label: string;
  hidden?: boolean;
};

type LynksTabsProps = {
  tabs: Array<LynksTab>;
  value: any;
  onSelectTab: (tab: LynksTab["value"]) => void;
};

const LynksTabs = memo(({ tabs, onSelectTab, value }: LynksTabsProps) => (
  <Tabs
    TabIndicatorProps={{
      style: {
        display: "none",
      },
    }}
    sx={{
      width: "100%",
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: theme.palette.primaryBackground.dark,
      borderRadius: 2,
      mb: 2,
    }}
    value={value}
    onChange={(event, value) => {
      onSelectTab(value);
    }}
    aria-label="users filter tab"
    variant="scrollable"
    scrollButtons="auto"
    allowScrollButtonsMobile
  >
    {tabs.map((tab, i) => {
      if (tab.hidden) {
        return null;
      }
      const isActive = value === tab.value;
      return (
        <Tab
          key={tab.value}
          sx={{
            flex: 1,
            maxWidth: "none",
            backgroundColor: isActive
              ? theme.palette.primaryBackground.main
              : "white",
            borderRightWidth: i < tabs.length - 1 ? 1 : 0,
            borderStyle: "solid",
            borderColor: theme.palette.primaryBackground.dark,
          }}
          label={
            <Typography
              fontWeight={isActive ? "600" : "500"}
              fontSize={13}
              color="primary"
            >
              {tab.label}
            </Typography>
          }
          value={tab.value}
        />
      );
    })}
  </Tabs>
));

export default LynksTabs;
