import { Box, Typography } from "@mui/material";
import notFoundImage from "../../../assets/images/not-found.svg";
import { useTranslation } from "react-i18next";

const EmptyState = ({
  title,
  message,
  showImage = true,
}: {
  title?: string;
  message?: string;
  showImage?: boolean;
}) => {
  const { t } = useTranslation("common");
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: showImage ? "75px" : 0,
      }}
    >
      {showImage ? <img src={notFoundImage} alt="not-found" /> : null}
      <Typography
        sx={{
          color: "black",
          mt: showImage ? "26px" : 0,
          fontWeight: "500",
          fontSize: 20,
        }}
      >
        {title || t("list.noResultFound", "No result found")}
      </Typography>
      {message && (
        <Typography
          sx={{
            color: "black",
            fontWeight: "300",
            fontSize: 15,
          }}
        >
          {message}
        </Typography>
      )}
    </Box>
  );
};

export default EmptyState;
