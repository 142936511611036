import {
  Autocomplete,
  AutocompleteProps,
  createFilterOptions,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { GoodProfile } from "../../../graphql/generated";
import { capitalize, sortBy } from "lodash";
import { useTranslation } from "react-i18next";

enum AutocompleteOptionType {
  GoodProfile = "GoodProfile",
  Address = "Address",
}

type AutocompleteOption = {
  id: string;
  label: string;
  type: AutocompleteOptionType;
};

const filter = createFilterOptions<AutocompleteOption>();

type SelectGoodProfile = Pick<GoodProfile, "label" | "code" | "_id">;

export type GoodProfileSelectProps = Omit<
  AutocompleteProps<string, true, undefined, undefined>,
  | "options"
  | "disablePortal"
  | "filterOptions"
  | "getOptionLabel"
  | "size"
  | "renderInput"
  | "onChange"
> & {
  goodProfiles?: Array<SelectGoodProfile>;
  onGoodProfilesRefetch: () => Promise<void>;
  onChange: (goodProfileIds: string[] | null) => void;
  inputProps?: TextFieldProps;
};

export default function GoodProfileSelect({
  goodProfiles,
  onGoodProfilesRefetch,
  inputProps: providedInputProps,
  onChange,
  ...props
}: GoodProfileSelectProps) {
  const { t } = useTranslation("assets");
  const getBeOptionName = (goodProfileId: string) => {
    const goodProfile = goodProfiles?.find((be) => be._id === goodProfileId);
    if (!goodProfile) {
      return "";
    }
    return `(${goodProfile.code}) ${goodProfile.label}`;
  };

  const rolodexAutocompleteOptions = sortBy(
    goodProfiles || [],
    (be) => be.label
  ).map((be) => ({
    id: be._id,
    label: getBeOptionName(be._id),
    type: AutocompleteOptionType.GoodProfile,
  }));

  return (
    <>
      <Autocomplete
        options={rolodexAutocompleteOptions}
        value={rolodexAutocompleteOptions.filter(
          (option) => props.value?.includes(option.id) || false
        )}
        multiple={props.multiple}
        getOptionLabel={(option) => option.label}
        filterOptions={(options, params) => {
          const filtered = filter(
            options.filter(
              (option) => option.type === AutocompleteOptionType.GoodProfile
            ),
            params
          );
          return filtered;
        }}
        onChange={(event, newValue) => {
          onChange(newValue.map((option) => option.id));
        }}
        size="small"
        renderInput={(inputProps) => (
          <TextField
            name={"commodity-profile"}
            required
            {...inputProps}
            {...providedInputProps}
            label={capitalize(t("commodity.one", "Commodity"))}
          />
        )}
        fullWidth
      />
    </>
  );
}
