import {
  useBulkAddDriversMutation,
  useBulkAddOrgUsersMutation,
  useDeleteGroupMutation,
  useGetGroupsQuery,
  useGetOrgUsersListQuery,
  useGetRolesQuery,
} from "../../../graphql/generated";

import UserList, { UserRole } from "./UserList";

export default function UserListContainer() {
  const bulkCreateUsers = useBulkAddOrgUsersMutation();
  const getRolesQuery = useGetRolesQuery();
  const roles = getRolesQuery.data?.roles || [];

  const bulkCreateDrivers = useBulkAddDriversMutation();

  let groupsRefetch = () => {};
  // I need a handle on refetch so I can call it
  // a new group has been created
  const useGetGroupsQueryCustom: typeof useGetGroupsQuery = (
    variables,
    options
  ) => {
    const query = useGetGroupsQuery(variables, {
      ...options,
    });
    groupsRefetch = query.refetch;
    return query;
  };
  useGetGroupsQueryCustom.fetcher = useGetGroupsQuery.fetcher;
  useGetGroupsQueryCustom.getKey = useGetGroupsQuery.getKey;

  return (
    <UserList
      queryHook={useGetOrgUsersListQuery}
      onBulkCreateDrivers={(drivers) =>
        bulkCreateDrivers.mutateAsync({ newDriverData: drivers })
      }
      onBulkCreateAdmins={(users) =>
        bulkCreateUsers.mutateAsync({
          newOrgUsersData: users.map((user) => ({
            ...user,
            roles: roles.filter((r) => r.name === UserRole.CARRIER_ADMIN),
          })),
        })
      }
      groupsQueryHook={useGetGroupsQueryCustom}
      groupDeleteMutation={useDeleteGroupMutation}
      onGroupsRefresh={() => {
        groupsRefetch();
      }}
    />
  );
}
