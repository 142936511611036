import React from "react";
import {
  Divider,
  Grid,
  TextField,
  Typography,
  Stack,
  IconButton,
  Box,
  InputAdornment,
  Alert,
  useMediaQuery,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import {
  BusinessEntityType,
  useGetBusinessEntityDetailsQuery,
} from "../../../../graphql/generated";
import ShipperForm from "../ShipperForm";
import ReceiverForm from "../ReceiverForm";
import { useDispatch } from "react-redux";

import {
  FieldErrors,
  FormError,
  NewShipmentInputData,
  ShipmentLocationInputData,
} from "../../../../redux/slices/Types";
import {
  addReceiver,
  addShipper,
  editShipment,
  removeShipperOrReceiver,
} from "../../../../redux/slices/shipment/ShipmentForm.slice";
import AddButton from "../../../common/AddButton";
import {
  hideDialog,
  showDialog,
} from "../../../../redux/slices/alert/Alert.slice";
import BusinessEntitySelectContainer from "../BusinessEntitySelect";
import ShipmentRecurrenceForm from "../ShipmentRecurrenceForm/ShipmentRecurrenceForm";
import { Repeat } from "@mui/icons-material";
import { fromPairs, without } from "lodash";
import theme from "../../../../theme";
import ShipmentReferencesForm, {
  referenceNumberFields,
  referenceNumberTypeToShipmentField,
} from "../ShipmentReferencesForm/ShipmentReferencesForm";
import { useTranslation } from "react-i18next";
import {
  isDropoffLocation,
  isPickupLocation,
} from "../../../../utils/location/isPickupLocation";

interface ShipmentLocationProps {
  onChange: (name: string, value: string | null | undefined) => void;
  onUpdate: (updates: Partial<NewShipmentInputData>) => void;
  errors?: FieldErrors;
  shipmentLocations: Array<ShipmentLocationInputData>;
  shipmentLocationErrors: Array<FormError>;
  shipment: NewShipmentInputData;
  isChildLoad?: boolean;
  isSplit?: boolean;
}

export default function ShipmentDetailsForm({
  onChange,
  onUpdate,
  errors,
  shipment,
  shipmentLocations,
  shipmentLocationErrors,
  isChildLoad,
  isSplit,
}: ShipmentLocationProps) {
  const { t } = useTranslation(["orders", "common"]);
  const shippers = shipmentLocations.filter(isPickupLocation);

  const receivers = shipmentLocations.filter(isDropoffLocation);

  const dispatch = useDispatch();

  const handleAddReceiverClick = () => {
    dispatch(addReceiver());
  };

  const handleAddShipperClick = () => {
    dispatch(addShipper());
  };

  const handleRemoveShipperOrReceiver = (type: string, id: string) => {
    dispatch(
      showDialog({
        title: t("common:error.dangerZone", "Danger zone"),
        description: t(
          `error.deleteShipperReceiverConfirmation.${type}`,
          `Do you really want to delete this ${type} ? This action cannot be undone.`
        ),
        type: "error",
        actions: [
          {
            type: "error",
            title: t("common:error.yesDelete", "Yes, Delete"),
            onClick: async () => {
              dispatch(removeShipperOrReceiver(id));
              dispatch(hideDialog());
            },
          },
          {
            type: "primary",
            title: t("common:error.noCancel", "No, Cancel"),
          },
        ],
      })
    );
  };

  const handleDailyRotationCountChange = (count: number | null) => {
    dispatch(
      editShipment({
        ...shipment,
        dailyRotationCount: count,
      })
    );
  };

  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Grid sx={{ mt: 2, p: 1 }} container>
      <Grid container item spacing={2}>
        <Grid item xs={12}>
          <BusinessEntitySelectContainer
            businessEntityType={BusinessEntityType.Customer}
            value={shipment?.customer || null}
            onChange={async (customerId) => {
              onChange("customer", customerId || "");
              if (customerId) {
                const selectedCustomerResponse =
                  await useGetBusinessEntityDetailsQuery.fetcher({
                    id: customerId,
                  })();

                const selectedCustomer =
                  selectedCustomerResponse.businessEntityById;

                if (selectedCustomer) {
                  const nonSelectedReferenceNumberFields = without(
                    referenceNumberFields,
                    ...(selectedCustomer.referenceNumberTypes || []).map(
                      (type) => referenceNumberTypeToShipmentField[type]
                    )
                  );
                  const removedReferencesUpdate = fromPairs(
                    nonSelectedReferenceNumberFields.map((field) => [field, []])
                  );

                  const referenceNumberUpdates = fromPairs(
                    (selectedCustomer.referenceNumberTypes || []).map(
                      (referenceNumberType) => {
                        const referenceNumberField =
                          referenceNumberTypeToShipmentField[
                            referenceNumberType
                          ];
                        const existing = shipment[referenceNumberField];
                        return [
                          referenceNumberField,
                          existing && existing.length ? existing : [""],
                        ];
                      }
                    )
                  );
                  onUpdate({
                    ...removedReferencesUpdate,
                    ...referenceNumberUpdates,
                    customer: customerId,
                  });
                }
              }
            }}
            inputProps={{
              error: errors && errors["customer"] ? true : false,
              helperText: errors && errors["customer"],
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <ShipmentReferencesForm
            shipment={shipment}
            onUpdate={onUpdate}
            errors={errors}
          />
        </Grid>
        <Grid item sm={12}>
          {shipment ? (
            <ShipmentRecurrenceForm
              shipment={shipment}
              onUpdate={onUpdate}
              isChildLoad={isChildLoad}
              validationResult={null}
            />
          ) : null}
        </Grid>
      </Grid>

      {isSplit ? (
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Alert severity="warning">
            {t(
              "error.editLocationSplitLoadError",
              "You cannot edit locations on a split load"
            )}
          </Alert>
        </Grid>
      ) : null}
      <Grid
        container
        item
        sx={{ mt: 2, p: 1 }}
        spacing={1}
        columns={12}
        position="relative"
      >
        {isSplit ? (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              backgroundColor: "white",
              opacity: 0.5,
              zIndex: 2000,
            }}
          />
        ) : null}
        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          spacing={2}
          id="shipper-form-container"
        >
          <Divider
            sx={{
              "&::before, &::after": {
                borderColor: "primary.light",
              },
            }}
            flexItem
          >
            <Typography
              color="primary"
              variant="h6"
              gutterBottom
              id="shipper-title"
            >
              {t("shipper")}
            </Typography>
          </Divider>

          {shippers.map((shipper, index) => (
            <Box key={`${shipper._id}-${index}`} sx={{ position: "relative" }}>
              {shippers.length > 1 && (
                <IconButton
                  sx={{ position: "absolute", right: -30, top: -30 }}
                  color="default"
                  onClick={() =>
                    handleRemoveShipperOrReceiver("shipper", shipper._id)
                  }
                >
                  <RemoveCircleIcon />
                </IconButton>
              )}
              <ShipperForm
                key={index}
                errors={
                  shipmentLocationErrors?.find(
                    (err) => err.formId === shipper._id
                  ) || undefined
                }
                shipper={shipper}
              />
            </Box>
          ))}
          <Stack
            direction="row"
            spacing={2}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <AddButton
              sx={{ height: 50, width: 20 }}
              onClick={handleAddShipperClick}
              id="add-shipper-button"
            >
              <AddIcon />
            </AddButton>
            <span>{t("orders:addShipper", "Add Shipper")}</span>
          </Stack>
        </Grid>
        <Grid
          item
          md={2}
          sm={12}
          xs={12}
          display={{ md: "flex" }}
          justifyContent={{ md: "center" }}
          margin={{ sm: "2rem 0" }}
        >
          <Divider
            variant="middle"
            orientation={isDesktop ? "vertical" : "horizontal"}
          ></Divider>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          sx={{ padding: 2 }}
          id="receiver-form-container"
        >
          <Divider
            sx={{
              "&::before, &::after": {
                borderColor: "secondary.light",
              },
            }}
            flexItem
          >
            <Typography
              variant="h6"
              color="secondary"
              gutterBottom
              id="receiver-title"
            >
              {t("consigneeReceiver")}
            </Typography>
          </Divider>
          {receivers.map((receiver, index) => (
            <Box key={`${receiver._id}-${index}`} sx={{ position: "relative" }}>
              {receivers.length > 1 && (
                <IconButton
                  sx={{ position: "absolute", right: -30, top: -30 }}
                  color="default"
                  onClick={() =>
                    handleRemoveShipperOrReceiver("receiver", receiver._id)
                  }
                >
                  <RemoveCircleIcon />
                </IconButton>
              )}
              <ReceiverForm
                shippedGoods={shippers.map((s) => s.shippedGoods).flat()}
                receivedGoods={receivers.map((r) => r.receivedGoods).flat()}
                key={index}
                receiver={receiver}
                errors={shipmentLocationErrors?.find(
                  (err) => err.formId === receiver._id
                )}
              />
            </Box>
          ))}
          <Stack
            direction="row"
            spacing={2}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <AddButton
              sx={{ height: 50, width: 20 }}
              onClick={handleAddReceiverClick}
              id="add-receiver-button"
            >
              <AddIcon />
            </AddButton>
            <span>{t("orders:addReceiver", "Add receiver")}</span>
          </Stack>
        </Grid>
      </Grid>
      <Grid item xs={12} display="flex" justifyContent="center">
        <TextField
          sx={{
            width: {
              xs: 0.9,
              md: 0.6,
            },
          }}
          label={t("numberOfOrdersPerDay")}
          value={
            shipment?.dailyRotationCount
              ? String(shipment?.dailyRotationCount)
              : ""
          }
          name="dailyRotationCount"
          size="small"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleDailyRotationCountChange(parseInt(event.target.value) || null)
          }
          type="number"
          inputProps={{
            min: 1,
          }}
          placeholder={"1"}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Repeat />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
}
