import {
  CustomField,
  CustomFieldSelectOption,
  CustomFieldType,
} from "../../../graphql/generated";
import { CustomFieldDefinitionListItem } from "../CustomFieldsList/CustomFieldsList";
import InfoBlock from "../../common/InfoBlock";
import LabeledInfo from "../../common/LabeledInfo";
import { isArray } from "lodash";
import {
  formatDate,
  formatDateTime,
} from "../../../utils/labels/formatDateTime";
import { isValid } from "date-fns";
import { getCustomFieldOptions } from "../../extensions/CustomFieldsForm/custom-field-inputs/SelectCustomFieldInput";
import { useEffect, useState } from "react";

export type CustomFieldsDisplayProps = {
  customFields?: CustomField[] | null;
  customFieldDefinitions: CustomFieldDefinitionListItem[];
};

const CustomFieldsDisplay = ({
  customFields,
  customFieldDefinitions,
}: CustomFieldsDisplayProps) => {
  if (!customFields?.length) {
    return null;
  }
  return (
    <InfoBlock title={"Custom Fields"}>
      {customFields?.map((customField) => {
        const customFieldDefinition = customFieldDefinitions.find(
          (definition) => definition.key === customField.key
        );
        if (!customFieldDefinition) {
          return null;
        }
        return (
          <LabeledInfo
            key={customField.key}
            title={customFieldDefinition?.label || customField.key}
            value={
              <CustomFieldValue
                value={customField.value}
                customFieldDefinition={customFieldDefinition}
              />
            }
          />
        );
      })}
    </InfoBlock>
  );
};

export default CustomFieldsDisplay;

export const customFieldValueLabel = (
  value: unknown,
  customFieldDefinition: CustomFieldDefinitionListItem
) => {
  if (isArray(value)) {
    return value.join(", ");
  }
  if (customFieldDefinition.type === CustomFieldType.Date && isValid(value)) {
    return value ? formatDate(value as string, null, false) : "";
  }
  if (
    customFieldDefinition.type === CustomFieldType.Datetime &&
    isValid(value)
  ) {
    return value ? formatDateTime(value as string, null, true) : "";
  }
  if (customFieldDefinition.type === CustomFieldType.Boolean) {
    return value ? "Yes" : "No";
  }
  return String(value);
};

const CustomFieldValue = ({
  value,
  customFieldDefinition,
}: {
  value: unknown;
  customFieldDefinition: CustomFieldDefinitionListItem;
}) => {
  const [options, setOptions] = useState<CustomFieldSelectOption[]>([]);
  useEffect(() => {
    getCustomFieldOptions(customFieldDefinition).then(setOptions);
  }, [customFieldDefinition]);

  if (
    customFieldDefinition.type === CustomFieldType.Select ||
    customFieldDefinition.type === CustomFieldType.Multiselect
  ) {
    if (isArray(value)) {
      return (
        <>
          {value
            .map(
              (val) => options.find((option) => option.key === val)?.label || ""
            )
            .join(", ")}
        </>
      );
    }
    return <>{options.find((option) => option.key === value)?.label || ""}</>;
  }

  return <>{customFieldValueLabel(value, customFieldDefinition)}</>;
};
