import { AssetStatus } from "../../graphql/generated";
import domicileMapping from "./domicile";
import { ExcelMapping } from "./mappingTypes";

const tractorsMapping: ExcelMapping = {
  "Asset Code": { key: "serialNumber", type: "string", required: true },
  "VIN#": { key: "plateNumber", type: "string" },
  "License Number": { key: "licenseNumber", type: "string", required: true },
  Make: { key: "make", type: "string" },
  Model: { key: "model", type: "string" },
  Year: { key: "year", type: "number" },
  "Tare Weight": { key: "tareWeight", type: "number", unitType: "weight" },
  Status: {
    key: "status",
    type: "enum",
    enumOptions: Object.values(AssetStatus),
  },
  ...domicileMapping,
};

export default tractorsMapping;
