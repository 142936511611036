import {
  TripSortCriteria,
  GetRelationshipListQuery,
} from "../../../graphql/generated";
import SmartLynksTable, {
  DeleteMutation,
  QueryFn,
} from "../../common/SmartLynksTable/SmartLynksTable";
// import relationshipSchema from "../RelationshipForm/relationshipSchema";
// import relationshipsMapping from "../../../utils/mappin-s/relationships";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import RelationshipFormContainer from "../RelationshipForm";
import RoleGuard, { useRoles } from "../../account/Access/RoleGuard";
import RelationshipItem from "./RelationshipItem";
import useDialog from "../../../utils/hooks/useDialog";
import { useTranslation } from "react-i18next";
import { capitalize } from "lodash";

type RelationshipListItem =
  GetRelationshipListQuery["relationships"]["data"][0];

export enum RelationshipsTab {
  LIST,
  CALENDAR,
}

export default function RelationshipsList({
  query,
  deleteMutation,
  onRefresh,
}: // onBulkCreate,
{
  query: QueryFn<RelationshipListItem, "relationships", TripSortCriteria>;
  deleteMutation: DeleteMutation;
  onRefresh: () => void;
  // onBulkCreate: (data: NewRelationshipInput[]) => Promise<any>;
}) {
  const { t } = useTranslation(["business", "assets", "common"]);
  const [formOpen, setFormOpen] = useState(false);
  const [selectedRelationship, setSelectedRelationship] =
    useState<RelationshipListItem | null>(null);
  const roles = useRoles();
  const { showDialog, hideDialog } = useDialog();
  const deleteMut = deleteMutation();

  const hasWriteAccess = roles.some((role) =>
    ["Carrier Admin", "Manager", "Dispatcher"].includes(role)
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <RoleGuard roles={["Carrier Admin", "Manager", "Dispatcher"]}>
        <Box
          sx={{
            display: "flex",
            alignSelf: "flex-end",
            mb: 2,
          }}
        >
          {/* <ExcelImporter
            mapping={relationshipsMapping}
            schema={relationshipSchema}
            onBulkCreate={onBulkCreate}
          /> */}
          <Button
            variant="contained"
            color="secondary"
            size="large"
            id="new-relationship-button"
            onClick={() => {
              setFormOpen(true);
              setSelectedRelationship(null);
            }}
          >
            {t("relation.new", "New Relation")}
          </Button>
        </Box>
      </RoleGuard>
      <SmartLynksTable
        query={query}
        dataKey="relationships"
        deleteMutation={hasWriteAccess ? deleteMutation : undefined}
        renderRow={(row) => (
          <Box sx={{ mt: 3 }}>
            <RelationshipItem
              relationship={row}
              onEdit={() => {
                if (!hasWriteAccess) return;
                setFormOpen(true);
                setSelectedRelationship(row);
              }}
              onDelete={async () => {
                if (!hasWriteAccess) return;
                showDialog({
                  title: t("common:error.dangerZone", "Danger zone"),
                  description: t(
                    "error.deleteRelationConfirmation",
                    "Do you really want to delete this relation? This action cannot be undone."
                  ),
                  type: "error",
                  actions: [
                    {
                      type: "primary",
                      title: t("common:error.noCancel", "No, Cancel"),
                    },
                    {
                      type: "error",
                      title: t("common:error.yesDelete", "Yes, Delete"),
                      onClick: async () => {
                        try {
                          await deleteMut.mutateAsync({
                            id: row._id,
                          });
                          hideDialog();
                          onRefresh();
                        } catch (error) {
                          console.error(error);
                          showDialog({
                            title: t("common:error.title", "Error"),
                            description:
                              t(
                                "error.deleteRelationError",
                                "An error occurred while deleting the relation."
                              ) +
                              ((error as Error)?.message ||
                                t(
                                  "common:error.unknownError",
                                  "Unknown error"
                                )),
                          });
                        }
                      },
                    },
                  ],
                });
              }}
            />
            <Divider
              sx={{
                mt: 3,
              }}
            />
          </Box>
        )}
        renderHeader={() => (
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={6}
            sx={{
              mt: 2,
            }}
          >
            <HeaderBox title={capitalize(t("supplier.one", "Supplier"))} />
            <HeaderBox title={capitalize(t("shipper.one", "Shipper"))} />
            <HeaderBox
              title={capitalize(t("assets:commodity.one", "Commodity"))}
            />
            <HeaderBox title={capitalize(t("receiver.one", "Receiver"))} />
            <HeaderBox title={capitalize(t("customer.one", "Customer"))} />
            {/* Actions spacer */}
            <Box
              sx={{
                width: 60,
              }}
            />
          </Stack>
        )}
      />
      <Dialog
        open={formOpen}
        onClose={() => {
          setFormOpen(false);
        }}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>
          {capitalize(
            selectedRelationship
              ? t("relation.edit", "Edit relation")
              : t("relation.new", "New relation")
          )}
        </DialogTitle>
        <DialogContent>
          {formOpen ? (
            <RelationshipFormContainer
              relationshipId={selectedRelationship?._id}
              onSaved={() => {
                setFormOpen(false);
                onRefresh();
              }}
            />
          ) : null}
        </DialogContent>
      </Dialog>
    </div>
  );
}

const HeaderBox = ({ title }: { title: string }) => (
  <Box
    flex={1}
    textAlign="center"
    sx={{
      p: 3,
      backgroundColor: "primaryBackground.main",
      borderRadius: 2.5,
    }}
  >
    <Typography color="primary" fontWeight="bold" fontSize={14}>
      {title}
    </Typography>
  </Box>
);
