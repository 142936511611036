import {
  useBulkAddBusinessEntitiesMutation,
  useGetBusinessEntityListQuery,
} from "../../../graphql/generated";
import BusinessLocationsList from "./BusinessLocationsList";

export default function BusinessLocationsListContainer() {
  const bulkCreateBusinessEntities = useBulkAddBusinessEntitiesMutation();
  return (
    <BusinessLocationsList
      onBulkCreate={(locations) =>
        bulkCreateBusinessEntities.mutateAsync({
          newBusinessEntityData: locations,
        })
      }
      query={useGetBusinessEntityListQuery}
    />
  );
}
