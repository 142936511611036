import { Box } from "@mui/material";
import CustomerOrdersListContainer from "../../components/customer-portal/CustomerOrdersList";

const CustomerPortalHome = () => {
  return (
    <Box
      sx={{
        height: "calc(100% - 110px)",
      }}
    >
      <CustomerOrdersListContainer />
    </Box>
  );
};

export default CustomerPortalHome;
