import { useGetTripListQuery } from "../../../graphql/generated";
import TripsList, { TripsListProps } from "./TripsList";

type TripsListContainerProps = Omit<TripsListProps, "query">;

export default function TripsListContainer({
  ...props
}: TripsListContainerProps) {
  return <TripsList query={useGetTripListQuery} {...props} />;
}
