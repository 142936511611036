import {
  Alert,
  AlertProps,
  Button,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import {
  ForecastingNotification,
  ForecastingNotificationLevel,
  GetForecastingNotificationsQuery,
} from "../../../graphql/generated";
import { Info, PriorityHigh, Warning } from "@mui/icons-material";
import { sortBy } from "lodash";

type ForecastingNotificationListItem =
  GetForecastingNotificationsQuery["forecastingNotifications"][0];

export type ForecastingNotificationsAlertsProps = {
  forecastingNotifications: ForecastingNotification[];
  onOpen?: (notification: ForecastingNotificationListItem) => void;
  compact?: boolean;
  levels?: ForecastingNotificationLevel[];
};

const ForecastingNotificationsAlerts = (
  props: ForecastingNotificationsAlertsProps
) => {
  const notifications = sortBy(
    props.forecastingNotifications.filter(
      (notification) =>
        !props.levels || props.levels.includes(notification.level)
    ),
    (notification) =>
      notification.level === ForecastingNotificationLevel.Critical
        ? 1
        : notification.level === ForecastingNotificationLevel.Danger
        ? 2
        : notification.level === ForecastingNotificationLevel.Warning
        ? 3
        : 4
  );
  const content = (
    <Stack spacing={2}>
      {notifications.map((notification) => (
        <Alert
          severity={forecastingNotificationLevelToSeverity[notification.level]}
          icon={
            notification.level === ForecastingNotificationLevel.Critical ? (
              <PriorityHigh />
            ) : undefined
          }
          action={
            props.onOpen ? (
              <Button
                size="small"
                onClick={() => {
                  if (props.onOpen) {
                    props.onOpen(notification);
                  }
                }}
              >
                View
              </Button>
            ) : undefined
          }
        >
          {notification.message}
        </Alert>
      ))}
    </Stack>
  );

  const hasCritical = notifications.some(
    (notification) =>
      notification.level === ForecastingNotificationLevel.Critical
  );

  const hasDanger = notifications.some(
    (notification) => notification.level === ForecastingNotificationLevel.Danger
  );

  const hasWarning = notifications.some(
    (notification) =>
      notification.level === ForecastingNotificationLevel.Warning
  );

  if (props.compact) {
    if (!notifications.length) {
      return null;
    }
    return (
      <Tooltip title={content}>
        <IconButton
          color={
            hasCritical
              ? "error"
              : hasDanger
              ? "error"
              : hasWarning
              ? "warning"
              : "info"
          }
        >
          {hasCritical ? (
            <PriorityHigh />
          ) : hasDanger ? (
            <Warning />
          ) : hasWarning ? (
            <Warning />
          ) : (
            <Info />
          )}
        </IconButton>
      </Tooltip>
    );
  }

  return content;
};

const forecastingNotificationLevelToSeverity: Record<
  ForecastingNotificationLevel,
  AlertProps["severity"]
> = {
  DANGER: "error",
  INFO: "info",
  WARNING: "warning",
  CRITICAL: "error",
};

export default ForecastingNotificationsAlerts;
