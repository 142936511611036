import { BusinessEntityType } from "../../graphql/generated";
import { ExcelMapping } from "./mappingTypes";

const customersMapping: ExcelMapping = {
  "Business Name": { key: "name", type: "string", required: true },
  "Additionnal Types": {
    key: "additionalTypes",
    type: "[string]",
    required: true,
  },
  "Business Type": {
    key: "type",
    type: "enum",
    required: true,
    enumOptions: Object.values(BusinessEntityType),
  },
  "Business Code": { key: "code", type: "string" },
  "MC Number": { key: "mcNumber", type: "string" },
  "Contact First Name": { key: "contact.firstname", type: "string" },
  "Contact Last Name": { key: "contact.lastname", type: "string" },
  "Contact Email Address": { key: "contact.email", type: "string" },
  "Contact Phone Number": { key: "contact.phoneNumber", type: "string" },
  "Business Physical Address": { key: "address", type: "physicalAddress" },
  "Business Physical Address Latitude": {
    key: "address.coordinates.latitude",
    type: "number",
  },
  "Business Physical Address Longitude": {
    key: "address.coordinates.longitude",
    type: "number",
  },
  "Billing Address": { key: "billingAddress", type: "textualAddress" },
  "Billing Email": { key: "billingEmail", type: "string" },
  "Billing Terms": { key: "billingTermsDay", type: "number" },
  "Remit Address": { key: "remitAddress", type: "textualAddress" },
  "Remit Email": { key: "remitEmail", type: "string" },
};

export default customersMapping;
