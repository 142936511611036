import { Grid } from "@mui/material";
import { GetExtensionsQuery } from "../../../graphql/generated";
import ExtensionCardContainer from "../ExtensionCard";
import ExtensionsMessagesContainer from "../ExtensionsMessages";

export type ExtensionWithStatusListItemData =
  GetExtensionsQuery["extensions"]["data"][0];

type ExtensionsManagerProps = {
  extensions: ExtensionWithStatusListItemData[];
  onInstalled: () => void;
  onUninstalled: () => void;
};

const ExtensionsManager = ({
  extensions,
  onInstalled,
  onUninstalled,
}: ExtensionsManagerProps) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <ExtensionsMessagesContainer />
      </Grid>
      {extensions.map((extensionWithStatus) => {
        return (
          <Grid item sm={4}>
            <ExtensionCardContainer
              extension={extensionWithStatus}
              onInstalled={onInstalled}
              onUninstalled={onUninstalled}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default ExtensionsManager;
