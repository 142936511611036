import { Autocomplete, AutocompleteProps, TextField } from "@mui/material";
import { BusinessEntity } from "../../../../../graphql/generated";
import { ConditionValueSelectorComponentProps } from "../../ConditionForm";
import { castArray } from "lodash";

const BusinessEntityInput = ({
  value,
  onChange,
  businessEntities,
  ...otherProps
}: ConditionValueSelectorComponentProps & {
  businessEntities: Pick<BusinessEntity, "_id" | "name">[];
} & Pick<AutocompleteProps<string, true, undefined, undefined>, "sx">) => {
  return (
    <Autocomplete
      disablePortal
      options={businessEntities || []}
      getOptionLabel={(businessEntity) => businessEntity.name}
      onChange={(event, value) => {
        if (!value) {
          return;
        }
        onChange(value.map((be) => be._id));
      }}
      value={
        businessEntities.filter((be) => castArray(value).includes(be._id)) ||
        null
      }
      size="small"
      renderInput={(inputProps) => <TextField {...inputProps} />}
      multiple
      sx={{
        minWidth: 300,
      }}
      {...otherProps}
    />
  );
};

export default BusinessEntityInput;
