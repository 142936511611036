import { useNavigate } from "react-router-dom";
import {
  GetOrderDetailsQuery,
  Status,
  useAddCustomerOrderMutation,
  useEditCustomerOrderMutation,
  useGetGoodProfileListQuery,
  useGetOrderDetailsQuery,
} from "../../../graphql/generated";
import LoadingOverlay from "../../common/LoadingOverlay";
import { useEffect } from "react";
import { omit } from "lodash";
import OrderForm, { OrderFormProps } from "../../order/OrderForm/OrderForm";
import { Container } from "@mui/material";

type OrderFormContainerProps = Omit<
  OrderFormProps,
  "goodProfiles" | "onSave" | "submitting" | "error"
> & {
  orderId?: string;
  onLoad?: (order: GetOrderDetailsQuery["orderById"]) => void;
};

const CustomerOrderFormContainer = ({
  onLoad,
  orderId,
  ...props
}: OrderFormContainerProps) => {
  const getGoodProfilesQuery = useGetGoodProfileListQuery();
  const goodProfiles = getGoodProfilesQuery.data?.goodProfiles.data || [];

  const addOrderMutation = useAddCustomerOrderMutation();
  const editOrderMutation = useEditCustomerOrderMutation();
  const navigate = useNavigate();

  const getOrderDetailsQuery = useGetOrderDetailsQuery(
    {
      id: orderId || "",
    },
    {
      enabled: !!orderId,
    }
  );

  useEffect(() => {
    if (getOrderDetailsQuery.data?.orderById) {
      onLoad?.(getOrderDetailsQuery.data?.orderById);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getOrderDetailsQuery.data]);

  if (orderId && getOrderDetailsQuery.isLoading) {
    return <LoadingOverlay loading />;
  }

  return (
    <Container maxWidth="md">
      <OrderForm
        {...props}
        goodProfiles={goodProfiles}
        onSave={async (order) => {
          if (orderId) {
            await editOrderMutation.mutateAsync({
              id: orderId,
              editOrderData: {
                ...order,
                locations: order.locations.map((location) => ({
                  ...location,
                  receivedGoods: location.receivedGoods.map((receivedGood) => ({
                    ...omit(receivedGood, ["goodProfile"]),
                    goodId: receivedGood.goodId,
                  })),
                })),
                // API expects a customerID, but it gets overriden based on the
                // business entity access token we provided
                customerId: "65968526f595bc3563fdff9a",
              },
            });
            navigate(`/customer-portal`);
          } else {
            await addOrderMutation.mutateAsync({
              newOrderData: {
                ...order,
                status: Status.WaitingForAssignment,
                // API expects a customerID, but it gets overriden based on the
                // business entity access token we provided
                customerId: "65968526f595bc3563fdff9a",
              },
            });
            navigate(`/customer-portal`);
          }
        }}
        initialOrder={getOrderDetailsQuery.data?.orderById}
        submitting={addOrderMutation.isLoading || editOrderMutation.isLoading}
        error={addOrderMutation.error || editOrderMutation.error}
        hideCustomerSelect
        allowNewLocation={false}
      />
    </Container>
  );
};

export default CustomerOrderFormContainer;
