import { Container } from "@mui/material";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import TractorForm from "../../../components/asset-management/TractorForm";
import HeaderTitle from "../../../components/common/HeaderTitle";
import { Tractor } from "../../../graphql/generated";
import { useNavigationOptions } from "../../LayoutRoutes";
import { useTranslation } from "react-i18next";

function EditTractor() {
  const { t } = useTranslation("assets");
  const { id } = useParams();
  const { setNavigationOptions } = useNavigationOptions();
  const onLoad = useCallback(
    (tractor: Pick<Tractor, "serialNumber">) => {
      setNavigationOptions({
        title: (
          <HeaderTitle
            label={t("tractor.edit", "Edit Tractor")}
            suffix={tractor?.serialNumber || ""}
          />
        ),
        showBackIcon: true,
      });
    },
    [setNavigationOptions, t]
  );
  if (!id) {
    return null;
  }
  return (
    <Container>
      <TractorForm tractorId={id} onLoad={onLoad} />
    </Container>
  );
}

export default EditTractor;
