import { Stack, TextField } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import {
  ShipmentEventInput,
  ShipmentEventType,
} from "../../../graphql/generated";
import EnumSelect from "../../common/EnumSelect";

export type ShipmentEventFormProps = {
  shipmentEvent: ShipmentEventInput | null;
  onChange: (event: Partial<ShipmentEventInput>) => void;
};

const ShipmentEventForm = ({
  shipmentEvent,
  onChange,
}: ShipmentEventFormProps) => {
  return (
    <Stack spacing={2}>
      <EnumSelect
        label="Type"
        disabled
        enumObject={ShipmentEventType}
        value={shipmentEvent?.type || null}
        onChange={(event, type) => {
          if (!type) {
            return;
          }
          onChange({ ...event, type });
        }}
      />
      <DateTimePicker
        label="Date"
        ampm={false}
        value={shipmentEvent?.date || null}
        onChange={(date) => {
          onChange({ ...shipmentEvent, date });
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </Stack>
  );
};

export default ShipmentEventForm;
