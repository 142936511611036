import { Box, Chip, Stack, Typography } from "@mui/material";
import { Driver, Tractor, Trailer } from "../../../graphql/generated";
import driverLabel from "../../../utils/labels/driverLabel";
import tractorLabel from "../../../utils/labels/tractorLabel";
import trailerLabel from "../../../utils/labels/trailerLabel";
import TrailerTypeIcon from "../../shipment/ShipmentDetails/TrailerFormIcon";

import tractorIcon from "../../../assets/icons/tractor.svg";
import truckIcon from "../../../assets/icons/truck.svg";
import { Person, Speed } from "@mui/icons-material";
import { formatDateTime } from "../../../utils/labels/formatDateTime";
import { formatMileage } from "../../../utils/labels/formatDistance";

type TrailerIdentificationData = Pick<Trailer, "_id" | "serialNumber" | "type">;
type TractorIdentificationData = Pick<
  Tractor,
  "_id" | "serialNumber" | "lastKnownLocation"
>;
type DriverIdentificationData = Pick<Driver, "_id" | "firstname" | "lastname">;

type LinkedAssetHeaderProps = {
  linkedAsset: {
    trailer: TrailerIdentificationData | undefined | null;
    tractor: TractorIdentificationData | undefined | null;
    driver: DriverIdentificationData | undefined | null;
    lastKnownLocationDate: Date | undefined | null;
  };
  isSummarizedView?: boolean;
};

const LinkedAssetHeader = ({
  isSummarizedView,
  linkedAsset,
}: LinkedAssetHeaderProps) => {
  const isDriverOnly =
    linkedAsset.driver && !linkedAsset.tractor && !linkedAsset.trailer;
  // https://transistorsoft.github.io/react-native-background-geolocation/interfaces/coords.html#speed
  // Speed in meters / second. :warning: Note: Only present when location came from GPS. -1 otherwise.
  const assetSpeed =
    linkedAsset.tractor?.lastKnownLocation?.speed === -1
      ? null
      : linkedAsset.tractor?.lastKnownLocation?.speed;
  return (
    <Box>
      <Stack
        direction="row"
        spacing={3}
        justifyContent={isSummarizedView ? "center" : "left"}
      >
        {isSummarizedView ? null : linkedAsset.tractor &&
          linkedAsset.trailer ? (
          <img style={{ width: "20%" }} src={truckIcon} alt="Truck icon" />
        ) : linkedAsset.trailer && !linkedAsset.tractor ? (
          <TrailerTypeIcon type={linkedAsset.trailer.type} />
        ) : linkedAsset.tractor && !linkedAsset.trailer ? (
          <img style={{ width: "20%" }} src={tractorIcon} alt="Tractor icon" />
        ) : (
          <Person />
        )}
        <Typography variant="subtitle2">
          {tractorLabel(linkedAsset.tractor)} /{" "}
          {trailerLabel(linkedAsset.trailer)}
          <br />
          {isDriverOnly ? (
            driverLabel(linkedAsset.driver)
          ) : linkedAsset.driver ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: isSummarizedView ? "center" : "left",
              }}
            >
              {!isSummarizedView && <Person />}
              <Typography
                variant="subtitle1"
                sx={{
                  ml: isSummarizedView ? 0 : 1,
                }}
              >
                {driverLabel(linkedAsset.driver)}
              </Typography>
            </Box>
          ) : null}
          {assetSpeed !== null && assetSpeed !== undefined ? (
            <Chip
              label={`${formatMileage(assetSpeed * 3.6 * 1000)}/h`}
              color="primary"
              icon={<Speed />}
              variant="outlined"
            />
          ) : null}
        </Typography>
      </Stack>
      <Stack
        direction="column"
        sx={{
          mt: 2,
        }}
      >
        {linkedAsset.lastKnownLocationDate ? (
          <Chip
            label={`Last seen at ${formatDateTime(
              new Date(linkedAsset.lastKnownLocationDate)
            )}`}
          />
        ) : null}
      </Stack>
    </Box>
  );
};

export default LinkedAssetHeader;
