import { useGetExtensionsQuery } from "../../../graphql/generated";
import ErrorMessage from "../../common/ErrorMessage/ErrorMessage";
import ExtensionInstallPrompt, {
  ExtensionInstallPromptProps,
} from "./ExtensionInstallPrompt";

type ExtensionInstallPromptContainerProps = Omit<
  ExtensionInstallPromptProps,
  "extensions"
> & {
  extensionNames: string[];
};

const ExtensionInstallPromptContainer = (
  props: ExtensionInstallPromptContainerProps
) => {
  const getExtensionsQuery = useGetExtensionsQuery();

  if (getExtensionsQuery.isLoading) {
    return null;
  }

  if (getExtensionsQuery.error) {
    return <ErrorMessage error={getExtensionsQuery.error} />;
  }

  if (!getExtensionsQuery.data) {
    return null;
  }

  const extensionsToInstall = getExtensionsQuery.data.extensions.data.filter(
    (extension) => props.extensionNames.includes(extension.extension.name)
  );

  if (!extensionsToInstall) {
    return null;
  }

  const isInstalled = extensionsToInstall.some(
    (extension) => extension.installed
  );

  if (isInstalled) {
    return null;
  }

  return <ExtensionInstallPrompt {...props} extensions={extensionsToInstall} />;
};

export default ExtensionInstallPromptContainer;
