import { Alert } from "@mui/material";
import { omit } from "lodash";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  GetTractorDetailsQuery,
  useAddTractorMutation,
  useEditTractorMutation,
  useGetTractorDetailsQuery,
} from "../../../graphql/generated";
import { showDialog } from "../../../redux/slices/alert/Alert.slice";
import LoadingOverlay from "../../common/LoadingOverlay";
import TractorForm from "./TractorForm";
import { useTranslation } from "react-i18next";

type TractorFormContainerProps = {
  tractorId?: string;
  onLoad?: (tractor: GetTractorDetailsQuery["tractorById"]) => void;
};

function TractorFormContainer({
  tractorId,
  onLoad,
}: TractorFormContainerProps) {
  const { t } = useTranslation(["assets", "common"]);
  const addTractorMutation = useAddTractorMutation();
  const editTractorMutation = useEditTractorMutation();
  const initialTractorId = useRef(tractorId);

  // We call this hook conditionally because the component
  // should never rerender with a different value for
  // businessEntityId, but if it does we don't update
  // the ref anyway so this is safe
  const getTractorQuery = initialTractorId.current
    ? // eslint-disable-next-line react-hooks/rules-of-hooks
      useGetTractorDetailsQuery(
        {
          id: initialTractorId.current,
        },
        {
          refetchOnWindowFocus: false,
          retry: false,
        }
      )
    : null;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (getTractorQuery?.data?.tractorById) {
      onLoad?.(getTractorQuery.data?.tractorById);
    }
  }, [onLoad, getTractorQuery?.data]);

  if (tractorId) {
    if (getTractorQuery?.isLoading) {
      return <LoadingOverlay loading />;
    }
    if (getTractorQuery?.error) {
      return (
        <Alert severity="error">
          {t(
            "error.fetchTractorError",
            "An error occurred while fetching the tractor"
          )}
          .{" "}
          {(getTractorQuery?.error as Error).message ||
            t("common:error.unknownError", "Unknown error")}
        </Alert>
      );
    }
  }

  return (
    <TractorForm
      initialTractor={getTractorQuery?.data?.tractorById}
      saving={addTractorMutation.isLoading || editTractorMutation.isLoading}
      onSave={async (tractor) => {
        try {
          if (tractorId) {
            const result = await editTractorMutation.mutateAsync({
              id: tractorId,
              editTractorData: omit(tractor, "_id"),
            });
            navigate(`/tractors/details/${result.editTractor._id}`);
          } else {
            const result = await addTractorMutation.mutateAsync({
              newTractorData: tractor,
            });
            navigate(`/tractors/details/${result.addTractor._id}`);
            window.analytics?.track("Tractor Created", {
              tractorId: result.addTractor._id,
            });
            window.analytics?.identify({
              tractorCreated: true,
              lastTractorCreationDate: new Date(),
              lastTractorCreationDateOnly: new Date()
                .toISOString()
                .split("T")[0],
              numberOfTractorsCreated:
                (window.analytics?.user?.()?.traits?.()
                  ?.numberOfTractorsCreated || 0) + 1,
            });
            window.analytics?.group(window.analytics?.group?.()?.id?.(), {
              tractorCreated: true,
              lastTractorCreationDate: new Date(),
              lastTractorCreationDateOnly: new Date()
                .toISOString()
                .split("T")[0],
              numberOfTractorsCreated:
                (window.analytics?.group?.()?.traits?.()
                  ?.numberOfTractorsCreated || 0) + 1,
            });
          }
        } catch (error) {
          dispatch(
            showDialog({
              title: t("common:error.title", "Error"),
              description:
                t("error.createEditTractorError", {
                  action: tractorId
                    ? t("common:editing", "editing")
                    : t("common:creating", "creating"),
                  defaultValue: `An error occurred while ${
                    tractorId ? "editing" : "creating"
                  } the tractor. `,
                }) + (error as Error).message,
              type: "error",
            })
          );
        }
      }}
    />
  );
}

export default TractorFormContainer;
