import NumberTextField from "./NumberTextField";
import {
  Box,
  Grid,
  InputAdornment,
  TextFieldProps,
  Typography,
} from "@mui/material";
import {
  feetInchesToFeet,
  feetToFeetInches,
} from "../../utils/conversion/distance";
import { MeasurementSystem } from "../../graphql/generated";
import LocaleProvider from "../../providers/LocaleProvider";
import { metersToLocalDistance } from "../../utils/conversion/distance";
import { localDistanceToMeters } from "../../utils/conversion/distance";

type DistanceInputProps = Omit<
  TextFieldProps,
  "onChange" | "label" | "value"
> & {
  /**
   * The string distance value in meters (SI)
   */
  value: string;
  label: string;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
};

const DistanceInput = ({
  value,
  label,
  onChange,
  ...props
}: DistanceInputProps) => {
  if (LocaleProvider.getMeasurementSystem() === MeasurementSystem.Metric) {
    return (
      <NumberTextField
        label={label}
        fullWidth
        value={value}
        onChange={onChange}
        {...props}
      />
    );
  }

  const feet = value
    ? feetToFeetInches(
        metersToLocalDistance(Number.parseFloat(value))
      ).feet.toString()
    : "";
  const inches = value
    ? feetToFeetInches(
        metersToLocalDistance(Number.parseFloat(value))
      ).inches.toString()
    : "";

  return (
    <Box>
      <Typography>{label}</Typography>
      <Grid container direction="row" spacing={1}>
        <Grid item xs={12} lg={6} md={12}>
          <NumberTextField
            allowDecimal={false}
            {...props}
            InputProps={{
              endAdornment: <InputAdornment position="end">ft</InputAdornment>,
            }}
            variant="outlined"
            value={feet}
            onChange={(e) => {
              e.target.value = localDistanceToMeters(
                feetInchesToFeet(
                  Number(e.target.value || 0),
                  Number(inches || 0)
                )
              ).toString();
              onChange(e);
            }}
            sx={{ width: 100 }}
          />
        </Grid>
        <Grid item xs={12} lg={6} md={12}>
          <NumberTextField
            allowDecimal={false}
            {...props}
            InputProps={{
              endAdornment: <InputAdornment position="end">in</InputAdornment>,
            }}
            inputProps={{ max: 11 }}
            variant="outlined"
            value={inches}
            onChange={(e) => {
              if (Number(e.target.value) > 11) {
                return;
              }
              e.target.value = localDistanceToMeters(
                feetInchesToFeet(Number(feet || 0), Number(e.target.value || 0))
              ).toString();
              onChange(e);
            }}
            sx={{ width: 100 }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default DistanceInput;
