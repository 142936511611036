import { TextualAddress } from "../../graphql/generated";

export function formatAddress(address: TextualAddress | undefined | null) {
  return [
    address?.line1,
    address?.line2,
    address?.city,
    address?.state,
    address?.postalCode,
    address?.country,
  ]
    .filter(Boolean)
    .join(", ");
}
