import { Button, Link } from "@mui/material";
import {
  TripSortCriteria,
  GetTransactionListQuery,
  AssetStatus,
  TransactionInput,
  TransactionType,
} from "../../../graphql/generated";
import { TableField } from "../../common/LynksTable/LynksTable";
import SmartLynksTable, {
  DeleteMutation,
  QueryFn,
} from "../../common/SmartLynksTable/SmartLynksTable";
import LocaleProvider from "../../../providers/LocaleProvider";
import TransactionFormModal from "../../shipment/ShipmentForm/TransactionFormModal";
import { useState } from "react";
import { Edit } from "@mui/icons-material";
import { isString } from "lodash";
import { useTranslation } from "react-i18next";

type TransactionListItem = GetTransactionListQuery["transactions"]["data"][0];

export default function TransactionsList({
  query,
  deleteMutation,
  onTransactionAdded,
  onTransactionChanged,
  transactionType = TransactionType.Expense,
  transactionLabel,
  isTemplate,
  isFlatFee,
  editable = true,
}: {
  query: QueryFn<TransactionListItem, "transactions", TripSortCriteria>;
  deleteMutation: DeleteMutation;
  onTransactionAdded: (transaction: TransactionInput) => void;
  onTransactionChanged: (id: string, transaction: TransactionInput) => void;
  transactionType?: TransactionType;
  transactionLabel?: string;
  isTemplate?: boolean;
  isFlatFee?: boolean;
  editable?: boolean;
}) {
  const { t } = useTranslation("finance");
  const [transactionToEdit, setTransactionToEdit] =
    useState<TransactionListItem | null>(null);
  const [isTransactionModalOpen, setTransactionModalOpen] = useState(false);

  const closeTransactionModal = () => {
    setTransactionModalOpen(false);
  };

  const openTransactionModal = () => {
    setTransactionModalOpen(true);
  };

  const addTitle = transactionLabel
    ? t("transactionLabel", {
        transactionLabel,
        defaultValue: transactionLabel,
      })
    : transactionType === TransactionType.Expense
    ? t("addAccessorial", "Add Accessorial")
    : t("addCharge", "Add Charge");

  const fields: TableField<TransactionListItem, AssetStatus>[] = [
    {
      label: t("number", "Number"),
      type: "string",
      value: (transaction) => `${transaction.number || "N/A"}`,
      key: "number",
    },
    {
      label: t("label", "Label"),
      type: "string",
      value: "label",
      key: "label",
    },
    {
      label: t("quantity", "Quantity"),
      type: "string",
      value: (transaction) => `${transaction.unit} (${transaction.unitType})`,
      key: "quantity",
    },
    {
      label: t("unitPrice", "Unit Price"),
      type: "string",
      value: (transaction) =>
        `${LocaleProvider.getCurrencySymbol()} ${transaction.rate}`,
      key: "unitPrice",
    },
    {
      label: t("total", "Total"),
      type: "string",
      value: (transaction) =>
        `${LocaleProvider.getCurrencySymbol()} ${(
          transaction.unit * transaction.rate
        ).toFixed(2)}`,
      key: "total",
    },
    {
      label: t("addToSettlement", "Add to Settlement"),
      type: "boolean",
      value: "reimburseToDriver",
      key: "addToSettlement",
    },
    {
      label: t("document", "Document"),
      type: "custom",
      value: (transaction) => {
        return transaction.document ? (
          <Link href={transaction.document.url} component="a" target="_blank">
            {transaction.document.name}
          </Link>
        ) : null;
      },
      key: "document",
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {editable ? (
        <Button
          variant="contained"
          color="secondary"
          size="large"
          sx={{
            alignSelf: "end",
            mb: 2,
          }}
          onClick={() => {
            openTransactionModal();
          }}
          id="add-transaction-button"
        >
          {addTitle}
        </Button>
      ) : null}
      <SmartLynksTable
        query={query}
        dataKey="transactions"
        fields={fields.filter((field) => {
          if (isTemplate) {
            if (field.key === "number") {
              return false;
            }
            if (
              field.key === "document" ||
              field.key === "quantity" ||
              field.key === "total"
            ) {
              return false;
            }
            if (
              field.key === "addToSettlement" &&
              transactionType === TransactionType.Income
            ) {
              return false;
            }
            return true;
          }
          if (isFlatFee) {
            return (
              !isString(field.key) ||
              ["label", "unitPrice", "document"].includes(field.key)
            );
          }
          return true;
        })}
        actions={
          editable
            ? [
                {
                  icon: <Edit color="primary" />,
                  tooltip: "Edit",
                  onClick(record) {
                    setTransactionToEdit(record);
                    openTransactionModal();
                  },
                },
              ]
            : []
        }
        deleteMutation={editable ? deleteMutation : undefined}
      />

      <TransactionFormModal
        title={addTitle}
        initialTransaction={transactionToEdit}
        isOpened={isTransactionModalOpen}
        onClose={() => {
          setTransactionToEdit(null);
          closeTransactionModal();
        }}
        transactionType={transactionType}
        isTemplate={isTemplate}
        isFlatFee={isFlatFee}
        onSubmit={(transaction) => {
          if (transactionToEdit) {
            onTransactionChanged(transactionToEdit._id, transaction);
          } else {
            onTransactionAdded(transaction);
          }
          setTransactionToEdit(null);
          closeTransactionModal();
        }}
      />
    </div>
  );
}
