import { ArrowDropDown } from "@mui/icons-material";
import { Box, Typography, useTheme } from "@mui/material";
import { flatten, keyBy } from "lodash";
import {
  ShipmentEvent,
  ShipmentEventType,
  ShipmentEventInput,
  ShipmentLocationType,
  Status,
} from "../../../graphql/generated";
import StepIndicator from "../../common/StepIndicator";
import { Step } from "../../common/StepIndicator/StepIndicator";
import {
  ShipmentLocationDetails,
  ShipmentLocationTime,
} from "../../shipment/ShipmentSummary/ShipmentSummary";
import { TripSummaryData } from "../TripPlanner/TripsGenerator";

type TripTimelineData = Omit<TripSummaryData, "shipments"> & {
  shipments: (TripSummaryData["shipments"][0] & {
    events?: Pick<ShipmentEvent, "_id" | "type" | "shipmentLocation">[] | null;
  })[];
};

export interface TripTimelineProps {
  trip: TripTimelineData;
  horizontal?: boolean;
  showProgress?: boolean;
  onShipmentEventLog: (event: ShipmentEventInput) => void;
}

export enum LoadLocationStatus {
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETE = "COMPLETE",
}

export function getLocationStatusFromTrip(
  trip: TripTimelineData,
  locationId: string
): LoadLocationStatus {
  const locationEvents = flatten(
    trip.shipments.map((shipment) => shipment.events || [])
  ).filter((event) => event.shipmentLocation === locationId);

  if (
    locationEvents.find(
      (event) =>
        event.type === ShipmentEventType.PickupCheckpointLeft ||
        event.type === ShipmentEventType.DropOffCheckpointLeft
    )
  ) {
    return LoadLocationStatus.COMPLETE;
  }
  if (
    locationEvents.find(
      (event) =>
        event.type === ShipmentEventType.PickupCheckpointLoadStart ||
        event.type === ShipmentEventType.DropoffCheckpointUnloadStart ||
        event.type === ShipmentEventType.PickupCheckpointArrive ||
        event.type === ShipmentEventType.DropOffCheckpointArrive
    )
  ) {
    return LoadLocationStatus.IN_PROGRESS;
  }

  return LoadLocationStatus.NOT_STARTED;
}

function TripTimeline({
  trip,
  horizontal,
  showProgress,
  onShipmentEventLog,
}: TripTimelineProps) {
  const theme = useTheme();
  const allShippedGoodsById = keyBy(
    flatten(
      trip.shipmentLocations.map((location) => location.shippedGoods || [])
    ),
    "_id"
  );
  const currentTripLocation = getTripCurrentLocation(trip);
  const nextTripLocation = getTripNextLocation(trip);
  const steps: Step[] = trip.shipmentLocations.map(
    (tripShipmentLocation, index) => {
      const locationId = tripShipmentLocation._id;

      const isCurrent = currentTripLocation === tripShipmentLocation;
      const isNext = nextTripLocation === tripShipmentLocation;
      const status = getLocationStatusFromTrip(trip, tripShipmentLocation._id);

      return {
        id: locationId,
        header: horizontal ? (
          <Box
            sx={{
              height: showProgress ? 80 : "auto",
              display: "flex",
              flexDirection: "column",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            {showProgress && (isNext || isCurrent) ? (
              <>
                <Typography variant="h6">
                  {isCurrent ? "Current Stop" : "Next Stop"}
                </Typography>
                <ArrowDropDown color="primary" fontSize="large" />
              </>
            ) : null}
            <Box
              sx={{ ml: 2, height: horizontal ? 15 : undefined }}
              textAlign="right"
            >
              <ShipmentLocationTime shipmentLocation={tripShipmentLocation} />
            </Box>
          </Box>
        ) : null,
        content: (
          <Box>
            <ShipmentLocationDetails
              allShippedGoodsById={allShippedGoodsById}
              shipmentLocation={tripShipmentLocation}
              hideTime={horizontal}
              centered={horizontal}
              shipment={trip.shipments.find(
                (shipment) => shipment._id === tripShipmentLocation.shipmentId
              )}
              onShipmentEventLog={(eventType) => {
                if (!tripShipmentLocation.shipmentId) {
                  return;
                }
                onShipmentEventLog({
                  type: eventType,
                  trip: trip._id,
                  shipment: tripShipmentLocation.shipmentId,
                  shipmentLocation: tripShipmentLocation._id,
                  location: {
                    latitude: 0,
                    longitude: 0,
                  },
                });
              }}
            />
          </Box>
        ),
        color:
          status === LoadLocationStatus.COMPLETE
            ? "gray"
            : tripShipmentLocation.locationType === ShipmentLocationType.Pickup
            ? theme.palette.primary.main
            : theme.palette.accent.main,
        separatorLength: 40,
        hideFirstLine: index === 0,
        hideLastLine: index === trip.shipmentLocations.length - 1,
      };
    }
  );
  return (
    <Box>
      <StepIndicator steps={steps} horizontal={horizontal} />
    </Box>
  );
}

export const getTripNextLocation = (trip: TripTimelineData) => {
  if (trip.status === Status.Complete) {
    return null;
  }
  const allEvents = flatten(
    trip.shipments.map((shipment) => shipment.events || [])
  );
  return trip.shipmentLocations.find(
    (tripShipmentLocation) =>
      !allEvents.some(
        (event) =>
          event.shipmentLocation === tripShipmentLocation._id &&
          (event.type === ShipmentEventType.PickupCheckpointArrive ||
            event.type === ShipmentEventType.DropOffCheckpointArrive)
      )
  );
};

export const getTripCurrentLocation = (trip: TripTimelineData) => {
  const allEvents = flatten(
    trip.shipments.map((shipment) => shipment.events || [])
  );
  return trip.shipmentLocations.find(
    (tripShipmentLocation) =>
      allEvents.some(
        (event) =>
          event.shipmentLocation === tripShipmentLocation._id &&
          (event.type === ShipmentEventType.PickupCheckpointArrive ||
            event.type === ShipmentEventType.DropOffCheckpointArrive)
      ) &&
      !allEvents.some(
        (event) =>
          event.shipmentLocation === tripShipmentLocation._id &&
          (event.type === ShipmentEventType.PickupCheckpointLeft ||
            event.type === ShipmentEventType.DropOffCheckpointLeft)
      )
  );
};

export default TripTimeline;
