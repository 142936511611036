import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import LocaleProvider from "./providers/LocaleProvider";

export const defaultNS = "common";

i18n // i18next-http-backend
  // loads translations from your server
  // https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(I18nextBrowserLanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: "en",
    lng: LocaleProvider.getLanguage().toLowerCase() || undefined,
    debug: true,
    ns: [
      "common",
      "orders",
      "trips",
      "business",
      "trailers",
      "account",
      "assets",
      "users",
      "finance",
      "planning",
      "billingRules",
      "paymentRules",
      "settings",
      "loadboard",
      "planning",
    ],
    defaultNS: "common",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    saveMissing: true,
  });

export type TrueTMSTFunction = typeof i18n.t;
