import { CustomFieldInputComponent } from "../CustomFieldsForm";
import NumberTextField from "../../../common/NumberTextField";

const NumberCustomFieldInput: CustomFieldInputComponent = ({
  value,
  customFieldDefinition,
  onChange,
  label,
}) => {
  return (
    <NumberTextField
      onChange={(event) => {
        onChange(event.target.value === "" ? null : Number(event.target.value));
      }}
      value={String(value || "")}
      size="medium"
      fullWidth
      label={label || customFieldDefinition.label}
    />
  );
};

export default NumberCustomFieldInput;
