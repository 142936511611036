import { Box, IconButton, Stack, Typography } from "@mui/material";
import { GetRelationshipListQuery } from "../../../graphql/generated";
import { ArcherContainer, ArcherElement } from "react-archer";
import { RelationType } from "react-archer/lib/types";
import {
  Business,
  Delete,
  Edit,
  Handshake,
  Inventory,
  LocationOn,
  Warehouse,
} from "@mui/icons-material";
import { ReactNode } from "react";

type RelationshipItemProps = {
  relationship: NonNullable<
    GetRelationshipListQuery["relationships"]["data"][0]
  >;
  onEdit?: () => void;
  onDelete?: () => void;
};

const entityKeys = [
  "suppliers",
  "shippers",
  "commodities",
  "receivers",
  "customers",
] as const;

type RelationEntityType = typeof entityKeys[number];

const RelationshipItem = ({
  relationship,
  onEdit,
  onDelete,
}: RelationshipItemProps) => {
  return (
    <ArcherContainer strokeColor="black" noCurves>
      <Stack direction="row" justifyContent="space-between" spacing={6}>
        {entityKeys.map((key) => {
          const nextKey = entityKeys[entityKeys.indexOf(key) + 1];
          const relations: RelationType[] = nextKey
            ? relationship[nextKey]?.length
              ? (relationship[nextKey] || []).map((entity) => ({
                  targetId: `${relationship._id}-${nextKey}-${entity._id}`,
                  targetAnchor: "left",
                  sourceAnchor: "right",
                  style: { strokeColor: "black", strokeWidth: 1 },
                }))
              : [
                  {
                    targetId: `${relationship._id}-${nextKey}-ANY`,
                    targetAnchor: "left",
                    sourceAnchor: "right",
                    style: { strokeColor: "black", strokeWidth: 1 },
                  },
                ]
            : [];
          return (
            <Stack direction="column" spacing={2} flex={1}>
              {relationship[key]?.length ? (
                relationship[key]?.map((entity) => (
                  <ArcherElement
                    id={`${relationship._id}-${key}-${entity._id}`}
                    relations={relations}
                  >
                    <div>
                      <RelationEntityBox entity={entity} entityType={key} />
                    </div>
                  </ArcherElement>
                ))
              ) : (
                <ArcherElement
                  id={`${relationship._id}-${key}-ANY`}
                  relations={relations}
                >
                  <div>
                    <RelationEntityBox
                      entity={{ _id: "ANY", label: "Any " + key }}
                      entityType={key}
                    />
                  </div>
                </ArcherElement>
              )}
            </Stack>
          );
        })}
        <Stack
          direction="row"
          // flex={0.4}
          justifyContent="flex-end"
          alignItems="flex-start"
          sx={{
            width: 60,
          }}
        >
          <IconButton
            onClick={() => {
              onEdit?.();
            }}
          >
            <Edit />
          </IconButton>
          <IconButton
            onClick={() => {
              onDelete?.();
            }}
          >
            <Delete />
          </IconButton>
        </Stack>
      </Stack>
    </ArcherContainer>
  );
};

export default RelationshipItem;

const RelationEntityBox = ({
  entity,
  entityType,
}: {
  entity:
    | NonNullable<RelationshipItemProps["relationship"]["shippers"]>[0]
    | NonNullable<RelationshipItemProps["relationship"]["suppliers"]>[0]
    | NonNullable<RelationshipItemProps["relationship"]["commodities"]>[0]
    | NonNullable<RelationshipItemProps["relationship"]["receivers"]>[0]
    | NonNullable<RelationshipItemProps["relationship"]["customers"]>[0];
  entityType: RelationEntityType;
}) => {
  return (
    <Box
      sx={{
        border: "solid 1px lightgray",
        p: 2,
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
      }}
    >
      <Box
        sx={{
          backgroundColor: "primaryBackground.main",
          p: 1,
        }}
      >
        {iconByRelationEntityType[entityType]}
      </Box>
      <Stack
        sx={{
          ml: 1,
        }}
      >
        <Typography color="primary" fontWeight="bold" fontSize={13}>
          {entity.code}
        </Typography>
        <Typography fontSize={14}>
          {"name" in entity ? entity.name : entity.label}
        </Typography>
      </Stack>
    </Box>
  );
};

const iconByRelationEntityType: Record<RelationEntityType, ReactNode> = {
  suppliers: <Business color="primary" />,
  shippers: <Warehouse color="primary" />,
  commodities: <Inventory color="primary" />,
  receivers: <LocationOn color="primary" />,
  customers: <Handshake color="primary" />,
};
