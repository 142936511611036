import Joi from "joi";
import {
  Shipment,
  ShipmentConstraint,
  ShipmentConstraintType,
} from "../../../../graphql/generated";

const trailerRequirementsSchema = Joi.object<Shipment>({
  trailerType: Joi.string().required().label("Trailer Type"),
  loadType: Joi.string().required().label("Load Type"),
  constraints: Joi.array<ShipmentConstraint[]>()
    .items(
      Joi.object<ShipmentConstraint>({
        type: Joi.string().required().label("Constraint Type"),
        unit: Joi.string().label("Constraint Unit").allow(null, ""),
        value: Joi.string()
          .required()
          .label("Constraint Value")
          .allow(null, ""),
      })
    )
    .allow(null)
    .custom((value: ShipmentConstraint[] | null | undefined, helper) => {
      const isTemperatureSensitive = !!value?.find(
        (c) =>
          c.type === ShipmentConstraintType.MinTemperature ||
          c.type === ShipmentConstraintType.MaxTemperature
      );

      const minTemperature = value?.find(
        (c) => c.type === ShipmentConstraintType.MinTemperature
      )?.value;
      const maxTemperature = value?.find(
        (c) => c.type === ShipmentConstraintType.MaxTemperature
      )?.value;

      if (
        isTemperatureSensitive &&
        (minTemperature === "" || maxTemperature === "")
      ) {
        return helper.message(
          // @ts-ignore
          "Temperatures are required"
        );
      }

      if (
        minTemperature &&
        maxTemperature &&
        Number(minTemperature) > Number(maxTemperature)
      ) {
        return helper.message(
          // @ts-ignore
          "Min temperature cannot be greater than max temperature"
        );
      }

      return value;
    }),
}).label("Shipment");

export default trailerRequirementsSchema;
