import { useTranslation } from "react-i18next";
import {
  useInstallExtensionMutation,
  useUninstallExtensionMutation,
} from "../../../graphql/generated";
import useDialog from "../../../utils/hooks/useDialog";
import { ExtensionWithStatusListItemData } from "../ExtensionsManager/ExtensionsManager";
import ExtensionCard from "./ExtensionCard";

type ExtensionCardContainerProps = {
  extension: ExtensionWithStatusListItemData;
  onInstalled?: () => void;
  onUninstalled?: () => void;
};

const ExtensionCardContainer = ({
  extension,
  onInstalled,
  onUninstalled,
}: ExtensionCardContainerProps) => {
  const { t } = useTranslation("common");
  const installExtensionMutation = useInstallExtensionMutation();
  const uninstallExtensionMutation = useUninstallExtensionMutation();
  const { showDialog } = useDialog();

  return (
    <ExtensionCard
      extension={extension}
      installing={installExtensionMutation.isLoading}
      uninstalling={uninstallExtensionMutation.isLoading}
      installExtension={async (extensionId, configurationValues) => {
        try {
          const response = await installExtensionMutation.mutateAsync({
            extensionId,
            configurationValues,
          });
          if (response.installExtension.redirectUrl) {
            window.location.href = response.installExtension.redirectUrl;
          }
          onInstalled?.();
        } catch (e) {
          console.error(e);
          showDialog({
            title: t("error.title", "Error"),
            description: `${t(
              "error.installExtensionError",
              "An error occurred while installing the extension"
            )}. ${(e as Error).message}`,
            type: "error",
          });
        }
      }}
      uninstallExtension={async (extensionId) => {
        try {
          await uninstallExtensionMutation.mutateAsync({
            extensionId,
          });
          onUninstalled?.();
        } catch (e) {
          console.error(e);
          showDialog({
            title: t("error.title", "Error"),
            description: `${t(
              "error.uninstallExtensionError",
              "An error occurred while uninstalling the extension"
            )}. ${(e as Error).message}`,
            type: "error",
          });
        }
      }}
    />
  );
};

export default ExtensionCardContainer;
