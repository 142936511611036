import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import GroupSelect from "../../asset-management/GroupSelect";
import { Edit } from "@mui/icons-material";

type BulkActionModalProps = {
  onSubmit: (args: { groups: string[] }, runAfterSubmit: () => void) => void;
  loading?: boolean;
};

export default function BulkActionModal({
  onSubmit,
  loading,
}: BulkActionModalProps) {
  const { t } = useTranslation(["common"]);
  const [open, setOpen] = useState(false);
  const [selectedGroups, setSelectedGroup] = useState<string[]>([]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      <Button
        variant="outlined"
        color="secondary"
        size="large"
        id="assign-to-group-button"
        onClick={() => {
          setOpen(true);
        }}
        endIcon={<Edit />}
      >
        {t("common:list.edit", "Edit")}
      </Button>
      <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClose}>
        <DialogTitle
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          {t("common:list.edit", "Edit")}
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2} py={2}>
            <GroupSelect
              onChange={(groups) => {
                setSelectedGroup(groups);
              }}
              value={selectedGroups}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={handleClose} sx={{ mr: 1 }}>
            {t("common:cancel", "Cancel")}
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />
          <LoadingButton
            onClick={() => {
              onSubmit(
                {
                  groups: selectedGroups,
                },
                handleClose
              );
            }}
            variant="contained"
            loading={loading}
          >
            {t("common:save", "Save")}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
