import { KeycloakUserRole } from "../../graphql/generated";

const roleLabelMapping: { [key: string]: string | undefined } = {
  "Carrier Admin": "Admin",
  "Carrier Driver": "Driver",
};

function roleLabel(role: KeycloakUserRole) {
  return roleLabelMapping[role.name] || role.name;
}

export default roleLabel;
