import { Container } from "@mui/material";
import { useParams } from "react-router-dom";
import UserForm from "../../components/account/UserForm";
import { useTranslation } from "react-i18next";
import { useNavigationOptions } from "../LayoutRoutes";
import { useCallback } from "react";
import { GetOrgUserDetailsQuery } from "../../graphql/generated";
import HeaderTitle from "../../components/common/HeaderTitle";

function EditUser() {
  const { t } = useTranslation("users");
  const { id } = useParams();
  const { setNavigationOptions } = useNavigationOptions();
  const onLoad = useCallback(
    (user: GetOrgUserDetailsQuery["orgUserById"]) => {
      setNavigationOptions({
        title: (
          <HeaderTitle
            label={t("user.edit", "Edit User")}
            suffix={user?.firstName || ""}
          />
        ),
        showBackIcon: true,
      });
    },
    [setNavigationOptions, t]
  );
  if (!id) {
    return null;
  }

  return (
    <Container>
      <UserForm userId={id} onLoad={onLoad} />
    </Container>
  );
}

export default EditUser;
