import Joi from "joi";
import { Relationship } from "../../../graphql/generated";

const driverShiftSchema = Joi.object<Relationship>({
  supplierIds: Joi.array().items(Joi.string()).label("Supplier IDs"),
  shipperIds: Joi.array().items(Joi.string()).label("Shipper IDs"),
  commodityIds: Joi.array().items(Joi.string()).label("Commodity IDs"),
  receiverIds: Joi.array().items(Joi.string()).label("Receiver IDs"),
  customerIds: Joi.array().items(Joi.string()).label("Customer IDs"),
});

export default driverShiftSchema;
