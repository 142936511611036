import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  TransactionInputObject,
  TransactionType,
} from "../../../graphql/generated";
import { useState } from "react";
import TransactionsList from "../TransactionsList/TransactionsList";
import { useTranslation } from "react-i18next";

type TransactionTemplatesModalProps = {
  open: boolean;
  templates: TransactionInputObject[];
  templateType: TransactionType;
  onClose: () => void;
  onSubmit: (data: TransactionInputObject[]) => void;
};

const TransactionTemplatesModal = ({
  open,
  templates,
  templateType,
  onClose,
  onSubmit,
}: TransactionTemplatesModalProps) => {
  const { t } = useTranslation("settings");
  const [localTransactionTemplates, setLocalTransactionTemplates] =
    useState<TransactionInputObject[]>(templates);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg">
      <DialogTitle>{t("configure", "Configure")}</DialogTitle>
      <DialogContent>
        <TransactionsList
          transactionType={templateType}
          query={() => ({
            data: {
              transactions: {
                data: localTransactionTemplates.map((t, index) => ({
                  ...t,
                  _id: t._id || String(index),
                })),
                count: localTransactionTemplates.length,
              },
            },
            isLoading: false,
          })}
          isTemplate={true}
          onTransactionAdded={(transaction) => {
            setLocalTransactionTemplates([
              ...localTransactionTemplates,
              { ...transaction, type: templateType },
            ]);
          }}
          onTransactionChanged={(transactionId, updatedTransaction) => {
            setLocalTransactionTemplates(
              localTransactionTemplates.map((t) =>
                t._id === transactionId ? updatedTransaction : t
              )
            );
          }}
          deleteMutation={() => ({
            mutateAsync: async ({ id }) => {
              console.log(localTransactionTemplates);
              setLocalTransactionTemplates(
                localTransactionTemplates.filter((t) => t._id !== id)
              );
              return Promise.resolve();
            },
            isLoading: false,
          })}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onSubmit(localTransactionTemplates);
          }}
        >
          {t("submit", "Submit")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TransactionTemplatesModal;
