import {
  Download,
  Error,
  FileCopy,
  ForwardToInbox,
  Paid,
  Refresh,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import {
  GetShipmentInvoiceDetailsQuery,
  InvoiceStatus,
} from "../../../graphql/generated";
import useDialog from "../../../utils/hooks/useDialog";
import { useTranslation } from "react-i18next";
import NumberTextField from "../../common/NumberTextField";
import { debounce } from "lodash";

type DownloadSendInvoiceButtonsProps = {
  invoice: GetShipmentInvoiceDetailsQuery["invoiceByShipmentId"];
  generating: boolean;
  canGenerateInvoice?: boolean;
  onGenerate: (invoiceNumber?: string) => void;
  onSendInvoice: () => void;
  onTaxRateChange: (taxRate: number) => void;
  onMarkAsPaid: (paidAmount: number) => void;
  sending: boolean;
  onShowPdfGenerationError: () => void;
  canSetTaxRate?: boolean;
};

const DownloadSendInvoiceButtons = ({
  invoice,
  generating,
  canGenerateInvoice = true,
  onGenerate,
  onSendInvoice,
  sending,
  onShowPdfGenerationError,
  onTaxRateChange,
  onMarkAsPaid,
  canSetTaxRate,
}: DownloadSendInvoiceButtonsProps) => {
  const { t } = useTranslation(["finance", "common"]);

  const [invoiceNumberModalOpen, setInvoiceNumberModalOpen] = useState(false);
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [markAsPaidModalOpen, setMarkAsPaidModalOpen] = useState(false);
  const [paidAmount, setPaidAmount] = useState(
    invoice?.charges
      .map(
        (charge) =>
          charge.unit *
          charge.rate *
          (charge.taxable ? 1 + (invoice?.taxRate || 0) / 100 : 1)
      )
      .reduce((a, b) => a + b, 0) || 0
  );
  const { showDialog, hideDialog } = useDialog();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onTaxRateChangeDebounced = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement>) => {
      onTaxRateChange(parseFloat(event.target.value));
    }, 500),
    [onTaxRateChange, debounce]
  );

  useEffect(() => {
    setPaidAmount(
      invoice?.charges
        .map(
          (charge) =>
            charge.unit *
            charge.rate *
            (charge.taxable ? 1 + (invoice?.taxRate || 0) / 100 : 1)
        )
        .reduce((a, b) => a + b, 0) || 0
    );
  }, [invoice?.charges, invoice?.taxRate]);

  return (
    <Stack>
      {!invoice || !invoice.pdfDocument ? (
        canGenerateInvoice ? (
          <Button
            variant="outlined"
            color="lightPrimary"
            startIcon={<FileCopy />}
            disabled={generating}
            onClick={() => {
              setInvoiceNumberModalOpen(true);
            }}
            id="generate-invoice"
          >
            {t("generateInvoice")}
          </Button>
        ) : null
      ) : invoice.pdfDocument ? (
        <Stack direction="row" spacing={1}>
          {canSetTaxRate ? (
            <NumberTextField
              label="Tax rate"
              defaultValue={String(invoice.taxRate || "")}
              onChange={onTaxRateChangeDebounced}
              InputProps={{
                endAdornment: "%",
              }}
            />
          ) : null}
          <Button
            variant="outlined"
            color="lightPrimary"
            startIcon={<Refresh />}
            disabled={generating}
            onClick={() => {
              setInvoiceNumberModalOpen(true);
              setInvoiceNumber(invoice.invoiceNumber);
            }}
            id="regenerate-invoice"
          >
            {t("regenerateInvoice")}
          </Button>
          <LoadingButton
            variant="outlined"
            color={invoice.pdfDocument.error ? "error" : "lightPrimary"}
            size="small"
            target="_blank"
            LinkComponent="a"
            disabled={invoice.pdfDocument.isGenerating}
            href={invoice.pdfDocument.url || ""}
            startIcon={invoice.pdfDocument.error ? <Error /> : <Download />}
            onClick={() => {
              if (invoice.pdfDocument.error) {
                onShowPdfGenerationError();
              }
            }}
            loading={invoice.pdfDocument.isGenerating}
            id="download-invoice"
          >
            {invoice.pdfDocument.error
              ? t("errorGeneratingInvoice")
              : t("downloadInvoice")}
          </LoadingButton>
          {!invoice.pdfDocument.error && (
            <Button
              variant="outlined"
              color={invoice.pdfDocument.error ? "error" : "lightPrimary"}
              size="small"
              disabled={sending}
              startIcon={
                invoice.pdfDocument.error ? <Error /> : <ForwardToInbox />
              }
              onClick={() => {
                if (invoice.pdfDocument.error) {
                  onShowPdfGenerationError();
                } else {
                  const emails = [
                    invoice.customer?.contact.email,
                    ...(invoice.customer?.additionalContacts
                      ?.filter((c) => c.includeInInvoicing)
                      .map((c) => c.email) || []),
                  ].filter(Boolean) as string[];
                  if (!emails.length) {
                    showDialog({
                      type: "error",
                      title: t("common:error.title"),
                      description: t("customerNoEmail"),
                    });
                    return;
                  }
                  showDialog({
                    type: "primary",
                    title: t("sendInvoice"),
                    description: t("sendInvoiceDescription", {
                      customerName: invoice.customer?.name,
                      customerEmail: `${emails.join(", ")}`,
                    }),
                    actions: [
                      {
                        title: t("common:cancel"),
                        type: "secondary",
                      },
                      {
                        title: t("send"),
                        type: "primary",
                        onClick: () => {
                          onSendInvoice();
                          hideDialog();
                        },
                      },
                    ],
                  });
                }
              }}
              id="send-invoice"
            >
              {t("sendInvoice")}
            </Button>
          )}

          <Button
            startIcon={<Paid />}
            disabled={invoice.status === InvoiceStatus.Paid}
            onClick={() => {
              setMarkAsPaidModalOpen(true);
            }}
            variant="outlined"
            size="small"
          >
            {invoice.status === InvoiceStatus.Paid
              ? `$${invoice.paidAmount?.toFixed(2)} paid`
              : t("markAsPaid")}
          </Button>
        </Stack>
      ) : null}

      <Dialog
        open={invoiceNumberModalOpen}
        onClose={() => {
          setInvoiceNumberModalOpen(false);
        }}
      >
        <DialogTitle>{t("generateInvoice")}</DialogTitle>
        <DialogContent dividers>
          <Typography variant="body1">
            {t("generateInvoiceDescription")}
            {/*  */}
          </Typography>
          <TextField
            sx={{
              mt: 2,
            }}
            autoFocus
            label={t("invoiceNumber")}
            fullWidth
            value={invoiceNumber}
            onChange={(event) => setInvoiceNumber(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              onGenerate(invoiceNumber);
              setInvoiceNumberModalOpen(false);
              setInvoiceNumber("");
            }}
            id="generate-invoice-button-modal"
          >
            {invoice ? t("regenerate") : t("generate")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={markAsPaidModalOpen}
        onClose={() => {
          setMarkAsPaidModalOpen(false);
        }}
      >
        <DialogTitle>{t("markAsPaid")}</DialogTitle>
        <DialogContent dividers>
          <Typography variant="body1">{t("markAsPaidDescription")}</Typography>
          <NumberTextField
            sx={{
              mt: 2,
            }}
            autoFocus
            label={t("paidAmount")}
            fullWidth
            value={(paidAmount || 0).toFixed(2)}
            onChange={(event) => {
              setPaidAmount(parseFloat(event.target.value));
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              onMarkAsPaid(paidAmount);
              setMarkAsPaidModalOpen(false);
              setPaidAmount(0);
            }}
            id="generate-invoice-button-modal"
          >
            {t("markAsPaid")}
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};

export default DownloadSendInvoiceButtons;
