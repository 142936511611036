import { useCallback } from "react";
import { useParams } from "react-router-dom";
import TractorDetails from "../../../components/asset-management/TractorDetails";
import HeaderTitle from "../../../components/common/HeaderTitle";
import { Tractor } from "../../../graphql/generated";
import { useNavigationOptions } from "../../LayoutRoutes";
import { useTranslation } from "react-i18next";

function ViewTractor() {
  const { t } = useTranslation("assets");
  const { id } = useParams();
  const { setNavigationOptions } = useNavigationOptions();
  const onLoad = useCallback(
    (tractor: Pick<Tractor, "serialNumber">) => {
      setNavigationOptions({
        title: (
          <HeaderTitle
            label={t("tractor.one", "Tractor")}
            suffix={tractor?.serialNumber || ""}
          />
        ),
        showBackIcon: true,
      });
    },
    [setNavigationOptions, t]
  );
  if (!id) {
    return null;
  }
  return <TractorDetails onLoad={onLoad} tractorId={id} />;
}

export default ViewTractor;
