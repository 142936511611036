import Add from "@mui/icons-material/Add";
import { ButtonProps, Stack, SxProps } from "@mui/material";
import AddButton from "./AddButton";

type LabeledAddButtonProps = {
  label: string;
  containerSx?: SxProps;
} & ButtonProps;

const LabeledAddButton = ({
  label,
  id,
  containerSx,
  ...props
}: LabeledAddButtonProps) => {
  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{ mt: 2, display: "flex", alignItems: "center", ...containerSx }}
    >
      <AddButton
        id={id}
        name="add-button-with-label"
        sx={{ height: 50, width: 20 }}
        {...props}
      >
        <Add />
      </AddButton>
      <span> {label}</span>
    </Stack>
  );
};

export default LabeledAddButton;
