import { useGetShipmentSummaryQuery } from "../../../graphql/generated";
import ShipmentSummary, { ShipmentSummaryProps } from "./ShipmentSummary";

type ShipmentSummaryContainerProps = Omit<ShipmentSummaryProps, "shipment"> & {
  shipmentId: string;
};

const ShipmentSummaryContainer = ({
  shipmentId,
  ...props
}: ShipmentSummaryContainerProps) => {
  const shipmentSummaryQuery = useGetShipmentSummaryQuery({
    id: shipmentId,
  });
  const shipment = shipmentSummaryQuery.data?.shipmentById;
  if (!shipment) {
    return null;
  }
  return <ShipmentSummary shipment={shipment} {...props} />;
};

export default ShipmentSummaryContainer;
