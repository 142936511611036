import {
  useBulkAddBusinessEntitiesMutation,
  useGetBusinessEntityListQuery,
} from "../../../graphql/generated";
import CustomersList from "./CustomersList";

export default function CustomersListContainer() {
  const bulkCreateBusinessEntities = useBulkAddBusinessEntitiesMutation();
  return (
    <CustomersList
      onBulkCreate={(customers) =>
        bulkCreateBusinessEntities.mutateAsync({
          newBusinessEntityData: customers,
        })
      }
      query={useGetBusinessEntityListQuery}
    />
  );
}
