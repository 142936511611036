import { DoneAll, Loop, PanTool, RestorePage, Send } from "@mui/icons-material";
import { Box, Link, Tab, Tabs } from "@mui/material";
import { capitalize, round, sum } from "lodash";
import { useState } from "react";
import {
  GetDriverSettlementListQuery,
  DriverSettlementStatus,
  DeductionType,
} from "../../../graphql/generated";
import LocaleProvider from "../../../providers/LocaleProvider";
import { TableField } from "../../common/LynksTable/LynksTable";
import SmartLynksTable, {
  DeleteMutation,
  QueryFn,
} from "../../common/SmartLynksTable/SmartLynksTable";
import driverLabel from "../../../utils/labels/driverLabel";
import { useTranslation } from "react-i18next";

export type DriverSettlementListItemData =
  GetDriverSettlementListQuery["driverSettlements"]["data"][0];
type DriverSettlementListProps = {
  queryHook: QueryFn<DriverSettlementListItemData, "driverSettlements">;
  deleteMutation: DeleteMutation;
  onProcess: (tripIds: string[], driverSettlementNumber?: string) => void;
  onDriverSettlementStatusUpdate: (
    driverSettlement: DriverSettlementListItemData,
    status: DriverSettlementStatus,
    closingNote?: string
  ) => void;
};

export enum DriverSettlementStatusTab {
  NOT_SETTLED,
  IN_PROCESS,
  RELEASED,
  ON_HOLD,
  HISTORY,
}

const statusToDriverSettlementStatus: {
  [key in DriverSettlementStatusTab]: DriverSettlementStatus | null;
} = {
  [DriverSettlementStatusTab.NOT_SETTLED]: DriverSettlementStatus.NotSettled,
  [DriverSettlementStatusTab.IN_PROCESS]: DriverSettlementStatus.Generated,
  [DriverSettlementStatusTab.RELEASED]: DriverSettlementStatus.Sent,
  [DriverSettlementStatusTab.ON_HOLD]: DriverSettlementStatus.OnHold,
  [DriverSettlementStatusTab.HISTORY]: DriverSettlementStatus.Closed,
};

const DriverSettlementList = ({
  queryHook: query,
  onProcess,
  deleteMutation,
  onDriverSettlementStatusUpdate,
}: DriverSettlementListProps) => {
  const { t } = useTranslation(["finance", "common"]);

  const [statusTab, setStatusTab] = useState<DriverSettlementStatusTab>(
    DriverSettlementStatusTab.NOT_SETTLED
  );

  const fields: Array<
    TableField<DriverSettlementListItemData, DriverSettlementStatus>
  > = [
    {
      label: t("common:trips", "Trips"),
      type: "custom",
      value: (driverSettlement) =>
        (driverSettlement.trips || []).map((trip) => (
          <Box>
            <Link target="_blank" href={`/trips/details/${trip._id}`}>
              {t("common:trip", "Trip")} {trip.tripNumber}
            </Link>
          </Box>
        )),
    },
    {
      label: t("finance:driverSettlementNumber", "Driver Settlement Number"),
      type: "string",
      value: (driverSettlement) =>
        driverSettlement.status !== DriverSettlementStatus.NotSettled
          ? driverSettlement.driverSettlementNumber
          : "",
    },
    {
      label: t("finance:amount", "Amount"),
      type: "string",
      value: (driverSettlement) =>
        driverSettlement.status !== DriverSettlementStatus.NotSettled
          ? `${LocaleProvider.getCurrencySymbol()} ${round(
              sum(
                driverSettlement.payments
                  .map((payment) => payment.unit * payment.rate)
                  .concat(
                    driverSettlement.deductions.map((deduction) =>
                      deduction.type === DeductionType.Bonus
                        ? deduction.amount
                        : -deduction.amount
                    )
                  )
              ),
              2
            )}`
          : "",
    },
    {
      label: capitalize(t("users:driver.one", "Driver")),
      type: "custom",
      value: (driverSettlement) =>
        driverSettlement.driver ? (
          <Link href={`/customers/details/${driverSettlement.driver._id}`}>
            {driverLabel(driverSettlement.driver)}
          </Link>
        ) : null,
    },
    {
      label: t("finance:document", "Document"),
      type: "custom",
      value: (driverSettlement) =>
        driverSettlement.status !== DriverSettlementStatus.NotSettled &&
        driverSettlement.pdfDocument.url ? (
          <>
            <Link
              target="_blank"
              component="a"
              href={driverSettlement.pdfDocument.url}
            >
              Open
            </Link>
          </>
        ) : null,
    },
  ];

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Tabs
          value={statusTab}
          onChange={(event, value) => {
            setStatusTab(value);
          }}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
        >
          <Tab
            label={t("finance:notSettled", "Not Settled")}
            value={DriverSettlementStatusTab.NOT_SETTLED}
          />
          <Tab
            label={t("finance:inProcess", "In process")}
            value={DriverSettlementStatusTab.IN_PROCESS}
          />
          <Tab
            label={t("finance:released", "Released")}
            value={DriverSettlementStatusTab.RELEASED}
          />
          <Tab
            label={t("finance:onHold", "On hold")}
            value={DriverSettlementStatusTab.ON_HOLD}
          />
          <Tab
            label={t("finance:history", "History")}
            value={DriverSettlementStatusTab.HISTORY}
          />
        </Tabs>
      </Box>
      <SmartLynksTable
        query={query}
        deleteMutation={deleteMutation}
        dataKey="driverSettlements"
        id="driverSettlement-list"
        fields={fields}
        additionalQueryVariables={{
          status: statusToDriverSettlementStatus[statusTab],
        }}
        detailsUrlPrefix="/settlements/details"
        actions={[
          {
            icon: <Loop />,
            tooltip: t("finance:computePayProcess", "Compute pay & Process"),
            isApplicable: (driverSettlement) =>
              driverSettlement.status === DriverSettlementStatus.NotSettled,
            onClick: (driverSettlement) => {
              onProcess(
                (driverSettlement.trips || []).map((trip) => trip._id),
                driverSettlement.driverSettlementNumber
              );
            },
          },
          {
            icon: <Send />,
            tooltip: t("finance:releaseSettl", "Release driver settlement"),
            isApplicable: (driverSettlement) =>
              driverSettlement.status === DriverSettlementStatus.Generated ||
              driverSettlement.status === DriverSettlementStatus.OnHold,
            onClick: (driverSettlement) => {
              onDriverSettlementStatusUpdate(
                driverSettlement,
                DriverSettlementStatus.Sent
              );
            },
          },
          {
            icon: <PanTool />,
            tooltip: t("finance:holdSettl", "Put driver settlement on hold"),
            isApplicable: (driverSettlement) =>
              driverSettlement.status === DriverSettlementStatus.Generated ||
              driverSettlement.status === DriverSettlementStatus.Sent,
            onClick: (driverSettlement) => {
              onDriverSettlementStatusUpdate(
                driverSettlement,
                DriverSettlementStatus.OnHold
              );
            },
          },
          {
            icon: <DoneAll />,
            tooltip: t("finance:closeSettl", "Close driver settlement"),
            isApplicable: (driverSettlement) =>
              driverSettlement.status !== DriverSettlementStatus.Closed &&
              driverSettlement.status !== DriverSettlementStatus.NotSettled,
            onClick: (driverSettlement) => {
              onDriverSettlementStatusUpdate(
                driverSettlement,
                DriverSettlementStatus.Closed
              );
            },
          },
          {
            icon: <RestorePage />,
            isApplicable: (driverSettlement) =>
              driverSettlement.status === DriverSettlementStatus.Closed,
            tooltip: t("finance:reopenSettl", "Reopen driver settlement"),
            onClick: (driverSettlement) => {
              onDriverSettlementStatusUpdate(
                driverSettlement,
                DriverSettlementStatus.Generated,
                ""
              );
            },
          },
        ]}
      />
    </>
  );
};

export default DriverSettlementList;
