import SegmentProvider from "../../components/account/SegmentProvider";
import ShipmentTracker from "../../components/tracking/ShipmentTracker";
import { useSearchParams } from "react-router-dom";
import ErrorMessage from "../../components/common/ErrorMessage/ErrorMessage";

const ViewShipmentTracker = () => {
  const [searchParams] = useSearchParams();

  const code = searchParams.get("code");

  if (!code) {
    return <ErrorMessage message="Invalid tracking link. Missing code" />;
  }
  return (
    <>
      <ShipmentTracker code={code} />
      <SegmentProvider />
    </>
  );
};

export default ViewShipmentTracker;
