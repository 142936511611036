import { Autocomplete, AutocompleteProps, TextField } from "@mui/material";
import { capitalize } from "lodash";
import { useTranslation } from "react-i18next";
import { GetTrailersQuery } from "../../../graphql/generated";

export type TrailerSelectProps = {
  trailers: GetTrailersQuery["trailers"]["data"];
  onChange: (
    trailer: GetTrailersQuery["trailers"]["data"][0] | null | undefined
  ) => void;
} & Omit<
  AutocompleteProps<
    GetTrailersQuery["trailers"]["data"][0],
    undefined,
    undefined,
    undefined
  >,
  | "options"
  | "disablePortal"
  | "filterOptions"
  | "getOptionLabel"
  | "size"
  | "renderInput"
  | "onChange"
>;

export default function TrailerSelect({
  trailers,
  onChange,
  ...props
}: TrailerSelectProps) {
  const { t } = useTranslation(["assets"]);
  return (
    <Autocomplete
      disablePortal
      fullWidth
      options={trailers || []}
      getOptionLabel={(option) => option.serialNumber}
      onChange={(e, trailer) => {
        onChange(trailer);
      }}
      value={props.value}
      size="small"
      renderInput={(inputProps) => (
        <TextField
          {...inputProps}
          label={capitalize(t("assets:trailer.one", "Tractor"))}
          name="trailer"
        />
      )}
      {...props}
    />
  );
}
