import {
  InputAdornment,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DriverPaymentMethod, PaymentRule } from "../../../graphql/generated";
import LocaleProvider from "../../../providers/LocaleProvider";
import paymentMethodLabel from "../../../utils/labels/paymentRule/paymentMethodLabel";
import { useTranslation } from "react-i18next";

type PaymentRuleFormProps = {
  paymentRule: PaymentRule;
  onChange: (paymentRule: PaymentRule) => void;
};

const PaymentRuleForm = ({ paymentRule, onChange }: PaymentRuleFormProps) => {
  const { t } = useTranslation("paymentRules");
  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Typography variant="body2">{t("form.driverIsPaid")}</Typography>
      <Select
        label={t("form.paymentMethod")}
        size="small"
        value={paymentRule.method}
        onChange={(event) => {
          onChange({
            ...paymentRule,
            method: event.target.value as DriverPaymentMethod,
          });
        }}
        name="paymentMethod"
      >
        {Object.values(DriverPaymentMethod).map((paymentMethod) => (
          <MenuItem key={paymentMethod} value={paymentMethod}>
            <ListItemText primary={paymentMethodLabel(paymentMethod)} />
          </MenuItem>
        ))}
      </Select>
      <TextField
        type="number"
        value={String(paymentRule.amount) || ""}
        onChange={(event) => {
          onChange({
            ...paymentRule,
            amount: parseFloat(event.target.value) || 0,
          });
        }}
        size="small"
        label={t("form.amount")}
        name="amount"
        inputProps={{
          type: "number",
          min: 0,
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {paymentRule.method === DriverPaymentMethod.RevenueShare
                ? "%"
                : LocaleProvider.getCurrencySymbol()}
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
};

export default PaymentRuleForm;
