import { Container } from "@mui/material";
import GoodProfileFormContainer from "../../../components/commodity-management/GoodProfileForm";

function CreateGoodProfile() {
  return (
    <Container>
      <GoodProfileFormContainer />
    </Container>
  );
}

export default CreateGoodProfile;
