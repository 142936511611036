import { Trailer, TrailerType } from "../../../graphql/generated";
import { feetInchesToMeters } from "../../../utils/conversion/distance";
import { poundsToKg } from "../../../utils/conversion/weight";

type DefaultCapacityByTrailerType = {
  [key in TrailerType]?: Pick<
    Trailer,
    | "width"
    | "height"
    | "length"
    | "maxWeight"
    | "maxTemperature"
    | "minTemperature"
    | "numberOfAxles"
  >;
};

const defaultCapacityByTrailerType: DefaultCapacityByTrailerType = {
  [TrailerType.StandardDeck]: {
    width: feetInchesToMeters(8),
    height: feetInchesToMeters(12.5),
    length: feetInchesToMeters(28),
    numberOfAxles: 2,
    maxTemperature: null,
    minTemperature: null,
    maxWeight: null,
  },
  [TrailerType.FlatBed]: {
    width: feetInchesToMeters(8),
    length: feetInchesToMeters(26),
    height: null,
    numberOfAxles: 2,
    maxTemperature: null,
    minTemperature: null,
    maxWeight: poundsToKg(50000),
  },
  [TrailerType.Tanker]: {
    width: feetInchesToMeters(8),
    height: feetInchesToMeters(12),
    length: feetInchesToMeters(40),
    numberOfAxles: 2,
  },
  [TrailerType.DryBulk]: {
    width: feetInchesToMeters(8),
    length: feetInchesToMeters(26),
    numberOfAxles: 2,
    maxTemperature: null,
    minTemperature: null,
    maxWeight: null,
  },
  [TrailerType.Conestoga]: {
    width: null,
    length: null,
    numberOfAxles: null,
    maxTemperature: null,
    minTemperature: null,
    maxWeight: null,
  },
  [TrailerType.Lowboy]: {
    width: null,
    length: null,
    numberOfAxles: null,
    maxTemperature: null,
    minTemperature: null,
    maxWeight: null,
  },
  [TrailerType.Enclosed]: {
    width: null,
    length: null,
    numberOfAxles: null,
    maxTemperature: null,
    minTemperature: null,
    maxWeight: null,
  },
  [TrailerType.MultiCar]: {
    width: null,
    length: null,
    numberOfAxles: null,
    maxTemperature: null,
    minTemperature: null,
    maxWeight: null,
  },
  [TrailerType.Refrigerated]: {
    length: feetInchesToMeters(28),
    width: feetInchesToMeters(8),
    height: feetInchesToMeters(0, 12.5),
    numberOfAxles: 2,
    maxTemperature: null,
    minTemperature: null,
    maxWeight: null,
  },

  // To be added later
  // dropdeck/goosenek
  // [TrailerType.Dropdeck]: {
  //   width: feetInchesToMeters(8),
  //   length: feetInchesToMeters(40),
  //   maxWeight: poundsToKg(70000),
  // },
  // deepdrop/electronic
  // [TrailerType.DeepDrop]: {
  //   width: feetInchesToMeters(8),
  //   height: feetInchesToMeters(13, 6),
  //   length: feetInchesToMeters(45),
  // },
  // timber/loggers
  // [TrailerType.DeepDrop]: {
  //   length: feetInchesToMeters(40),
  //   numberOfAxles: 2,
  // },
};

export const defaultCapacity = {
  length: null,
  width: null,
  height: null,
  maxWeight: null,
  maxTemperature: null,
  minTemperature: null,
  numberOfAxles: null,
};

export default defaultCapacityByTrailerType;
