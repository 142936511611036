import { Alert, AlertProps, Stack } from "@mui/material";
import {
  ExtensionMessageType,
  GetExtensionsMessagesQuery,
} from "../../../graphql/generated";

type ExtensionMessageData = GetExtensionsMessagesQuery["extensionsMessages"][0];

type ExtensionMessagesProps = {
  messages: ExtensionMessageData[];
};

const messageTypeToSeverity: {
  [key in ExtensionMessageType]: AlertProps["severity"];
} = {
  [ExtensionMessageType.Error]: "error",
  [ExtensionMessageType.Warning]: "warning",
  [ExtensionMessageType.Info]: "info",
};

const ExtensionsMessages = ({ messages }: ExtensionMessagesProps) => {
  return (
    <Stack>
      {messages.map((message) => (
        <Alert severity={messageTypeToSeverity[message.type]}>
          Extension {message.extensionName}: {message.text}
        </Alert>
      ))}
    </Stack>
  );
};

export default ExtensionsMessages;
