import {
  GetTripQuery,
  ShipmentLocationType,
  Trip,
} from "../../../../graphql/generated";
import { InfoWindowF, MarkerF, PolylineF } from "@react-google-maps/api";
import { Box, Typography, useTheme } from "@mui/material";
import { flatten, groupBy, keyBy, map, uniq } from "lodash";
import Map from "../../../common/Map/Map";
import toLatLng from "../../../../utils/geo/toLaLng";
import { useState, useMemo, useEffect } from "react";
import pickupPin from "../../../../assets/icons/pickup-pin.svg";
import dropoffPin from "../../../../assets/icons/dropoff-pin.svg";
import truckPin from "../../../../assets/icons/truck-pin.svg";

import { ShipmentLocationDetails } from "../../../shipment/ShipmentSummary/ShipmentSummary";
import { ReactNode } from "react";
import LinkedAssetHeader from "../../../tracking/LinkedAssetHeader/LinkedAssetHeader";
import TripLiveSummary from "../../../tracking/TripLiveSummary/TripLiveSummary";

type TripRouteProps = {
  trip: Pick<
    Exclude<GetTripQuery["tripById"], null | undefined>,
    | "_id"
    | "status"
    | "shipmentLocations"
    | "shipments"
    | "lastTrailerLocation"
    | "lastTrailerLocationDate"
    | "tripNumber"
    | "driver"
    | "trailer"
    | "tractor"
  > & {
    polyline: Trip["polyline"];
  };
  children?: ReactNode;
  showAssetLocation?: boolean;
};

const TripRoute = ({ trip, children, showAssetLocation }: TripRouteProps) => {
  const [mapLoaded, setMapLoaded] = useState(false);
  const center = useMemo(
    () => toLatLng(trip.shipmentLocations[0].location),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const [isAssetInfoWindowOpen, setIsAssetInfoWindowOpen] = useState(false);

  useEffect(() => {
    if (mapLoaded && showAssetLocation) {
      setIsAssetInfoWindowOpen(true);
    }
  }, [mapLoaded, showAssetLocation]);

  return (
    <div style={{ height: "100%" }}>
      <Map
        containerStyle={{ height: "100%" }}
        center={center}
        zoom={10}
        onLoad={(map) => {
          setMapLoaded(true);
          const bounds = new google.maps.LatLngBounds();
          trip.shipmentLocations.forEach((location) => {
            bounds.extend(toLatLng(location.location));
          });
          map.fitBounds(bounds);
        }}
      >
        {mapLoaded ? <TripMarkersAndPath trip={trip} /> : null}
        {showAssetLocation && trip.lastTrailerLocation ? (
          <MarkerF
            position={toLatLng(trip.lastTrailerLocation)}
            options={{
              icon: {
                url: truckPin,
                anchor: new window.google.maps.Point(17, 17),
              },
            }}
            onClick={() => {
              setIsAssetInfoWindowOpen(true);
            }}
          >
            {isAssetInfoWindowOpen ? (
              <InfoWindowF
                onCloseClick={() => {
                  setIsAssetInfoWindowOpen(false);
                }}
                position={toLatLng(trip.lastTrailerLocation)}
              >
                <>
                  <LinkedAssetHeader
                    linkedAsset={{
                      trailer: trip.trailer,
                      tractor: trip.tractor,
                      driver: trip.driver,
                      lastKnownLocationDate: trip.lastTrailerLocationDate,
                    }}
                  />
                  <TripLiveSummary trip={trip} hideTripNumber={true} />
                </>
              </InfoWindowF>
            ) : null}
          </MarkerF>
        ) : null}
        {children}
      </Map>
    </div>
  );
};

type TripMarkersAndPathProps = {
  trip: TripRouteProps["trip"];
};

export const TripMarkersAndPath = ({ trip }: TripMarkersAndPathProps) => {
  const allShippedGoodsById = useMemo(
    () =>
      keyBy(
        flatten(
          trip.shipmentLocations.map((location) => location.shippedGoods || [])
        ),
        "_id"
      ),
    [trip]
  );
  const [openShipmentLocations, setOpenShipmentLocations] = useState<
    TripRouteProps["trip"]["shipmentLocations"]
  >([]);
  const theme = useTheme();

  return (
    <>
      <>
        {map(
          groupBy(
            trip.shipmentLocations.filter(
              (shipmentLocation) =>
                shipmentLocation.location.latitude &&
                shipmentLocation.location.longitude
            ),
            (location) =>
              `${location.location.latitude}-${location.location.longitude}`
          ),
          (shipmentLocationGroup, index) => {
            const shipmentLocation = shipmentLocationGroup[0];
            return (
              <MarkerF
                position={toLatLng(shipmentLocation.location)}
                key={index}
                options={{
                  icon: {
                    url:
                      shipmentLocation.locationType ===
                        ShipmentLocationType.Pickup ||
                      shipmentLocation.locationType ===
                        ShipmentLocationType.TrailerPickup
                        ? pickupPin
                        : dropoffPin,
                    anchor: new window.google.maps.Point(13.5, 13.5),
                  },
                }}
                onClick={() => {
                  setOpenShipmentLocations(
                    uniq(openShipmentLocations.concat(shipmentLocation))
                  );
                }}
              >
                {openShipmentLocations.includes(shipmentLocation) ? (
                  <InfoWindowF
                    position={toLatLng(shipmentLocation.location)}
                    onCloseClick={() => {
                      setOpenShipmentLocations(
                        openShipmentLocations.filter(
                          (location) => location !== shipmentLocation
                        )
                      );
                    }}
                  >
                    <>
                      {shipmentLocationGroup.map((location, index) => (
                        <Box
                          sx={{
                            "&:not(:last-of-type)": { pb: 1 },
                            display: "flex",
                            flexDirection: "row",
                          }}
                          key={index}
                        >
                          <Typography
                            sx={{ fontSize: 13, fontWeight: 600, mr: 1 }}
                          >
                            {trip.shipmentLocations.indexOf(location) + 1}.
                          </Typography>
                          <ShipmentLocationDetails
                            shipmentLocation={location}
                            allShippedGoodsById={allShippedGoodsById}
                          />
                        </Box>
                      ))}
                    </>
                  </InfoWindowF>
                ) : null}
              </MarkerF>
            );
          }
        )}
      </>
      {window.google ? (
        <PolylineF
          path={window.google.maps.geometry.encoding.decodePath(trip.polyline)}
          options={{
            strokeColor: theme.palette.primary.main,
            strokeWeight: 4,
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
};
export default TripRoute;
