import { Container } from "@mui/material";
import UserForm from "../../components/account/UserForm";

function CreateUser() {
  return (
    <Container>
      <UserForm />
    </Container>
  );
}

export default CreateUser;
