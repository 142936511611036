import LocaleProvider from "../../providers/LocaleProvider";
import { kgToLocalWeight } from "../conversion/weight";

export default function formatWeight(
  weightInKg: number | null | undefined,
  includeUnit = true
) {
  const weightInLocal = kgToLocalWeight(weightInKg || 0);
  const roundedWeightInLocal = Number.parseFloat(weightInLocal.toFixed(2));
  return `${roundedWeightInLocal} ${
    includeUnit
      ? `${LocaleProvider.getWeightUnit()}${
          roundedWeightInLocal > 1 ? "s" : ""
        }`
      : ""
  }`.trim();
}
