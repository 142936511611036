import { GOOGLE_MAPS_API_KEY } from "../../config/keys";
import { Address } from "../../graphql/generated";

export default async function fetchAddress(
  address: string
): Promise<Address | null> {
  try {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
        address
      )}&key=${GOOGLE_MAPS_API_KEY}`
    );
    const data = await response.json();

    if (data.status !== "OK") {
      console.error("Unknown error");
      return null;
    }

    if (!data.results.length) {
      console.error("No results found");
      return null;
    }

    const result: google.maps.GeocoderResult = data.results[0];

    const addressComponents = result.address_components;
    const city =
      addressComponents.find((component: any) => {
        return component.types.includes("locality");
      })?.long_name || "";
    const streetNumber =
      addressComponents.find((component: any) => {
        return component.types.includes("street_number");
      })?.long_name || "";
    const route =
      addressComponents.find((component: any) => {
        return component.types.includes("route");
      })?.long_name || "";
    const line1 = `${streetNumber}${route ? ` ${route}` : ""}`;
    const state =
      addressComponents.find((component: any) => {
        return component.types.includes("administrative_area_level_1");
      })?.long_name || "";
    const country =
      addressComponents.find((component: any) => {
        return component.types.includes("country");
      })?.long_name || "";
    const postalCode =
      addressComponents.find((component: any) => {
        return component.types.includes("postal_code");
      })?.long_name || "";
    const googlePlaceId = result.place_id;
    const label = result.formatted_address;
    const potentialStructuredAddress: Address = {
      city,
      line1,
      state,
      country,
      postalCode,
      googlePlaceId,
      label,
      coordinates: {
        latitude: Number(result.geometry.location.lat),
        longitude: Number(result.geometry.location.lng),
      },
    };

    return potentialStructuredAddress;
  } catch (e) {
    console.error(e);
    return null;
  }
}

export function parseTextualAddress(address: string) {
  // Split the address string into its components using commas as separators
  const components = address.split(", ");

  // Extract individual components
  const line1 = components[0];
  let line2 = "";
  const city = components[components.length - 3];
  const statePostalCode = components[components.length - 2];
  const country = components[components.length - 1];

  // Check if line2 exists
  if (components.length > 4) {
    line2 = components.slice(1, components.length - 3).join(", ");
  }

  // Split the statePostalCode into state and postalCode
  const [state, postalCode] = statePostalCode.split(" ");

  // Return the address components as an object
  return {
    line1,
    line2,
    city,
    state,
    postalCode,
    country,
  };
}
