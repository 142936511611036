import { omit, toArray } from "lodash";
import { useSnackbar } from "notistack";
import { useRef } from "react";
import {
  BusinessEntityType,
  useAddBillingRuleMutation,
  useDeleteBillingRuleMutation,
  useEditBillingRuleMutation,
  useGetBrokerDetailsQuery,
  useGetCustomerBillingRulesQuery,
  useGetCustomerDetailsQuery,
} from "../../../graphql/generated";
import ErrorMessage from "../../common/ErrorMessage/ErrorMessage";
import LoadingOverlay from "../../common/LoadingOverlay";
import BillingRulesEditor from "./BillingRulesEditor";

type BillignRulesEditorContainerProps = {
  customerId: string;
  customerType: BusinessEntityType.Customer | BusinessEntityType.Broker;
};

const BillingRulesEditorContainer = ({
  customerId,
  customerType,
}: BillignRulesEditorContainerProps) => {
  const initialCustomerType = useRef(customerType);
  const getCustomerBillingRulesQuery = useGetCustomerBillingRulesQuery(
    {
      customer: customerId,
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  const getCustomerQuery =
    initialCustomerType.current === BusinessEntityType.Customer
      ? // eslint-disable-next-line react-hooks/rules-of-hooks
        useGetCustomerDetailsQuery({
          id: customerId,
        })
      : // eslint-disable-next-line react-hooks/rules-of-hooks
        useGetBrokerDetailsQuery({
          id: customerId,
        });
  const editBillingRuleMutation = useEditBillingRuleMutation();
  const addBillingRuleMutation = useAddBillingRuleMutation();
  const deleteBillingRuleMutation = useDeleteBillingRuleMutation();
  const { enqueueSnackbar } = useSnackbar();
  if (getCustomerBillingRulesQuery.isLoading || getCustomerQuery.isLoading) {
    return <LoadingOverlay loading />;
  }
  if (
    getCustomerBillingRulesQuery.error ||
    !getCustomerBillingRulesQuery.data
  ) {
    return (
      <ErrorMessage
        error={
          getCustomerBillingRulesQuery.error ||
          "Unknown error while fetching billing rules"
        }
      />
    );
  }
  if (
    getCustomerQuery.error ||
    !getCustomerQuery.data ||
    !toArray(getCustomerQuery.data)[0]
  ) {
    return (
      <ErrorMessage
        error={
          getCustomerQuery.error || "Unknown error while fetching customer"
        }
      />
    );
  }
  const initialBillingRules =
    getCustomerBillingRulesQuery.data.billingRules.data;
  return (
    <>
      <ErrorMessage
        error={
          editBillingRuleMutation.error ||
          addBillingRuleMutation.error ||
          deleteBillingRuleMutation.error
        }
      />
      <BillingRulesEditor
        customer={toArray(getCustomerQuery.data)[0]}
        initialBillingRules={initialBillingRules}
        saving={
          editBillingRuleMutation.isLoading ||
          addBillingRuleMutation.isLoading ||
          deleteBillingRuleMutation.isLoading
        }
        onSave={async (billingRules) => {
          const deletedBillingRules = initialBillingRules.filter(
            (initialBillingRule) =>
              !billingRules.find(
                (billingRule) => billingRule._id === initialBillingRule._id
              )
          );

          await Promise.all([
            ...billingRules.map(async (billingRule) => {
              if (billingRule._id.startsWith("NEW")) {
                await addBillingRuleMutation.mutateAsync({
                  newBillingRuleData: {
                    ...omit(billingRule, "_id"),
                    customer: customerId,
                  },
                });
              } else {
                await editBillingRuleMutation.mutateAsync({
                  id: billingRule._id,
                  editBillingRuleData: {
                    ...omit(billingRule, "customer", "_id"),
                  },
                });
              }
            }),
            ...deletedBillingRules.map(async (billingRule) => {
              await deleteBillingRuleMutation.mutateAsync({
                id: billingRule._id,
              });
            }),
          ]);
          enqueueSnackbar("Saved");
          getCustomerBillingRulesQuery.refetch();
        }}
      />
    </>
  );
};

export default BillingRulesEditorContainer;
