import { Stack, Tooltip } from "@mui/material";
import { Qualification } from "../../../graphql/generated";
import { Warning } from "@mui/icons-material";
import { differenceInDays } from "date-fns";
import { formatDateTime } from "../../../utils/labels/formatDateTime";

type QualificationItem = Pick<
  Qualification,
  "_id" | "label" | "number" | "startDate" | "endDate" | "required"
>;

export type QualificationWarningsProps = {
  qualifications: QualificationItem[];
};

const QualificationWarnings = ({
  qualifications,
}: QualificationWarningsProps) => {
  if (!qualifications.length) {
    return null;
  }

  const shortlyExpiringQualifications = qualifications.filter(
    (task) =>
      new Date(task.endDate) > new Date() &&
      differenceInDays(new Date(task.endDate), new Date()) <= 7
  );

  const expiredQualifications = qualifications.filter(
    (task) => new Date(task.endDate) < new Date()
  );

  return (
    <Stack direction="row" spacing={1}>
      {shortlyExpiringQualifications.length
        ? shortlyExpiringQualifications.map((qualification) => (
            <Tooltip
              title={`${qualification.label} expiring on ${formatDateTime(
                qualification.endDate
              )}`}
              key={qualification._id}
            >
              <Warning color="warning" />
            </Tooltip>
          ))
        : null}
      {expiredQualifications.map((qualification) => (
        <Tooltip
          title={`${qualification.label} expired since ${formatDateTime(
            qualification.endDate
          )}`}
          key={qualification._id}
        >
          <Warning color={qualification.required ? "error" : "warning"} />
        </Tooltip>
      ))}
    </Stack>
  );
};

export default QualificationWarnings;
