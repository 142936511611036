import { Container } from "@mui/material";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import BusinessEntityForm from "../../../components/asset-management/BusinessEntityForm";
import HeaderTitle from "../../../components/common/HeaderTitle";
import { BusinessEntity, BusinessEntityType } from "../../../graphql/generated";
import { useNavigationOptions } from "../../LayoutRoutes";
import { useTranslation } from "react-i18next";

function EditSupplier() {
  const { t } = useTranslation("business");
  const { id } = useParams();
  const { setNavigationOptions } = useNavigationOptions();
  const onLoad = useCallback(
    (businessEntity: Pick<BusinessEntity, "code">) => {
      setNavigationOptions({
        title: (
          <HeaderTitle
            label={t("supplier.edit", "Edit Supplier")}
            suffix={businessEntity?.code || ""}
          />
        ),
        showBackIcon: true,
      });
    },
    [setNavigationOptions, t]
  );
  if (!id) {
    return null;
  }
  return (
    <Container>
      <BusinessEntityForm
        businessEntityId={id}
        onLoad={onLoad}
        urlPrefix="/suppliers"
        availableTypes={[BusinessEntityType.Supplier]}
      />
    </Container>
  );
}

export default EditSupplier;
