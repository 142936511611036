import Joi from "joi";
import { mapValues } from "lodash";
import { TextualAddress } from "../../graphql/generated";

const rules = {
  line1: Joi.string().allow("", null).label("Address Line 1"),
  line2: Joi.string().allow("", null).label("Address Line 2"),
  postalCode: Joi.string().allow("", null).label("Postal Code"),
  city: Joi.string().allow("", null).label("City"),
  state: Joi.string().allow("", null).label("State"),
  country: Joi.string().allow("", null).label("Country"),
  googlePlaceId: Joi.string().allow("", null).label("Google Place ID"),
};

const textualAddressSchema = Joi.object<TextualAddress>(rules);

const requiredTextualAddressSchema = Joi.object(
  mapValues(rules, (rule, ruleKey) =>
    ruleKey === "googlePlaceId" || ruleKey === "line2"
      ? rule
      : rule.required().disallow("", null)
  )
);

export { textualAddressSchema, requiredTextualAddressSchema };
