import { useEffect, useState } from "react";
import {
  Box,
  Stack,
  TextField,
  Autocomplete,
  FormHelperText,
} from "@mui/material";

import UploadButton from "../../common/UploadButton/UploadButton";
import { FormError } from "../../../redux/slices/Types";
import _, { capitalize } from "lodash";
import { DocumentWithFile } from "./DocumentsUploadModal";
import { useTranslation } from "react-i18next";

interface DocumentFormProps {
  id: number;
  document: DocumentWithFile;
  hasLabel?: boolean;
  onFileSelect: (id: number, file: File | null) => void;
  onChange: (id: number, document: DocumentWithFile) => void;
  errors?: FormError;
  accept?: string;
}

export default function DocumentForm({
  id,
  document,
  hasLabel,
  onChange,
  onFileSelect,
  errors,
  accept,
}: DocumentFormProps) {
  const { t } = useTranslation("users");
  const [formErrors, setFormErrors] = useState<FormError | undefined>(errors);

  useEffect(() => {
    setFormErrors(errors);
  }, [errors]);

  const removeError = (name: string) => {
    const err: FormError = {
      formId: formErrors?.formId || "",
      error: _.omit(formErrors?.error, name),
    };
    setFormErrors(err);
  };

  const handleChange = (name: string, value: string) => {
    removeError(name);
    onChange(id, { ...document, [name]: value });
  };

  const handleUpload = (file: File | null) => {
    removeError("url");
    onFileSelect(id, file);
  };

  return (
    <Stack
      spacing={1}
      direction="row"
      component="form"
      justifyContent={hasLabel ? undefined : "stretch"}
      sx={{
        width: "100%",
      }}
    >
      <Box
        sx={{
          width: 1 / 2,
          flexDirection: "column",
          flex: 1,
        }}
      >
        <UploadButton onUpload={handleUpload} accept={accept} />
        {formErrors && formErrors.error["file"] && (
          <FormHelperText sx={{ ml: 2 }} error>
            {formErrors.error["file"]}
          </FormHelperText>
        )}
      </Box>
      {hasLabel ? (
        <Autocomplete
          sx={{ width: 1 / 3 }}
          disablePortal
          options={[]}
          onChange={(
            event: React.SyntheticEvent<Element, Event>,
            value: string | null
          ) => {
            handleChange("name", value || "");
          }}
          freeSolo
          value={document.name || null}
          size="small"
          renderInput={(inputProps) => (
            <TextField
              required
              name="name"
              error={formErrors && formErrors.error["name"] ? true : false}
              helperText={formErrors && formErrors.error["name"]}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleChange(event.target.name, event.target.value)
              }
              {...inputProps}
              label={capitalize(t("driver.document.label", "Document Label"))}
            />
          )}
        />
      ) : null}
    </Stack>
  );
}
