import React, { ReactNode, useState } from "react";
import { createPortal } from "react-dom";
import { useGoogleMap } from "@react-google-maps/api";

type MapControlProps = {
  position: google.maps.ControlPosition;
  children: ReactNode;
  zIndex?: number;
};

const MapControl = ({ position, children, zIndex = 0 }: MapControlProps) => {
  const map = useGoogleMap();

  const [container] = useState(document.createElement("div"));

  React.useEffect(() => {
    if (!map) {
      return;
    }
    const controlsContainer = map.controls[position];

    controlsContainer.push(container);

    return () => {
      // @ts-ignore
      const index = controlsContainer.indexOf(container);
      if (index !== -1) {
        controlsContainer.removeAt(index);
      }
    };
  }, [map, container, position]);

  React.useEffect(() => {
    container.style.zIndex = String(zIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zIndex]);

  return createPortal(children, container);
};

export default MapControl;
