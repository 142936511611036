import {
  DeleteOutlineOutlined,
  ModeEditOutlineOutlined,
  Abc,
  AutoGraph,
  CloudUpload,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import {
  GetReceiverForecastDetailsQuery,
  useUploadStorageFacilityReadingsCsvDataMutation,
} from "../../../graphql/generated";
import InfoBlock from "../../common/InfoBlock";
import LabeledInfo from "../../common/LabeledInfo";

import enumLabel from "../../../utils/labels/enumLabel";
import ForecastSimulatorContainer from "../ForecastSimulator";
import { useState } from "react";
import * as XLSX from "xlsx";
import { LoadingButton } from "@mui/lab";
import { formatDateTime } from "../../../utils/labels/formatDateTime";
import ForecastModelVisualizer from "../ForecastModelVisualizer.tsx/ForecastModelVisualizer";
import useDialog from "../../../utils/hooks/useDialog";

export type ReceiverForecastDetailsData =
  GetReceiverForecastDetailsQuery["receiverForecastById"];

type ReceiverForecastDetailsProps = {
  receiverForecast: ReceiverForecastDetailsData;
  onReadingsRefresh: () => void;
  onEdit: () => void;
  onDelete: () => void;
};

function ReceiverForecastDetails({
  receiverForecast,
  onEdit,
  onDelete,
  onReadingsRefresh,
}: ReceiverForecastDetailsProps) {
  //   const { t } = useTranslation(["assets", "common"]);
  const [simulatorModalOpen, setSimulatorModalOpen] = useState(false);
  const [readingsFile, setReadingsFile] = useState<File | null>(null);
  const uploadReadingsDataMutation =
    useUploadStorageFacilityReadingsCsvDataMutation();
  const { showDialog } = useDialog();

  return (
    <Box>
      <Stack direction="row" flexDirection="row-reverse" spacing={2}>
        <IconButton onClick={onDelete} color="error">
          <DeleteOutlineOutlined />
        </IconButton>
        <IconButton onClick={onEdit} color="primary">
          <ModeEditOutlineOutlined />
        </IconButton>
        <Button
          variant="outlined"
          startIcon={<AutoGraph />}
          onClick={() => {
            setSimulatorModalOpen(true);
          }}
        >
          Simulate
        </Button>
      </Stack>
      <Grid container spacing={3}>
        <Grid item sm={12}>
          <InfoBlock title="Forecating Details">
            <LabeledInfo
              title="Receiver"
              value={receiverForecast.receiver?.name}
              icon={<Abc />}
            />
          </InfoBlock>
        </Grid>
        <Grid item sm={12}>
          <InfoBlock title="Tanks">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Tank</TableCell>
                  <TableCell>Commodity</TableCell>
                  <TableCell>Capacity</TableCell>
                  <TableCell>Frequency</TableCell>
                  <TableCell>Threshold</TableCell>
                  <TableCell>Target</TableCell>
                  <TableCell>Level</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {receiverForecast.models?.map((model) => {
                  const storageFacility =
                    receiverForecast.receiver?.storageFacilities?.find(
                      (sf) => sf.identifier === model.storageFacilityId
                    );
                  if (!storageFacility) {
                    return null;
                  }

                  const lastReading = storageFacility.latestReading;
                  return (
                    <TableRow>
                      <TableCell>{storageFacility.identifier}</TableCell>
                      <TableCell>{storageFacility.commodity?.label}</TableCell>
                      <TableCell>
                        {storageFacility.capacity}
                        <br />
                        {storageFacility.safeFillLevel ? (
                          <Typography variant="caption" color="green">
                            Safe Fill: {storageFacility.safeFillLevel}
                          </Typography>
                        ) : null}
                        {storageFacility.safeFillLevel &&
                        storageFacility.shutDownLevel ? (
                          <br />
                        ) : (
                          ""
                        )}
                        {storageFacility.shutDownLevel ? (
                          <Typography variant="caption" color="red">
                            Shut Down: {storageFacility.shutDownLevel}
                          </Typography>
                        ) : null}
                      </TableCell>
                      <TableCell>{enumLabel(model.slots?.[0]?.type)}</TableCell>
                      <TableCell>{model.threshold}</TableCell>
                      <TableCell>{model.target}</TableCell>
                      <TableCell>
                        {lastReading ? (
                          <Stack spacing={1} alignItems="flex-start">
                            <LinearProgress
                              variant="determinate"
                              color={
                                model.target &&
                                lastReading.level >= model.target
                                  ? "success"
                                  : lastReading.level <=
                                    (model.threshold ||
                                      storageFacility.shutDownLevel ||
                                      0)
                                  ? "error"
                                  : "primary"
                              }
                              value={
                                ((lastReading?.level || 0) /
                                  storageFacility.capacity) *
                                100
                              }
                              sx={{
                                alignSelf: "stretch",
                              }}
                            />
                            {lastReading?.level || 0} /{" "}
                            {storageFacility.capacity}
                            <br />
                          </Stack>
                        ) : null}
                        {lastReading ? formatDateTime(lastReading?.date) : ""}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>

            <LoadingButton
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<CloudUpload />}
              loading={readingsFile !== null}
            >
              Upload CSV readings
              <VisuallyHiddenInput
                type="file"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                onChange={async (e) => {
                  if (!e.target.files) {
                    return;
                  }
                  const file = e.target.files[0];
                  setReadingsFile(file);
                  try {
                    if (!file) {
                      return;
                    }
                    const data = await file.arrayBuffer();
                    /* data is an ArrayBuffer */
                    const workbook = XLSX.read(data, {
                      cellDates: true,
                    });

                    const sheet = workbook.Sheets[workbook.SheetNames[0]];

                    const readingsFileData = XLSX.utils.sheet_to_json(sheet);

                    if (!readingsFileData.length) {
                      throw new Error("No readings found in the file");
                    }

                    await uploadReadingsDataMutation.mutateAsync({
                      data: readingsFileData,
                      receiverId: receiverForecast.receiverId,
                    });

                    showDialog({
                      title: "Readings uploaded",
                      description: "Readings uploaded successfully",
                      type: "primary",
                    });

                    setReadingsFile(null);
                    onReadingsRefresh();
                    e.target.value = "";
                  } catch (error) {
                    console.error(error);
                    showDialog({
                      title: "Error",
                      description:
                        "An error occurred while uploading readings." +
                          (error as Error).message || "Unknown error",
                      type: "error",
                    });
                    setReadingsFile(null);
                  }
                }}
              />
            </LoadingButton>
          </InfoBlock>

          <InfoBlock title="Sales Model">
            <ForecastModelVisualizer receiverForecast={receiverForecast} />
          </InfoBlock>
        </Grid>

        <Grid item sm={12}>
          <InfoBlock title="Trailer Profile">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Compartment</TableCell>
                  <TableCell>Capacity</TableCell>
                  <TableCell>Max Weight</TableCell>
                  <TableCell>Min Usage</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {receiverForecast.trailerProfile.compartments.map(
                  (compartment) => {
                    return (
                      <TableRow>
                        <TableCell>{compartment.label}</TableCell>
                        <TableCell>{compartment.capacity}</TableCell>
                        <TableCell>{compartment.maxWeight}</TableCell>
                        <TableCell>{compartment.minUsage}</TableCell>
                      </TableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
          </InfoBlock>
        </Grid>
      </Grid>

      <Dialog
        open={simulatorModalOpen}
        onClose={() => setSimulatorModalOpen(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>Simulate Forecast</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              pt: 2,
            }}
          >
            <ForecastSimulatorContainer
              receiverForecastId={receiverForecast._id}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default ReceiverForecastDetails;

export const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
