import Joi from "joi";
import { Qualification } from "../../../graphql/generated";
import documentSchema from "../../../utils/form/schemas/document.schema";

const qualificationSchema = Joi.object<Qualification>({
  label: Joi.string().required().label("Type"),
  number: Joi.string().allow(null).label("Number"),
  assetType: Joi.string().required().label("Asset Type"),
  assetId: Joi.string().required().label("Asset ID"),
  startDate: Joi.date().required().label("Issued Date"),
  endDate: Joi.date().required().label("Expiration Date"),
  required: Joi.boolean().allow(null).label("Required"),
  documents: Joi.array().items(documentSchema).label("Documents"),
});

export default qualificationSchema;
