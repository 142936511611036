import { MenuItem, Select, Stack } from "@mui/material";
import {
  ForecastingUptickInput,
  useGetBusinessStorageFacilitiesQuery,
} from "../../../graphql/generated";
import NumberTextField from "../../common/NumberTextField";

type UptickFormProps = {
  uptick: ForecastingUptickInput;
  receiverId: string;
  onChange: (uptick: ForecastingUptickInput) => void;
};

const UptickForm = ({ uptick, onChange, receiverId }: UptickFormProps) => {
  const storageFacilitiesQuery = useGetBusinessStorageFacilitiesQuery({
    id: receiverId,
  });

  const storageFacilities =
    storageFacilitiesQuery.data?.businessEntityById.storageFacilities || [];

  return (
    <Stack spacing={2}>
      <Select
        value={uptick.storageFacilityId}
        onChange={(event) => {
          onChange({
            ...uptick,
            storageFacilityId: event.target.value,
          });
        }}
        fullWidth
      >
        {storageFacilities.map((storageFacility) => (
          <MenuItem
            key={storageFacility.identifier}
            value={storageFacility.identifier}
          >
            {storageFacility.identifier} - {storageFacility.commodity?.label}
          </MenuItem>
        ))}
      </Select>

      <NumberTextField
        value={uptick.multiplier * 100}
        onChange={(event) => {
          onChange({
            ...uptick,
            multiplier: Number((Number(event.target.value) / 100).toFixed(2)),
          });
        }}
      />
    </Stack>
  );
};

export default UptickForm;
