import i18next from "i18next";
import { BillingRuleConditionTarget } from "../../../graphql/generated";

const billingRuleConditionTargetLabel = (
  target: BillingRuleConditionTarget
) => {
  return i18next.t(`billingRules:target.${target}`) as string;
};

export default billingRuleConditionTargetLabel;
