import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useEffect, useState } from "react";
import { AssetLinkingInput } from "../../../graphql/generated";
import AssetLinkingForm, {
  AssetLinkingFormContainerProps,
} from "../AssetLinkingForm";

export type AssetLinkingFormModalProps = Omit<
  AssetLinkingFormContainerProps,
  "assetLinking" | "onChange"
> & {
  assetLinking: AssetLinkingInput | null;
  onSubmit: (assetLinking: AssetLinkingInput) => void;
  isOpen: boolean;
  onClose: () => void;
};

const AssetLinkingFormModal = ({
  assetLinking,
  onSubmit,
  isOpen,
  onClose,
  ...props
}: AssetLinkingFormModalProps) => {
  const [localAssetLinking, setLocalAssetLinking] =
    useState<AssetLinkingInput | null>(assetLinking);

  useEffect(() => {
    if (!localAssetLinking) {
      setLocalAssetLinking(assetLinking);
    }
  }, [assetLinking, localAssetLinking]);

  return (
    <Dialog open={isOpen} onClose={() => onClose()}>
      <DialogTitle>Link to tractor/trailer</DialogTitle>

      <DialogContent>
        <AssetLinkingForm
          assetLinking={localAssetLinking}
          onChange={(assetLinking) => {
            setLocalAssetLinking(assetLinking);
          }}
          {...props}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={() => onClose()}>Close</Button>
        <Button
          variant="contained"
          disabled={!localAssetLinking}
          onClick={() => {
            if (!localAssetLinking) {
              return;
            }
            onSubmit(localAssetLinking);
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssetLinkingFormModal;
