import { useGetReceiverForecastListQuery } from "../../../graphql/generated";
import ReceiverForecastsList from "./ReceiverForecastsList";

export default function ReceiverForecastsListContainer() {
  return (
    <ReceiverForecastsList
      onBulkCreate={(goodProfiles) => {}}
      query={useGetReceiverForecastListQuery}
    />
  );
}
