import { Grid, Button, Box, Alert, AlertTitle } from "@mui/material";
import { flatten, keyBy } from "lodash";
import { GenerateTripsForPendingShipmentsMutation } from "../../../graphql/generated";
import ErrorMessage from "../../common/ErrorMessage/ErrorMessage";
import LoadingOverlay from "../../common/LoadingOverlay";
import { ShipmentLocationDetails } from "../../shipment/ShipmentSummary/ShipmentSummary";
import TripSummary from "../TripSummary";
import EmptyState from "../../common/EmptyState";
import { useTranslation } from "react-i18next";

export type TripSummaryData =
  GenerateTripsForPendingShipmentsMutation["generateTripsForPendingShipments"]["trips"][0];

interface TripsGeneratorProps {
  generatedTrips: TripSummaryData[];
  unassignedShipmentLocations: GenerateTripsForPendingShipmentsMutation["generateTripsForPendingShipments"]["unassignedShipmentLocations"];
  generating?: boolean;
  creating?: boolean;
  onValidate: () => void;
  error: Error | null;
}

export default function TripsGenerator({
  generatedTrips,
  unassignedShipmentLocations,
  generating,
  creating,
  onValidate,
  error,
}: TripsGeneratorProps) {
  const { t } = useTranslation("trips");
  return (
    <Grid container spacing={5} sx={{ height: "100%" }}>
      <Grid item xs={12}>
        <ErrorMessage error={error} />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          {unassignedShipmentLocations.length ? (
            <Alert severity="warning" sx={{ maxHeight: 200, overflow: "auto" }}>
              <AlertTitle>
                {t("unfulfilledLocations", "Unfulfilled locations")}
              </AlertTitle>
              {t(
                "unfulfilledDescription",
                "The following locations could not be visited due to unavailable assets or timing constraints"
              )}
              <br />
              <br />
              {unassignedShipmentLocations.map((shipmentLocation) => {
                const allShippedGoodsById = keyBy(
                  flatten(
                    shipmentLocation.shipment.shipmentLocations.map(
                      (location) => location.shippedGoods || []
                    )
                  ),
                  "_id"
                );
                return (
                  <ShipmentLocationDetails
                    allShippedGoodsById={allShippedGoodsById}
                    shipmentLocation={shipmentLocation}
                    shipment={shipmentLocation.shipment}
                  />
                );
              })}
            </Alert>
          ) : null}
        </Box>
        <Button variant="contained" color="secondary" onClick={onValidate}>
          {t("createMany", "Create the trips")}
        </Button>
      </Grid>
      <Grid
        container
        item
        spacing={5}
        sx={{
          height: "100%",
        }}
      >
        <LoadingOverlay
          loading={generating || creating || false}
          animationType="map"
        />
        {!(generating || creating) && !generatedTrips.length ? (
          <Grid
            item
            sx={{
              height: "100%",
              width: "100%",
            }}
          >
            <EmptyState />
          </Grid>
        ) : null}
        {generatedTrips.map((trip) => {
          return (
            <Grid
              item
              sx={{
                height: "100%",
              }}
              key={trip._id}
            >
              <TripSummary trip={trip} />
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
}
