import { useGetTransactionTemplatesQuery } from "../../../../graphql/generated";
import TransactionForm, { TransactionFormProps } from "./TransactionForm";

type TransactionFormContainerProps = Omit<
  TransactionFormProps,
  "transactionTemplates"
>;

export default function TransactionFormContainer(
  props: TransactionFormContainerProps
) {
  const getTransactionTemplatesQuery = useGetTransactionTemplatesQuery();

  return (
    <TransactionForm
      {...props}
      transactionTemplates={
        getTransactionTemplatesQuery.data?.templates?.transactionTemplates || []
      }
    />
  );
}
