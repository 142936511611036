import { AirlineSeatReclineNormal, WorkHistory } from "@mui/icons-material";
import {
  Box,
  Chip,
  Grid,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import {
  DriverHosStatus,
  GetAllAssetsLocationsQuery,
} from "../../../../graphql/generated";
import driverLabel from "../../../../utils/labels/driverLabel";
import {
  formatDateTime,
  formatDuration,
} from "../../../../utils/labels/formatDateTime";
import formatPhoneNumber from "../../../../utils/labels/formatPhoneNumber";
import enumLabel from "../../../../utils/labels/enumLabel";
import { useTranslation } from "react-i18next";
import driverStatusKey from "../../../../utils/i18n/driverStatusKey";

export type DriverStatus = DriverHosStatus | "UNASSIGNED" | "ASSIGNED";

export type LiveTrackerDriverData = Pick<
  GetAllAssetsLocationsQuery["drivers"]["data"][0],
  "_id" | "firstname" | "lastname" | "phoneNumber" | "hos"
> & { status: DriverStatus };

const DriverStatusColor: { [key in DriverStatus]: string } = {
  UNASSIGNED: "primary.main",
  ASSIGNED: "secondary.main",
  DRIVING: "accent.main",
  ON_DUTY: "secondary.main",
  OFF_DUTY: "grey.500",
  SLEEPING: "grey.500",
};

interface LiveTrackerDriverProps {
  driver: LiveTrackerDriverData;
  selected?: boolean;
  onClick?: (driver: LiveTrackerDriverData) => void;
}

export default function LiveTrackerDriver({
  driver,
  selected,
  onClick,
}: LiveTrackerDriverProps) {
  const { t } = useTranslation("users");
  const { firstname, lastname, phoneNumber, status } = driver;
  return (
    <Grid
      container
      columns={12}
      onClick={() => onClick && onClick(driver)}
      sx={{
        cursor: "pointer",
        backgroundColor: selected ? "secondary.light" : "white",
        p: 1,
        alignItems: "center",
      }}
    >
      <Grid item xs={8} sm={8} sx={{ display: "flex" }}>
        <Box
          sx={{
            backgroundColor: "primary.main",
            borderRadius: 3,
            minWidth: 50,
            maxWidth: "fit-content",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              "&": (theme) => ({
                color: theme.palette.secondary.contrastText,
              }),
            }}
          >
            {[firstname[0], lastname[lastname.length - 1]]
              .join("")
              .toUpperCase()}
          </Typography>
        </Box>
        <Box sx={{ ml: 1 }}>
          <Typography
            variant="body1"
            sx={{
              fontWeight: "bold",
            }}
          >
            {driverLabel({ firstname, lastname })}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: 100,
            }}
          >
            {formatPhoneNumber(phoneNumber)}
          </Typography>
        </Box>
      </Grid>
      <Grid
        item
        xs={4}
        sm={4}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Chip
          sx={{
            backgroundColor: DriverStatusColor[status],
            color: "white",
            fontWeight: "bold",
            minWidth: 110,
          }}
          label={t("driver.statusLabel", {
            status: driverStatusKey[status],
            defaultValue: enumLabel(status),
          })}
        />
      </Grid>
      {driver.hos ? (
        <Grid item xs={12} sx={{ mt: 1 }}>
          {driver.hos.currentDrivingTime && driver.hos.totalDrivingTime ? (
            <Box>
              <Stack direction="row" alignItems="center">
                <AirlineSeatReclineNormal color="primary" />
                <LinearProgress
                  variant="determinate"
                  value={
                    (driver.hos.currentDrivingTime /
                      driver.hos.totalDrivingTime) *
                    100
                  }
                  sx={{
                    height: 15,
                    flex: 1,
                    ml: 1,
                  }}
                />
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="caption">
                  {formatDuration(driver.hos.currentDrivingTime)} driven
                </Typography>
                <Typography variant="caption">
                  {formatDuration(
                    driver.hos.totalDrivingTime - driver.hos.currentDrivingTime
                  )}{" "}
                  left
                </Typography>
              </Stack>
            </Box>
          ) : null}
          {driver.hos.currentOnDutyTime && driver.hos.totalOnDutyTime ? (
            <Box>
              <Stack direction="row" alignItems="center">
                <WorkHistory color="secondary" />
                <LinearProgress
                  variant="determinate"
                  color="secondary"
                  value={
                    ((driver.hos.currentOnDutyTime +
                      driver.hos.currentDrivingTime) /
                      50400) *
                    100
                  }
                  sx={{
                    height: 15,
                    flex: 1,
                    ml: 1,
                  }}
                />
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="caption">
                  {formatDuration(
                    driver.hos.currentOnDutyTime + driver.hos.currentDrivingTime
                  )}{" "}
                  on duty
                </Typography>
                <Typography variant="caption">
                  {formatDuration(
                    50400 -
                      (driver.hos.currentOnDutyTime +
                        driver.hos.currentDrivingTime)
                  )}{" "}
                  left
                </Typography>
              </Stack>
            </Box>
          ) : null}
          {driver.hos.statusTime ? (
            <Chip
              label={`Last update ${formatDateTime(driver.hos.statusTime)}`}
            />
          ) : null}
        </Grid>
      ) : null}
    </Grid>
  );
}
