// These custom select filter operators are sligtly modified versions of
// https://github.com/mui/mui-x/blob/master/packages/x-data-grid/src/colDef/gridSingleSelectOperators.ts
// to make them support selects with multiple values
import {
  GridFilterInputMultipleSingleSelect,
  GridFilterInputSingleSelect,
  GridFilterOperator,
} from "@mui/x-data-grid-pro";
import { castArray, isObject } from "lodash";
export const customSelectFilterOperators: GridFilterOperator[] = [
  {
    value: "is",
    getApplyFilterFn: (filterItem) => {
      if (filterItem.value == null || filterItem.value === "") {
        return null;
      }
      return (value): boolean =>
        castArray(value)
          .map(parseObjectValue)
          .includes(parseObjectValue(filterItem.value));
    },
    InputComponent: GridFilterInputSingleSelect,
  },
  {
    value: "not",
    getApplyFilterFn: (filterItem) => {
      if (filterItem.value == null || filterItem.value === "") {
        return null;
      }
      return (value): boolean =>
        !castArray(value)
          .map(parseObjectValue)
          .includes(parseObjectValue(filterItem.value));
    },
    InputComponent: GridFilterInputSingleSelect,
  },
  {
    value: "isAnyOf",
    getApplyFilterFn: (filterItem) => {
      if (!Array.isArray(filterItem.value) || filterItem.value.length === 0) {
        return null;
      }
      const filterItemValues = filterItem.value.map(parseObjectValue);
      return (value): boolean =>
        castArray(value)
          .map(parseObjectValue)
          .some((v) => filterItemValues.includes(v));
    },
    InputComponent: GridFilterInputMultipleSingleSelect,
  },
];

const parseObjectValue = (value: unknown) => {
  if (value == null || !isObject(value)) {
    return value;
  }
  // @ts-ignore
  return value?.value;
};
