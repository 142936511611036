import { BusinessEntityType } from "../../graphql/generated";
import { ExcelMapping } from "./mappingTypes";

const locationsMapping: ExcelMapping = {
  "Location Name": { key: "name", type: "string", required: true },
  "Additionnal Types": {
    key: "additionalTypes",
    type: "[string]",
    required: true,
  },
  "Location Type": {
    key: "type",
    type: "enum",
    enumOptions: Object.values(BusinessEntityType),
    required: true,
  },
  "Business Code": { key: "code", type: "string" },
  "Contact First Name": { key: "contact.firstname", type: "string" },
  "Contact Last Name": { key: "contact.lastname", type: "string" },
  "Contact Email Address": { key: "contact.email", type: "string" },
  "Contact Phone Number": { key: "contact.phoneNumber", type: "string" },
  "Location Physical Address": { key: "address", type: "physicalAddress" },
  "Location Physical Address Latitude": {
    key: "address.coordinates.latitude",
    type: "number",
  },
  "Location Physical Address Longitude": {
    key: "address.coordinates.longitude",
    type: "number",
  },
};

export default locationsMapping;
