import { Box, Stack, Typography } from "@mui/material";

const DownloadApps = () => {
  return (
    <Stack direction="column" justifyContent="space-around" alignItems="center">
      <Typography variant="h4">
        Download the TrueTMS app for your mobile device
      </Typography>
      <Box>
        <a href="https://play.google.com/store/apps/details?id=com.truetms&utm_source=truetms_web_app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
          <img
            alt="Get it on Google Play"
            src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
            width="250"
          />
        </a>
      </Box>
      <Box>
        <a
          href="https://apps.apple.com/us/app/truetms/id1667571427?itsct=apps_box_badge&amp;itscg=30200"
          style={{
            display: "inline-block",
            overflow: "hidden",
            borderRadius: "13px",
            width: "250px",
            height: "83px",
          }}
        >
          <img
            src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1674518400"
            alt="Download on the App Store"
            style={{ borderRadius: "13px", width: "250px", height: "83px" }}
          />
        </a>
      </Box>
    </Stack>
  );
};

export default DownloadApps;
