import { omit } from "lodash";
import { useSnackbar } from "notistack";
import {
  useAddDeductionRuleMutation,
  useAddPaymentRuleMutation,
  useDeleteDeductionRuleMutation,
  useDeletePaymentRuleMutation,
  useEditDeductionRuleMutation,
  useEditPaymentRuleMutation,
  useGetDriverDeductionRulesQuery,
  useGetDriverPaymentRulesQuery,
} from "../../../graphql/generated";
import ErrorMessage from "../../common/ErrorMessage/ErrorMessage";
import LoadingOverlay from "../../common/LoadingOverlay";
import DriverPaymentEditor from "./DriverPaymentEditor";

type DriverPaymentEditorContainerProps = {
  driverId: string;
};

const DriverPaymentEditorContainer = ({
  driverId,
}: DriverPaymentEditorContainerProps) => {
  const getDriverPaymentRulesQuery = useGetDriverPaymentRulesQuery(
    {
      driver: driverId,
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  const editPaymentRuleMutation = useEditPaymentRuleMutation();
  const addPaymentRuleMutation = useAddPaymentRuleMutation();
  const deletePaymentRuleMutation = useDeletePaymentRuleMutation();

  const getDriverDeductionRulesQuery = useGetDriverDeductionRulesQuery(
    {
      driver: driverId,
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  const editDeductionRuleMutation = useEditDeductionRuleMutation();
  const addDeductionRuleMutation = useAddDeductionRuleMutation();
  const deleteDeductionRuleMutation = useDeleteDeductionRuleMutation();
  const { enqueueSnackbar } = useSnackbar();
  if (
    getDriverPaymentRulesQuery.isLoading ||
    getDriverDeductionRulesQuery.isLoading
  ) {
    return <LoadingOverlay loading />;
  }
  if (getDriverPaymentRulesQuery.error || !getDriverPaymentRulesQuery.data) {
    return (
      <ErrorMessage
        error={
          getDriverPaymentRulesQuery.error ||
          "Unknown error while fetching payment rules"
        }
      />
    );
  }
  if (
    getDriverDeductionRulesQuery.error ||
    !getDriverDeductionRulesQuery.data
  ) {
    return (
      <ErrorMessage
        error={
          getDriverDeductionRulesQuery.error ||
          "Unknown error while fetching deduction rules"
        }
      />
    );
  }
  const initialPaymentRules = getDriverPaymentRulesQuery.data.paymentRules.data;
  const initialDeductionRules =
    getDriverDeductionRulesQuery.data.deductionRules.data;
  return (
    <>
      <ErrorMessage
        error={
          editPaymentRuleMutation.error ||
          addPaymentRuleMutation.error ||
          deletePaymentRuleMutation.error
        }
      />
      <DriverPaymentEditor
        initialPaymentRules={initialPaymentRules}
        initialDeductionRules={initialDeductionRules}
        saving={
          editPaymentRuleMutation.isLoading ||
          addPaymentRuleMutation.isLoading ||
          deletePaymentRuleMutation.isLoading
        }
        onSave={async ({ paymentRules, deductionRules }) => {
          const deletedPaymentRules = initialPaymentRules.filter(
            (initialPaymentRule) =>
              !paymentRules.find(
                (paymentRule) => paymentRule._id === initialPaymentRule._id
              )
          );
          const deletedDeductionRules = initialDeductionRules.filter(
            (initialDeductionRule) =>
              !deductionRules.find(
                (deductionRule) =>
                  deductionRule._id === initialDeductionRule._id
              )
          );

          await Promise.all([
            ...paymentRules.map(async (paymentRule) => {
              if (paymentRule._id.startsWith("NEW")) {
                await addPaymentRuleMutation.mutateAsync({
                  newPaymentRuleData: {
                    ...omit(paymentRule, "_id"),
                    driver: driverId,
                  },
                });
              } else {
                await editPaymentRuleMutation.mutateAsync({
                  id: paymentRule._id,
                  editPaymentRuleData: {
                    ...omit(paymentRule, "driver", "_id"),
                  },
                });
              }
            }),
            ...deletedPaymentRules.map(async (paymentRule) => {
              await deletePaymentRuleMutation.mutateAsync({
                id: paymentRule._id,
              });
            }),

            ...deductionRules.map(async (deductionRule) => {
              if (deductionRule._id.startsWith("NEW")) {
                await addDeductionRuleMutation.mutateAsync({
                  newDeductionRuleData: {
                    ...omit(deductionRule, "_id"),
                    driver: driverId,
                  },
                });
              } else {
                await editDeductionRuleMutation.mutateAsync({
                  id: deductionRule._id,
                  editDeductionRuleData: {
                    ...omit(deductionRule, "driver", "_id"),
                  },
                });
              }
            }),
            ...deletedDeductionRules.map(async (deductionRule) => {
              await deleteDeductionRuleMutation.mutateAsync({
                id: deductionRule._id,
              });
            }),
          ]);
          enqueueSnackbar("Saved");
          getDriverPaymentRulesQuery.refetch();
          getDriverDeductionRulesQuery.refetch();
        }}
      />
    </>
  );
};

export default DriverPaymentEditorContainer;
