import { Box, LinearProgress, Stack, Typography } from "@mui/material";
import { ReceiverForecast, StorageFacility } from "../../../graphql/generated";
import { differenceInHours } from "date-fns";
import { formatDateTime } from "../../../utils/labels/formatDateTime";

type StorageFacilityLevelBarProps = {
  storageFacility: Pick<
    StorageFacility,
    | "identifier"
    | "capacity"
    | "safeFillLevel"
    | "shutDownLevel"
    | "latestReading"
    | "currentForecastedReading"
    | "runoutDate"
    | "runoutDateAfterNextShipment"
  >;
  model: Pick<ReceiverForecast["models"][0], "threshold" | "target">;
};

const StorageFacilityLevelBar: React.FC<StorageFacilityLevelBarProps> = (
  props
) => {
  const { storageFacility, model } = props;

  const runoutIsInLessThan4Hours =
    differenceInHours(new Date(storageFacility.runoutDate), new Date()) < 4;

  const currentLevel = storageFacility.currentForecastedReading?.level || 0;
  const isBelowTarget = model.target && currentLevel < model.target;

  const levelPercentage = (currentLevel / storageFacility.capacity) * 100;
  const lastReadingLevelPercentage =
    ((storageFacility.latestReading?.level || 0) / storageFacility.capacity) *
    100;

  return (
    <Box>
      <Box
        sx={{
          height: 16,
          position: "relative",
          my: 1,
        }}
      >
        {model.threshold ? (
          <Box
            sx={{
              position: "absolute",
              left: `${(model.threshold / storageFacility.capacity) * 100}%`,
              height: "150%",
              backgroundColor: "orange",
              width: 4,
              zIndex: 200,
            }}
          />
        ) : null}
        {model.target ? (
          <Box
            sx={{
              position: "absolute",
              left: `${(model.target / storageFacility.capacity) * 100}%`,
              height: "150%",
              backgroundColor: "green",
              width: 4,
              zIndex: 200,
            }}
          />
        ) : null}

        {storageFacility.shutDownLevel ? (
          <LinearProgress
            variant="determinate"
            value={
              (storageFacility.shutDownLevel / storageFacility.capacity) * 100
            }
            color="error"
            sx={{
              backgroundColor: "rgba(0, 0, 0, 0)",
              position: "absolute",
              left: 0,
              width: "100%",
              zIndex: 100,
              height: "100%",
            }}
          />
        ) : null}

        {storageFacility.safeFillLevel ? (
          <LinearProgress
            variant="determinate"
            value={
              ((storageFacility.capacity - storageFacility.safeFillLevel) /
                storageFacility.capacity) *
              100
            }
            color="error"
            sx={{
              backgroundColor: "rgba(0, 0, 0, 0)",
              position: "absolute",
              left: 0,
              width: "100%",
              zIndex: 100,
              height: "100%",
              transform: "rotate(180deg)",
            }}
          />
        ) : null}

        <LinearProgress
          variant="buffer"
          value={levelPercentage}
          valueBuffer={lastReadingLevelPercentage}
          sx={{
            ".MuiLinearProgress-dashed": {
              animation: "none",
            },
            color: runoutIsInLessThan4Hours
              ? "red"
              : isBelowTarget
              ? "orange"
              : "green",
            height: 16,
          }}
          color={
            runoutIsInLessThan4Hours
              ? "error"
              : isBelowTarget
              ? "warning"
              : "success"
          }
        />
      </Box>
      <Stack>
        <Typography>
          {(storageFacility.currentForecastedReading?.level || 0).toFixed(0)} /{" "}
          {storageFacility.safeFillLevel}
        </Typography>
        {storageFacility.latestReading ? (
          <Typography variant="caption" color="text.secondary">
            Last reading:{" "}
            {(storageFacility.latestReading?.level || 0).toFixed(0)} at{" "}
            {formatDateTime(storageFacility.latestReading?.date)}
          </Typography>
        ) : (
          <Typography variant="caption" color="text.secondary">
            No reading yet
          </Typography>
        )}
      </Stack>
      {/* <Slider
        aria-label="Tank level"
        value={storageFacility.currentForecastedReading?.level || 0}
        step={10}
        max={storageFacility?.safeFillLevel || storageFacility.capacity}
        size="medium"
        color="primary"
        sx={{
          color: runoutIsInLessThan4Hours
            ? "red"
            : isBelowTarget
            ? "orange"
            : "green",
        }}
        marks={[
          {
            value: storageFacility?.shutDownLevel || 0,
            label: "SD",
          },
          {
            value: model.threshold || 0,
            label: "TH",
          },
          {
            value: model.target || 0,
            label: "TA",
          },
        ].filter((mark) => !!mark.value)}
      /> */}
    </Box>
  );
};

export default StorageFacilityLevelBar;
