import {
  MaintenanceTaskStatus,
  Scalars,
  TripAssetTypes,
  useGetMaintenanceTasksQuery,
  useGetTractorMileageQuery,
  useGetTrailerMileageQuery,
} from "../../../graphql/generated";
import MaintenanceWarnings, {
  MaintenanceWarningsProps,
} from "./MaintenanceWarnings";

type MaintenanceWarningsContainerProps = Omit<
  MaintenanceWarningsProps,
  "upcomingMaintenanceTasks" | "currentMaintenanceTasks"
> & {
  assetType: TripAssetTypes;
  assetId: Scalars["ObjectId"];
};

const MaintenanceWarningsContainer = ({
  assetType,
  assetId,
  ...props
}: MaintenanceWarningsContainerProps) => {
  const { data } = useGetMaintenanceTasksQuery({
    assetType,
    assetId,
  });

  const tractorMileageQuery = useGetTractorMileageQuery(
    {
      id: assetId,
    },
    {
      enabled: assetType === TripAssetTypes.Tractor,
    }
  );

  const trailerMileageQuery = useGetTrailerMileageQuery(
    {
      id: assetId,
    },
    {
      enabled: assetType === TripAssetTypes.Trailer,
    }
  );

  const mileage =
    assetType === TripAssetTypes.Tractor
      ? tractorMileageQuery.data?.tractorById?.mileage
      : trailerMileageQuery.data?.trailerById?.mileage;

  const upcomingMaintenanceTasks = data?.maintenanceTasks.data.filter((task) =>
    task.startDate
      ? new Date(task.startDate) > new Date()
      : task.startMileage && mileage
      ? task.startMileage > mileage
      : false
  );

  const currentMaintenanceTasks = data?.maintenanceTasks.data.filter((task) =>
    task.startDate
      ? new Date(task.startDate) <= new Date() &&
        task.status !== MaintenanceTaskStatus.Completed
      : task.startMileage && mileage
      ? task.startMileage <= mileage &&
        task.status !== MaintenanceTaskStatus.Completed
      : false
  );

  return (
    <MaintenanceWarnings
      upcomingMaintenanceTasks={upcomingMaintenanceTasks || []}
      currentMaintenanceTasks={currentMaintenanceTasks || []}
      mileage={mileage || undefined}
      {...props}
    />
  );
};

export default MaintenanceWarningsContainer;
