import { GoodUnits } from "../../graphql/generated";

const goodUnitLabelMapping: { [key in GoodUnits]: string | undefined } = {
  [GoodUnits.Pounds]: "lb",
  [GoodUnits.Kilograms]: "kg",
  [GoodUnits.Liters]: "L",
  [GoodUnits.Gallons]: "gal",
  [GoodUnits.Item]: "items",
};

function goodUnitLabel(unit: GoodUnits | undefined | null) {
  if (!unit) return "items";
  return goodUnitLabelMapping[unit];
}

export default goodUnitLabel;
