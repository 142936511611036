import { useState } from "react";
import ReceiverForecastsListContainer from "../../components/forecasting/ReceiverForecastsList";
import { Box, Tab, Tabs } from "@mui/material";
import ForecastingDashboardContainer from "../../components/forecasting/ForecastingDashboard";

export enum ForecastingTab {
  LIST,
  DASHBOARD,
}

export default function ReceiverForecasts() {
  const [tab, setTab] = useState<ForecastingTab>(ForecastingTab.LIST);

  return (
    <Box>
      <Tabs
        value={tab}
        onChange={(event, value) => {
          setTab(value);
        }}
        aria-label="basic tabs example"
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
        sx={{
          mb: 1,
        }}
      >
        <Tab label={"Models"} value={ForecastingTab.LIST} />
        <Tab label="Dashboard" value={ForecastingTab.DASHBOARD} />
      </Tabs>
      {tab === ForecastingTab.LIST && <ReceiverForecastsListContainer />}
      {tab === ForecastingTab.DASHBOARD && <ForecastingDashboardContainer />}
    </Box>
  );
}
