import {
  Box,
  Chip,
  List,
  Tab,
  Tabs,
  Typography,
  ChipProps,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import trueLogo from "../../../assets/icons/true-logo.svg";
import conestoga from "../../../assets/icons/conestoga.svg";
import { TabPanel } from "../LiveTripsTracker/LiveTripsTracker";
import { Status, TrackShipmentQuery } from "../../../graphql/generated";
import TripRoute from "../../trip-planning/TripPlanner/TripRoute";
import EmptyState from "../../common/EmptyState";
import {
  driverDisplay,
  eventDescriptions,
  EventReport,
  EventReportData,
  EventReportType,
  eventTitles,
} from "../EventReport/EventReport";
import { capitalize, flatten, isFunction, keyBy, sortBy } from "lodash";
import TripTimeline from "../../trip-planning/TripTimeline";
import ErrorMessage from "../../common/ErrorMessage/ErrorMessage";
import enumLabel from "../../../utils/labels/enumLabel";

function getStatusColor(status: Status | undefined) {
  let color: ChipProps["color"] = "default";
  switch (status) {
    case Status.InProgress:
      color = "lightPrimary";
      break;
    case Status.Complete:
      color = "primary";
      break;
    // todo add delayed status
    default:
      break;
  }
  return color;
}

export function getAllEventReports(
  shipment: Pick<
    TrackShipmentQuery["trackShipment"]["shipment"],
    "events" | "_id" | "shipmentNumber"
  > & {
    issues?: Array<{
      _id: string;
      type: string;
      content: string;
      date: any;
      shipmentLocation: string;
      delayAmount: number;
    }>;
  },
  trip:
    | Pick<
        NonNullable<TrackShipmentQuery["trackShipment"]["trip"]>,
        "shipmentLocations" | "_id" | "tripNumber" | "driver"
      >
    | undefined
    | null,
  includeIssues: boolean = false
) {
  return sortBy(
    [
      ...(shipment.events || []).map((event) => {
        const eventDescription = eventDescriptions[event.type];
        const eventReport: EventReportData = {
          _id: event._id,
          type: EventReportType.INFO,
          title: eventTitles[event.type],
          message: isFunction(eventDescription)
            ? eventDescription({
                ...event,
                shipment: shipment,
                trip: trip,
                shipmentLocation: trip?.shipmentLocations.find(
                  (sl) => sl._id === event.shipmentLocation
                ),
                allShippedGoodsById: keyBy(
                  flatten(
                    trip?.shipmentLocations.map(
                      (location) => location.shippedGoods || []
                    )
                  ),
                  "_id"
                ),
              })
            : eventDescription,
          date: new Date(event.date),
          shipment: {
            _id: shipment._id,
            shipmentNumber: shipment.shipmentNumber,
          },
          trip: {
            _id: trip?._id || "",
            tripNumber: trip?.tripNumber || "",
            driver: null,
          },
        };
        return eventReport;
      }),
      ...(includeIssues ? shipment.issues || [] : [])
        .map((event) => ({
          ...event,
          shipment,
          trip,
        }))
        .map((issue) => ({
          _id: issue._id,
          type: EventReportType.DANGER,
          title: capitalize(issue.type),
          message: (
            <>
              <Typography
                fontWeight="bold"
                variant="body2"
                display="inline"
                component="span"
              >
                {driverDisplay(issue.trip?.driver)}:
              </Typography>
              <Typography variant="body2" display="inline" component="span">
                {" "}
                {issue.content}
              </Typography>
            </>
          ),
          date: new Date(issue.date),
          shipment: issue.shipment,
          trip: issue.trip,
        })),
    ],
    "date"
  ).reverse();
}

export type ShipmentTrackerProps = {
  trackingInfos: TrackShipmentQuery["trackShipment"];
  mapOnly?: boolean;
};

const ShipmentTracker = ({ trackingInfos, mapOnly }: ShipmentTrackerProps) => {
  const [currentTab, setCurrentTab] = useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  const allEventReports: EventReportData[] = useMemo(() => {
    return getAllEventReports(trackingInfos.shipment, trackingInfos.trip);
  }, [trackingInfos.shipment, trackingInfos.trip]);

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Box
        sx={{
          width: "35%",
          minWidth: "200px",
          height: "100%",
          p: 6,
          display: mapOnly ? "none" : "block",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            pb: 2,
          }}
        >
          <img alt="lynk Logo" src={trueLogo} style={{ width: 150 }} />
        </Box>
        <Box sx={{ borderBottom: 1, borderColor: "divider", overflow: "auto" }}>
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            centered
            value={currentTab}
            onChange={handleTabChange}
          >
            <Tab label="Order" />
            <Tab label="Events" />
          </Tabs>
        </Box>

        <TabPanel value={currentTab} index={0}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              overflow: "auto",
              paddingBottom: "33px",
              paddingTop: "13px",
            }}
          >
            <Typography variant="h6">
              ORDER #: {trackingInfos.shipment.shipmentNumber}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                paddingTop: "22px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  mb: 2,
                }}
              >
                <Box sx={{ mr: "16px" }}>
                  <img
                    style={{ width: "27px" }}
                    alt="trailerType"
                    src={conestoga}
                  />
                </Box>
                <Typography color="black" fontWeight={600} fontSize="13px">
                  {trackingInfos.shipment.trailerType}
                </Typography>
              </Box>
              <Chip
                label={enumLabel(trackingInfos.trip?.status)}
                color={getStatusColor(trackingInfos.trip?.status)}
                component="span"
              />
            </Box>
            {trackingInfos.trip ? (
              <TripTimeline trip={trackingInfos.trip} />
            ) : (
              <ErrorMessage message="No trip found for this load" />
            )}
          </Box>
        </TabPanel>
        <TabPanel value={currentTab} index={1}>
          {(trackingInfos.shipment.events || [])?.length > 0 ? (
            <List sx={{ pt: 0 }}>
              {(allEventReports || []).map((eventReport) => (
                <EventReport
                  key={eventReport._id}
                  eventReport={eventReport}
                  onClick={() => {}}
                />
              ))}
            </List>
          ) : (
            <EmptyState />
          )}
        </TabPanel>
      </Box>
      <Box sx={{ width: mapOnly ? "100%" : "65%", height: "100%" }}>
        {trackingInfos.trip ? (
          <TripRoute trip={trackingInfos.trip} showAssetLocation={true} />
        ) : (
          <Box
            sx={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ErrorMessage message="No trip found for this load" />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ShipmentTracker;
