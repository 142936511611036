import {
  Scalars,
  TripAssetTypes,
  useGetQualificationsQuery,
} from "../../../graphql/generated";
import QualificationWarnings, {
  QualificationWarningsProps,
} from "./QualificationWarnings";

type QualificationWarningsContainerProps = Omit<
  QualificationWarningsProps,
  "qualifications"
> & {
  assetType: TripAssetTypes;
  assetId: Scalars["ObjectId"];
};

const QualificationWarningsContainer = ({
  assetType,
  assetId,
  ...props
}: QualificationWarningsContainerProps) => {
  const { data } = useGetQualificationsQuery({
    assetType,
    assetId,
  });

  const qualifications = data?.qualifications.data || [];

  return (
    <QualificationWarnings qualifications={qualifications || []} {...props} />
  );
};

export default QualificationWarningsContainer;
