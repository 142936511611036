import { ExcelMapping } from "./mappingTypes";

const domicileMapping: ExcelMapping = {
  Domicile: { key: "domicile", type: "physicalAddress" },
  "Domicile Latitude": {
    key: "domicile.coordinates.latitude",
    type: "number",
  },
  "Domicile Longitude": {
    key: "domicile.coordinates.longitude",
    type: "number",
  },
};

export default domicileMapping;
