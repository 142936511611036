import { Box, CircularProgress } from "@mui/material";
import Lottie from "lottie-react";
import loadingDeliveryTruckAnimation from "../../../assets/animations/loading-delivery-truck.json";
import mapRoutingAnimation from "../../../assets/animations/map-routing.json";

interface LoadingOverlayProps {
  loading: boolean;
  animationType?: "progress-circle" | "truck" | "map";
}

function LoadingOverlay({
  loading,
  animationType = "progress-circle",
}: LoadingOverlayProps) {
  if (!loading) {
    return null;
  }
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 20,
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          backgroundColor: "white",
          opacity: 0.7,
        }}
      ></Box>
      {animationType === "progress-circle" ? (
        <CircularProgress />
      ) : (
        <Lottie
          animationData={
            animationType === "truck"
              ? loadingDeliveryTruckAnimation
              : mapRoutingAnimation
          }
          style={{
            width: 500,
            height: "100%",
          }}
        />
      )}
    </Box>
  );
}

export default LoadingOverlay;
