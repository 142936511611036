import { ReactNode } from "react";
import { Dangerous, InfoOutlined, WarningAmber } from "@mui/icons-material";
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import {
  Driver,
  Good,
  Shipment,
  ShipmentEvent,
  ShipmentEventType,
  ShipmentLocation,
  Trip,
} from "../../../graphql/generated";
import driverLabel from "../../../utils/labels/driverLabel";
import { formatDateTime } from "../../../utils/labels/formatDateTime";
import ShipmentLink from "../../common/ShipmentLink";
import { shipmentLocationAddressLabel } from "../../../utils/labels/shipmentLocationLabel";
import i18next from "i18next";
import { mapValues } from "lodash";

export enum EventReportType {
  INFO = "info",
  WARNING = "warning",
  DANGER = "danger",
}

export type EventReportData = {
  _id: string;
  type: EventReportType;
  title: string;
  message: ReactNode;
  date: Date;
  shipment: Pick<Shipment, "_id" | "shipmentNumber">;
  trip:
    | (Pick<Trip, "tripNumber" | "_id"> & {
        driver?:
          | Pick<Driver, "firstname" | "lastname" | "_id">
          | undefined
          | null;
      })
    | undefined
    | null;
};

type ShipmentLocationAddressData = {
  shipper?: {
    address: {
      label: string;
    };
  } | null;
  receiver?: {
    address: {
      label: string;
    };
  } | null;
};

export type EventWithShipmentAndTrip = Omit<
  NonNullable<
    Pick<
      ShipmentEvent,
      "_id" | "type" | "date" | "shipmentLocation" | "label" | "description"
    >
  >,
  "shipmentLocation"
> & {
  shipment: Pick<Shipment, "_id" | "shipmentNumber">;
  shipmentLocation?: Pick<
    ShipmentLocation,
    "shippedGoods" | "receivedGoods" | "_id"
  > &
    ShipmentLocationAddressData;
  trip:
    | (Pick<Trip, "tripNumber" | "_id"> & {
        driver?:
          | Pick<Driver, "firstname" | "lastname" | "_id">
          | undefined
          | null;
      })
    | undefined
    | null;
  allShippedGoodsById: { [key: string]: Good };
};

export let eventDescriptions: {
  [key in ShipmentEventType]:
    | string
    | ((event: EventWithShipmentAndTrip) => string);
} = {
  [ShipmentEventType.TripStarted]: (event) =>
    `${driverDisplay(event.trip?.driver)} has started trip ${
      event.trip?.tripNumber
    }`,
  [ShipmentEventType.TripEnd]: (event) =>
    `${driverDisplay(event.trip?.driver)} has finished trip ${
      event.trip?.tripNumber
    }`,
  [ShipmentEventType.PickupCheckpointArrive]: (event) =>
    `${driverDisplay(
      event.trip?.driver
    )} has arrived at ${shipmentLocationAddressLabel(event.shipmentLocation)}`,
  [ShipmentEventType.PickupCheckpointLeft]: (event) =>
    `${driverDisplay(
      event.trip?.driver
    )} has left ${shipmentLocationAddressLabel(event.shipmentLocation)}`,
  [ShipmentEventType.PickupCheckpointLoadStart]: (event) =>
    `${driverDisplay(
      event.trip?.driver
    )} is picking up ${event.shipmentLocation?.shippedGoods
      ?.map((shippedGood) => shippedGood.label)
      .join(", ")} at ${shipmentLocationAddressLabel(event.shipmentLocation)}`,
  [ShipmentEventType.DropOffCheckpointArrive]: (event) =>
    `${driverDisplay(
      event.trip?.driver
    )} has arrived at ${shipmentLocationAddressLabel(event.shipmentLocation)}`,
  [ShipmentEventType.DropOffCheckpointLeft]: (event) =>
    `${driverDisplay(
      event.trip?.driver
    )} has left ${shipmentLocationAddressLabel(event.shipmentLocation)}`,
  [ShipmentEventType.DropoffCheckpointUnloadStart]: (event) =>
    `${driverDisplay(
      event.trip?.driver
    )} is dropping ${event.shipmentLocation?.receivedGoods
      ?.map(
        (receivedGood) => event.allShippedGoodsById[receivedGood.goodId]?.label
      )
      .join(", ")} at ${shipmentLocationAddressLabel(event.shipmentLocation)}`,
  [ShipmentEventType.ShipmentComplete]: (event) =>
    `${driverDisplay(event.trip?.driver)} has finished delivering load ${
      event.shipment.shipmentNumber
    }`,
  [ShipmentEventType.TripBreakStarted]: (event) =>
    `${driverDisplay(event.trip?.driver)} went on a break`,
  [ShipmentEventType.TripResumed]: (event) =>
    `${driverDisplay(event.trip?.driver)} resumed the trip`,
  [ShipmentEventType.CheckCall]: (event) =>
    `${event.label || "Check Call"}: ${event.description || ""}`,
  [ShipmentEventType.TripAssetAssignmentUpdated]: (event) =>
    `Temporary description for typescript`,
};

type EventReportProps = {
  eventReport: EventReportData;
  onClick?: () => void;
  showShipmentLink?: boolean;
};

export const EventReport = ({
  eventReport,
  onClick,
  showShipmentLink = true,
}: EventReportProps) => {
  const theme = useTheme();

  const eventReportTypeColors: { [key in EventReportType]: string } = {
    [EventReportType.INFO]: theme.palette.info.main,
    [EventReportType.WARNING]: theme.palette.warning.light,
    [EventReportType.DANGER]: theme.palette.error.light,
  };

  const eventReportTypeIcons: { [key in EventReportType]: ReactNode } = {
    [EventReportType.INFO]: <InfoOutlined />,
    [EventReportType.WARNING]: <WarningAmber />,
    [EventReportType.DANGER]: <Dangerous />,
  };

  return (
    <ListItem
      secondaryAction={
        <Typography variant="body2">
          {formatDateTime(eventReport.date)}
        </Typography>
      }
      sx={(theme) => ({
        // Put the secondary action on the same line as the title
        "& .MuiListItemSecondaryAction-root": {
          top: theme.spacing(1),
          transform: "translateY(0)",
          color: theme.palette.primary.main,
        },
        cursor: onClick ? "pointer" : undefined,
      })}
      divider
      onClick={onClick ? () => onClick() : undefined}
    >
      <ListItemAvatar>
        <Avatar
          sx={{
            backgroundColor: eventReportTypeColors[eventReport.type],
          }}
        >
          {eventReportTypeIcons[eventReport.type]}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        sx={{
          pt: 1,
          pb: 1,
          color:
            eventReport.type === EventReportType.DANGER
              ? eventReportTypeColors[eventReport.type]
              : undefined,
        }}
        primary={eventReport.title}
        primaryTypographyProps={{
          fontWeight:
            eventReport.type === EventReportType.DANGER ? 600 : undefined,
        }}
        secondary={
          <>
            {eventReport.message}
            <br />
            {showShipmentLink && (
              <ShipmentLink shipment={eventReport.shipment} />
            )}
          </>
        }
      />
    </ListItem>
  );
};

export let eventTitles: { [key in ShipmentEventType]: string } = {
  [ShipmentEventType.TripStarted]: "Trip started",
  [ShipmentEventType.TripEnd]: "Trip ended",
  [ShipmentEventType.PickupCheckpointArrive]: "Arrived at pickup location",
  [ShipmentEventType.PickupCheckpointLeft]: "Left pickup location",
  [ShipmentEventType.PickupCheckpointLoadStart]:
    "Started loading at pickup location",
  [ShipmentEventType.DropOffCheckpointArrive]: "Arrived at delivery location",
  [ShipmentEventType.DropOffCheckpointLeft]: "Left delivery location",
  [ShipmentEventType.DropoffCheckpointUnloadStart]:
    "Started unloading at delivery location",
  [ShipmentEventType.ShipmentComplete]: "Order completed",
  [ShipmentEventType.TripBreakStarted]: "Break started",
  [ShipmentEventType.TripResumed]: "Trip resumed",
  [ShipmentEventType.CheckCall]: "Check call",
  [ShipmentEventType.TripAssetAssignmentUpdated]: "Asset assignment updated",
};

export const driverDisplay = (
  driver:
    | {
        firstname: string;
        lastname: string;
        _id: string;
      }
    | null
    | undefined
) => (driver ? driverLabel(driver) : "The driver");

export default EventReport;

i18next.on("initialized", () => {
  eventTitles = mapValues(
    eventTitles,
    (value, type) =>
      // @ts-expect-error
      i18next.t(`trips:events.${type}`) as string
  );

  eventDescriptions = mapValues(
    eventDescriptions,
    (value, type) => (event: EventWithShipmentAndTrip) =>
      // @ts-expect-error
      i18next.t(`trips:eventDescription.${type}`, {
        driver: driverDisplay(event.trip?.driver),
        shipmentLocation: shipmentLocationAddressLabel(event.shipmentLocation),
        shippedGoods: event.shipmentLocation?.shippedGoods
          ?.map((shippedGood) => shippedGood.label)
          .join(", "),
        receivedGoods: event.shipmentLocation?.receivedGoods
          ?.map(
            (receivedGood) =>
              event.allShippedGoodsById[receivedGood.goodId]?.label
          )
          .join(", "),
        tripNumber: event.trip?.tripNumber,
        shipmentNumber: event.shipment.shipmentNumber,
        label: event.label,
        description: event.description,
      }) as string
  );
});
