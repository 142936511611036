import { BusinessEntityType } from "../../graphql/generated";
import { TFunction } from "i18next"; // Importing the TFunction type from i18next


type arg = BusinessEntityType | undefined;

type IncludeOption = {
  field: string;
  label: string;
} | null;

export default function getIncludeOption(type: arg , t: TFunction<["business", "common", "assets", "orders"], undefined>): IncludeOption  {
  switch (type) {
    case BusinessEntityType.Customer:
      return {
        field: 'includeInInvoicing',
        label: t('orders:documents.includeForInvoice', 'Include for Invoice'),
      };
    case BusinessEntityType.Carrier:
      return {
        field: 'includeInInvoicing',
        label: t('orders:documents.includeForRatecon', 'Include for Ratecon'),
      };
    case BusinessEntityType.Receiver:
      return {
        field: 'includeInInvoicing',
        label: t(
          'orders:documents.includeForOrderCompletion',
          'Include for Order Completion'
        ),
      };
    default:
      return null; // No checkbox for other types
  }
}