import { Link } from "@mui/material";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { BillingRule, Transaction } from "../../../../graphql/generated";
import LocaleProvider from "../../../../providers/LocaleProvider";
import {
  hideDialog,
  showDialog,
} from "../../../../redux/slices/alert/Alert.slice";
import { ShipmentChargeInputData } from "../../../../redux/slices/Types";
import LynksTable, {
  LynksTableProps,
  TableField,
} from "../../../common/LynksTable/LynksTable";
import { useTranslation } from "react-i18next";

type TransactionData = Pick<
  Transaction,
  | "label"
  | "rate"
  | "unit"
  | "unitType"
  | "_id"
  | "reimburseToDriver"
  | "document"
  | "taxable"
> & {
  billingRule?: Pick<BillingRule, "_id" | "label" | "referenceNumber"> | null;
};

export type ShipmentTransactionsListProps = {
  transactions: TransactionData[];
  onDelete: (id: string) => void;
  onEdit: (id: string) => void;
  onChange: (transactions: ShipmentChargeInputData[]) => void;
  readonly?: boolean;
  actions?: LynksTableProps<TransactionData>["actions"];
  isReimbursable?: boolean;
  id?: string;
};

export default function ShipmentTransactionsList({
  transactions,
  actions,
  onDelete,
  onEdit,
  onChange,
  readonly,
  isReimbursable = true,
  id,
}: ShipmentTransactionsListProps) {
  const { t } = useTranslation(["finance", "common"]);
  const dispatch = useDispatch();

  const handleDelete = (id: string) => {
    dispatch(
      showDialog({
        type: "primary",
        title: t("common:delete"),
        description: t("deleteTransactionDescription"),
        actions: [
          {
            title: t("common:delete", "Delete"),
            type: "info",
            onClick: () => {
              onDelete(id);
              dispatch(hideDialog());
            },
          },
        ],
      })
    );
  };

  const shipmentTransactionTableFields: Array<TableField<TransactionData> | null> =
    useMemo(
      () => [
        {
          label: t("label"),
          type: "string",
          value: "label",
        },
        {
          label: t("quantity"),
          type: "string",
          value: (transaction) =>
            `${transaction.unit} (${transaction.unitType})`,
        },
        {
          label: t("unitPrice"),
          type: "string",
          value: (transaction) =>
            `${LocaleProvider.getCurrencySymbol()} ${transaction.rate}`,
        },
        {
          label: t("total"),
          type: "string",
          value: (transaction) =>
            `${LocaleProvider.getCurrencySymbol()} ${parseFloat(
              String(transaction.unit * transaction.rate)
            ).toFixed(2)}`,
        },
        {
          label: t("origin"),
          type: "string",
          value: (transaction) =>
            transaction.billingRule
              ? t("originBillingRule", {
                  referenceNumber:
                    transaction.billingRule.referenceNumber || "",
                })
              : t("originManual"),
        },
        {
          label: t("addToSettlement"),
          type: "boolean",
          value: "reimburseToDriver",
        },
        {
          label: t("document"),
          type: "custom",
          value: (transaction) => {
            return transaction.document ? (
              <Link
                href={transaction.document.url}
                component="a"
                target="_blank"
              >
                {transaction.document.name}
              </Link>
            ) : null;
          },
        },
        isReimbursable
          ? null
          : {
              label: t("taxable"),
              type: "boolean",
              value: "taxable",
            },
      ],
      [t, isReimbursable]
    );

  const shipmentTransactionTableFieldsWithoutNull: Array<
    TableField<TransactionData>
  > = useMemo(
    () =>
      shipmentTransactionTableFields.filter((field) => field !== null) as Array<
        TableField<TransactionData>
      >,
    [shipmentTransactionTableFields]
  );

  const computedFields = useMemo(() => {
    if (isReimbursable) {
      return shipmentTransactionTableFieldsWithoutNull;
    }
    return shipmentTransactionTableFieldsWithoutNull.filter(
      (field) => field.value !== "reimburseToDriver"
    );
  }, [isReimbursable, shipmentTransactionTableFieldsWithoutNull]);

  return (
    <LynksTable
      fields={computedFields}
      data={transactions}
      actions={actions}
      id={id}
      showEmptyStateImage={false}
      onDelete={!readonly ? handleDelete : undefined}
      onRowChange={
        !readonly
          ? (id, updatedTransaction) => {
              onChange(
                transactions
                  .map((transaction) =>
                    transaction._id === id ? updatedTransaction : transaction
                  )
                  .map((transactions) => ({
                    ...transactions,
                    billingRule: transactions.billingRule?._id,
                  }))
              );
            }
          : undefined
      }
      onEdit={!readonly ? onEdit : undefined}
    />
  );
}
