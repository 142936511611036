import { BusinessEntityType } from "../../graphql/generated";

const businessEntityLabelKey: {
  [key in BusinessEntityType]: string;
} = {
  BROKER: "broker",
  CARRIER: "carrier",
  SHIPPER: "shipper",
  CUSTOMER: "customer",
  DOMICILE: "domicile",
  GENERAL_LOCATION: "generalLocation",
  PAY_TO_PROFILE: "payToProfile",
  PORT: "port",
  RECEIVER: "receiver",
  SUPPLIER: "supplier",
  TERMINAL: "terminal",
  TRUCK_STOP: "truckStop",
};

export default businessEntityLabelKey;
