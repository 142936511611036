import { useDispatch } from "react-redux";
import { GetShipmentQuery } from "../../../../graphql/generated";
import {
  hideDialog,
  showDialog,
} from "../../../../redux/slices/alert/Alert.slice";
import DocumentsList, { DocumentsListProps } from "./DocumentsList";
import { useTranslation } from "react-i18next";

export default function ShipmentDocumentsList({
  documents,
  onDelete,
  onChange,
  readonly,
}: {
  documents: GetShipmentQuery["shipmentById"]["documents"];
  onDelete: (id: string) => void;
  onChange: DocumentsListProps["onChange"];
  readonly?: boolean;
}) {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();

  const toShipmentDocument = (
    data: GetShipmentQuery["shipmentById"]["documents"]
  ) => {
    return data?.map((d) => ({
      ...d,
      receiver: {
        _id: d.receiver?._id || "",
        name: d.receiver?.name || "",
      },
      shipper: {
        _id: d.shipper?._id || "",
        name: d.shipper?.name || "",
      },
    }));
  };

  const handleDelete = (id: string) => {
    dispatch(
      showDialog({
        type: "primary",
        title: t("delete", "Delete"),
        description: t(
          "documents.deleteThisConfirmation",
          "Delete this document ?"
        ),
        actions: [
          {
            title: t("delete", "Delete"),
            type: "info",
            onClick: () => {
              onDelete(id);
              dispatch(hideDialog());
            },
          },
        ],
      })
    );
  };
  return (
    <DocumentsList
      documents={toShipmentDocument(documents) || []}
      readonly={readonly}
      onDelete={handleDelete}
      onChange={onChange}
    />
  );
}
