import {
  Box,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Fab,
  Stack,
  SxProps,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  CustomFieldContext,
  GetAssignmentAssetsQuery,
  ShipmentLocationType,
  Status,
  useGetCustomFieldDefinitionsQuery,
} from "../../../graphql/generated";
import {
  AugmentedDriver,
  AugmentedShipment,
  PlanningToolProps,
} from "./PlanningTool";
import LynksTable from "../../common/LynksTable";
import { EnumTableField, TableField } from "../../common/LynksTable/LynksTable";
import {
  renderExtraLocations,
  shipmentStatusColors,
} from "../../shipment/ShipmentsList/ShipmentsList";
import {
  formatDateTimeForLocation,
  formatDurationClock,
  formatTimeForLocation,
} from "../../../utils/labels/formatDateTime";
import { first, keyBy, last, sum } from "lodash";
import { shipmentLocationLabel } from "../../../utils/labels/shipmentLocationLabel";
import {
  DragEvent,
  ReactNode,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import driverLabel from "../../../utils/labels/driverLabel";
import tractorLabel from "../../../utils/labels/tractorLabel";
import trailerLabel from "../../../utils/labels/trailerLabel";
import { formatMileage } from "../../../utils/labels/formatDistance";
import { differenceInSeconds } from "date-fns";
import goodUnitLabel from "../../../utils/labels/goodUnitsLabel";
import { Feature, useFeature } from "../../account/Access/FeatureGuard";
import { CallSplit, Merge } from "@mui/icons-material";
import { AugmentedShipmentDetails } from "./PlanningCalendar";
import TripViolations from "../../trip/TripDetails/TripViolations";
import { useTranslation } from "react-i18next";
import statusLabel from "../../../utils/labels/statusLabel";
import LoadSplittingModalContainer from "../../shipment/LoadSplittingModal";

import { useGetGroupsQuery } from "../../../graphql/generated";
export type PlanningGridProps = {
  drivers: AugmentedDriver[];
  tractors: GetAssignmentAssetsQuery["tractors"]["data"];
  trailers: GetAssignmentAssetsQuery["trailers"]["data"];
  filteredShipments: AugmentedShipment[];
  onShipmentsSelect: (shipments: AugmentedShipment[]) => void;
  onShipmentsChange: (shipments: AugmentedShipment[]) => void;
  onShipmentsCombine: (shipments: AugmentedShipment[]) => void;
  onFilteredShipmentsChange: (shipments: AugmentedShipment[]) => void;
  onRefresh: PlanningToolProps["onRefresh"];
};

type ShipmentItem = PlanningGridProps["filteredShipments"][0];

const PlanningGrid: React.FC<PlanningGridProps> = memo(
  ({
    drivers,
    tractors,
    trailers,
    filteredShipments,
    onShipmentsSelect,
    onShipmentsCombine,
    onRefresh,
    onFilteredShipmentsChange,
  }) => {
    const { t } = useTranslation(["orders", "trips", "common"]);
    const [selectedShipmentIds, setSelectedShipmentIds] = useState<string[]>(
      []
    );
    const groupsQuery = useGetGroupsQuery();
    const groups = groupsQuery.data?.groups.data;

    const groupMap = useMemo(() => keyBy(groups, (g) => g._id), [groups]);

    const [openedShipmentId, setOpenedShipmentId] =
      useState<string | null>(null);

    const [loadSplittingModalOpen, setLoadSplittingModalOpen] = useState(false);
    const [selectedShipmentForSplitting, setSelectedShipmentForSplitting] =
      useState<AugmentedShipment | null>(null);

    const commodityManagementFeature = useFeature(Feature.CommodityManagement);

    const statusField: EnumTableField<ShipmentItem, Status> = useMemo(
      () => ({
        value: (shipment) => shipment.status,
        label: t("status.label", "Status"),
        type: "enum",
        colors: shipmentStatusColors,
        values: Object.values(Status),
        valueLabel: statusLabel,
      }),
      [t]
    );

    const shipmentFields: TableField<ShipmentItem, Status>[] = useMemo(
      () => [
        {
          value: (shipment) =>
            renderForAugmentedShipment(shipment, (shipment) => (
              <Typography>{shipment.shipmentNumber}</Typography>
            )),
          label: t("orderNumber"),
          type: "number",
          sortBy: "shipmentNumber",
        },
        {
          value: (shipment) =>
            renderForAugmentedShipment(shipment, (shipment) => (
              <Typography>{shipment.trip?.tripNumber}</Typography>
            )),
          label: t("tripNumber"),
          type: "number",
          sortBy: "trip.tripNumber",
        },
        {
          value: (shipment) =>
            sum(
              [shipment, ...shipment.otherShipmentsInTrip]
                .flatMap((shipment) => shipment.shipmentLocations)
                .flatMap((loc) =>
                  loc.shippedGoods?.map((good) => good.quantity)
                )
            ),
          label: t("totalVolume"),
          type: "number",
          sortBy: "shipmentLocations.0.shippedGoods.0.quantity",
        },
        {
          value: (shipment) =>
            renderForAugmentedShipment(shipment, (shipment) =>
              shipment.shipmentLocations.flatMap((location) =>
                location.shippedGoods?.map((good) => (
                  <Typography>
                    {good.label} - {good.quantity}
                    {goodUnitLabel(good.unit)}
                  </Typography>
                ))
              )
            ),
          label: t("commodities"),
          type: "string",
          sortBy: "shipmentLocations.0.shippedGoods.0.label",
        },
        statusField,
        {
          value: (shipment) => (
            <Typography variant="body1">
              {formatDateTimeForLocation(
                shipment.trip?.firstPickupTime ||
                  shipment.route?.firstPickupTime,
                shipment.route?.locations?.[0]
              )}
            </Typography>
          ),
          subtitle: (shipment) => {
            return `${formatTimeForLocation(
              shipment.earliestPickup,
              shipment.route?.locations?.[0],
              false
            )} - ${formatTimeForLocation(
              shipment.latestPickup,
              shipment.route?.locations?.[0],
              false
            )}`;
          },
          label: t("pickupDate"),
          type: "datetime",
          sortBy: "route.firstPickupTime",
        },
        {
          value: (shipment) => (
            <Typography variant="body1">
              {formatDateTimeForLocation(
                shipment.trip?.lastDropoffTime ||
                  shipment.route?.lastDropoffTime,
                last(shipment.route?.locations)
              )}
            </Typography>
          ),
          subtitle: (shipment) => {
            return `${formatTimeForLocation(
              shipment.earliestDropoff,
              last(shipment.route?.locations),
              false
            )} - ${formatTimeForLocation(
              shipment.latestDropoff,
              last(shipment.route?.locations),
              false
            )}`;
          },
          label: t("deliveryDate"),
          type: "datetime",
          sortBy: "route.lastDropoffTime",
        },
        {
          value: (shipment) =>
            renderForAugmentedShipment(shipment, (shipment) => (
              <Typography>
                {shipmentLocationLabel(first(shipment.route?.locations))}
              </Typography>
            )),
          extra: (shipment) => {
            return renderExtraLocations(shipment, ShipmentLocationType.Pickup);
          },
          label: t("shipper"),
          type: "string",
          sortBy: "route.locations.0.shipper.name",
        },
        {
          value: (shipment) =>
            renderForAugmentedShipment(shipment, (shipment) => (
              <Typography>
                {[shipment.originCity, shipment.originState]
                  .filter(Boolean)
                  .join(", ")}
              </Typography>
            )),
          label: t("shipperAddress"),
          type: "string",
          sortBy: "originCity",
        },
        {
          value: (shipment) =>
            renderForAugmentedShipment(shipment, (shipment) => (
              <Typography>
                {shipmentLocationLabel(last(shipment.route?.locations))}
              </Typography>
            )),
          extra: (shipment) => {
            return renderExtraLocations(shipment, ShipmentLocationType.DropOff);
          },
          label: t("consigneeReceiver"),
          type: "string",
          sortBy: "route.locations.1.receiver.name",
        },
        {
          value: (shipment) =>
            renderForAugmentedShipment(shipment, (shipment) => (
              <Typography>
                {[shipment.destinationCity, shipment.destinationState]
                  .filter(Boolean)
                  .join(", ")}
              </Typography>
            )),
          label: t("receiverAddress"),
          type: "string",
          sortBy: "destinationCity",
        },
        {
          value: (shipment) =>
            formatMileage(
              shipment.trip?.routeDistance || shipment.route?.routeDistance || 0
            ),
          label: t("distance"),
          type: "number",
          sortBy: "trip.routeDistance",
        },
        {
          value: (shipment) => (
            <Typography>
              {formatDurationClock(
                differenceInSeconds(
                  new Date(
                    shipment.trip?.lastDropoffTime ||
                      shipment.route?.lastDropoffTime
                  ),
                  new Date(
                    shipment.trip?.firstPickupTime ||
                      shipment.route?.firstPickupTime
                  )
                )
              )}
            </Typography>
          ),
          label: t("duration"),
          type: "number",
          sortBy: "trip.duration",
        },
        {
          value: (shipment) =>
            `$ ${sum(
              [shipment, ...shipment.otherShipmentsInTrip]
                .flatMap((s) => s.charges)
                .map((charge) => charge.total)
            ).toFixed(2)}`,
          label: t("revenue"),
          type: "number",
          sortBy: "charges[0].total",
        },
        {
          value: (shipment) => {
            const driver = drivers.find((d) => d._id === shipment.trip?.driver);
            return driver ? driverLabel(driver) : null;
          },
          label: t("trips:driver"),
          type: "string",
          sortBy: "trip.driver.firstname",
        },
        {
          value: (shipment) => {
            const tractor = tractors.find(
              (d) => d._id === shipment.trip?.tractor
            );
            return tractor ? tractorLabel(tractor) : null;
          },
          label: t("trips:tractor"),
          type: "string",
          sortBy: "trip.tractor.serialNumber",
        },
        {
          value: (shipment) => {
            const trailer = trailers.find(
              (d) => d._id === shipment.trip?.trailer
            );
            return trailer ? trailerLabel(trailer) : null;
          },
          label: t("trips:trailer"),
          type: "string",
        },
        {
          value: (shipment) => {
            return shipment.trip ? (
              <TripViolations trip={shipment.trip} />
            ) : null;
          },
          label: t("trips:violations"),
          type: "custom",
        },
        {
          value: (shipment) => (
            <Stack direction="row" spacing={1}>
              {shipment.tags?.map((tag) => (
                <Chip label={tag} />
              ))}
            </Stack>
          ),
          sortBy: "tags",
          label: t("common:tags"),
          type: "string",
        },
        {
          value: (customer) =>
            customer?.groupIds?.map((id) => groupMap[id]?.name) || [],
          label: t("common:groups"),
          type: "select",
          values: groups?.map((g) => g.name),
        },
      ],
      [drivers, tractors, trailers, t, statusField, groups, groupMap]
    );

    const filteredShipmentFields = useMemo(
      () =>
        shipmentFields.filter((field) => {
          if (commodityManagementFeature.isAvailable) {
            return true;
          }
          return field.label !== "Total Volume" && field.label !== "Commodity";
        }),
      [commodityManagementFeature.isAvailable, shipmentFields]
    );

    useEffect(() => {
      setSelectedShipmentIds([]);
    }, [filteredShipments]);

    const selectedShipments = useMemo(
      () =>
        filteredShipments.filter((s) => selectedShipmentIds.includes(s._id)),
      [filteredShipments, selectedShipmentIds]
    );

    const openedShipment = useMemo(
      () => filteredShipments.find((s) => s._id === openedShipmentId),
      [openedShipmentId, filteredShipments]
    );

    const combineSelectedShipments = () => {
      const shipmentsToCombine = selectedShipments;
      onShipmentsCombine(shipmentsToCombine);
    };

    const onSelect = useCallback(
      (records: AugmentedShipment[]) => {
        setSelectedShipmentIds(records.map((r) => r._id));
        onShipmentsSelect(records);
      },
      [onShipmentsSelect]
    );

    const onDragStart = useCallback(
      (_: DragEvent<HTMLDivElement>, shipment: AugmentedShipment) => {
        setTimeout(() => {
          // setSelectedShipmentIds([shipment._id]);
          onShipmentsSelect([shipment]);
        });
      },
      [onShipmentsSelect]
    );

    const onDragEnd = useCallback(
      (_: DragEvent<HTMLDivElement>, shipment: AugmentedShipment) => {
        setTimeout(() => {
          // setSelectedShipmentIds([]);
          onShipmentsSelect([]);
        });
      },
      [onShipmentsSelect]
    );

    const onRecordClick = useCallback((shipment: AugmentedShipment) => {
      setOpenedShipmentId(shipment._id);
    }, []);

    const onFilteredRecordsChange = useCallback(
      (shipments: AugmentedShipment[]) => {
        onFilteredShipmentsChange(shipments);
      },
      [onFilteredShipmentsChange]
    );

    const rowStyle = useCallback(
      (shipment: AugmentedShipment): SxProps<Theme> => {
        const hasViolations = !!shipment.trip?.violations?.length;
        return (theme) => ({
          backgroundColor: hasViolations
            ? theme.palette.warning.light
            : undefined,
        });
      },
      []
    );

    const customFieldDefinitionsQuery = useGetCustomFieldDefinitionsQuery();

    const customFieldDefinitions =
      customFieldDefinitionsQuery.data?.cutomFieldDefinitions.data.filter(
        (customFieldDefinition) =>
          customFieldDefinition.context.includes(CustomFieldContext.Shipment)
      );

    if (customFieldDefinitionsQuery.isLoading || groupsQuery.isLoading) {
      return null;
    }

    return (
      <Box
        sx={{
          position: "relative",
          height: "calc(100% - 10px)",
        }}
      >
        <Box
          sx={{
            height: "100%",
            overflow: "auto",
          }}
        >
          <LynksTable
            data={filteredShipments}
            fields={filteredShipmentFields}
            compact
            selected={selectedShipments}
            onSelect={onSelect}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            draggable
            onRecordClick={onRecordClick}
            onFilteredRecordsChange={onFilteredRecordsChange}
            stickyHeader
            rowStyle={rowStyle}
            id="planning-grid-2"
            customFieldDefinitions={customFieldDefinitions}
            actions={[
              {
                icon: <CallSplit />,
                label: "Split",
                tooltip: "Split Order",
                onClick: (row) => {
                  console.log("split", row);
                  setSelectedShipmentForSplitting(row);
                  setLoadSplittingModalOpen(true);
                },
              },
            ]}
          />
        </Box>
        {selectedShipmentIds.length >= 2 ? (
          <Tooltip title={t("combineOrdersTooltip")}>
            <Fab
              sx={{
                position: "absolute",
                bottom: 32,
                right: 16,
              }}
              variant="extended"
              color="secondary"
              onClick={combineSelectedShipments}
            >
              <Merge />
            </Fab>
          </Tooltip>
        ) : null}
        <Dialog
          open={!!openedShipmentId}
          onClose={() => setOpenedShipmentId(null)}
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle>{t("orderDetails")}</DialogTitle>
          <DialogContent>
            {openedShipment && (
              <AugmentedShipmentDetails
                shipment={openedShipment}
                onRefresh={(shipmentIds) => {
                  setOpenedShipmentId(null);
                  return onRefresh(shipmentIds);
                }}
              />
            )}
          </DialogContent>
        </Dialog>

        {selectedShipmentForSplitting ? (
          <LoadSplittingModalContainer
            open={loadSplittingModalOpen}
            onClose={() => {
              setLoadSplittingModalOpen(false);
              onRefresh();
            }}
            shipment={selectedShipmentForSplitting}
          />
        ) : null}
      </Box>
    );
  }
);

export default PlanningGrid;

const renderForAugmentedShipment = (
  shipment: AugmentedShipment,
  renderFn: (shipment: AugmentedShipment) => ReactNode
) => {
  return (
    <Stack spacing={1}>
      {[shipment, ...shipment.otherShipmentsInTrip].map((shipment, i) => (
        <>
          {i > 0 ? <Divider /> : null}
          {renderFn(shipment)}
        </>
      ))}
    </Stack>
  );
};
