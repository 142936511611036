import React from "react";
import { Snackbar, Alert } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { hideToast } from "../../../redux/slices/alert/Alert.slice";

const GlobalToast = () => {
  const dispatch = useDispatch();
  const toast = useSelector((state: RootState) => state.alertReducer.toast);
  const { isVisible: isToastVisible, message, type: toastType } = toast;

  return (
    <Snackbar
      sx={{
        position: "absolute",
      }}
      anchorOrigin={{ horizontal: "right", vertical: "top" }}
      open={isToastVisible}
      autoHideDuration={6000}
      onClose={() => dispatch(hideToast())}
    >
      <Alert
        onClose={() => dispatch(hideToast())}
        severity={toastType}
        sx={{ width: "100%" }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default GlobalToast;
