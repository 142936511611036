import { Container } from "@mui/material";
import { useParams } from "react-router-dom";
import ReceiverForecastForm from "../../components/forecasting/ReceiverForecastForm";

function EditReceiverForecast() {
  const { id } = useParams();

  if (!id) {
    return null;
  }
  return (
    <Container>
      <ReceiverForecastForm receiverForecastId={id} />
    </Container>
  );
}

export default EditReceiverForecast;
