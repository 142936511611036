import { DriverStatus } from "../../components/tracking/LiveTrackerDriversList/LiveTrackerDriver/LiveTrackerDriver";

const driverStatusKey: {
  [key in DriverStatus]: string;
} = {
  DRIVING: "driving",
  OFF_DUTY: "offDuty",
  ON_DUTY: "onDuty",
  SLEEPING: "sleeping",
  UNASSIGNED: "unassigned",
  ASSIGNED: "assigned",
};

export default driverStatusKey;
