import { useEffect, useState } from "react";
import {
  useGetSubscriptionInfoQuery,
  useGetSubscriptionPortalSessionMutation,
} from "../../../graphql/generated";
import SubscriptionInfoBanner from "./SubscriptionInfoBanner";

const SubscriptionInfoBannerContainer = () => {
  const getSubscriptionInfoQuery = useGetSubscriptionInfoQuery({});
  const subscriptionInfo = getSubscriptionInfoQuery.data?.subscriptionInfo;
  const subscriptionPortalSessionMutation =
    useGetSubscriptionPortalSessionMutation();
  const [subscriptionPortalUrl, setSubscriptionPortalUrl] =
    useState<string | null>(null);
  useEffect(() => {
    async function initSubscriptionPortal() {
      const result = await subscriptionPortalSessionMutation.mutateAsync({});
      setSubscriptionPortalUrl(result.getSubscriptionPortalSession.url);
    }
    initSubscriptionPortal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!subscriptionInfo) {
    return null;
  }
  return (
    <SubscriptionInfoBanner
      subscriptionInfo={subscriptionInfo}
      subscriptionPortalUrl={subscriptionPortalUrl}
    />
  );
};

export default SubscriptionInfoBannerContainer;
