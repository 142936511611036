import { configureStore } from "@reduxjs/toolkit";
import shipmentReducer from "./slices/shipment/ShipmentForm.slice";
import alertReducer from "./slices/alert/Alert.slice";

export const store = configureStore({
  reducer: {
    shipmentReducer: shipmentReducer,
    alertReducer: alertReducer,
  },
  devTools: true,
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
