import { Stack, TextField } from "@mui/material";
import { ReadingsFileConfiguration } from "../../../graphql/generated";
import NumberTextField from "../../common/NumberTextField";

type ReadingsFileConfigurationProps = {
  readingsFileConfiguration: ReadingsFileConfiguration;
  onChange: (configuration: ReadingsFileConfiguration) => void;
};
const ReadingsFileConfigurationForm = ({
  readingsFileConfiguration,
  onChange,
}: ReadingsFileConfigurationProps) => {
  return (
    <Stack spacing={2}>
      <TextField
        label="Tank Id Column"
        value={readingsFileConfiguration.tankIdColumn}
        onChange={(event) => {
          onChange({
            ...readingsFileConfiguration,
            tankIdColumn: event.target.value,
          });
        }}
        fullWidth
        required
      />

      <Stack direction="row" spacing={2}>
        <TextField
          label="Date Column"
          value={readingsFileConfiguration.dateColumn}
          onChange={(event) => {
            onChange({
              ...readingsFileConfiguration,
              dateColumn: event.target.value,
            });
          }}
          fullWidth
          required
        />
        <TextField
          label="Date Format"
          value={readingsFileConfiguration.dateFormat}
          onChange={(event) => {
            onChange({
              ...readingsFileConfiguration,
              dateFormat: event.target.value,
            });
          }}
          fullWidth
          required
        />
      </Stack>

      <Stack direction="row" spacing={2}>
        <TextField
          label="Time Column"
          value={readingsFileConfiguration.timeColumn}
          onChange={(event) => {
            onChange({
              ...readingsFileConfiguration,
              timeColumn: event.target.value,
            });
          }}
          fullWidth
          required
        />
        <TextField
          label="Time Format"
          value={readingsFileConfiguration.timeFormat}
          onChange={(event) => {
            onChange({
              ...readingsFileConfiguration,
              timeFormat: event.target.value,
            });
          }}
          fullWidth
          required
        />
      </Stack>

      <TextField
        label="Level Column"
        value={readingsFileConfiguration.levelColumn}
        onChange={(event) => {
          onChange({
            ...readingsFileConfiguration,
            levelColumn: event.target.value,
          });
        }}
        fullWidth
        required
      />

      <TextField
        label="Store Column"
        value={readingsFileConfiguration.storeColumn}
        onChange={(event) => {
          onChange({
            ...readingsFileConfiguration,
            storeColumn: event.target.value,
          });
        }}
        fullWidth
      />

      <Stack direction="row" spacing={2}>
        <NumberTextField
          label="Stale Readings Warning Threshold (minutes)"
          value={readingsFileConfiguration.staleReadingWarningThreshold}
          onChange={(event) => {
            onChange({
              ...readingsFileConfiguration,
              staleReadingWarningThreshold: parseInt(event.target.value),
            });
          }}
          fullWidth
        />
        <NumberTextField
          label="Stale Readings Danger Threshold (minutes)"
          value={readingsFileConfiguration.staleReadingDangerThreshold}
          onChange={(event) => {
            onChange({
              ...readingsFileConfiguration,
              staleReadingDangerThreshold: parseInt(event.target.value),
            });
          }}
          fullWidth
        />
        <NumberTextField
          label="Stale Readings Critical Threshold (minutes)"
          value={readingsFileConfiguration.staleReadingCriticalThreshold}
          onChange={(event) => {
            onChange({
              ...readingsFileConfiguration,
              staleReadingCriticalThreshold: parseInt(event.target.value),
            });
          }}
          fullWidth
        />
      </Stack>

      <Stack direction="row" spacing={2}>
        <NumberTextField
          label="Reading deviation warning threshold (%)"
          value={
            (readingsFileConfiguration.readingDeviationWarningThreshold || 0) *
            100
          }
          onChange={(event) => {
            onChange({
              ...readingsFileConfiguration,
              readingDeviationWarningThreshold:
                (parseInt(event.target.value) || 0) / 100,
            });
          }}
          fullWidth
        />
        <NumberTextField
          label="Reading deviation danger threshold (%)"
          value={
            (readingsFileConfiguration.readingDeviationDangerThreshold || 0) *
            100
          }
          onChange={(event) => {
            onChange({
              ...readingsFileConfiguration,
              readingDeviationDangerThreshold:
                (parseInt(event.target.value) || 0) / 100,
            });
          }}
          fullWidth
        />
        <NumberTextField
          label="Reading deviation critical threshold (%)"
          value={
            (readingsFileConfiguration.readingDeviationCriticalThreshold || 0) *
            100
          }
          onChange={(event) => {
            onChange({
              ...readingsFileConfiguration,
              readingDeviationCriticalThreshold:
                (parseInt(event.target.value) || 0) / 100,
            });
          }}
          fullWidth
        />
      </Stack>

      <Stack direction="row" spacing={2}>
        <NumberTextField
          label="Runout warning threshold (minutes)"
          value={readingsFileConfiguration.runoutWarningThreshold || 0}
          onChange={(event) => {
            onChange({
              ...readingsFileConfiguration,
              runoutWarningThreshold: parseInt(event.target.value) || 0,
            });
          }}
          fullWidth
        />
        <NumberTextField
          label="Runout danger threshold (minutes)"
          value={readingsFileConfiguration.runoutDangerThreshold || 0}
          onChange={(event) => {
            onChange({
              ...readingsFileConfiguration,
              runoutDangerThreshold: parseInt(event.target.value) || 0,
            });
          }}
          fullWidth
        />
        <NumberTextField
          label="Runout critical threshold (minutes)"
          value={readingsFileConfiguration.runoutCriticalThreshold || 0}
          onChange={(event) => {
            onChange({
              ...readingsFileConfiguration,
              runoutCriticalThreshold: parseInt(event.target.value) || 0,
            });
          }}
          fullWidth
        />
      </Stack>
    </Stack>
  );
};

export default ReadingsFileConfigurationForm;
