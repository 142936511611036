import { useGetGroupsQuery } from "../../../graphql/generated";
import GroupSelect, { GroupSelectProps } from "./GroupSelect";

type GroupSelectContainerProps = Omit<GroupSelectProps, "groups">;

const GroupSelectContainer = (props: GroupSelectContainerProps) => {
  const groupsQuery = useGetGroupsQuery();
  const groups = groupsQuery.data?.groups.data || [];
  return <GroupSelect {...props} groups={groups} />;
};

export default GroupSelectContainer;
