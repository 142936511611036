import {
  useDeleteDriverShiftMutation,
  // useBulkAddDriverShiftsMutation,
  useGetDriverShiftListQuery,
} from "../../../graphql/generated";
import DriverShiftsList from "./DriverShiftsList";

export default function DriverShiftsListContainer() {
  // const bulkCreateDriverShifts = useBulkAddDriverShiftsMutation();
  let refetch = () => {};
  // I need a handle on refetch so I can call it
  // a new settlement has been generated
  const useGetDriverShiftListQueryCustom: typeof useGetDriverShiftListQuery = (
    variables,
    options
  ) => {
    const query = useGetDriverShiftListQuery(variables, {
      ...options,
    });
    refetch = query.refetch;
    return query;
  };
  useGetDriverShiftListQueryCustom.fetcher = useGetDriverShiftListQuery.fetcher;
  useGetDriverShiftListQueryCustom.getKey = useGetDriverShiftListQuery.getKey;

  return (
    <DriverShiftsList
      // onBulkCreate={(driverShifts) =>
      //   bulkCreateDriverShifts.mutateAsync({ newDriverShiftsData: driverShifts })
      // }
      query={useGetDriverShiftListQueryCustom}
      deleteMutation={useDeleteDriverShiftMutation}
      onRefresh={() => {
        refetch();
      }}
    />
  );
}
