import { Container } from "@mui/material";
import DriverForm from "../../../components/asset-management/DriverForm";

function CreateDriver() {
  return (
    <Container>
      <DriverForm />
    </Container>
  );
}

export default CreateDriver;
