import { useCallback } from "react";
import HeaderTitle from "../../components/common/HeaderTitle";
import EditShipmentForm from "../../components/shipment/EditShipmentForm";
import { Shipment } from "../../graphql/generated";
import { useNavigationOptions } from "../LayoutRoutes";
import { useTranslation } from "react-i18next";

export default function EditShipment() {
  const { setNavigationOptions } = useNavigationOptions();
  const { t } = useTranslation("orders");
  const onLoad = useCallback(
    (shipment: Pick<Shipment, "shipmentNumber">) => {
      setNavigationOptions({
        title: (
          <HeaderTitle
            label={t("editOrder")}
            suffix={shipment?.shipmentNumber || ""}
          />
        ),
        showBackIcon: true,
      });
    },
    [setNavigationOptions, t]
  );
  return <EditShipmentForm onLoad={onLoad} />;
}
