import { Autocomplete, Chip, TextField } from "@mui/material";
import { Group } from "../../../graphql/generated";
import { useTranslation } from "react-i18next";

export type GroupSelectProps = {
  value: string[];
  onChange: (value: string[]) => void;
  groups: Pick<Group, "_id" | "name">[];
};

const GroupSelect = ({ value, onChange, groups }: GroupSelectProps) => {
  const { t } = useTranslation("common");
  return (
    <Autocomplete
      value={groups.filter((group) => value.includes(group._id))}
      clearIcon={false}
      options={groups}
      multiple
      renderTags={(value, props) =>
        value.map((group, index) => (
          <Chip label={group.name} {...props({ index })} />
        ))
      }
      renderInput={(params) => (
        <TextField name="groups" label={t("groups", "Groups")} {...params} />
      )}
      getOptionLabel={(group) => group.name}
      onChange={(_, newValue) => {
        onChange(newValue.map((group) => group._id));
      }}
    />
  );
};

export default GroupSelect;
