import { Grid, Stack } from "@mui/material";
import { ComponentType, CSSProperties } from "react";
import {
  CustomField,
  CustomFieldContext,
  CustomFieldType,
  GetCustomFieldDefinitionsQuery,
} from "../../../graphql/generated";
import SelectCustomFieldInput from "./custom-field-inputs/SelectCustomFieldInput";
import StringCustomFieldInput from "./custom-field-inputs/StringCustomFieldInput";
import NumberCustomFieldInput from "./custom-field-inputs/NumberCustomFieldInput";
import MultiSelectCustomFieldInput from "./custom-field-inputs/MultiSelectCustomFieldInput";
import DateInput from "./custom-field-inputs/DateInput";
import TimeInput from "./custom-field-inputs/TimeInput";
import BooleanCustomFieldInput from "./custom-field-inputs/BooleanCustomFieldInput";
import { castArray } from "lodash";

type CustomFieldDefinitionFormData =
  GetCustomFieldDefinitionsQuery["cutomFieldDefinitions"]["data"][0];

export type CustomFieldsFormProps = {
  customFieldDefinitions: CustomFieldDefinitionFormData[];
  customFields: CustomField[] | null;
  onChange: (customFields: CustomField[]) => void;
  context: CustomFieldContext;
};

export type CustomFieldValue = string | number | boolean | string[] | number[];

export type CustomFieldInputProps = {
  value: CustomFieldValue;
  onChange: (value: CustomFieldValue | null) => void;
  customFieldDefinition: CustomFieldDefinitionFormData;
  label?: string;
  style?: CSSProperties;
};

export type CustomFieldInputComponent = ComponentType<CustomFieldInputProps>;

const CustomFieldInputComponentByTarget: {
  [key in CustomFieldType]: CustomFieldInputComponent | null;
} = {
  [CustomFieldType.Select]: SelectCustomFieldInput,
  [CustomFieldType.Boolean]: BooleanCustomFieldInput,
  [CustomFieldType.Date]: DateInput,
  [CustomFieldType.Datetime]: null,
  [CustomFieldType.Multiselect]: MultiSelectCustomFieldInput,
  [CustomFieldType.Number]: NumberCustomFieldInput,
  [CustomFieldType.String]: StringCustomFieldInput,
  [CustomFieldType.Time]: TimeInput,
};

const requirementCustomFieldRangeContexts = [
  CustomFieldContext.Shipment,
  CustomFieldContext.Customer,
  CustomFieldContext.Location,
  CustomFieldContext.Commodity,
];

const CustomFieldsForm = ({
  customFields,
  customFieldDefinitions,
  onChange,
  context,
}: CustomFieldsFormProps) => {
  return (
    <Stack direction="column" spacing={2}>
      {customFieldDefinitions.map((customFieldDefinition) => {
        const InputComponent =
          CustomFieldInputComponentByTarget[customFieldDefinition.type];
        if (!InputComponent) {
          return null;
        }
        const customField = customFields?.find(
          (customField) => customField.key === customFieldDefinition.key
        );
        if (
          customFieldDefinition.isRequirement &&
          customFieldDefinition.requirementOptions?.isRange &&
          requirementCustomFieldRangeContexts.includes(context)
        ) {
          const customFieldValue = castArray(customField?.value);
          return (
            <Stack key={customFieldDefinition._id} direction="row">
              <InputComponent
                customFieldDefinition={customFieldDefinition}
                value={customFieldValue[0] || null}
                onChange={(value) => {
                  onChange(
                    (
                      customFields?.filter(
                        (customField) =>
                          customField.key !== customFieldDefinition.key
                      ) || []
                    ).concat({
                      key: customFieldDefinition.key,
                      value: [value, customFieldValue[1]],
                    })
                  );
                }}
                label={customFieldDefinition.label + " (min)"}
              />
              <InputComponent
                customFieldDefinition={customFieldDefinition}
                value={customFieldValue[1] || null}
                onChange={(value) => {
                  onChange(
                    (
                      customFields?.filter(
                        (customField) =>
                          customField.key !== customFieldDefinition.key
                      ) || []
                    ).concat({
                      key: customFieldDefinition.key,
                      value: [customFieldValue[0], value],
                    })
                  );
                }}
                label={customFieldDefinition.label + " (max)"}
              />
            </Stack>
          );
        }
        return (
          <Grid style={customFieldDefinitions.length ? { marginTop: 20 } : {}}>
            <InputComponent
              style={{ marginTop: 5 }}
              key={customFieldDefinition._id}
              customFieldDefinition={customFieldDefinition}
              value={
                customField?.value === undefined ? null : customField.value
              }
              onChange={(value) => {
                if (value === null || value === undefined) {
                  onChange(
                    customFields?.filter(
                      (customField) =>
                        customField.key !== customFieldDefinition.key
                    ) || []
                  );
                  return;
                }
                onChange(
                  (
                    customFields?.filter(
                      (customField) =>
                        customField.key !== customFieldDefinition.key
                    ) || []
                  ).concat({
                    key: customFieldDefinition.key,
                    value,
                  })
                );
              }}
            />
          </Grid>
        );
      })}
    </Stack>
  );
};

export default CustomFieldsForm;
