import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  DialogActions,
  Button,
  DialogProps,
  Breakpoint,
  Divider,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  GetShipmentQuery,
  S3_Operation,
  useGetSignedUrlMutation,
} from "../../../../graphql/generated";
import AddButton from "../../../common/AddButton";
import AddIcon from "@mui/icons-material/Add";
import ShipmentDocumentForm from "./ShipmentDocumentForm";
import { useDispatch } from "react-redux";
import { showDialog } from "../../../../redux/slices/alert/Alert.slice";
import {
  FieldErrors,
  FormError,
  ShipmentDocumentInputData,
} from "../../../../redux/slices/Types";
import { isEmpty } from "lodash";
import { v4 as uuid } from "uuid";
import { defaultShipmentDocument } from "../../../../redux/slices/shipment/ShipmentForm.slice";
import { useTranslation } from "react-i18next";

interface ShipmentDocumentsUploadModalProps {
  openModal: boolean;
  width?: Breakpoint;
  onClose: (document: Array<ShipmentDocumentInputData>) => void;
  shipmentLocations: GetShipmentQuery["shipmentById"]["shipmentLocations"];
}

export default function ShipmentDocumentsUploadModal({
  openModal,
  width,
  onClose,
  shipmentLocations,
}: ShipmentDocumentsUploadModalProps) {
  const { t } = useTranslation(["orders", "common"]);
  const dispatch = useDispatch();
  const [documents, setDocuments] = useState<Array<ShipmentDocumentInputData>>(
    []
  );
  const [maxWidth] = useState<DialogProps["maxWidth"]>(width);
  const [errors, setErrors] = useState<Array<FormError>>([]);
  const signUrlMutation = useGetSignedUrlMutation();

  useEffect(() => {
    if (openModal && !documents.length) {
      setDocuments([{ ...defaultShipmentDocument, _id: uuid() }]);
    }
  }, [openModal, documents]);

  const handleChange = (id: number, document: ShipmentDocumentInputData) => {
    const _documents = [...documents];
    _documents[id] = document;
    setDocuments(_documents);
  };

  const handleUpload = async (
    id: number,
    file: File | null,
    error?: string
  ) => {
    if (!error && file) {
      try {
        const result = await signUrlMutation.mutateAsync({
          document: {
            operation: S3_Operation.PutObject,
            extension: file.name.split(".").pop(),
          },
        });

        return handleChange(id, {
          ...documents[id],
          url: result.documentSignedUrl.url,
          file,
        });
      } catch (error) {
        dispatch(
          showDialog({
            type: "error",
            title: t(
              "common:documents.errors.uploadError",
              "Error while uploading document"
            ),
            description:
              (error as FieldErrors).message ||
              t("common:error.unknownError", "Unknown error"),
          })
        );
      }
    }
  };

  const handleAddDocument = () => {
    setDocuments([...documents, { ...defaultShipmentDocument, _id: uuid() }]);
  };

  const handleCancel = () => {
    setDocuments([]);
    onClose([]);
  };

  const handleSubmit = () => {
    if (documents.length) {
      const errors: Array<FormError> = [];
      for (const document of documents) {
        const err: FieldErrors = {};
        if (!document.name) {
          err["name"] = t(
            "common:documents.errors.emptyLabel",
            "Document label cannot be empty"
          );
        }

        if (!document.url) {
          err["url"] = t(
            "common:documents.errors.attachFile",
            "Please attach a file"
          );
        }

        if (!document.accessLevel.length) {
          err["accessLevel"] = t(
            "orders:documents.errors.selectVisibility",
            "Please select at least a visibility level"
          );
        }

        if (!isEmpty(err)) {
          errors.push({ formId: document._id || "", error: err });
        }
      }
      if (errors.length) {
        return setErrors(errors);
      }
      setDocuments([]);
      onClose(documents);
    }
  };

  return (
    <Dialog maxWidth={maxWidth} fullWidth open={openModal}>
      <DialogTitle>
        <Typography variant="h6">
          {t("common:documents.uploadMany", "Upload Documents")}
        </Typography>
      </DialogTitle>
      <DialogContent dividers sx={{ p: 2 }}>
        <Stack spacing={2}>
          {documents?.map((document, index) => (
            <div key={index}>
              <ShipmentDocumentForm
                id={index}
                shipmentLocations={shipmentLocations}
                key={index}
                document={document}
                onChange={handleChange}
                onUpload={handleUpload}
                errors={errors.find((e) => e.formId === document._id)}
              />
              <Divider sx={{ mt: 5 }} />
            </div>
          ))}
        </Stack>
        <Stack
          direction="row"
          spacing={2}
          sx={{ m: 1, display: "flex", alignItems: "center" }}
        >
          <AddButton
            id="add-document-button"
            sx={{ height: 50, width: 20 }}
            onClick={handleAddDocument}
          >
            <AddIcon />
          </AddButton>
          <span>{t("common:documents.add", "Add Document")}</span>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          color="inherit"
          variant="contained"
          onClick={handleCancel}
        >
          {t("common:cancel", "Cancel")}
        </Button>
        <Button color="primary" variant="contained" onClick={handleSubmit}>
          {t("common:upload", "Upload")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
