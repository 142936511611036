import { Stack, TextField } from "@mui/material";
import {
  ForecastingModelSlot,
  ForecastingModelSlotType,
} from "../../../graphql/generated";
import { TimePicker } from "@mui/x-date-pickers";

type ForecastingModelSlotFormProps = {
  slot: ForecastingModelSlot;
  onChange: (newSlot: ForecastingModelSlot) => void;
  readonly?: boolean;
};

export const ForecastingModelSlotForm = (
  props: ForecastingModelSlotFormProps
) => {
  const { slot } = props;
  const startTime = new Date();
  const endTime = new Date();
  if (slot.type === ForecastingModelSlotType.Hourly && slot.hourly) {
    startTime.setHours(
      slot.hourly.startTime.hour,
      slot.hourly.startTime.minute
    );
    endTime.setHours(slot.hourly.endTime.hour, slot.hourly.endTime.minute);
  }

  const updateSlot = (updates: Partial<ForecastingModelSlot>) => {
    props.onChange({
      ...slot,
      ...updates,
    });
  };

  return (
    <Stack spacing={2} direction="row">
      {slot.type === ForecastingModelSlotType.Hourly && slot.hourly ? (
        <>
          <TimePicker
            label="Start Time"
            value={startTime}
            ampm={false}
            disabled={props.readonly}
            renderInput={(params) => {
              return <TextField {...params} fullWidth />;
            }}
            onChange={(updatedStartTime) => {
              if (!updatedStartTime) {
                return;
              }
              if (!slot.hourly) {
                return;
              }
              updateSlot({
                hourly: {
                  ...slot.hourly,
                  startTime: {
                    hour: updatedStartTime.getHours(),
                    minute: updatedStartTime.getMinutes(),
                  },
                },
              });
            }}
          />
          <TimePicker
            label="End Time"
            value={endTime}
            ampm={false}
            disabled={props.readonly}
            renderInput={(params) => {
              return <TextField {...params} fullWidth />;
            }}
            onChange={(updatedEndTime) => {
              if (!updatedEndTime) {
                return;
              }
              if (!slot.hourly) {
                return;
              }
              updateSlot({
                hourly: {
                  ...slot.hourly,
                  endTime: {
                    hour: updatedEndTime.getHours(),
                    minute: updatedEndTime.getMinutes(),
                  },
                },
              });
            }}
          />
        </>
      ) : null}

      <TextField
        label="Sales Amount"
        value={slot.salesAmount}
        onChange={(event) => {
          updateSlot({
            salesAmount: parseFloat(event.target.value),
          });
        }}
        type="number"
        fullWidth
        disabled={props.readonly}
      />
    </Stack>
  );
};
