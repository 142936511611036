import { useState } from "react";
import {
  useGenerateReportPdfMutation,
  useGetDriverListQuery,
  useSendReportPdfMutation,
} from "../../../graphql/generated";
import useDialog from "../../../utils/hooks/useDialog";
import ReportGenerator from "./ReportGenerator";
import { useTranslation } from "react-i18next";
import LocaleProvider from "../../../providers/LocaleProvider";

const ReportGeneratorContainer = () => {
  const { t } = useTranslation("common");
  const [generating, setGenerating] = useState(false);
  const generateReportPdfMutation = useGenerateReportPdfMutation();
  const sendReportPdfMutation = useSendReportPdfMutation();
  const { showDialog } = useDialog();
  const getDriversQuery = useGetDriverListQuery();
  return (
    <ReportGenerator
      drivers={getDriversQuery.data?.drivers.data || []}
      generating={generating}
      onGenerateAndDownloadReport={async (reportType, period, filter) => {
        try {
          setGenerating(true);
          const result = await generateReportPdfMutation.mutateAsync({
            reportType,
            period,
            filter,
            timezone: LocaleProvider.getTimezone(),
            lang: LocaleProvider.getLanguage().toLowerCase(),
          });
          await new Promise(async (resolve, reject) => {
            async function checkFileReady(retryCount = 0) {
              try {
                const response = await fetch(result.generateReportPdf.url);
                if (response.status === 404) {
                  if (retryCount > 10) {
                    reject(
                      new Error(
                        t("error.reportTimeout", "Timeout waiting for report")
                      )
                    );
                  }
                  setTimeout(() => checkFileReady(retryCount + 1), 1000);
                } else {
                  resolve(null);
                }
              } catch (error) {
                reject(error);
              }
            }
            setTimeout(checkFileReady, 1000);
          });
          window.open(result.generateReportPdf.url, "_blank");
        } catch (error) {
          showDialog({
            type: "error",
            title: t("error.generateReportError", "Error generating report"),
            description:
              (error as Error).message ||
              t("error.unknownError", "Unknown error"),
          });
        } finally {
          setGenerating(false);
        }
      }}
      onSendReport={async (reportType, period, filter, email) => {
        try {
          await sendReportPdfMutation.mutateAsync({
            reportType,
            period,
            filter,
            email,
            timezone: LocaleProvider.getTimezone(),
            lang: LocaleProvider.getLanguage().toLowerCase()
          });
          showDialog({
            type: "primary",
            title: t("reportSent", "Report sent"),
            description: t(
              "reportSentDescription",
              "The report has been sent to the email address you provided"
            ),
          });
        } catch (error) {
          showDialog({
            type: "error",
            title: t("error.sendReportError", "Error sending report"),
            description:
              (error as Error).message ||
              t("error.unknownError", "Unknown error"),
          });
        }
      }}
      sending={sendReportPdfMutation.isLoading}
    />
  );
};

export default ReportGeneratorContainer;
