import {
  useBulkAddGoodProfilesMutation,
  useGetGoodProfileListQuery,
} from "../../../graphql/generated";
import GoodProfilesList from "./GoodProfilesList";

export default function GoodProfilesListContainer() {
  const bulkCreateGoodProfiles = useBulkAddGoodProfilesMutation();
  return (
    <GoodProfilesList
      onBulkCreate={(goodProfiles) =>
        bulkCreateGoodProfiles.mutateAsync({
          newGoodProfilesData: goodProfiles,
        })
      }
      query={useGetGoodProfileListQuery}
    />
  );
}
