import { Box, Grid } from "@mui/material";
import {
  ForecastingModel,
  ForecastingModelSlot,
  ForecastingModelSlotType,
} from "../../../graphql/generated";
import NumberTextField from "../../common/NumberTextField";

type MonthlyForecastModelFormProps = {
  model: ForecastingModel;
  onChange: (model: ForecastingModel) => void;
};

const MonthlyForecastModelForm = (props: MonthlyForecastModelFormProps) => {
  return (
    <Box>
      <Grid container spacing={2}>
        {Array.from({ length: 12 }, (_, i) => i).map((month) => (
          <Grid item xs={4} sm={2}>
            <NumberTextField
              label={monthLabel(month)}
              value={
                props.model.slots?.find((slot) => slot.monthly?.month === month)
                  ?.salesAmount || 0
              }
              onChange={(event) => {
                const existingSlot = props.model.slots?.find(
                  (slot) => slot.monthly?.month === month
                );
                if (!existingSlot) {
                  const newSlot: ForecastingModelSlot = {
                    type: ForecastingModelSlotType.Monthly,
                    monthly: {
                      month,
                    },
                    salesAmount: parseFloat(event.target.value),
                  };
                  props.onChange({
                    ...props.model,
                    slots: [...(props.model.slots || []), newSlot],
                  });
                  return;
                } else {
                  const newSlots = props.model.slots?.map((slot) => {
                    if (slot.monthly?.month === month) {
                      return {
                        ...slot,
                        salesAmount: parseFloat(event.target.value) || 0,
                      };
                    }
                    return slot;
                  });
                  props.onChange({
                    ...props.model,
                    slots: newSlots,
                  });
                  return;
                }
              }}
              type="number"
              fullWidth
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default MonthlyForecastModelForm;

const monthLabel = (month: number) => {
  switch (month) {
    case 0:
      return "January";
    case 1:
      return "February";
    case 2:
      return "March";
    case 3:
      return "April";
    case 4:
      return "May";
    case 5:
      return "June";
    case 6:
      return "July";
    case 7:
      return "August";
    case 8:
      return "September";
    case 9:
      return "October";
    case 10:
      return "November";
    case 11:
      return "December";
  }
};
