import {
  useCompleteMaintenanceTaskMutation,
  useDeleteMaintenanceTaskMutation,
  useGetMaintenanceTasksQuery,
} from "../../../graphql/generated";
import MaintenanceTasksList, {
  MaintenanceTaskListProps,
} from "./MaintenanceTasksList";

type MaintenanceTasksListContainerProps = Omit<
  MaintenanceTaskListProps,
  "query" | "deleteMutation" | "onRefresh" | "onComplete"
>;

export default function MaintenanceTasksListContainer(
  props: MaintenanceTasksListContainerProps
) {
  // const bulkCreateMaintenanceTasks = useBulkAddMaintenanceTasksMutation();
  let refetch = () => {};
  // I need a handle on refetch so I can call it
  // a new settlement has been generated
  const useGetMaintenanceTaskListQueryCustom: typeof useGetMaintenanceTasksQuery =
    (variables, options) => {
      const query = useGetMaintenanceTasksQuery(
        { ...variables, assetType: props.assetType, assetId: props.assetId },
        {
          ...options,
        }
      );
      refetch = query.refetch;
      return query;
    };
  useGetMaintenanceTaskListQueryCustom.fetcher =
    useGetMaintenanceTasksQuery.fetcher;
  useGetMaintenanceTaskListQueryCustom.getKey =
    useGetMaintenanceTasksQuery.getKey;

  const completeMaintenanceTaskMutation = useCompleteMaintenanceTaskMutation();

  return (
    <MaintenanceTasksList
      // onBulkCreate={(maintenanceTasks) =>
      //   bulkCreateMaintenanceTasks.mutateAsync({ newMaintenanceTasksData: maintenanceTasks })
      // }
      query={useGetMaintenanceTaskListQueryCustom}
      deleteMutation={useDeleteMaintenanceTaskMutation}
      onRefresh={() => {
        refetch();
      }}
      onComplete={async (task) => {
        try {
          await completeMaintenanceTaskMutation.mutateAsync({
            id: task._id,
          });
          await refetch();
        } catch (error) {
          console.error(error);
        }
      }}
      {...props}
    />
  );
}
