import {
  useBulkAddTractorsMutation,
  useGetTractorListQuery,
} from "../../../graphql/generated";
import TractorsList from "./TractorsList";

export default function TractorsListContainer() {
  const bulkCreateTractors = useBulkAddTractorsMutation();
  return (
    <TractorsList
      onBulkCreate={(tractors) =>
        bulkCreateTractors.mutateAsync({ newTractorsData: tractors })
      }
      query={useGetTractorListQuery}
    />
  );
}
