import { Add, RemoveCircle } from "@mui/icons-material";
import {
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import { format, isValid, parse, setDay } from "date-fns";
import { capitalize, range, without } from "lodash";
import { OpeningSchedule } from "../../../graphql/generated";
import AddButton from "../../common/AddButton";
import { useTranslation } from "react-i18next";
import { localeEnumToDateFnLocale } from "../../../utils/labels/formatDateTime";
import LocaleProvider from "../../../providers/LocaleProvider";

type OpeningHoursFormProps = {
  value: OpeningSchedule[];
  onChange: (schedules: OpeningSchedule[]) => void;
};

function OpeningHoursForm({ value, onChange }: OpeningHoursFormProps) {
  const { t } = useTranslation(["business", "common"]);
  return (
    <Stack direction="column" spacing={2}>
      {value.map((schedule, index) => (
        <Stack direction="row" alignItems="center" spacing={2}>
          <FormControl fullWidth>
            <InputLabel>{capitalize(t("days_other", "Days"))}</InputLabel>
            <Select
              multiple
              name="openingHours.days"
              value={schedule.days}
              onChange={(event) =>
                onChange(
                  value.map((existingSchedule) =>
                    existingSchedule === schedule
                      ? {
                          ...existingSchedule,
                          days: event.target.value as number[],
                        }
                      : existingSchedule
                  )
                )
              }
              input={
                <OutlinedInput label={capitalize(t("days_other", "days"))} />
              }
              renderValue={(selected) =>
                selected.map((day) => dayLabel(day)).join(", ")
              }
            >
              {[...range(1, 7), 0].map((dayOfWeek) => (
                <MenuItem key={dayOfWeek} value={dayOfWeek}>
                  <Checkbox checked={schedule.days.indexOf(dayOfWeek) > -1} />
                  <ListItemText primary={dayLabel(dayOfWeek)} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TimePicker
            label={t("openingTime", "Opening time")}
            ampm={false}
            value={parse(schedule.openingTime, "HH:mm", new Date())}
            onChange={(openingTime) => {
              if (!openingTime) {
                return;
              }
              if (!isValid(new Date(openingTime))) {
                return;
              }
              onChange(
                value.map((existingSchedule) =>
                  existingSchedule === schedule
                    ? {
                        ...existingSchedule,
                        openingTime: format(new Date(openingTime), "HH:mm"),
                      }
                    : existingSchedule
                )
              );
            }}
            renderInput={(params) => (
              <TextField {...params} name="openingHours.openingTime" />
            )}
          />
          <TimePicker
            label={t("closingTime", "Closing time")}
            ampm={false}
            value={parse(schedule.closingTime, "HH:mm", new Date())}
            minTime={parse(schedule.openingTime, "HH:mm", new Date())}
            onChange={(closingTime) => {
              if (!closingTime) {
                return;
              }
              if (!isValid(new Date(closingTime))) {
                return;
              }
              onChange(
                value.map((existingSchedule) =>
                  existingSchedule === schedule
                    ? {
                        ...existingSchedule,
                        closingTime: format(new Date(closingTime), "HH:mm"),
                      }
                    : existingSchedule
                )
              );
            }}
            renderInput={(params) => (
              <TextField {...params} name="openingHours.closingTime" />
            )}
          />
          <IconButton onClick={() => onChange(without(value, schedule))}>
            <RemoveCircle />
          </IconButton>
        </Stack>
      ))}

      <Stack
        direction="row"
        spacing={2}
        sx={{ m: 1, display: "flex", alignItems: "center" }}
      >
        <AddButton
          sx={{ height: 50, width: 20 }}
          onClick={() => {
            onChange(
              value.concat({
                days: [],
                openingTime: "00:00",
                closingTime: "23:59",
              })
            );
          }}
          id="add-schedule-button"
        >
          <Add />
        </AddButton>
        <span>{t("addSchedule", "Add Schedule")}</span>
      </Stack>
    </Stack>
  );
}

export const dayLabel = (day: number) =>
  capitalize(
    format(setDay(new Date(), day), "EEEE", {
      locale: localeEnumToDateFnLocale[LocaleProvider.getLocale()],
    })
  );

export default OpeningHoursForm;
