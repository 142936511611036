import { Alert, Box, Button, Grid, Typography } from "@mui/material";
import { startOfDay } from "date-fns";
import { capitalize, keyBy, uniq } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useDrop } from "react-dnd";
import LoadingOverlay from "../../common/LoadingOverlay";
import ShipmentSummariesList from "../../shipment/ShipmentSummariesList";
import { ShipmentDataSummary } from "../../shipment/ShipmentSummary/ShipmentSummary";
import ReorderableShipments from "../ReorderableShipments";
import EmptyState from "../../common/EmptyState";
import { useTranslation } from "react-i18next";

interface TripCreatorProps {
  shipments: ShipmentDataSummary[];
  creating?: boolean;
  loading?: boolean;
  error?: Error | null;
  onCreate: (shipments: ShipmentDataSummary[]) => void;
  onShipmentsChange?: () => void;
}

export const validateShipmentGrouping = (shipments: ShipmentDataSummary[]) => {
  const trailerTypes = uniq(shipments.map((s) => s.trailerType));
  if (trailerTypes.length > 1) {
    return "You can't mix loads of different trailer types on the same trip";
  }
  const startDates = uniq(
    shipments
      .map(
        (shipment) =>
          shipment.route?.locations && shipment.route?.firstPickupTime
      )
      .filter(Boolean)
      .map((pickupDate) => startOfDay(new Date(pickupDate)).toDateString())
  );
  if (startDates.length > 1) {
    return "You can't mix loads with different pick up dates on the same trip";
  }
  return null;
};

export default function TripCreator({
  shipments,
  creating,
  loading,
  error,
  onCreate,
  onShipmentsChange,
}: TripCreatorProps) {
  const { t } = useTranslation("trips");
  const [selectedShipmentIds, setSelectedShipmentIds] = useState<string[]>([]);
  const shipmentsById = useMemo(
    () => keyBy(shipments, (shipment) => shipment._id),
    [shipments]
  );

  const [{ canDrop, isOver, item: draggedShipment }, shipmentDropRef] = useDrop(
    () => ({
      accept: "Shipment",
      drop: (item: ShipmentDataSummary) =>
        setSelectedShipmentIds(selectedShipmentIds.concat(item._id)),
      canDrop(shipment, monitor) {
        const selectedShipments = selectedShipmentIds.map(
          (id) => shipmentsById[id]
        );
        const groupingError = validateShipmentGrouping([
          ...selectedShipments,
          shipment,
        ]);
        if (groupingError) {
          return false;
        }
        return true;
      },
      collect(monitor) {
        return {
          canDrop: monitor.canDrop(),
          isOver: monitor.isOver(),
          item: monitor.getItem(),
        };
      },
    }),
    [selectedShipmentIds, shipmentsById]
  );

  const dragErrorMessage = useMemo(() => {
    if (!draggedShipment) {
      return null;
    }
    const selectedShipments = selectedShipmentIds.map(
      (id) => shipmentsById[id]
    );
    return validateShipmentGrouping([...selectedShipments, draggedShipment]);
  }, [draggedShipment, selectedShipmentIds, shipmentsById]);

  useEffect(() => {
    if (onShipmentsChange) {
      onShipmentsChange();
    }
  }, [onShipmentsChange, selectedShipmentIds]);

  return (
    <Box
      sx={{
        height: "100%",
      }}
    >
      <Grid container sx={{ height: "100%" }} columnSpacing={5}>
        <Grid
          item
          xs={12}
          md={8}
          sx={{
            height: "100%",
            overflow: "auto",
            position: "relative",
          }}
        >
          <LoadingOverlay loading={loading || false} />
          {!loading && shipments.length === 0 ? <EmptyState /> : null}
          {shipments.length > 0 ? (
            <ShipmentSummariesList
              shipments={shipments.filter(
                (shipment) => !selectedShipmentIds.includes(shipment._id)
              )}
            />
          ) : null}
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            height: "100%",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            fullWidth
            href="/trips/generate"
          >
            {t("suggest", "Suggest trips")}
          </Button>
          <Box
            ref={shipmentDropRef}
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "calc(100% - 60px)",
              position: "relative",
              pt: 3,
              mt: 3,
              borderStyle: "dashed",
              borderWidth: 1,
              borderRadius: 15,
              pl: 2,
              pr: 2,
            }}
            bgcolor={
              canDrop ? "honeyDew.main" : isOver ? "error.light" : "white"
            }
            data-testid="TripCreatorDropTarget"
          >
            {selectedShipmentIds.length === 0 ? (
              <Typography textAlign="center" variant="caption">
                {t("dropToCreate", "Drop shipments here to create a trip")}
              </Typography>
            ) : null}
            <Box
              sx={{
                height: "100%",
                overflow: "auto",
              }}
            >
              {error ? (
                <Alert severity="error">
                  {error.message || "An unknown error occurred"}
                </Alert>
              ) : null}
              {isOver && !canDrop ? (
                <Alert severity="warning">{dragErrorMessage}</Alert>
              ) : null}
              <ReorderableShipments
                shipments={selectedShipmentIds.map((id) => shipmentsById[id])}
                onChange={(reorderedShipments) =>
                  setSelectedShipmentIds(
                    reorderedShipments.map((shipment) => shipment._id)
                  )
                }
              />
            </Box>
            <Button
              variant="contained"
              color="secondary"
              disabled={!selectedShipmentIds.length || creating}
              onClick={() => {
                if (!selectedShipmentIds.length) {
                  return;
                }
                onCreate(selectedShipmentIds.map((id) => shipmentsById[id]));
              }}
              sx={{
                position: "absolute",
                bottom: 10,
                left: "calc(50% - 65px)",
                width: 130,
              }}
            >
              {capitalize(t("create", "Create trip"))}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
