import { useGetExtensionsQuery } from "../../../graphql/generated";
import ErrorMessage from "../../common/ErrorMessage/ErrorMessage";
import LoadingOverlay from "../../common/LoadingOverlay";
import ExtensionsManager from "./ExtensionsManager";

type ExtensionsManagerContainerProps = {
  onInstall?: () => void;
};

const ExtensionsManagerContainer = ({
  onInstall,
}: ExtensionsManagerContainerProps) => {
  const getExtensionsQuery = useGetExtensionsQuery();

  if (getExtensionsQuery.isLoading) {
    return <LoadingOverlay loading />;
  }

  if (getExtensionsQuery.error) {
    return <ErrorMessage error={getExtensionsQuery.error} />;
  }

  return (
    <ExtensionsManager
      extensions={getExtensionsQuery.data?.extensions.data || []}
      onInstalled={async () => {
        await getExtensionsQuery.refetch();
        onInstall?.();
      }}
      onUninstalled={async () => {
        await getExtensionsQuery.refetch();
      }}
    />
  );
};

export default ExtensionsManagerContainer;
