import { Container } from "@mui/material";
import BusinessEntityForm from "../../../components/asset-management/BusinessEntityForm";
import { BusinessEntityType } from "../../../graphql/generated";

function CreatePayto() {
  return (
    <Container>
      <BusinessEntityForm
        urlPrefix="/carriers"
        availableTypes={[BusinessEntityType.PayToProfile]}
        defaultType={BusinessEntityType.PayToProfile}
      />
    </Container>
  );
}

export default CreatePayto;
