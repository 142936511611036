import { Autocomplete, Box, Stack, TextField } from "@mui/material";
import {
  AssetLinkingInput,
  GetDriverListQuery,
  GetTractorListQuery,
  GetTrailerListQuery,
} from "../../../graphql/generated";
import driverLabel from "../../../utils/labels/driverLabel";
import tractorLabel from "../../../utils/labels/tractorLabel";
import trailerLabel from "../../../utils/labels/trailerLabel";
import LoadingOverlay from "../../common/LoadingOverlay";

export type AssetLinkingFormProps = {
  assetLinking: AssetLinkingInput | null;
  onChange: (assetLinking: AssetLinkingInput) => void;
  drivers: GetDriverListQuery["drivers"]["data"];
  trailers: GetTrailerListQuery["trailers"]["data"];
  tractors: GetTractorListQuery["tractors"]["data"];
  loading?: boolean;
  selectedDriverId?: string;
  selectedTrailerId?: string;
  selectedTractorId?: string;
};

const AssetLinkingForm = ({
  drivers,
  trailers,
  tractors,
  assetLinking,
  loading,
  selectedDriverId,
  selectedTrailerId,
  selectedTractorId,
  onChange,
}: AssetLinkingFormProps) => {
  const assetLinkingWithDefaults = {
    ...assetLinking,
    driver: selectedDriverId || assetLinking?.driver,
    trailer: selectedTrailerId || assetLinking?.trailer,
    tractor: selectedTractorId || assetLinking?.tractor,
  };

  return (
    <Box>
      <LoadingOverlay loading={loading || false} />
      <Stack
        direction="column"
        justifyContent="space-between"
        sx={{
          pt: 2,
        }}
        spacing={1}
      >
        <Autocomplete
          fullWidth
          options={drivers}
          getOptionLabel={(driver) => driverLabel(driver)}
          onChange={(e, driver) =>
            onChange({
              ...assetLinkingWithDefaults,
              driver: driver?._id || null,
            })
          }
          value={
            drivers.find(
              (driver) => driver._id === assetLinkingWithDefaults?.driver
            ) || null
          }
          size="small"
          renderInput={(inputProps) => (
            <TextField {...inputProps} label="Driver" />
          )}
          disabled={!!selectedDriverId}
        />
        <Autocomplete
          fullWidth
          options={tractors}
          getOptionLabel={(tractor) => tractorLabel(tractor)}
          onChange={(e, tractor) =>
            onChange({
              ...assetLinkingWithDefaults,
              tractor: tractor?._id || null,
            })
          }
          value={
            tractors.find(
              (tractor) => tractor._id === assetLinkingWithDefaults?.tractor
            ) || null
          }
          size="small"
          renderInput={(inputProps) => (
            <TextField {...inputProps} label="Tractor" />
          )}
          disabled={!!selectedTractorId}
        />
        <Autocomplete
          fullWidth
          options={trailers}
          getOptionLabel={(trailer) => trailerLabel(trailer)}
          onChange={(e, trailer) =>
            onChange({
              ...assetLinkingWithDefaults,
              trailer: trailer?._id || null,
            })
          }
          value={
            trailers.find(
              (trailer) => trailer._id === assetLinkingWithDefaults?.trailer
            ) || null
          }
          size="small"
          renderInput={(inputProps) => (
            <TextField {...inputProps} label="Trailer" />
          )}
          disabled={!!selectedTrailerId}
        />

        <Autocomplete
          fullWidth
          options={trailers}
          multiple={true}
          getOptionLabel={(trailer) => trailerLabel(trailer)}
          onChange={(e, trailers) =>
            onChange({
              ...assetLinkingWithDefaults,
              additionalTrailers: trailers.map((trailer) => trailer._id),
            })
          }
          value={
            trailers.filter((trailer) =>
              assetLinkingWithDefaults.additionalTrailers?.includes(trailer._id)
            ) || null
          }
          size="small"
          renderInput={(inputProps) => (
            <TextField {...inputProps} label="Pup Trailers" />
          )}
          getOptionDisabled={(trailer) =>
            assetLinkingWithDefaults?.trailer === trailer._id
          }
          // disabled={!!selectedTrailerId}
        />
      </Stack>
    </Box>
  );
};

export default AssetLinkingForm;
