import { Box, Link, Typography } from "@mui/material";
import { flatten, keyBy } from "lodash";
import { Trip } from "../../../graphql/generated";
import { ShipmentLocationDetails } from "../../shipment/ShipmentSummary/ShipmentSummary";
import { TripSummaryData } from "../../trip-planning/TripPlanner/TripsGenerator";
import TripTimeline from "../../trip-planning/TripTimeline";
import {
  getTripCurrentLocation,
  getTripNextLocation,
} from "../../trip-planning/TripTimeline/TripTimeline";

type TripLiveSummaryProps = {
  trip: TripSummaryData & {
    tripNumber?: Trip["tripNumber"];
  };
  isSummarizedView?: boolean;
  hideTripNumber?: boolean;
};

const TripLiveSummary = ({
  trip,
  isSummarizedView,
  hideTripNumber,
}: TripLiveSummaryProps) => {
  const allShippedGoodsById = trip
    ? keyBy(
        flatten(
          trip.shipmentLocations.map((location) => location.shippedGoods || [])
        ),
        "_id"
      )
    : {};
  const currentTripLocation = trip ? getTripCurrentLocation(trip) : null;
  const nextTripLocation = trip ? getTripNextLocation(trip) : null;
  const locationToDisplay =
    isSummarizedView && trip ? currentTripLocation || nextTripLocation : null;
  return (
    <Box>
      {trip ? (
        <Box>
          {!hideTripNumber ? (
            <Box sx={{ textAlign: "center" }}>
              <Link href={`/trips/${trip._id}`} target="_blank">
                Trip {trip.tripNumber}
              </Link>
            </Box>
          ) : null}
          {isSummarizedView && locationToDisplay ? (
            <Typography
              variant="subtitle2"
              textAlign="center"
              color="primary"
              sx={{
                mt: 1,
                mb: 1,
              }}
            >
              {locationToDisplay === currentTripLocation
                ? "Current Stop"
                : "Next Stop"}
            </Typography>
          ) : null}
          {!isSummarizedView ? (
            <TripTimeline trip={trip} horizontal showProgress />
          ) : locationToDisplay ? (
            <ShipmentLocationDetails
              shipmentLocation={locationToDisplay}
              allShippedGoodsById={allShippedGoodsById}
              centered
            />
          ) : null}
        </Box>
      ) : null}
    </Box>
  );
};

export default TripLiveSummary;
