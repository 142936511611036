import {
  GoogleMap,
  GoogleMapProps,
  useJsApiLoader,
} from "@react-google-maps/api";
import { CSSProperties } from "react";
import mapStyles from "./mapStyles";
import { GOOGLE_MAPS_API_KEY } from "../../../config/keys";

const containerStyle = {
  width: "100%",
  height: "400px",
};

function Map(
  props: GoogleMapProps & {
    containerStyle?: CSSProperties;
  }
) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: ["geometry", "places"],
  });

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{ ...containerStyle, ...props.containerStyle }}
      zoom={10}
      center={{
        lat: 39.90544474495378,
        lng: -82.91304185748207,
      }}
      options={{
        styles: mapStyles,
      }}
      {...props}
    />
  ) : null;
}

export default Map;
