import { useCallback } from "react";
import { useParams } from "react-router-dom";
import HeaderTitle from "../../components/common/HeaderTitle";
import { useNavigationOptions } from "../LayoutRoutes";
import DriverSettlementDetailsContainer from "../../components/accounting/DriverSettlementDetails";
import { DriverSettlement } from "../../graphql/generated";
import { useTranslation } from "react-i18next";

function ViewSettlement() {
  const { id } = useParams();
  const { t } = useTranslation("common");
  const { setNavigationOptions } = useNavigationOptions();
  const onLoad = useCallback(
    (businessEntity: Pick<DriverSettlement, "driverSettlementNumber">) => {
      setNavigationOptions({
        title: (
          <HeaderTitle
            label={t("settlement", "Settlement")}
            suffix={businessEntity?.driverSettlementNumber || ""}
          />
        ),
        showBackIcon: true,
      });
    },
    [setNavigationOptions, t]
  );
  if (!id) {
    return null;
  }
  return (
    <DriverSettlementDetailsContainer driverSettlementId={id} onLoad={onLoad} />
  );
}

export default ViewSettlement;
