import {
  CustomFieldContext,
  useGetCustomFieldDefinitionsQuery,
} from "../../../graphql/generated";
import CustomFieldsForm, { CustomFieldsFormProps } from "./CustomFieldsForm";

type CustomFieldsFormContainerProps = Omit<
  CustomFieldsFormProps,
  "customFieldDefinitions"
> & {
  context: CustomFieldContext;
};

const CustomFieldsFormContainer = ({
  context,
  ...props
}: CustomFieldsFormContainerProps) => {
  const getCustomFieldDefinitionsQuery = useGetCustomFieldDefinitionsQuery();

  const contextCustomFields =
    getCustomFieldDefinitionsQuery.data?.cutomFieldDefinitions.data.filter(
      (customFieldDefinition) => customFieldDefinition.context.includes(context)
    );

  return (
    <CustomFieldsForm
      customFieldDefinitions={contextCustomFields || []}
      context={context}
      {...props}
    />
  );
};

export default CustomFieldsFormContainer;
