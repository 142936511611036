import { parsePhoneNumber } from "libphonenumber-js";

export default function formatPhoneNumber(
  phoneNumber: string | null | undefined
) {
  if (!phoneNumber) {
    return "N/A";
  }
  try {
    return parsePhoneNumber(phoneNumber || "", "US")?.format("NATIONAL");
  } catch (e) {
    console.warn(e);
    return phoneNumber;
  }
}
