// import {
//   useBulkAddBusinessEntitiesMutation,
//   useGetBusinessEntityListQuery,
// } from "../../../graphql/generated";
import { useParams } from "react-router-dom";
import {
  useDeleteSupplierContractMutation,
  useSupplierContractsQuery,
} from "../../../../graphql/generated";
import SupplierContractsList from "./SupplierContractsList";

export default function SupplierContractsListContainer() {
  // const bulkCreateBusinessEntities = useBulkAddBusinessEntitiesMutation();
  const { id } = useParams();
  return (
    <SupplierContractsList
      onBulkCreate={(supplierContracts) => {
        // bulkCreateBusinessEntities.mutateAsync({
        //   newBusinessEntityData: supplierContracts,
        // });
        // TODO: to be removed
        return Promise.resolve();
      }}
      deleteMutation={useDeleteSupplierContractMutation}
      query={useSupplierContractsQuery}
      supplierId={id || ""}
    />
  );
}
