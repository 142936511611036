import { Alert } from "@mui/material";
import { omit } from "lodash";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";
import {
  BusinessEntityType,
  GetDriverShiftDetailsQuery,
  useAddDriverShiftMutation,
  useEditDriverShiftMutation,
  useGetBusinessEntityListQuery,
  useGetDriverListQuery,
  useGetDriverShiftDetailsQuery,
} from "../../../graphql/generated";
import { showDialog } from "../../../redux/slices/alert/Alert.slice";
import LoadingOverlay from "../../common/LoadingOverlay";
import DriverShiftForm from "./DriverShiftForm";
import { useTranslation } from "react-i18next";

type DriverShiftFormContainerProps = {
  driverShiftId?: string;
  onLoad?: (driverShift: GetDriverShiftDetailsQuery["driverShiftById"]) => void;
  onSaved?: (
    driverShift: GetDriverShiftDetailsQuery["driverShiftById"]
  ) => void;
};

function DriverShiftFormContainer({
  driverShiftId,
  onLoad,
  onSaved,
}: DriverShiftFormContainerProps) {
  const { t } = useTranslation(["users", "common"]);
  const addDriverShiftMutation = useAddDriverShiftMutation();
  const editDriverShiftMutation = useEditDriverShiftMutation();
  const initialDriverShiftId = useRef(driverShiftId);

  const driversQuery = useGetDriverListQuery({});
  const domicilesQuery = useGetBusinessEntityListQuery({
    businessEntityTypes: [BusinessEntityType.Domicile],
  });

  // We call this hook conditionally because the component
  // should never rerender with a different value for
  // businessEntityId, but if it does we don't update
  // the ref anyway so this is safe
  const getDriverShiftQuery = initialDriverShiftId.current
    ? // eslint-disable-next-line react-hooks/rules-of-hooks
      useGetDriverShiftDetailsQuery(
        {
          id: initialDriverShiftId.current,
        },
        {
          refetchOnWindowFocus: false,
          retry: false,
        }
      )
    : null;
  // const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (getDriverShiftQuery?.data?.driverShiftById) {
      onLoad?.(getDriverShiftQuery.data?.driverShiftById);
    }
  }, [onLoad, getDriverShiftQuery?.data]);

  if (driverShiftId) {
    if (getDriverShiftQuery?.isLoading) {
      return <LoadingOverlay loading />;
    }
    if (getDriverShiftQuery?.error) {
      return (
        <Alert severity="error">
          {t(
            "error.fetchDriverShiftError",
            "An error occurred while fetching the driver shift"
          )}
          .{" "}
          {(getDriverShiftQuery?.error as Error).message ||
            t("common:error.unknownError", "Unknown error")}
        </Alert>
      );
    }
  }

  return (
    <DriverShiftForm
      initialDriverShift={getDriverShiftQuery?.data?.driverShiftById}
      saving={
        addDriverShiftMutation.isLoading || editDriverShiftMutation.isLoading
      }
      drivers={driversQuery.data?.drivers.data || []}
      domiciles={domicilesQuery.data?.businessEntities.data || []}
      onSave={async (driverShift) => {
        try {
          if (driverShiftId) {
            const result = await editDriverShiftMutation.mutateAsync({
              id: driverShiftId,
              editDriverShiftData: omit(driverShift, "_id"),
            });
            onSaved?.({ ...driverShift, _id: result.editDriverShift._id });
            // navigate(`/driverShifts/details/${result.editDriverShift._id}`);
          } else {
            const result = await addDriverShiftMutation.mutateAsync({
              newDriverShiftData: driverShift,
            });
            onSaved?.({ ...driverShift, _id: result.addDriverShift._id });
            // navigate(`/driverShifts/details/${result.addDriverShift._id}`);
            // window.analytics?.track("DriverShift Created", {
            //   driverShiftId: result.addDriverShift._id,
            // });
            // window.analytics?.identify({
            //   driverShiftCreated: true,
            //   lastDriverShiftCreationDate: new Date(),
            //   lastDriverShiftCreationDateOnly: new Date()
            //     .toISOString()
            //     .split("T")[0],
            //   numberOfDriverShiftsCreated:
            //     (window.analytics?.user?.()?.traits?.()?.numberOfDriverShiftsCreated ||
            //       0) + 1,
            // });
            // window.analytics?.group(window.analytics?.group?.()?.id?.(), {
            //   driverShiftCreated: true,
            //   lastDriverShiftCreationDate: new Date(),
            //   lastDriverShiftCreationDateOnly: new Date()
            //     .toISOString()
            //     .split("T")[0],
            //   numberOfDriverShiftsCreated:
            //     (window.analytics?.group?.()?.traits?.()?.numberOfDriverShiftsCreated ||
            //       0) + 1,
            // });
          }
        } catch (error) {
          dispatch(
            showDialog({
              title: t("common:error.title", "Error"),
              description:
                t("error.createEditDriverShift", {
                  action: driverShiftId
                    ? t("common:editing", "editing")
                    : t("common:creating", "creating"),
                  defaultValue: `An error occurred while ${
                    driverShiftId ? "editing" : "creating"
                  } the driver shift. `,
                }) + (error as Error).message,
              type: "error",
            })
          );
        } finally {
          getDriverShiftQuery?.refetch();
        }
      }}
    />
  );
}

export default DriverShiftFormContainer;
