import { uniqBy } from "lodash";
import {
  BusinessEntityType,
  useGetBrokersQuery,
  useGetBusinessEntityListQuery,
  useGetCustomersQuery,
  useGetPayToProfilesQuery,
  useGetReceiversQuery,
  useGetShippersQuery,
} from "../../../graphql/generated";
import BusinessEntitySelect, {
  BusinessEntitySelectProps,
} from "./BusinessEntitySelect";

type BusinessEntitySelectContainerProps = Omit<
  BusinessEntitySelectProps,
  "businessEntities" | "onBusinessEntitiesRefetch" | "createBusinessEntity"
>;

const BusinessEntitySelectContainer = (
  props: BusinessEntitySelectContainerProps
) => {
  const shippersQuery = useGetShippersQuery();
  const receiversQuery = useGetReceiversQuery();
  const customersQuery = useGetCustomersQuery();
  const brokersQuery = useGetBrokersQuery();
  const suppliersQuery = useGetBusinessEntityListQuery({
    businessEntityTypes: [BusinessEntityType.Supplier],
  });
  const payToProfilesQuery = useGetPayToProfilesQuery();

  const shippers = shippersQuery.data?.shippers.data || [];
  const receivers = receiversQuery.data?.receivers.data || [];
  const customers = customersQuery.data?.customers.data || [];
  const brokers = brokersQuery.data?.brokers.data || [];
  const payToProfiles = payToProfilesQuery.data?.businessEntities.data || [];
  const suppliers = suppliersQuery.data?.businessEntities.data || [];

  return (
    <BusinessEntitySelect
      {...props}
      businessEntities={
        props.businessEntityType === BusinessEntityType.Shipper
          ? shippers
          : props.businessEntityType === BusinessEntityType.Receiver
          ? receivers
          : props.businessEntityType === BusinessEntityType.Customer
          ? uniqBy([...customers, ...brokers], "_id")
          : props.businessEntityType === BusinessEntityType.Broker
          ? brokers
          : props.businessEntityType === BusinessEntityType.PayToProfile
          ? payToProfiles
          : props.businessEntityType === BusinessEntityType.Supplier
          ? suppliers
          : []
      }
      onBusinessEntitiesRefetch={async () => {
        await Promise.all([
          shippersQuery.refetch(),
          receiversQuery.refetch(),
          customersQuery.refetch(),
          brokersQuery.refetch(),
          payToProfilesQuery.refetch(),
          suppliersQuery.refetch(),
        ]);
      }}
    />
  );
};

export default BusinessEntitySelectContainer;
