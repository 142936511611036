import { Link } from "@mui/material";
import { Trip } from "../../../graphql/generated";
import { useTranslation } from "react-i18next";

type TripLinkProps = {
  trip: Pick<Trip, "_id" | "tripNumber">;
};

const TripLink = ({ trip }: TripLinkProps) => {
  const { t } = useTranslation("trips");
  return (
    <Link
      onClick={(e) => e.stopPropagation()}
      href={`/trips/details/${trip._id}`}
      sx={{
        textTransform: "capitalize",
      }}
    >
      {t("trip")} {trip.tripNumber}
    </Link>
  );
};

export default TripLink;
