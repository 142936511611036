import { useCallback, useEffect, useState } from "react";
import { Box, Button, Grid, TextField } from "@mui/material";
import ErrorMessage from "../../common/ErrorMessage/ErrorMessage";
import { ValidationResult } from "joi";
import {
  NewGroupInput,
  GetGroupDetailsQuery,
} from "../../../graphql/generated";
import { isArray, mergeWith, omit } from "lodash";
import groupSchema from "./groupSchema";
import useConfirmBeforeLeave from "../../../utils/hooks/useConfirmBeforeLeave";
import { FormDivider } from "../BusinessEntityForm/BusinessEntityForm";
import { useTranslation } from "react-i18next";
import UserSelectContainer from "../../account/UserSelect";

type Group = GetGroupDetailsQuery["groupById"];

type GroupFormProps = {
  initialGroup?: Group;
  saving: boolean;
  onSave: (group: NewGroupInput) => void;
};
type PartialGroup = Partial<Group>;
type DeepPartialGroup = {
  [key in keyof PartialGroup]: Partial<PartialGroup[key]>;
};

const GroupForm = ({ initialGroup, saving, onSave }: GroupFormProps) => {
  const { t } = useTranslation(["users", "common"]);
  const [validationResult, setValidationResult] =
    useState<ValidationResult<Group> | null>(null);
  const [localGroup, setLocalGroup] = useState<Partial<NewGroupInput>>(
    initialGroup || {
      name: "",
      memberIds: [],
    }
  );

  const getFieldError = (field: string, partialPathMatch = false) =>
    validationResult?.error?.details.find((error) =>
      partialPathMatch
        ? error.path.join(".").startsWith(field)
        : error.path.join(".") === field
    )?.message;

  const onChange = useCallback((changes: DeepPartialGroup) => {
    setLocalGroup((localGroup) =>
      mergeWith({}, localGroup, changes, (objValue, srcValue) => {
        if (isArray(srcValue)) {
          return srcValue;
        }
      })
    );
  }, []);

  const validate = () => {
    const validationResult = groupSchema.validate(omit(localGroup, "_id"), {
      abortEarly: false,
    });
    setValidationResult(validationResult);
    return !validationResult.error;
  };

  useEffect(() => {
    if (validationResult) {
      validate();
    }
    // We don't want to run everytime validationResult changes
    // otherwise we ill have an infinite update loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localGroup]);

  const { cancelConfirm } = useConfirmBeforeLeave(localGroup);

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item sm={12}>
          <FormDivider variant="fullWidth" text={t("users:groupDetails","Group Details")} />
          <ErrorMessage message={t("errorUser",`${validationResult?.error?.message}`)} />
          <Grid container spacing={3}>
            <Grid item sm={12}>
              <TextField
                label={t("common:name")}
                fullWidth
                required
                value={localGroup.name || ""}
                error={!!getFieldError("name")}
                helperText={t("errorUser",`${getFieldError("name")}`)}
                onChange={(event) => {
                  onChange({ name: event.target.value });
                }}
                name="group-name"
              />
            </Grid>

            <Grid item sm={12}>
              <UserSelectContainer
                value={localGroup.memberIds || []}
                onChange={(value) => {
                  onChange({ memberIds: value });
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row-reverse",
          pt: 3,
        }}
      >
        <Button
          variant="contained"
          disabled={saving || !!validationResult?.error}
          size="large"
          onClick={() => {
            if (!localGroup) {
              return;
            }
            if (validate()) {
              cancelConfirm();
              onSave(localGroup as NewGroupInput);
            }
          }}
          id="saveGroupButton"
        >
          {t("common:save", "Save")}
        </Button>
      </Box>
    </Box>
  );
};

export default GroupForm;
