import { useState } from "react";
import { Stack } from "@mui/material";
import {
  GoodDistributionInput,
  ShipmentLocationType,
} from "../../../../graphql/generated";
import {
  FormError,
  ShipmentLocationInputData,
} from "../../../../redux/slices/Types";
import ShipperGoodsForm from "../ShipperGoodsForm";
import ShipmentLocationForm from "../ShipmentLocationForm";

export type ShipperFormProps = {
  shipmentLocation: ShipmentLocationInputData;
  shipperId: string;
  onChange: (shipper: ShipmentLocationInputData) => void;
  errors?: FormError;
  customer: string;
  allReceivedGoods?: Array<GoodDistributionInput>;
  noTimeWindows?: boolean;
  allowAddressLocation?: boolean;
};

export default function ShipperForm({
  shipmentLocation,
  errors,
  onChange,
  customer,
  allReceivedGoods,
  noTimeWindows,
  allowAddressLocation = true,
}: ShipperFormProps) {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const handleOpen = () => setOpenModal(true);

  const closeGoodsModal = () => {
    setOpenModal(false);
  };

  return (
    <Stack spacing={5}>
      <ShipmentLocationForm
        locationType={ShipmentLocationType.Pickup}
        shippedGoods={shipmentLocation?.shippedGoods || []}
        data={shipmentLocation}
        onChange={onChange}
        onGoodsOpen={handleOpen}
        errors={errors}
        noTimeWindows={noTimeWindows}
        allowAddressLocation={allowAddressLocation}
      />
      <ShipperGoodsForm
        customer={customer}
        shipper={shipmentLocation.shipper || null}
        width="md"
        openModal={openModal}
        onClose={closeGoodsModal}
        shippedGoods={shipmentLocation?.shippedGoods}
        shipmentLocation={shipmentLocation}
        allReceivedGoods={allReceivedGoods}
      />
    </Stack>
  );
}
