import { Container } from "@mui/material";
import TractorForm from "../../../components/asset-management/TractorForm";

function CreateTractor() {
  return (
    <Container>
      <TractorForm />
    </Container>
  );
}

export default CreateTractor;
