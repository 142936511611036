import { Container } from "@mui/material";
import BusinessEntityForm from "../../../components/asset-management/BusinessEntityForm";
import { BusinessEntityType } from "../../../graphql/generated";

function CreateCustomer() {
  return (
    <Container>
      <BusinessEntityForm
        defaultType={BusinessEntityType.Customer}
        availableTypes={[
          BusinessEntityType.Customer,
          BusinessEntityType.Broker,
          BusinessEntityType.Shipper,
          BusinessEntityType.Receiver,
        ]}
        urlPrefix="/customers"
      />
    </Container>
  );
}

export default CreateCustomer;
