import { GoodProfile } from "../../graphql/generated";

function commodityLabel(
  commodity: Pick<GoodProfile, "code" | "label"> | null | undefined
) {
  if (!commodity) {
    return "N/A";
  }

  const { code, label } = commodity;
  return `(${code}) ${label}`;
}

export default commodityLabel;
