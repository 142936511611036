import { omit } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { toShipmentInputData } from "../../components/shipment/EditShipmentForm";
import ShipmentForm from "../../components/shipment/ShipmentForm";
import {
  GetShipmentQuery,
  ShipmentCommodityType,
  TrailerType,
  useCreateShipmentMutation,
  useGetLoadBoardLoadDetailsQuery,
  useGetOrderDetailsQuery,
  useGetShipmentQuery,
} from "../../graphql/generated";
import { showDialog } from "../../redux/slices/alert/Alert.slice";
import {
  editShipment,
  resetShipmentForm,
} from "../../redux/slices/shipment/ShipmentForm.slice";
import LoadingOverlay from "../../components/common/LoadingOverlay";
import { RootState } from "../../redux/store";
import { v4 as uuid } from "uuid";
import { ShipmentLocationInputData } from "../../redux/slices/Types";
import isLiquidUnit from "../../utils/liquid/isLiquidUnit";
import { useTranslation } from "react-i18next";

export default function CreateShipment() {
  const { t } = useTranslation(["orders", "common"]);
  const mutation = useCreateShipmentMutation();
  const [isUploadingDocuments, setIsUploadingDocuments] = useState(false);
  const navigate = useNavigate();
  const navigation = useLocation();
  const [searchParams] = useSearchParams();
  const duplicatedShipmentId =
    searchParams.get("duplicatedShipmentId") || navigation.state?.shipment;
  const shipmentQuery = useGetShipmentQuery(
    { id: duplicatedShipmentId || "" },
    {
      refetchOnWindowFocus: false,
      enabled: !!duplicatedShipmentId,
    }
  );
  const [params] = useSearchParams();
  const orderId = params.get("orderId");
  const orderQuery = useGetOrderDetailsQuery(
    { id: orderId || "" },
    {
      refetchOnWindowFocus: false,
      enabled: !!orderId,
    }
  );
  const loadBoardShipmentQuery = useGetLoadBoardLoadDetailsQuery(
    {
      loadId: navigation.state?.loadBoardShipmentId || "",
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  const shipment: GetShipmentQuery["shipmentById"] | undefined =
    shipmentQuery.data?.shipmentById ||
    loadBoardShipmentQuery.data?.loadBoardLoadById ||
    undefined;
  const stateShipment = useSelector(
    (state: RootState) => state.shipmentReducer.shipment
  );
  const [hasReset, setHasReset] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetShipmentForm());
    setHasReset(true);
    if (shipment) {
      dispatch(
        editShipment({
          ...omit(
            toShipmentInputData({
              ...shipment,
              shipmentNumber: "",
              documents: [],
              charges: navigation.state?.loadBoardShipmentId
                ? shipment.charges
                : [],
            }),
            "_id",
            "shipmentNumber",
            "status",
            "tripId",
            "reasonForCancellation"
          ),
          customer:
            (navigation.state?.customerId as string | undefined) ||
            shipment.customer._id,
        })
      );
    }
  }, [
    shipment,
    dispatch,
    navigation.state?.customerId,
    navigation.state?.loadBoardShipmentId,
  ]);

  const order = orderQuery.data?.orderById;
  useEffect(() => {
    if (order) {
      const isLiquid = order.locations.some((location) =>
        location.receivedGoods?.some(
          (good) =>
            good.goodProfile?.unit && isLiquidUnit(good.goodProfile.unit)
        )
      );
      const shipperLocation: ShipmentLocationInputData = {
        ...stateShipment.shipmentLocations[0],
        shippedGoods: (order.locations[0].receivedGoods || []).map(
          (receivedGood) => ({
            _id: receivedGood.goodId,
            goodProfileId: receivedGood.goodProfile?._id,
            quantity: receivedGood.quantity || 1,
            unit: receivedGood.goodProfile?.unit,
            label: receivedGood.goodProfile?.label || "",
            weight: receivedGood.goodProfile?.weight,
          })
        ),
      };
      dispatch(
        editShipment({
          ...stateShipment,
          customer: order.customerId,
          referenceNumbers: [order.orderNumber],
          orderId: order._id,
          billOfLadingNumbers: [""],
          purchaseOrderNumbers: [""],
          shipmentLocations: [shipperLocation].concat(
            order.locations.map((location) => {
              return {
                ...location,
                _id: location._id || uuid(),
                receivedGoods: (location.receivedGoods || []).map(
                  (receivedGood) => omit(receivedGood, "goodProfile")
                ),
                receiver: location.receiver?._id,
                shippedGoods: [],
                shipper: null,
              };
            })
          ),
          commodityType: isLiquid
            ? ShipmentCommodityType.Liquid
            : ShipmentCommodityType.Dry,
          trailerType: isLiquid
            ? TrailerType.Tanker
            : stateShipment.trailerType,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  if (!hasReset) {
    return <LoadingOverlay loading />;
  }
  if (
    navigation.state?.shipment &&
    (shipmentQuery.isLoading || !stateShipment.id)
  ) {
    return <LoadingOverlay loading />;
  }

  if (
    navigation.state?.loadBoardShipmentId &&
    (loadBoardShipmentQuery.isLoading || !stateShipment.id)
  ) {
    return <LoadingOverlay loading />;
  }

  return (
    <ShipmentForm
      loading={isUploadingDocuments || mutation.isLoading}
      onSubmit={async (_shipment) => {
        try {
          if (!_shipment) {
            return;
          }
          // if (newDocumentsToUpload) {
          //   setIsUploadingDocuments(true);
          //   await Promise.all(
          //     newDocumentsToUpload.map(async (d) => {
          //       await fetch(d.url, {
          //         method: "PUT",
          //         body: d.file,
          //       });
          //     })
          //   );
          //   setIsUploadingDocuments(false);
          // }
          const createShipmentResponse = await mutation.mutateAsync({
            shipment: _shipment,
          });
          dispatch(resetShipmentForm());
          navigate(`/orders/details/${createShipmentResponse.addShipment._id}`);
          window.analytics?.track("Load Created", {
            loadId: createShipmentResponse.addShipment._id,
          });
          window.analytics?.identify({
            loadCreated: true,
            lastLoadCreationDate: new Date(),
            lastLoadCreationDateOnly: new Date().toISOString().split("T")[0],
            numberOfLoadsCreated:
              (window.analytics?.user?.()?.traits?.()?.numberOfLoadsCreated ||
                0) + 1,
          });
          window.analytics?.group(window.analytics?.group?.()?.id?.(), {
            loadCreated: true,
            lastLoadCreationDate: new Date(),
            lastLoadCreationDateOnly: new Date().toISOString().split("T")[0],
            numberOfLoadsCreated:
              (window.analytics?.group?.()?.traits?.()?.numberOfLoadsCreated ||
                0) + 1,
          });
        } catch (error) {
          dispatch(
            showDialog({
              type: "error",
              title: t(
                "error.createShipmentError",
                "Error while creating shipment"
              ),
              description:
                (error as Error).message ||
                t("common:error.unknownError", "Unknown error"),
            })
          );
        } finally {
          setIsUploadingDocuments(false);
        }
      }}
    />
  );
}
