import {
  useDeleteRelationshipMutation,
  // useBulkAddRelationshipsMutation,
  useGetRelationshipListQuery,
} from "../../../graphql/generated";
import RelationshipsList from "./RelationshipsList";

export default function RelationshipsListContainer() {
  // const bulkCreateRelationships = useBulkAddRelationshipsMutation();
  let refetch = () => {};
  // I need a handle on refetch so I can call it
  // a new settlement has been generated
  const useGetRelationshipListQueryCustom: typeof useGetRelationshipListQuery =
    (variables, options) => {
      const query = useGetRelationshipListQuery(variables, {
        ...options,
      });
      refetch = query.refetch;
      return query;
    };
  useGetRelationshipListQueryCustom.fetcher =
    useGetRelationshipListQuery.fetcher;
  useGetRelationshipListQueryCustom.getKey = useGetRelationshipListQuery.getKey;

  return (
    <RelationshipsList
      // onBulkCreate={(relationships) =>
      //   bulkCreateRelationships.mutateAsync({ newRelationshipsData: relationships })
      // }
      query={useGetRelationshipListQueryCustom}
      deleteMutation={useDeleteRelationshipMutation}
      onRefresh={() => {
        refetch();
      }}
    />
  );
}
