import { Stack } from "@mui/material";
import {
  DriverLicenseEndorsement,
  GoodProfileRestriction,
  GoodProfileRestrictionType,
} from "../../../graphql/generated";
import EnumSelect from "../../common/EnumSelect";
import { castArray } from "lodash";

export type GoodProfileRestrictionFormProps = {
  restriction: GoodProfileRestriction;
  onChange: (restriction: GoodProfileRestriction) => void;
};

const GoodProfileRestrictionForm = ({
  restriction,
  onChange,
}: GoodProfileRestrictionFormProps) => {
  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      sx={{
        width: "100%",
      }}
    >
      <EnumSelect
        enumObject={GoodProfileRestrictionType}
        value={restriction.type}
        onChange={(event, value) => {
          if (!value) {
            return;
          }
          onChange({
            ...restriction,
            type: value,
            value: null,
          });
        }}
        sx={{
          flex: 1,
        }}
      />
      {restriction.type === GoodProfileRestrictionType.DriverEndorsement && (
        <EnumSelect
          enumObject={DriverLicenseEndorsement}
          value={
            castArray(restriction.value || []) as DriverLicenseEndorsement[]
          }
          multiple
          onChange={(event, value) => {
            onChange({
              ...restriction,
              value: value as DriverLicenseEndorsement[],
            });
          }}
          sx={{
            flex: 1,
          }}
        />
      )}
    </Stack>
  );
};

export default GoodProfileRestrictionForm;
