import { useTranslation } from "react-i18next";
import {
  GenerateTripsForShipmentsMutation,
  useGenerateTripsForShipmentsMutation,
  useGetShipmentListForAutoPlanQuery,
} from "../../../../graphql/generated";
import useDialog from "../../../../utils/hooks/useDialog";
import AutoPlanModal, { AutoPlanModalProps } from "./AutoPlanModal";
import { useConfirm } from "material-ui-confirm";
import { Alert, Stack } from "@mui/material";
import ShipmentLink from "../../../common/ShipmentLink";
import { Warning } from "@mui/icons-material";

type AutoPlanModalContainerProps = Omit<
  AutoPlanModalProps,
  "onPlan" | "shipments"
> & {
  onPlanned: (
    result: GenerateTripsForShipmentsMutation["generateTripsForShipments"]
  ) => void;
};

const AutoPlanModalContainer = ({ ...props }: AutoPlanModalContainerProps) => {
  const generateTripsForShipmentsMutation =
    useGenerateTripsForShipmentsMutation();
  const { showDialog } = useDialog();
  const confirm = useConfirm();
  const { t } = useTranslation("planning");
  const shipmentsForAutoplanQuery = useGetShipmentListForAutoPlanQuery();

  const shipments = shipmentsForAutoplanQuery.data?.shipments.data || [];

  const onPlan = async (
    startDate: Date,
    endDate: Date,
    shipmentIds: string[]
  ) => {
    try {
      const result = await generateTripsForShipmentsMutation.mutateAsync({
        shipmentIds,
        startDate,
        endDate,
      });

      const unplannedShipments =
        result.generateTripsForShipments.unplannedShipments;
      if (unplannedShipments.length) {
        try {
          await confirm({
            title: t("autoPlanModal.unplannedShipments"),
            description: (
              <Stack spacing={2}>
                <Alert severity="warning">
                  {t("autoPlanModal.unplannedShipmentsDescription")}
                </Alert>
                {unplannedShipments.map((shipment) => (
                  <ShipmentLink key={shipment._id} shipment={shipment} />
                ))}
              </Stack>
            ),
            confirmationText: t("autoPlanModal.unplannedShipmentsConfirm"),
            cancellationText: t("autoPlanModal.cancel"),
            confirmationButtonProps: {
              color: "warning",
              startIcon: <Warning />,
              variant: "contained",
            },
          });
        } catch (error) {
          if (!error) {
            return;
          }
          throw error;
        }
      }

      props.onPlanned(result.generateTripsForShipments);
    } catch (error) {
      console.error(error);
      showDialog({
        type: "error",
        title: t("autoPlanModal.error"),
        description: `${t("autoPlanModal.errorDescription")} ${
          (error as Error).message
        }`,
      });
    }
  };

  return <AutoPlanModal {...props} shipments={shipments} onPlan={onPlan} />;
};

export default AutoPlanModalContainer;
