import { useSnackbar } from "notistack";
import { useAddShipmentEventMutation } from "../../../graphql/generated";
import useDialog from "../../../utils/hooks/useDialog";
import TripTimeline, { TripTimelineProps } from "./TripTimeline";
import { useTranslation } from "react-i18next";

type TripTimelineContainerProps = Omit<TripTimelineProps, "onShipmentEventLog">;

const TripTimelineContainer = (props: TripTimelineContainerProps) => {
  const { t } = useTranslation(["trips", "common"]);
  const addShipmentEventMutation = useAddShipmentEventMutation();

  const { enqueueSnackbar } = useSnackbar();
  const { showDialog } = useDialog();

  return (
    <TripTimeline
      onShipmentEventLog={async (eventData) => {
        try {
          if (addShipmentEventMutation.isLoading) {
            return;
          }
          await addShipmentEventMutation.mutateAsync({
            shipmentEventData: eventData,
          });
          enqueueSnackbar(t("event.logged", "Event logged"));
        } catch (error) {
          showDialog({
            title: t("error.logEventError", "Error logging event"),
            type: "error",
            description:
              (error as Error | undefined)?.message ||
              t("common:error.unknownError", "Unknown error"),
          });
        }
      }}
      {...props}
    />
  );
};

export default TripTimelineContainer;
