import {
  DragAndDrop,
  Inject,
  Resize,
  ResourceDirective,
  ResourcesDirective,
  ScheduleComponent,
  TimeScaleModel,
  TimelineMonth,
  TimelineViews,
  View,
  ViewDirective,
  ViewsDirective,
} from "@syncfusion/ej2-react-schedule";
import { GetAllDriversAndShiftsQuery } from "../../../graphql/generated";
import driverLabel from "../../../utils/labels/driverLabel";
import { useTheme } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

export type DriverShiftsCalendarProps = {
  drivers: GetAllDriversAndShiftsQuery["drivers"]["data"];
  driverShifts: GetAllDriversAndShiftsQuery["driverShifts"]["data"];
};

const DriverShiftsCalendar: React.FC<DriverShiftsCalendarProps> = (
  props: DriverShiftsCalendarProps
) => {
  const { i18n, t } = useTranslation("planning");
  const theme = useTheme();

  const scheduleObjRef = useRef<ScheduleComponent>(null);
  const [currentViewDates, setCurrentViewDates] = useState<Date[]>([]);

  const driverResources = props.drivers.map((driver) => {
    return {
      id: driver._id,
      label: driverLabel(driver),
      color: theme.palette.primary.main,
    };
  });

  const [currentView, setCurrentView] = useState<View>("TimelineDay");

  type DriverShiftEvent = {
    Id: string;
    Subject: string;
    StartTime: Date;
    EndTime: Date;
    IsAllDay: boolean;
    driverId: string;
  };

  const driverShiftsAsEvents = useMemo(() => {
    return props.driverShifts
      .map((driverShift) => {
        const driversForShift = driverShift.driverIds?.length
          ? driverShift.driverIds
          : props.drivers
              .filter((driver) => driver.domicileId === driverShift.domicileId)
              .map((driver) => driver._id);
        return driversForShift.map((driverId) => {
          return currentViewDates.map((date) => {
            const startTime = new Date(date);
            startTime.setHours(
              driverShift.startTime.hour,
              driverShift.startTime.minute
            );
            const endTime = new Date(date);
            endTime.setHours(
              driverShift.endTime.hour,
              driverShift.endTime.minute
            );
            if (!driverShift.days.includes(date.getDay())) {
              return null;
            }
            return {
              Id: `${driverShift._id}-${date}`,
              Subject: "On Duty",
              StartTime: startTime,
              EndTime: endTime,
              IsAllDay: false,
              driverId: driverId,
            };
          });
        });
      })
      .flat(2)
      .filter(Boolean) as DriverShiftEvent[];
  }, [props.driverShifts, currentViewDates, props.drivers]);

  useEffect(() => {
    setTimeout(() => {
      setCurrentViewDates(scheduleObjRef.current?.getCurrentViewDates() || []);
    });
  }, []);

  useEffect(() => {
    if (!scheduleObjRef.current) {
      return;
    }
    scheduleObjRef.current.eventSettings.dataSource = driverShiftsAsEvents;
    scheduleObjRef.current.refreshEvents();
  }, [driverShiftsAsEvents]);
  return (
    <ScheduleComponent
      ref={scheduleObjRef}
      width="100%"
      height="100%"
      allowInline={false}
      eventSettings={{
        dataSource: driverShiftsAsEvents,
        allowEditing: false,
      }}
      group={{
        enableCompactView: false,
        resources: ["Drivers"],
      }}
      locale={i18n.language}
      rowAutoHeight
      allowDragAndDrop={true}
      allowResizing={false}
      currentView={currentView}
      navigating={(event) => {
        // Wait for the navigation event to finish before getting the current view dates
        setTimeout(() => {
          const updatedViewDates =
            scheduleObjRef.current?.getCurrentViewDates();
          if (updatedViewDates) {
            setCurrentViewDates(updatedViewDates);
          }
        });

        if (event.action === "view" && scheduleObjRef.current) {
          setCurrentView(event.currentView);
          scheduleObjRef.current.timeScale = getTimeScaleForView(
            event.currentView
          );
        }
      }}
    >
      <ResourcesDirective>
        <ResourceDirective
          field="driverId"
          title="Driver"
          name="Drivers"
          allowMultiple={false}
          dataSource={driverResources}
          textField="label"
          idField="id"
          colorField="color"
        ></ResourceDirective>
      </ResourcesDirective>
      <ViewsDirective>
        <ViewDirective option="TimelineDay" displayName={t("timelineDay")} />
        <ViewDirective option="TimelineWeek" displayName={t("timelineWeek")} />
        <ViewDirective
          option="TimelineMonth"
          displayName={t("timelineMonth")}
        />
      </ViewsDirective>
      <Inject services={[TimelineViews, TimelineMonth, Resize, DragAndDrop]} />
    </ScheduleComponent>
  );
};

export default DriverShiftsCalendar;

const getTimeScaleForView = (view: View): TimeScaleModel => {
  return view === "TimelineWeek"
    ? {
        enable: true,
        interval: 24 * 60,
        slotCount: 4,
      }
    : view === "TimelineDay" || view === "Day"
    ? {
        enable: true,
        interval: 4 * 60,
        slotCount: 4,
      }
    : {
        enable: true,
        interval: 24 * 60,
        slotCount: 1,
      };
};
