import { Button, List, Typography } from "@mui/material";
import { capitalize, flatten, isFunction, keyBy, sortBy } from "lodash";
import { useMemo, useEffect, useRef } from "react";
import { useSnackbar, VariantType } from "notistack";
import { LiveTrackerTripData } from "../LiveTripsTracker/LiveTripsTracker";
import { usePrevious } from "../../../utils/hooks/usePrevious";
import EventReport, {
  driverDisplay,
  eventDescriptions,
  EventReportData,
  EventReportType,
  eventTitles,
  EventWithShipmentAndTrip,
} from "../EventReport/EventReport";

type LiveEventsTrackerProps = {
  trips: LiveTrackerTripData[];
  onSelect?: (eventReport: EventReportData) => void;
  visible?: boolean;
};

const snackbarVariantByEventReportType: {
  [key in EventReportType]: VariantType;
} = {
  [EventReportType.INFO]: "info",
  [EventReportType.WARNING]: "warning",
  [EventReportType.DANGER]: "error",
};

const snackbarDurationByEventReportType: {
  [key in EventReportType]: number;
} = {
  [EventReportType.INFO]: 5000,
  [EventReportType.WARNING]: 10000,
  [EventReportType.DANGER]: 20000,
};

const LiveEventsTracker = ({
  trips,
  visible,
  onSelect,
}: LiveEventsTrackerProps) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const listRef = useRef<HTMLUListElement | null>(null);
  const hasScrolled = useRef<boolean>(false);

  const allEvents: EventWithShipmentAndTrip[] = useMemo(() => {
    return flatten(
      flatten(
        trips.map((trip) =>
          trip.shipments.map((shipment) =>
            (shipment.events || []).map((event) => ({
              ...event,
              shipment,
              trip,
              shipmentLocation: trip.shipmentLocations.find(
                (location) => location._id === event.shipmentLocation
              ),
              allShippedGoodsById: keyBy(
                flatten(
                  trip.shipmentLocations.map(
                    (location) => location.shippedGoods || []
                  )
                ),
                "_id"
              ),
            }))
          )
        )
      )
    );
  }, [trips]);
  const allIssues = useMemo(() => {
    return flatten(
      flatten(
        trips.map((trip) =>
          trip.shipments.map((shipment) =>
            (shipment.issues || []).map((event) => ({
              ...event,
              shipment,
              trip,
            }))
          )
        )
      )
    );
  }, [trips]);

  const allEventReports: EventReportData[] = useMemo(() => {
    return sortBy(
      [
        ...allEvents.map((event) => {
          const eventDescription = eventDescriptions[event.type];
          return {
            _id: event._id,
            type: EventReportType.INFO,
            title: eventTitles[event.type],
            message: isFunction(eventDescription)
              ? eventDescription(event)
              : eventDescription,
            date: new Date(event.date),
            shipment: event.shipment,
            trip: event.trip,
          };
        }),
        ...allIssues.map((issue) => ({
          _id: issue._id,
          type: EventReportType.DANGER,
          title: capitalize(issue.type),
          message: (
            <>
              <Typography
                fontWeight="bold"
                variant="body2"
                display="inline"
                component="span"
              >
                {driverDisplay(issue.trip.driver)}:
              </Typography>
              <Typography variant="body2" display="inline" component="span">
                {" "}
                {issue.content}
              </Typography>
            </>
          ),
          date: new Date(issue.date),
          shipment: issue.shipment,
          trip: issue.trip,
        })),
      ],
      "date"
    );
  }, [allEvents, allIssues]);

  useEffect(() => {
    if (listRef.current && visible && !hasScrolled.current) {
      (listRef.current.lastChild as HTMLLIElement | null)?.scrollIntoView({
        behavior: "smooth",
      });
      hasScrolled.current = true;
    }
  }, [visible]);

  const previousEventReports = usePrevious(allEventReports);
  useEffect(() => {
    const newEventReports = previousEventReports?.length
      ? allEventReports.filter(
          (eventReport) =>
            !(previousEventReports || []).find(
              (previousEventReport) =>
                previousEventReport._id === eventReport._id
            )
        )
      : [];
    newEventReports.forEach((eventReport) => {
      enqueueSnackbar(eventReport.message, {
        variant: snackbarVariantByEventReportType[eventReport.type],
        autoHideDuration: snackbarDurationByEventReportType[eventReport.type],
        persist: eventReport.type === EventReportType.DANGER ? true : false,
        action: (snackbarId) => (
          <Button
            onClick={() => {
              closeSnackbar(snackbarId);
            }}
            sx={{
              color: "white",
            }}
          >
            Dismiss
          </Button>
        ),
      });
    });
  }, [allEventReports, previousEventReports, enqueueSnackbar, closeSnackbar]);

  return (
    <List sx={{ pt: 0 }} ref={listRef}>
      {allEventReports.map((eventReport) => (
        <EventReport
          key={eventReport._id}
          eventReport={eventReport}
          onClick={onSelect ? () => onSelect(eventReport) : undefined}
        />
      ))}
    </List>
  );
};

export default LiveEventsTracker;
