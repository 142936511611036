import {
  InputAdornment,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import {
  DriverDeductionMethod,
  DeductionRule,
  DeductionPeriod,
  DeductionPeriodUnit,
} from "../../../graphql/generated";
import LocaleProvider from "../../../providers/LocaleProvider";
import deductionRuleLabel, {
  deductionPeriodUnitLabel,
} from "../../../utils/labels/deductionRule/deductionRuleLabel";
import { useTranslation } from "react-i18next";

type DeductionRuleFormProps = {
  deductionRule: DeductionRule;
  onChange: (deductionRule: DeductionRule) => void;
};

const DeductionRuleForm = ({
  deductionRule,
  onChange,
}: DeductionRuleFormProps) => {
  const { t } = useTranslation("paymentRules");
  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <TextField
        type="text"
        value={String(deductionRule.label) || ""}
        onChange={(event) => {
          onChange({
            ...deductionRule,
            label: event.target.value,
          });
        }}
        size="small"
        label={t("form.label")}
      />
      <Select
        label="Payment method"
        size="small"
        value={deductionRule.method}
        onChange={(event) => {
          onChange({
            ...deductionRule,
            method: event.target.value as DriverDeductionMethod,
          });
        }}
      >
        {Object.values(DriverDeductionMethod).map((paymentMethod) => (
          <MenuItem key={paymentMethod} value={paymentMethod}>
            <ListItemText primary={deductionRuleLabel(paymentMethod)} />
          </MenuItem>
        ))}
      </Select>
      <TextField
        type="number"
        value={deductionRule.amount || ""}
        onChange={(event) => {
          onChange({
            ...deductionRule,
            amount: parseFloat(event.target.value) || 0,
          });
        }}
        size="small"
        label={t("form.amount")}
        inputProps={{
          type: "number",
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {LocaleProvider.getCurrencySymbol()}
            </InputAdornment>
          ),
        }}
      />
      <Typography variant="body2">{t("form.every")}</Typography>
      <DeductionPeriodForm
        period={deductionRule.period}
        onChange={(period) => {
          onChange({
            ...deductionRule,
            period,
          });
        }}
      />
    </Stack>
  );
};

type DeductionPeriodFormProps = {
  period: DeductionPeriod;
  onChange: (period: DeductionPeriod) => void;
};

const DeductionPeriodForm = ({
  period,
  onChange,
}: DeductionPeriodFormProps) => {
  const { t } = useTranslation("paymentRules");
  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <TextField
        type="number"
        value={period.repeatEvery || ""}
        onChange={(event) => {
          onChange({
            ...period,
            repeatEvery: parseFloat(event.target.value) || 1,
          });
        }}
        size="small"
        label={t("form.period")}
        inputProps={{
          type: "number",
          min: 1,
        }}
      />

      <Select
        size="small"
        value={period.unit}
        onChange={(event) => {
          onChange({
            ...period,
            unit: event.target.value as DeductionPeriodUnit,
          });
        }}
        sx={{ maxWidth: 120, minWidth: 120 }}
      >
        {Object.values(DeductionPeriodUnit).map((periodUnit) => (
          <MenuItem key={periodUnit} value={periodUnit}>
            <ListItemText primary={deductionPeriodUnitLabel(periodUnit)} />
          </MenuItem>
        ))}
      </Select>

      <Typography variant="body2">{t("form.starting")}</Typography>
      <DatePicker
        label={t("form.starting")}
        value={period.start}
        onChange={(value) => {
          onChange({
            ...period,
            start: value,
          });
        }}
        renderInput={(params) => <TextField {...params} />}
      />
      <Typography variant="body2">{t("form.until")}</Typography>
      <DatePicker
        label={t("form.until")}
        value={period.end}
        onChange={(value) => {
          onChange({
            ...period,
            end: value,
          });
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </Stack>
  );
};

export default DeductionRuleForm;
