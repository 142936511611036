import {
  Grid,
  InputAdornment,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { BillingMethod, BillingRate } from "../../../graphql/generated";
import billingMethodLabel from "../../../utils/labels/billingRule/billingMethodLabel";
import LocaleProvider from "../../../providers/LocaleProvider";
import { useTranslation } from "react-i18next";
import { sortBy } from "lodash";

type RateFormProps = {
  rate: BillingRate;
  onChange: (condition: BillingRate) => void;
};
const billingMethodOrder = [
  BillingMethod.FlatFee,
  BillingMethod.PerMile,
  BillingMethod.PerEmptyMile,
  BillingMethod.PerMileWithDeadhead,
  BillingMethod.PerKm,
  BillingMethod.PerEmptyKm,
  BillingMethod.PerKmWithDeadhead,
];
const RateForm = ({ rate, onChange }: RateFormProps) => {
  const { t } = useTranslation("billingRules");

  return (
    <Grid container direction="row" alignItems="center" spacing={1} flex={1}>
      <Grid item xs={12} md={6} lg={4}>
        <Select
          label="Billing method"
          size="small"
          value={rate.method}
          onChange={(event) => {
            onChange({
              ...rate,
              method: event.target.value as BillingMethod,
            });
          }}
          sx={{ display: "flex" }}
        >
          {sortBy(Object.values(BillingMethod), (method) =>
            billingMethodOrder.indexOf(method) === -1
              ? Infinity
              : billingMethodOrder.indexOf(method)
          ).map((billingMethod) => (
            <MenuItem key={billingMethod} value={billingMethod}>
              <ListItemText primary={billingMethodLabel(billingMethod)} />
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <TextField
          type="number"
          value={String(rate.amount) || ""}
          onChange={(event) => {
            onChange({
              ...rate,
              amount: parseFloat(event.target.value) || 0,
            });
          }}
          size="small"
          label={t("form.amount")}
          inputProps={{
            type: "number",
            min: 0,
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {LocaleProvider.getCurrencySymbol()}
              </InputAdornment>
            ),
          }}
          sx={{ display: "flex" }}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <TextField
          type="string"
          value={rate.referenceNumber || ""}
          onChange={(event) => {
            onChange({
              ...rate,
              referenceNumber: event.target.value,
            });
          }}
          size="small"
          label={t("form.reference")}
          sx={{ display: "flex" }}
        />
      </Grid>
    </Grid>
  );
};

export default RateForm;
