import { TextField } from "@mui/material";
import { ConditionValueSelectorComponent } from "../ConditionForm";
import { DatePicker } from "@mui/x-date-pickers";
import { format, isValid, parse } from "date-fns";

const DateInput: ConditionValueSelectorComponent = ({ value, onChange }) => {
  const parsedDate = parse(String(value), "yyyy-MM-dd", new Date());
  return (
    <DatePicker
      value={value && isValid(parsedDate) ? parsedDate : null}
      onChange={(value) => {
        if (!value) {
          return;
        }
        if (!isValid(value)) {
          return;
        }
        onChange(format(value, "yyyy-MM-dd"));
      }}
      renderInput={(params) => <TextField {...params} />}
    />
  );
};

export default DateInput;
