import { ConditionValueSelectorComponent } from "../ConditionForm";
import NumberTextField from "../../../common/NumberTextField";

const NumberInput: ConditionValueSelectorComponent = ({ value, onChange }) => {
  const parsedValue = parseFloat(String(value));
  return (
    <NumberTextField
      value={isNaN(parsedValue) ? null : parsedValue}
      onChange={(e) => {
        const newValue = e.target.value;
        if (newValue === "") {
          onChange(null);
          return;
        }
        const parsedValue = parseFloat(newValue);
        if (isNaN(parsedValue)) {
          return;
        }
        onChange(parsedValue);
      }}
    />
  );
};

export default NumberInput;
