import { useSnackbar } from "notistack";
import {
  useDeleteInvoiceMutation,
  useGetInvoiceListQuery,
  useSendInvoiceMutation,
  useUpdateInvoiceStatusMutation,
} from "../../../graphql/generated";
import useDialog from "../../../utils/hooks/useDialog";
import InvoiceList from "./InvoiceList";
import { useTranslation } from "react-i18next";

const InvoiceListContainer = () => {
  const { t } = useTranslation(["finance", "common"]);
  let refetch = () => {};
  // I need a handle on refetch so I can call it
  // a new settlement has been generated
  const useGetInvoiceListQueryCustom: typeof useGetInvoiceListQuery = (
    variables,
    options
  ) => {
    const query = useGetInvoiceListQuery(variables, {
      ...options,
    });
    refetch = query.refetch;
    return query;
  };
  useGetInvoiceListQueryCustom.fetcher = useGetInvoiceListQuery.fetcher;
  useGetInvoiceListQueryCustom.getKey = useGetInvoiceListQuery.getKey;
  const sendInvoiceMutation = useSendInvoiceMutation();
  const updateInvoiceStatusMutation = useUpdateInvoiceStatusMutation();
  const { enqueueSnackbar } = useSnackbar();
  const { showDialog } = useDialog();
  return (
    <InvoiceList
      queryHook={useGetInvoiceListQueryCustom}
      deleteMutation={useDeleteInvoiceMutation}
      onSendInvoice={async (invoice) => {
        try {
          await sendInvoiceMutation.mutateAsync({
            invoiceId: invoice._id,
          });
          enqueueSnackbar(t("sendInvoiceSuccess", "Invoice successfully sent"));
          refetch();
        } catch (e) {
          console.error(e);
          showDialog({
            type: "error",
            title: t("error.sendInvoice", "Error sending invoice"),
            description:
              (e as Error).message ||
              t("common:error.unknownError", "Unknown error"),
          });
        }
      }}
      onInvoiceStatusUpdate={async (invoice, status, closingNote) => {
        try {
          await updateInvoiceStatusMutation.mutateAsync({
            invoiceId: invoice._id,
            status,
            closingNote,
          });
          enqueueSnackbar(
            t(
              "updateInvoiceStatusSuccess",
              "Invoice status successfully updated"
            )
          );
          refetch();
        } catch (e) {
          console.error(e);
          showDialog({
            type: "error",
            title: t(
              "error.updateInvoiceStatusError",
              "Error updating invoice status"
            ),
            description:
              (e as Error).message ||
              t("common:error.unknownError", "Unknown error"),
          });
        }
      }}
    />
  );
};

export default InvoiceListContainer;
