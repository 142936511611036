import {
  Scalars,
  useCreateShipmentMutation,
  useForecastMutation,
  useGetReceiverForecastDetailsQuery,
  usePurgeForecastedOrdersMutation,
} from "../../../graphql/generated";
import ForecastSimulator, {
  ForecastResultShipment,
  ForecastSimulatorProps,
} from "./ForecastSimulator";
import { useNavigate } from "react-router-dom";
import LoadingOverlay from "../../common/LoadingOverlay";
import { Alert } from "@mui/material";
import { useState } from "react";
import { toShipmentInputData } from "../../shipment/EditShipmentForm";
import { omit } from "lodash";
import useDialog from "../../../utils/hooks/useDialog";

type ForecastSimulatorContainerProps = Omit<
  ForecastSimulatorProps,
  | "receiverForecast"
  | "onSimulate"
  | "simulating"
  | "shipments"
  | "onSave"
  | "saving"
> & {
  receiverForecastId: Scalars["ObjectId"];
};

const ForecastSimulatorContainer = (props: ForecastSimulatorContainerProps) => {
  const query = useGetReceiverForecastDetailsQuery({
    id: props.receiverForecastId,
  });
  const { showDialog, hideDialog } = useDialog();
  const navigate = useNavigate();
  const forecastMutation = useForecastMutation();
  const [forecastedShipments, setForecastedShipments] = useState<
    ForecastResultShipment[]
  >([]);
  const createShipmentMutation = useCreateShipmentMutation();
  const purgeForecastedOrdersMutation = usePurgeForecastedOrdersMutation();

  if (query.isLoading) {
    return <LoadingOverlay loading />;
  }
  if (query.error) {
    return (
      <Alert severity="error">
        An error occurred while fetching.{" "}
        {(query.error as Error).message || "Unknown error"}
      </Alert>
    );
  }

  const receiverForecast = query.data.receiverForecastById;

  if (!receiverForecast) {
    return <Alert severity="error">Not found</Alert>;
  }

  return (
    <ForecastSimulator
      receiverForecast={receiverForecast}
      simulating={forecastMutation.isLoading}
      saving={createShipmentMutation.isLoading}
      onSimulate={async (payload) => {
        try {
          setForecastedShipments([]);
          const result = await forecastMutation.mutateAsync({
            payload,
          });
          setForecastedShipments(result.forecast.shipments);
        } catch (error) {
          showDialog({
            title: "Error",
            description:
              `An error occurred while simulating the forecast. ` +
              (error as Error).message,
          });
        }
      }}
      onSave={async (shipments) => {
        await purgeForecastedOrdersMutation.mutateAsync({
          receiverId: receiverForecast.receiver?._id || "",
        });
        const promises = shipments.map((shipment) =>
          createShipmentMutation.mutateAsync({
            shipment: omit(
              toShipmentInputData(shipment),
              "_id",
              "id",
              "route",
              "date",
              "shipmentNumber",
              "predefinedRecurrence"
            ),
          })
        );
        Promise.all(promises)
          .then(() => {
            showDialog({
              title: "Success",
              description: "Orders created successfully",
              type: "primary",
              actions: [
                {
                  title: "View Orders",
                  onClick: () => {
                    navigate("/orders");
                    hideDialog();
                  },
                  type: "primary",
                },
              ],
            });
          })
          .catch((error) => {
            showDialog({
              title: "Error",
              description:
                `An error occurred while creating the orders. ` +
                (error as Error).message,
            });
          });
      }}
      shipments={forecastedShipments}
      {...props}
    />
  );
};

export default ForecastSimulatorContainer;
