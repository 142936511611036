import {
  useTrackShipmentByIdQuery,
  useTrackShipmentQuery,
} from "../../../graphql/generated";
import EmptyState from "../../common/EmptyState";
import LoadingOverlay from "../../common/LoadingOverlay";
import ShipmentTracker, { ShipmentTrackerProps } from "./ShipmentTracker";

type ShipmentTrackerContainerProps = Omit<
  ShipmentTrackerProps,
  "trackingInfos"
> &
  (
    | {
        code: string;
      }
    | {
        shipmentId: string;
      }
  );

const ShipmentTrackerContainer = (props: ShipmentTrackerContainerProps) => {
  const trackShipmentByTokenQuery = useTrackShipmentQuery(
    {
      token: "code" in props ? props.code : "",
    },
    {
      enabled: "code" in props,
    }
  );

  const trackShipmentByIdQuery = useTrackShipmentByIdQuery(
    {
      shipmentId: "shipmentId" in props ? props.shipmentId : "",
    },
    {
      enabled: "shipmentId" in props,
    }
  );

  const trackShipmentQuery =
    "code" in props ? trackShipmentByTokenQuery : trackShipmentByIdQuery;

  if (trackShipmentQuery.isLoading) {
    return <LoadingOverlay loading={trackShipmentQuery.isLoading} />;
  }

  if (trackShipmentQuery.error || !trackShipmentQuery.data?.trackShipment) {
    return (
      <EmptyState
        message={trackShipmentQuery.error?.message || "Invalid shipment link"}
      />
    );
  } else {
    return (
      <ShipmentTracker
        trackingInfos={trackShipmentQuery.data?.trackShipment}
        {...props}
      />
    );
  }
};

export default ShipmentTrackerContainer;
