type Person = {
  firstname?: string;
  lastname?: string;
};
function personLabel(person: Person | null | undefined) {
  if (!person) {
    return "N/A";
  }
  return `${person.firstname || ""} ${person.lastname || ""}`.trim() || "N/A";
}

export default personLabel;
