import { Container } from "@mui/material";
import BusinessEntityForm from "../../../components/asset-management/BusinessEntityForm";
import { BusinessEntityType } from "../../../graphql/generated";

function CreateSupplier() {
  return (
    <Container>
      <BusinessEntityForm
        urlPrefix="/suppliers"
        availableTypes={[BusinessEntityType.Supplier]}
        defaultType={BusinessEntityType.Supplier}
      />
    </Container>
  );
}

export default CreateSupplier;
