import { Box } from "@mui/material";
import CustomerOrderFormContainer from "../../components/customer-portal/CustomerOrderForm";

const CustomerCreateOrder = () => {
  return (
    <Box
      sx={{
        p: 2,
      }}
    >
      <CustomerOrderFormContainer />
    </Box>
  );
};

export default CustomerCreateOrder;
