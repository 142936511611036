import { useGetGoodProfileListQuery } from "../../../graphql/generated";
import GoodProfileSelect, { GoodProfileSelectProps } from "./GoodProfileSelect";

type GoodProfileSelectContainerProps = Omit<
  GoodProfileSelectProps,
  "goodProfiles" | "onGoodProfilesRefetch"
>;

const GoodProfileSelectContainer = (props: GoodProfileSelectContainerProps) => {
  const goodProfilesQuery = useGetGoodProfileListQuery();

  const goodProfiles = goodProfilesQuery.data?.goodProfiles.data || [];

  return (
    <GoodProfileSelect
      {...props}
      goodProfiles={goodProfiles}
      onGoodProfilesRefetch={async () => {
        await goodProfilesQuery.refetch();
      }}
    />
  );
};

export default GoodProfileSelectContainer;
