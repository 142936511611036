import { omit, toArray } from "lodash";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  SubscriptionStatus,
  useGetSubscriptionInfoQuery,
} from "../../../graphql/generated";
import { getOrganizationId, useAuth } from "../../../providers/AuthProvider";

const SegmentProvider = () => {
  const { userInfo } = useAuth();
  const getSubscriptionInfoQuery = useGetSubscriptionInfoQuery(
    {},
    {
      refetchInterval(data) {
        if (!data?.subscriptionInfo) {
          return 1000;
        }
        if (data?.subscriptionInfo?.status === SubscriptionStatus.None) {
          return 10000;
        }
        return false;
      },
    }
  );
  const subscriptionInfo = getSubscriptionInfoQuery.data?.subscriptionInfo;
  useEffect(() => {
    if (userInfo) {
      window.analytics?.identify(userInfo.sub, {
        ...omit(userInfo, ["sub", "given_name", "family_name", "organization"]),
        id: userInfo.sub,
        firstName: userInfo.given_name,
        lastName: userInfo.family_name,
        company: {
          name: toArray(userInfo.organization)[0]?.name || "N/A",
          id: getOrganizationId(userInfo),
        },
        subscriptionInfo: subscriptionInfo
          ? {
              ...subscriptionInfo,
              trialStartDateOnly: subscriptionInfo.trialStart?.split("T")[0],
              createdAtDateOnly: subscriptionInfo.createdAt?.split("T")[0],
              activatedAtDateOnly: subscriptionInfo.activatedAt?.split("T")[0],
            }
          : null,
      });
      const orgId = getOrganizationId(userInfo);
      if (orgId) {
        window.analytics?.group(orgId, {
          name: toArray(userInfo.organization)[0]?.name || "N/A",
          dotNumber:
            toArray(userInfo.organization)[0]?.attributes.dotNumber[0] || "N/A",
          subscriptionInfo: subscriptionInfo
            ? {
                ...subscriptionInfo,
                trialStartDateOnly: subscriptionInfo.trialStart?.split("T")[0],
                createdAtDateOnly: subscriptionInfo.createdAt?.split("T")[0],
                activatedAtDateOnly:
                  subscriptionInfo.activatedAt?.split("T")[0],
              }
            : null,
        });
      }
    }
  }, [userInfo, subscriptionInfo]);
  const location = useLocation();
  useEffect(() => {
    // Wait for the page title to update
    setTimeout(() => {
      window.analytics?.page();
    }, 500);
  }, [location]);
  if (!userInfo) {
    return null;
  }
  return <></>;
};

export default SegmentProvider;
