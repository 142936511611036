import { useGetForecastingNotificationsQuery } from "../../../graphql/generated";
import ErrorMessage from "../../common/ErrorMessage/ErrorMessage";
import ForecastingNotificationsAlerts, {
  ForecastingNotificationsAlertsProps,
} from "./ForecastingNotificationsAlerts";

type ForecastingNotificationsAlertsContainerProps = Omit<
  ForecastingNotificationsAlertsProps,
  "forecastingNotifications"
> & {
  receiverId?: string;
};

const ForecastingNotificationsAlertsContainer = ({
  receiverId,
  ...props
}: ForecastingNotificationsAlertsContainerProps) => {
  const forecastingNotificationsQuery = useGetForecastingNotificationsQuery({
    receiverId,
  });

  if (forecastingNotificationsQuery.isLoading) {
    return null;
  }

  if (forecastingNotificationsQuery.error) {
    return <ErrorMessage error={forecastingNotificationsQuery.error} />;
  }

  const forecastingNotifications =
    forecastingNotificationsQuery.data?.forecastingNotifications ?? [];

  return (
    <ForecastingNotificationsAlerts
      forecastingNotifications={forecastingNotifications}
      {...props}
    />
  );
};

export default ForecastingNotificationsAlertsContainer;
