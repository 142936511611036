import { useMemo, useState } from "react";
import { Box, Grid, Paper } from "@mui/material";
import { Tabs, Tab } from "@mui/material";
import {
  AssetStatus,
  GetTodayTripsQuery,
  useGetAllAssetsLocationsQuery,
} from "../../../graphql/generated";
import AssetTrackingMap from "../AssetTrackingMap";
import TripSummariesList from "../../trip/TripSummariesList/TripSummariesList";
import LiveEventsTracker from "../LiveEventsTracker";
import ShipmentSummariesList from "../../shipment/ShipmentSummariesList";
import { ShipmentDataSummary } from "../../shipment/ShipmentSummary/ShipmentSummary";
import LiveTrackerDriversList from "../LiveTrackerDriversList/LiveTrackerDriversList";
import { LiveTrackerDriverData } from "../LiveTrackerDriversList/LiveTrackerDriver/LiveTrackerDriver";
import _ from "lodash";
import EmptyState from "../../common/EmptyState";
import LoadTrackingBoard from "../LoadTrackingBoard";
import { useTranslation } from "react-i18next";

export type LiveTrackerTripData = GetTodayTripsQuery["trips"]["data"][0];

type LiveTrackerProps = {
  trips: LiveTrackerTripData[];
  onRefetch: () => void;
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  style?: object;
}

export const toShipmentDataSummary = (
  trips: LiveTrackerTripData[]
): Array<ShipmentDataSummary> => {
  const shipments: ShipmentDataSummary[] =
    trips
      .map((trip) =>
        trip.shipments.map((shipment) => ({
          ...shipment,
          createdBy: "",
        }))
      )
      .flat() || [];

  return shipments;
};

export function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`${index}`}
      style={{ height: "100%", ...props.style }}
    >
      <Box
        sx={{
          p: 1,
          pt: 2,
          display: value === index ? "block" : "none",
          height: "100%",
        }}
      >
        {children}
      </Box>
    </div>
  );
}

const LiveTripsTracker = ({ trips, onRefetch }: LiveTrackerProps) => {
  const { t } = useTranslation(["trips", "orders", "users"]);
  const [value, setValue] = useState(0);
  const [topTabValue, setTopTabValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const getAllAssetsLocationsQuery = useGetAllAssetsLocationsQuery(
    {},
    {
      refetchInterval: 5000,
    }
  );

  const todayDrivers: LiveTrackerDriverData[] = useMemo(() => {
    const driversId = trips.map((trip) => trip.driver?._id).flat();
    return (
      getAllAssetsLocationsQuery.data?.drivers.data
        .filter((driver) => driver.status === AssetStatus.Active)
        .map((driver) => ({
          ..._.pick(driver, [
            "_id",
            "firstname",
            "lastname",
            "phoneNumber",
            "hos",
          ]),
          status: driver.hos?.currentStatus
            ? driver.hos.currentStatus
            : driversId.includes(driver._id)
            ? "ASSIGNED"
            : "UNASSIGNED",
        })) || []
    );
  }, [getAllAssetsLocationsQuery, trips]);

  const [selectedTrip, setSelectedTrip] =
    useState<LiveTrackerTripData | null>(null);

  const [selectedLoad, setSelectedLoad] =
    useState<ShipmentDataSummary | null>(null);

  const [selectedDriver, setSelectedDriver] =
    useState<LiveTrackerDriverData | null>(null);

  const shipmentData = toShipmentDataSummary(trips);

  return (
    <Box sx={{ height: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          value={topTabValue}
          onChange={(event, value) => setTopTabValue(value)}
        >
          <Tab label={t("trips:tracker.map", "Map")} aria-label="Map" />
          <Tab label={t("trips:tracker.board", "Board")} aria-label="Board" />
        </Tabs>
      </Box>
      <TabPanel
        value={topTabValue}
        index={0}
        style={{
          height: "calc(100% - 48px)",
        }}
      >
        <Grid
          container
          sx={{
            height: "100%",
          }}
        >
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              height: "100%",
              overflowY: "auto",
            }}
          >
            <Paper sx={{ height: "100%", overflow: "auto" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  variant="scrollable"
                  scrollButtons="auto"
                  allowScrollButtonsMobile
                  value={value}
                  onChange={handleChange}
                >
                  <Tab label={t("trips:trips", "Trips")} aria-label="Trips" />
                  <Tab
                    label={t("trips:orders", "Orders")}
                    aria-label="Orders"
                  />
                  <Tab
                    label={t("users:driver.many", "Drivers")}
                    aria-label="Drivers"
                  />
                  <Tab
                    label={t("trips:event.many", "Events")}
                    aria-label="Events"
                  />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                {trips.length ? (
                  <TripSummariesList
                    trips={trips}
                    selected={selectedTrip}
                    onSelect={(tripToSelect) =>
                      selectedTrip?._id === tripToSelect._id
                        ? setSelectedTrip(null)
                        : setSelectedTrip(
                            trips.find(
                              (trip) => trip._id === tripToSelect._id
                            ) || null
                          )
                    }
                    onRefetch={onRefetch}
                  />
                ) : (
                  <EmptyState />
                )}
              </TabPanel>
              <TabPanel value={value} index={1}>
                {shipmentData.length ? (
                  <ShipmentSummariesList
                    direction="column"
                    groupByTrailer={false}
                    shipments={shipmentData}
                    dragable={false}
                    selected={selectedLoad}
                    onSelect={(shipment) => {
                      if (selectedLoad?._id === shipment._id) {
                        setSelectedLoad(null);
                        setSelectedTrip(null);
                      } else {
                        setSelectedLoad(shipment);
                        setSelectedTrip(
                          trips.find((trip) =>
                            trip.shipments.find((sh) => sh._id === shipment._id)
                          ) || null
                        );
                      }
                    }}
                  />
                ) : (
                  <EmptyState />
                )}
              </TabPanel>
              <TabPanel value={value} index={2}>
                {todayDrivers.length ? (
                  <LiveTrackerDriversList
                    drivers={todayDrivers}
                    enableSearch
                    onSelect={(driver) => {
                      if (!driver || selectedDriver?._id === driver._id) {
                        setSelectedDriver(null);
                        setSelectedTrip(null);
                      } else {
                        setSelectedDriver(driver);
                        setSelectedTrip(
                          trips.find(
                            (trip) => trip.driver?._id === driver._id
                          ) || null
                        );
                      }
                    }}
                  />
                ) : (
                  <EmptyState />
                )}
              </TabPanel>
              <TabPanel value={value} index={3}>
                {trips.length ? (
                  <LiveEventsTracker
                    trips={trips}
                    onSelect={(eventReport) => {
                      setSelectedTrip(
                        trips.find(
                          (trip) => trip?._id === eventReport.trip?._id
                        ) || null
                      );
                    }}
                    visible={value === 3}
                  />
                ) : (
                  <EmptyState />
                )}
              </TabPanel>
            </Paper>
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            sx={{
              height: "100%",
            }}
          >
            <AssetTrackingMap
              trips={trips}
              selectedTrip={selectedTrip}
              selectedDriverId={selectedDriver?._id}
              onTripSelect={(trip) => {
                setSelectedTrip(trip);
              }}
              onTripUnselect={() => {
                setSelectedTrip(null);
                setSelectedLoad(null);
                setSelectedDriver(null);
              }}
            />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel
        value={topTabValue}
        index={1}
        style={{
          height: "calc(100% - 48px)",
        }}
      >
        <LoadTrackingBoard />
      </TabPanel>
    </Box>
  );
};

export default LiveTripsTracker;
