import { Trailer } from "../../graphql/generated";

function trailerLabel(
  trailer: Pick<Trailer, "serialNumber"> | null | undefined
) {
  if (!trailer) {
    return "N/A";
  }
  return trailer.serialNumber || "N/A";
}

export default trailerLabel;
