import { Box, Stack, Typography } from "@mui/material";
import { ReactNode } from "react";
import theme from "../../../theme";
import { capitalize } from "lodash";

const InfoBlock = ({
  title,
  children,
  action,
}: {
  title: string;
  children: ReactNode;
  action?: ReactNode;
}) => {
  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6" fontSize={16} fontWeight="600" color={"black"}>
          {capitalize(title)}
        </Typography>
        {action ? (
          <Stack direction="row" spacing={2} ml={1}>
            {action}
          </Stack>
        ) : null}
      </Box>
      <Box
        sx={{
          backgroundColor: theme.palette.primaryBackground.main,
          borderRadius: 2,
          padding: 1,
          mt: 1,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default InfoBlock;
