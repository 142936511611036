import { Autocomplete, Chip, TextField } from "@mui/material";
import { OrgUser } from "../../../graphql/generated";
import userLabel from "../../../utils/labels/userLabel";

import { useTranslation } from "react-i18next";

export type UserSelectProps = {
  value: string[];
  onChange: (value: string[]) => void;
  users: Pick<OrgUser, "_id" | "email" | "firstName" | "lastName">[];
};

const UserSelect = ({ value, onChange, users }: UserSelectProps) => {
    const {t} = useTranslation("users");
  return (
    <Autocomplete
      value={users.filter((user) => value.includes(user._id))}
      clearIcon={false}
      options={users}
      multiple
      renderTags={(value, props) =>
        value.map((user, index) => (
          <Chip label={userLabel(user)} {...props({ index })} />
        ))
      }
      renderInput={(params) => <TextField label={t("user.one","Users")} {...params} />}
      getOptionLabel={(user) => userLabel(user)}
      onChange={(_, newValue) => {
        onChange(newValue.map((user) => user._id));
      }}
      id="user-select"
    />
  );
};

export default UserSelect;
