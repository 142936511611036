import {
  useDeleteQualificationMutation,
  useGetQualificationsQuery,
} from "../../../graphql/generated";
import QualificationsList, {
  QualificationListProps,
} from "./QualificationsList";

type QualificationsListContainerProps = Omit<
  QualificationListProps,
  "query" | "deleteMutation" | "onRefresh" | "onComplete"
>;

export default function QualificationsListContainer(
  props: QualificationsListContainerProps
) {
  // const bulkCreateQualifications = useBulkAddQualificationsMutation();
  let refetch = () => {};
  // I need a handle on refetch so I can call it
  // a new settlement has been generated
  const useGetQualificationListQueryCustom: typeof useGetQualificationsQuery = (
    variables,
    options
  ) => {
    const query = useGetQualificationsQuery(
      { ...variables, assetType: props.assetType, assetId: props.assetId },
      {
        ...options,
      }
    );
    refetch = query.refetch;
    return query;
  };
  useGetQualificationListQueryCustom.fetcher =
    useGetQualificationsQuery.fetcher;
  useGetQualificationListQueryCustom.getKey = useGetQualificationsQuery.getKey;

  return (
    <QualificationsList
      // onBulkCreate={(qualifications) =>
      //   bulkCreateQualifications.mutateAsync({ newQualificationsData: qualifications })
      // }
      query={useGetQualificationListQueryCustom}
      deleteMutation={useDeleteQualificationMutation}
      onRefresh={() => {
        refetch();
      }}
      {...props}
    />
  );
}
