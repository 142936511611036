import { Container } from "@mui/material";
import BusinessEntityForm from "../../../components/asset-management/BusinessEntityForm";
import { BusinessEntityType } from "../../../graphql/generated";

function CreateBusinessLocation() {
  return (
    <Container>
      <BusinessEntityForm
        urlPrefix="/business-locations"
        availableTypes={[
          BusinessEntityType.Shipper,
          BusinessEntityType.Receiver,
          BusinessEntityType.Terminal,
          BusinessEntityType.TruckStop,
          BusinessEntityType.GeneralLocation,
          BusinessEntityType.Port,
          BusinessEntityType.Domicile,
        ]}
      />
    </Container>
  );
}

export default CreateBusinessLocation;
