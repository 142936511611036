import { Box, Stack } from "@mui/material";
import {
  ForecastingModel,
  ForecastingModelSlot,
  ForecastingModelSlotType,
} from "../../../graphql/generated";
import NumberTextField from "../../common/NumberTextField";

type DailyForecastModelFormProps = {
  model: ForecastingModel;
  onChange: (model: ForecastingModel) => void;
};

const DailyForecastModelForm = (props: DailyForecastModelFormProps) => {
  return (
    <Box>
      <Stack spacing={2} direction="row">
        {Array.from({ length: 7 }, (_, i) => i)
          .map((day) => (day + 1) % 7)
          .map((day) => (
            <NumberTextField
              label={dayOfWeekLabel(day)}
              value={
                props.model.slots?.find((slot) => slot.daily?.dayOfWeek === day)
                  ?.salesAmount || 0
              }
              onChange={(event) => {
                const existingSlot = props.model.slots?.find(
                  (slot) => slot.daily?.dayOfWeek === day
                );
                if (!existingSlot) {
                  const newSlot: ForecastingModelSlot = {
                    type: ForecastingModelSlotType.Daily,
                    daily: {
                      dayOfWeek: day,
                    },
                    salesAmount: parseFloat(event.target.value),
                  };
                  props.onChange({
                    ...props.model,
                    slots: [...(props.model.slots || []), newSlot],
                  });
                  return;
                } else {
                  const newSlots = props.model.slots?.map((slot) => {
                    if (slot.daily?.dayOfWeek === day) {
                      return {
                        ...slot,
                        salesAmount: parseFloat(event.target.value) || 0,
                      };
                    }
                    return slot;
                  });
                  props.onChange({
                    ...props.model,
                    slots: newSlots,
                  });
                  return;
                }
              }}
              type="number"
              fullWidth
            />
          ))}
      </Stack>
    </Box>
  );
};

export default DailyForecastModelForm;

const dayOfWeekLabel = (dayOfWeek: number) => {
  switch (dayOfWeek) {
    case 0:
      return "Sunday";
    case 1:
      return "Monday";
    case 2:
      return "Tuesday";
    case 3:
      return "Wednesday";
    case 4:
      return "Thursday";
    case 5:
      return "Friday";
    case 6:
      return "Saturday";
  }
};
