import { Alert, Box, Button, useMediaQuery, useTheme } from "@mui/material";
import {
  SubscriptionInfo,
  SubscriptionStatus,
} from "../../../graphql/generated";
import { formatDate } from "../../../utils/labels/formatDateTime";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

type SubscriptionInfoBannerProps = {
  subscriptionInfo: SubscriptionInfo;
  subscriptionPortalUrl: string | null;
};

const SubscriptionInfoBanner = ({
  subscriptionInfo,
  subscriptionPortalUrl,
}: SubscriptionInfoBannerProps) => {
  const { t } = useTranslation("account");
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();
  if (subscriptionInfo.status !== SubscriptionStatus.InTrial) {
    return null;
  }
  return (
    <Box
      sx={{
        display: location.pathname === "/planning" ? "none" : "block",
        width: "50%",
        zIndex: 999999,
        position: "fixed",
        top: 10,
        left: "60%",
        transform: "translateX(-50%)",
        [theme.breakpoints.down("md")]: {
          width: "100%",
          left: 0,
          top: 0,
          transform: "none",
        },
      }}
    >
      {subscriptionInfo.status === SubscriptionStatus.InTrial ? (
        <Alert
          severity="info"
          sx={{
            pt: 0,
            pb: 0,
            textAlign: "center",
            "& .MuiAlert-message": {
              pt: 1.2,
            },
          }}
          action={
            subscriptionPortalUrl ? (
              <Button
                color="info"
                size="small"
                href={subscriptionPortalUrl}
                LinkComponent="a"
              >
                {isSmDown
                  ? t("subscription", "Subscription")
                  : t("manageSubscription", "Manage Subscription")}
              </Button>
            ) : null
          }
        >
          {t("trialEndDescription", {
            defaultValue: `You are on a free trial until ${formatDate(
              subscriptionInfo.trialEnd,
              null,
              false
            )}`,
            trialEndDate: formatDate(subscriptionInfo.trialEnd, null, false),
          })}
        </Alert>
      ) : null}
    </Box>
  );
};

export default SubscriptionInfoBanner;
