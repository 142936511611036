import { Link } from "@mui/material";
import LynksTable, { TableField } from "../../common/LynksTable/LynksTable";
import { DriverDocument } from "../../../graphql/generated";
import { useTranslation } from "react-i18next";
import { capitalize } from "lodash";

export default function DriverDocumentsTable({
  onDelete,
  documents,
}: {
  onDelete?: (id: string) => void;
  documents: Array<DriverDocument>;
}) {
  const { t } = useTranslation("users");
  const fields: Array<TableField<DriverDocument>> = [
    {
      label: capitalize(t("users:driver.document.one", "Document")),
      type: "custom",
      value: (document) => {
        return (
          <Link target="_blank" component="a" href={document.url}>
            {document.name}
          </Link>
        );
      },
    },
  ];
  return <LynksTable fields={fields} data={documents} onDelete={onDelete} />;
}
