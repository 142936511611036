import {
  DeductionPeriodUnit,
  DriverDeductionMethod,
} from "../../../graphql/generated";
import i18next from "i18next";

const deductionRuleLabel = (method: DriverDeductionMethod) => {
  return i18next.t(`paymentRules:deductionMethod.${method}`);
};

const deductionPeriodUnitLabel = (unit: DeductionPeriodUnit) => {
  return i18next.t(`paymentRules:deductionPeriodUnit.${unit}`);
};

export default deductionRuleLabel;
export { deductionPeriodUnitLabel };
