import { Search } from "@mui/icons-material";
import { Box, Divider, InputAdornment, Stack, TextField } from "@mui/material";
import { useState, useEffect, Fragment } from "react";
import LiveTrackerDriver, {
  LiveTrackerDriverData,
} from "./LiveTrackerDriver/LiveTrackerDriver";
import { useTranslation } from "react-i18next";

interface LiveTrackerDriversListProps {
  drivers: Array<LiveTrackerDriverData>;
  onSelect?: (driver: LiveTrackerDriverData | null) => void;
  enableSearch?: boolean;
}

export default function LiveTrackerDriversList({
  drivers,
  onSelect,
  enableSearch,
}: LiveTrackerDriversListProps) {
  const { t } = useTranslation("common");
  const [state, setState] = useState(drivers);
  const [selectedDriver, setSelectedDriver] =
    useState<LiveTrackerDriverData | null>(null);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (query: string) => {
    const filtered = drivers.filter(
      (driver) =>
        driver.firstname.toLowerCase().includes(query.toLowerCase()) ||
        driver.lastname.toLowerCase().includes(query.toLowerCase())
    );
    setState(filtered);
  };

  useEffect(() => {
    setState(drivers);
  }, [drivers]);

  const handleClick = (driver: LiveTrackerDriverData) => {
    if (selectedDriver?._id === driver._id) {
      setSelectedDriver(null);
      onSelect?.(null);
      return;
    }
    setSelectedDriver(driver);
    onSelect?.(driver);
  };

  return (
    <Stack spacing={2}>
      {enableSearch && (
        <Box>
          <TextField
            label={t("search", "Search")}
            name="search"
            value={searchTerm}
            size="small"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setSearchTerm(event.target.value);
              handleSearch(event.target.value);
            }}
          />
        </Box>
      )}
      <Stack spacing={1}>
        {state.map((driver, index) => (
          <Fragment key={driver._id}>
            <LiveTrackerDriver
              onClick={handleClick}
              selected={selectedDriver?._id === driver._id}
              driver={driver}
              key={index}
            />
            <Divider />
          </Fragment>
        ))}
      </Stack>
    </Stack>
  );
}
