import { capitalize } from "lodash";
import { NoteAccessLevel, ShipmentNote } from "../../../../graphql/generated";
import LynksTable, { TableField } from "../../../common/LynksTable/LynksTable";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

export type NoteType = Pick<ShipmentNote, "_id" | "content" | "accessLevel"> & {
  receiver?: { _id: string; name: string };
} & {
  shipper?: { _id: string; name: string };
};

export default function NoteList({
  onItemClick,
  onEdit,
  onDelete,
  notes,
  readonly,
}: {
  onItemClick: (id: string) => void;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
  notes: Array<NoteType>;
  readonly?: boolean;
}) {
  const { t } = useTranslation("orders");
  const noteTableFields: Array<TableField<NoteType>> = [
    {
      label: t("notes.note"),
      type: "custom",
      value: (note: NoteType) => {
        return (
          <Typography
            sx={{
              "&:hover": {
                cursor: "pointer",
              },
            }}
            variant="body2"
            onClick={() => onItemClick(note._id)}
          >
            {`${note.content.substring(0, 50)}...`}
          </Typography>
        );
      },
    },
    {
      label: t("notes.visibility"),
      type: "string",
      value: (note: NoteType) => {
        if (note.accessLevel.length === Object.keys(NoteAccessLevel).length) {
          return "All";
        } else {
          return capitalize(note.accessLevel.join(", "));
        }
      },
    },
    {
      label: t("notes.location"),
      type: "string",
      value: (note: NoteType) => {
        return note.shipper?.name || note.receiver?.name;
      },
    },
  ];

  return (
    <LynksTable
      fields={noteTableFields}
      data={notes}
      showEmptyStateImage={false}
      onEdit={!readonly ? onEdit : undefined}
      onDelete={!readonly ? onDelete : undefined}
    />
  );
}
