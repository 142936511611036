import i18next from "i18next";
import { BillingRuleConditionOperator } from "../../../graphql/generated";

const billingRuleConditionOperatorLabel = (
  operator: BillingRuleConditionOperator
) => {
  return i18next.t(`billingRules:conditionOperator.${operator}`) as string;
};

export default billingRuleConditionOperatorLabel;
